export const sizeGuides = {
  label: 'Size Guides',
  name: 'sizeGuides',
  component: 'group-list',
  itemProps: (item) => {
    return {
      label: item?.name ? `${item.name} [${item?.tag}]` : 'New Size Guide',
    };
  },
  defaultItem: () => ({
    name: 'Bag',
    tag: 'bag',
  }),
  fields: [
    {
      label: 'Tag',
      name: 'tag',
      component: 'text',
      description: 'Must match a size-guide::tag',
    },
    {
      label: 'name',
      name: 'name',
      component: 'text',
    },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
      parse: (img) => img,
    },
  ],
};
