import { section } from '@settings/common';

export function Schema() {
  return {
    label: 'Parallax',
    key: 'parallax',
    fields: [
      {
        label: 'Image - Desktop',
        name: 'image',
        component: 'image',
      },
      {
        label: 'Image - Mobile',
        name: 'imageMob',
        component: 'image',
      },
      {
        label: 'Background Size Type',
        name: 'backgroundSizeType',
        component: 'select',
        options: [
          {
            label: 'Pixels (px)',
            value: 'px',
          },
          {
            label: 'Percentage (%)',
            value: '%',
          },
          {
            label: 'Cover',
            value: 'cover',
          },
        ],
        defaultValue: 'cover',
      },
      {
        label: 'Background Size Value - Desktop',
        name: 'backgroundSizeValue',
        description: 'Only needed if type is pixel or percentage',
        component: 'number',
        defaultValue: 100,
      },
      {
        label: 'Background Size Value - Mobile',
        name: 'backgroundSizeValueMob',
        description: 'Only needed if type is pixel or percentage',
        component: 'number',
        defaultValue: 100,
      },
      {
        label: 'Background Position',
        name: 'backgroundPosition',
        component: 'select',
        options: [
          {
            label: 'Top',
            value: 'top',
          },
          {
            label: 'Right',
            value: 'right',
          },
          {
            label: 'Bottom',
            value: 'bottom',
          },
          {
            label: 'Left',
            value: 'left',
          },
          {
            label: 'Center',
            value: 'center',
          },
        ],
        defaultValue: 'right',
      },
      section,
    ],
  };
}
