import { useCallback, useMemo } from 'react';
import { Paragraph, Flex, Box } from 'theme-ui';
import { useProductInventoryByHandle } from '@backpackjs/storefront';
import { useModal } from '@hooks';
import { BackInStockModal } from '@templates/product/Main/Details/AddToCart';
import { themed } from './QuickAddOptions.theme';
import { QuickAddOption } from './QuickAddOption';

export const QuickAddOptions = themed(
  ({
    theme,
    selectedVariant,
    fullProduct,
    isKitItem,
    isQuickAddActive,
    isHovered,
    styles,
  }) => {
    // NOTE for h2 migration. No reason to fetch live inventory again if already fetched server-side
    const { inventory, success: inventoryFetched } =
      useProductInventoryByHandle({
        handle: fullProduct?.handle,
        withInventory: false,
      });
    const [, { openModal }] = useModal();

    const option = useMemo(() => {
      if (!fullProduct) return { name: '', values: [], text: '' };
      const options = fullProduct.grouping?.isTransformed
        ? fullProduct.grouping.options
        : fullProduct.options;
      const _option = options.find(({ name }) => name !== 'Color');
      return {
        name: _option?.name,
        values: _option?.values || [],
      };
    }, [fullProduct?.id]);

    const handleOutOfStock = useCallback(() => {
      openModal(
        <BackInStockModal
          legacyResourceId={selectedVariant?.legacyResourceId}
          selectedOptionsMap={selectedVariant?.selectedOptionsMap}
          title={fullProduct.title || ''}
        />,
        true
      );
    }, [selectedVariant?.id, fullProduct.title]);

    const isNotifyMe =
      inventory?.totalInventory <= 0 && !inventory?.availableForSale;

    return (
      <Flex
        data-comp={QuickAddOptions.displayName}
        sx={{
          ...theme.container,
          opacity: [isQuickAddActive ? 0.8 : 0, isHovered ? 0.8 : 0],
          display: [isQuickAddActive ? 'flex' : 'none', 'flex'],
          ...styles,
        }}
      >
        <Box
          sx={{
            ...theme.textWrapper,
            display: isKitItem ? 'flex' : ['none', 'flex'],
            cursor: isNotifyMe ? 'pointer' : 'default',
          }}
          onClick={() => {
            if (isNotifyMe) {
              handleOutOfStock();
            }
          }}
        >
          <Paragraph
            variant="text.sm"
            sx={{
              ...theme.firstText,
              display: isKitItem ? 'inline-block' : ['none', 'inline-block'],
              borderBottom: isKitItem ? null : '1px solid',
              borderColor: isKitItem ? null : 'darkGray',
            }}
          >
            {isNotifyMe ? 'Notify Me' : 'Add To Cart'}
          </Paragraph>
        </Box>

        {!isNotifyMe && (
          <Flex
            sx={{
              ...theme.sizeContainer,
              opacity: isKitItem ? 0 : [1, 0],
              ':hover': { opacity: 1 },
            }}
          >
            {option.values.map((value) => {
              return (
                <QuickAddOption
                  optionName={option.name}
                  inventory={inventory}
                  inventoryFetched={inventoryFetched}
                  fullProduct={fullProduct}
                  selectedVariant={selectedVariant}
                  value={value}
                  isQuickAddActive={isQuickAddActive}
                />
              );
            })}
          </Flex>
        )}
      </Flex>
    );
  }
);

QuickAddOptions.displayName = 'QuickAddOptions';
