import { Picture } from '@snippets';

import { hexToRgbA } from '@utils/common';

import { themed } from './Slide.theme';

export const Image = themed(({ theme, slide }) => {
  const { media } = slide;

  const image = media?.image;

  const overlayActive = media?.image?.imageOverlay;
  const overlayOpacity = media?.image?.overlayOpacity;
  const overlayColor = media?.image?.overlayColor;

  return (
    <Picture
      data-comp={Image.displayName}
      alt={image?.alt}
      aboveTheFold
      images={[
        {
          src: image?.imageMob?.src,
          width: 768,
          ratio: 1.2,
        },
        {
          src: image?.image?.src,
          width: 1024,
          ratio: 1.2,
        },
        {
          src: image?.image?.src,
          width: 1280,
          ratio: 1.2,
        },
        {
          src: image?.image?.src,
          width: 1440,
          ratio: 1.2,
        },
      ]}
      sx={{
        ...theme.image,
        ':after': {
          content: "''",
          position: 'absolute',
          background: overlayActive
            ? hexToRgbA(overlayColor, overlayOpacity / 100)
            : 'transparent',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      }}
      imageSx={{
        objectPosition: [image?.positionMob, image?.position],
      }}
    />
  );
});

Image.displayName = 'Image';
