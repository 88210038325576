import store, { useRecoilState, useRecoilValue } from '@store';
import { useProduct } from '@backpackjs/storefront';

export const useSelectedPlan = (
  { handle: passedHandle } = { handle: null }
) => {
  const product = useProduct();
  const handle = passedHandle || product?.handle || null;
  if (!handle) {
    return [null, () => {}];
  }
  const selectedVariant = useRecoilValue(store.selectedVariant());
  return useRecoilState(store.selectedPlan(selectedVariant?.id));
};
