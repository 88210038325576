import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      // px: 10,
      variant: 'flex.column',
    };
  })()
);
