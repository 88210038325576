import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.slide = (enableHover) => ({
      position: 'relative',
      ':hover': {
        img: {
          transform: enableHover ? 'scale(1.05)' : 'scale(1)',
        },
      },
    });

    this.image = {
      img: {
        transition: '0.3s ease-in-out transform',
      },
    };

    this.content = (tileContentPosition) => ({
      position: tileContentPosition === 'below' ? 'static' : 'absolute',
      transform: 'translateZ(1px)',
      bottom: 0,
      left: 0,
      right: 0,
      p: 8,
      color: tileContentPosition === 'below' ? 'black' : 'white',
      background:
        tileContentPosition === 'below'
          ? null
          : 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 19, 52, 0.50) 100%)',
    });

    this.heading = {
      fontsize: 8,
      fontWeight: 700,
      textTransform: 'uppercase',
    };

    this.description = {
      '*': {
        fontSize: 3,
        lineHeight: 1,
      },
      p: {
        p: 0,
        mt: 4,
      },
    };
  })()
);
