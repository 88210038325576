import { store } from '@backpackjs/storefront';

const getJunipBottomLine$ = store.recoil.selectorFamily({
  key: 'junip/stars',
  get:
    (legacyResourceId) =>
    async ({ get }) => {
      if (!legacyResourceId)
        throw new Error('Junip stars legacyResourceId not provided');

      const test = await junipApiClient('getProductBottomline')({
        legacyResourceId,
      });

      return await junipApiClient('getProductBottomline')({
        legacyResourceId,
      });
    },
});

/**
 * Helpers
 */
const junipApiClient = (action) => async (data) => {
  try {
    const request = await fetch('/api/junip', {
      method: 'POST',
      body: JSON.stringify({ action, ...data }),
    });

    if (!request.ok) {
      throw new Error(`Junip ${action} request not ok`);
    }

    const response = await request.json();

    return response.data;
  } catch (error) {
    console.error(action, error.message);
    throw error;
  }
};

export default {
  // atom;

  // selectors
  getJunipBottomLine$,
};
