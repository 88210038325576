import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.heading = {
      fontSize: '36px',
      fontWeight: '700',
      textAlign: 'center',
      mb: 11,
    };

    this.tile = {
      width: ['100%', 'calc(50% - 20px)', 'calc(33.33% - 20px)'],
      maxWidth: '360px',
      border: '1px solid rgba(0, 0, 0, 0)',
      mb: [12, null, 0],
      '&:last-child': {
        mb: 0,
      },
    };

    this.tileContainer = {
      height: '100%',
      width: '100%',
    };

    this.tileHeader = {
      textAlign: 'center',
      width: '100%',
      p: '33px',
    };

    this.tileHeading = {
      fontSize: 8,
      fontWeight: 700,
    };

    this.tileSubheading = {
      fontSize: 6,
      fontWeight: 700,
    };

    this.tileBenefits = {
      flex: '0 0 auto',
      height: '100%',
      p: '33px',
      li: {
        mb: 8,
        '&:last-child': {
          mb: 0,
        },
      },
    };
  })()
);
