import { useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { Flex, Heading, Paragraph, Button, Box } from 'theme-ui';
import { useModal } from '@hooks';

import { Link, Markdown } from '@snippets';

import { themed } from './RotatingBar.theme';

export const RotatingBar = themed(({ theme, inputRef }) => {
  const settings = useSettings();
  const { rotatingBar, promoBar } = { ...settings?.header };
  const { background, enabled, messages } = {
    ...rotatingBar,
  };

  const [, { openModal }] = useModal();

  const [isHovered, setIsHovered] = useState(false);

  if (!enabled || messages?.length < 1 || promoBar?.enabled) return null;

  const onOpenModal = ({ heading, body } = {}) => {
    openModal(
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          textAlign: 'center',
          py: '25px',
          minHeight: '400px',
        }}
      >
        <Heading as="h2" variant="text.h2">
          {heading}
        </Heading>
        <Markdown text={body} />
      </Box>,
      {
        container: {
          bg: 'white !important',
        },
        close: {
          top: ['10px'],
          right: ['10px'],
        },
      }
    );
  };

  return (
    <Flex
      variant="flex.row.center"
      data-comp={RotatingBar.displayName}
      sx={{
        ...theme.wrapper({ background }),
        zIndex: 32,
      }}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      ref={inputRef}
    >
      {[...Array(4).keys()].map((_, index) => {
        return (
          <Flex
            key={index}
            sx={{
              ...theme.messageTextWrapper,
              animationPlayState: isHovered ? 'paused' : 'running',
            }}
          >
            {messages?.map((message, index) => {
              const { copy, link, color, modal } = message;
              if (!copy) return null;
              return (
                <Flex key={index}>
                  {modal?.enabled ? (
                    <Button
                      onClick={() => onOpenModal(modal)}
                      variant="buttons.plain"
                      sx={theme.text(color)}
                    >
                      {message.copy}
                    </Button>
                  ) : (
                    <Link href={link?.url}>
                      <Paragraph sx={theme.text(color)}>
                        {message.copy}
                      </Paragraph>
                    </Link>
                  )}
                </Flex>
              );
            })}
          </Flex>
        );
      })}
    </Flex>
  );
});

RotatingBar.displayName = 'RotatingBar';
