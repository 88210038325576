import { useState, useCallback } from 'react';
import { Container, Box, Heading, Paragraph, Input } from 'theme-ui';
import {
  useCustomer,
  useSettings,
  useStartFinishErrors,
} from '@backpackjs/storefront';
import startCase from 'lodash.startcase';
import { StatefulButton } from '@snippets';
import { useModal, useDataLayerActions, useSelectedOptions } from '@hooks';

export function BackInStockModal({
  legacyResourceId,
  title,
  selectedOptionsMap,
}) {
  const settings = useSettings();
  const customer = useCustomer();
  const { closeModal } = useModal()[1];
  const [email, setEmail] = useState(customer?.email || '');
  const {
    heading = 'Notify Me When Available',
    description,
    modalButtonText = 'Submit',
  } = { ...settings?.product?.backInStock };
  const { setStarted, setFinished, reset, ...status } = useStartFinishErrors();
  const { sendSubscribeEvent } = useDataLayerActions();

  const handleSubscribe = useCallback(
    async (e) => {
      e.preventDefault();
      if (!email || !legacyResourceId) return;

      reset();
      setStarted(true);

      const endpoint = '/api/klaviyo';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'backInStockSubscribe',
          email,
          variant: legacyResourceId,
        }),
      };

      const response = await fetch(endpoint, options);
      const data = await response.json();

      if (!data) {
        throw new Error('No data returned from subscribe');
      }

      setFinished(true);
      sendSubscribeEvent({ email });

      setTimeout(() => {
        closeModal();
      }, 2000);
    },
    [email, legacyResourceId]
  );

  return (
    <Box
      data-comp={BackInStockModal.displayName}
      sx={{
        bg: 'white',
        width: '100%',
      }}
    >
      <Container
        sx={{
          bg: 'white',
          width: '100%',
          minWidth: '300px',
          maxWidth: '500px',
          '@media only screen and (max-width: 479px)': {
            minWidth: '250px',
          },
          textAlign: 'center',
          p: 10,
        }}
      >
        <Heading as="h2">{heading}</Heading>
        {description && (
          <Paragraph variant="sm" mt={6}>
            {description}
          </Paragraph>
        )}
        <Box
          sx={{
            my: 10,
          }}
        >
          <Heading as="h3" variant="text.h5">
            {title}
          </Heading>
          <Box>
            {Object.entries({ ...selectedOptionsMap }).map(
              ([key, value], index) => {
                return (
                  <Paragraph key={key + index} variant="text.h5">
                    {startCase(key)}: {startCase(value)}
                  </Paragraph>
                );
              }
            )}
          </Box>
        </Box>
        <Box as="form" onSubmit={handleSubscribe}>
          <Input
            name="email"
            placeholder="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            mb={11}
            px={11}
          />
          <StatefulButton
            {...status}
            showErrors={false}
            text={{
              started: '',
              success: 'Email Subscribed!',
              error: 'Please Try Again Later',
              default: modalButtonText,
            }}
            sx={{ width: '100%', minHeight: '52px' }}
          />
        </Box>
      </Container>
    </Box>
  );
}
BackInStockModal.displayName = 'BackInStockModal';
