import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.image = {
      mb: 8,
      bg: '#FAFAFA',
      border: (t) => `1px solid ${t.colors.lightGray}`,
      borderRadius: '6px',
      position: 'relative',
      ':hover button': {
        opacity: 0.8,
      },
    };

    this.spinner = {
      width: '30px',
      color: 'gray',
      m: 'auto',
    };

    this.heading = {
      variant: 'text.h5',
      mb: 4,
    };
    this.description = {
      variant: 'text.sm',
      mb: 4,
      color: 'mediumGray',
    };
    this.link = {
      width: 'fit-content',
      fontSize: '14px',
      fontWeight: 400,
      textTransform: 'none',
    };
  })()
);
