import { Box, Button, Container, Flex, Heading } from 'theme-ui';
import { themed } from './Header.theme';

export const Header = themed(
  ({ activeIndex, content, setActiveIndex, tabs, theme }) => {
    const { heading } = { ...content };

    return (
      <Container sx={theme.header}>
        <Box>
          {heading && (
            <Heading
              as="h2"
              sx={{ ...theme.heading, color: content?.headingColor || 'black' }}
            >
              {heading}
            </Heading>
          )}
        </Box>

        {tabs?.length > 0 && (
          <Box>
            <Flex role="tablist" sx={theme.buttonList}>
              {tabs.map(({ name }, index) => {
                const isActive = index === activeIndex;
                const lastItem = index === tabs.length - 1;
                return (
                  <Box
                    key={index}
                    sx={{
                      flex: '0 0 auto',
                    }}
                  >
                    <Button
                      role="tab"
                      variant="buttons.plain"
                      aria-selected={isActive ? 'true' : 'false'}
                      onClick={() => setActiveIndex(index)}
                      sx={{
                        ...theme.button,
                        color: isActive ? content?.tabColor || 'black' : 'gray',
                        mr: lastItem ? 0 : [5, 10],
                        '&:hover': {
                          color: isActive
                            ? 'black'
                            : content?.tabHoverColor || '#DC96DE',
                        },
                      }}
                    >
                      {name}
                    </Button>
                  </Box>
                );
              })}
            </Flex>
          </Box>
        )}
      </Container>
    );
  }
);

Header.displayName = 'Header';
