export const colors = {
  // General
  black: '#000000',
  text: '#282828',
  white: '#FFFFFF',
  background: '#FFFFFF',

  // Grays
  lightestGray: '#FAFAFA',
  lighterGray: '#E8E8E8',
  lightGray: '#DDDDDD',
  gray: '#ACACAC',
  mediumGray: '#838383',
  mediumDarkGray: '#4A4A4A',
  darkGray: '#282828',

  // Accents
  sand: '#F1EAE1',
  seafoam: '#CBEBEA',
  yellow: '#EAFF74',
};
