import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = {
      mb: 10,
    };

    this.heading = {
      fontSize: ['22px', '28px'],
      fontWeight: 700,
      lineHeight: '1',
      letterSpacing: '0.03em',
      textTransform: 'uppercase',
      mb: 7,
    };

    this.buttonList = {
      overflowY: 'auto',
      position: 'relative',
      paddingBottom: 2,
      '&:before': {
        content: '""',
        width: '100%',
        position: 'absolute',
        borderBottom: '1px solid #E6E6E6',
        bottom: '0px',
      },
    };

    this.button = (isActive) => ({
      fontSize: [5, 6],
      fontWeight: 400,
      lineHeight: 1,
      color: isActive ? 'black' : 'lightGray',
      transition: '0.3s ease-in-out color',
    });
  })()
);
