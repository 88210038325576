import { section } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Instagram Slider',
    key: 'instaSlider',
    fields: [
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
        defaultValue: 'INSTASHOP',
      },
      {
        name: 'description',
        label: 'Description',
        component: 'markdown',
        defaultValue: 'Follow Us @alohacollection On Instagram',
      },
      {
        label: 'Styles HTML',
        description:
          'Add styles code here. Include opening and closing style tags "<style>...</style>". Do not add script tags as this is already added in code "<script>...</script>".',
        name: 'stylesHTML',
        component: 'html',
      },
      section,
    ],
  };
};
