import { layout, margin, padding } from '@settings/common';

const fields = [];

const mobileFields = [
  {
    label: 'Container width (mobile)',
    component: 'select',
    name: 'container',
    options: layout.options,
  },
  {
    name: 'mt',
    label: 'Margin top (mobile)',
    component: 'select',
    options: margin.options,
  },
  {
    name: 'py',
    label: 'Vertical padding (mobile)',
    component: 'select',
    options: padding.options,
  },
];

const mobileDefaultValue = {
  container: layout.value.container,
  mt: 'none',
  py: 'none',
};

const desktopFields = [
  {
    label: 'Container width (desktop)',
    component: 'select',
    name: 'container',
    options: layout.options,
  },
  {
    name: 'mt',
    label: 'Margin top (desktop)',
    component: 'select',
    options: margin.options,
  },
  {
    name: 'py',
    label: 'Vertical padding (desktop)',
    component: 'select',
    options: padding.options,
  },
];

const desktopDefaultValue = {
  container: layout.value.container,
  mt: 'none',
  py: 'none',
};

const mobile = {
  label: 'Mobile settings',
  name: 'mobile',
  component: 'group',
  fields: mobileFields,
  defaultValue: mobileDefaultValue,
};

const desktop = {
  label: 'Desktop settings',
  name: 'desktop',
  component: 'group',
  fields: desktopFields,
  defaultValue: desktopDefaultValue,
};

const section = {
  label: 'Section settings',
  name: 'section',
  description:
    'Background color, container width, margin top, vertical padding',
  component: 'group',
  fields: [...fields, desktop, mobile],
};

export const Schema = () => {
  return {
    label: 'Shortstack Embed',
    key: 'shortstack-embed',
    fields: [
      {
        label: 'Campaign Id',
        name: 'campaignId',
        component: 'text',
        defaultValue: '',
      },
      {
        label: 'Source',
        name: 'src',
        component: 'text',
        defaultValue: '',
      },
      section,
    ],
  };
};
