import { Container, Box } from 'theme-ui';
import dynamic from 'next/dynamic';

import { withInView } from '@utils';
import { Section } from '@snippets';

import { Grid } from './Grid';

import { Schema } from './SuperCollection.schema';
import { themed } from './SuperCollection.theme';

const Header = dynamic(
  () => import('./Header').then((module) => module.Header),
  { ssr: false, suspense: false }
);

const Swiper = dynamic(
  () => import('./Swiper').then((module) => module.Swiper),
  { ssr: false, suspense: false }
);

export const SuperCollection = withInView(
  themed(({ theme, cms, inView }) => {
    const { collection, content, section } = cms;
    const { displayType, gridDisplay, gridDisplayDt } = { ...collection };

    const sectionProps = {
      ...section,
    };

    // forces full bleed for sliders
    if (displayType === 'slider') {
      sectionProps.desktop = {
        ...section?.desktop,
        container: 'container',
      };
      sectionProps.mobile = {
        ...section?.mobile,
        container: 'container',
      };
    }

    return (
      <Section section={sectionProps}>
        <Container
          data-comp={SuperCollection.displayName}
          sx={theme.wrapper(collection?.hideLines)}
        >
          <Box sx={theme.header}>
            {inView && (
              <Header
                content={content}
                collection={collection}
                displayType={displayType}
              />
            )}
          </Box>

          {inView && (
            <Box>
              {displayType === 'grid' ? (
                <Grid
                  collection={collection}
                  content={content}
                  cta={collection?.cta}
                  ctaStyle={content?.ctaStyle}
                  ctaAlign={content?.ctaAlign}
                  ctaAlignMobile={content?.ctaAlignMobile}
                  gridDisplay={gridDisplay}
                  gridDisplayDt={gridDisplayDt}
                />
              ) : (
                <Box sx={theme.slider}>
                  <Swiper
                    products={collection?.products}
                    ctaStyle={content?.ctaStyle}
                    ctaAlign={content?.ctaAlign}
                    ctaAlignMobile={content?.ctaAlignMobile}
                    cta={collection?.cta}
                    displayQuickAdd
                  />
                </Box>
              )}
            </Box>
          )}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

SuperCollection.displayName = 'SuperCollection';
SuperCollection.Schema = Schema;
