import { Container, Heading, Paragraph } from 'theme-ui';
import store, { useRecoilValue } from '@store';
import { WishlistProduct } from './WishlistProduct';
import { themed } from './Wishlist.theme';

export const WishlistPage = themed(({ theme }) => {
  const wishlist = useRecoilValue(store.wishlist);

  return (
    <Container data-comp="WishlistPage" sx={theme.wrapper}>
      <Heading as="h1" sx={theme.heading}>
        Wishlist
      </Heading>
      <Container sx={theme.grid}>
        {wishlist?.length ? (
          [...wishlist].reverse().map(({ epi, du }, index) => {
            const handle = du.split('/').pop()?.split('?variant')[0];
            return (
              <WishlistProduct
                key={epi}
                variant={epi}
                handle={handle}
                index={index}
              />
            );
          })
        ) : (
          <Paragraph>Your wishlist is empty.</Paragraph>
        )}
      </Container>
    </Container>
  );
});
