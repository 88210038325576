import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.heading = {
      fontSize: [5, 9],
      textAlign: ['center'],
      mb: [0, 12],
    };

    this.links = {
      // maxWidth: 1050,
      mx: 'auto',
      pb: 10,
    };

    this.link = {
      width: ['70px', '110px'],
      position: 'relative',
      '&:hover img': {
        transform: 'scale(1.10)',
      },
      '&:hover h3': {
        color: 'gray',
      },
    };

    this.linkBackground = {
      mx: 'auto',
      width: ['62px', '98px'],
      height: ['62px', '98px'],
      bg: 'lightGray',
      borderRadius: '100%',
    };
    this.linkImageWrapper = {
      mx: 'auto',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      width: ['62px', '98px'],
      height: ['62px', '98px'],
      overflow: 'visible',
    };

    this.linkImage = {
      transition: '0.3s ease-in-out transform',
    };

    this.linkHeading = {
      fontSize: ['10px', 3],
      lineHeight: 1,
      transition: '0.3s ease-in-out color',
      textDecoration: 'none',
      textAlign: 'center',
      mt: [2, 4],
    };
  })()
);
