import { useEffect } from 'react';
import _uniqBy from 'lodash.uniqby';
import store, { useRecoilState } from '@store';
import { useCustomer } from '@backpackjs/storefront';
import { useWishlist } from './useWishlist';

const fetchUserWishlist =
  ({ email, cachedWishlist }) =>
  async () => {

    const endpoint = '/api/swym';
    try {
      const response = await fetch(endpoint, {
        method: 'post',
        body: JSON.stringify({
          action: 'getLists',
          email,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        let wishlist = data?.data?.find((list) => {
          return list.lname === 'wishlist';
        });

        if (!wishlist) {
          const createResponse = await fetch(endpoint, {
            method: 'post',
            body: JSON.stringify({
              action: 'createList',
              lname: 'wishlist',
              email
            }),
          });

          const json = await createResponse.json();
          const { data } = json;
          wishlist = data;
        }

        const { lid } = wishlist;

        const remoteWishlist = wishlist.listcontents
          ? wishlist.listcontents.reduce(
              (itemsObj, item) => {
                itemsObj.items.push({
                  empi: item.empi,
                  epi: item.epi,
                  du: item.du,
                });
                return itemsObj;
              },
              { items: [] }
            )
          : { items: [] };

        // merge local storage plus remote items
        const mergedWishlistItems = cachedWishlist?.items
          ? _uniqBy([...cachedWishlist.items, ...remoteWishlist.items], 'epi')
          : remoteWishlist.items;

        return { items: mergedWishlistItems, lid };
      }
      return { items: cachedWishlist?.items || [] };
    } catch (error) {
      console.error('fetchUserWishlist:', error.message);
      return null;
    }
  };

export const useInitWishlist = () => {
  const { mergeSessions, updateWishlist } = useWishlist();
  const [wishlist, setWishlist] = useRecoilState(store.wishlist);
  const [anonymousWishlist, setAnonymousWishlist] = useRecoilState(
    store.anonymousWishlist
  );
  const [wishlistEmail, setWishlistEmail] = useRecoilState(store.wishlistEmail);
  const [wishlistId, setWishlistId] = useRecoilState(store.wishlistId);
  const customer = useCustomer();

  useEffect(() => {
    let getWishlistedProducts;

    // return until customer hook returns null or has fetched customer
    if (customer === undefined) return;

    if (customer === null) {
      setWishlistId(null);
      setWishlistEmail(null);
      return;
    }

    const _wishlistEmail = customer?.email || wishlistEmail?.email || null;

    const fetchAnonymousWishlist = (list) => () =>
      new Promise((resolve) => {
        resolve(list);
      });

    (async () => {
      await mergeSessions({
        params: {
          email: _wishlistEmail,
        },
      });

      if (_wishlistEmail) {
        // console.warn('fetch wishlist');
        getWishlistedProducts = fetchUserWishlist({
          email: _wishlistEmail,
          cachedWishlist: wishlist,
        });
      } else if (wishlist) {
        // console.warn('use anonymous wishlist');
        getWishlistedProducts = fetchAnonymousWishlist(wishlist);
      } else {
        // console.warm('use empty wishlist');
        getWishlistedProducts = fetchAnonymousWishlist({ items: [] });
      }

      const data = await getWishlistedProducts();

      if (Array.isArray(data?.items)) {
        if (setWishlist) {
          setWishlistEmail(_wishlistEmail);
          setWishlist(data.items);
          setWishlistId(data.lid);

          const parsedAnonymousWishlist = JSON.parse(
            localStorage.getItem('WISHLIST_ANONYMOUS')
          );

          // // add items from anonymous wishlist to swym wishlist
          if (parsedAnonymousWishlist?.length && customer?.email) {
            const uniqueItems = _uniqBy(
              [...wishlist, ...parsedAnonymousWishlist],
              'epi'
            );

            setWishlist(uniqueItems);

            await updateWishlist({
              params: {
                lid: data?.lid,
                email: _wishlistEmail,
                add: uniqueItems,
                remove: [],
              },
            });

            setAnonymousWishlist([]);
          }
        }
      } else {
        console.warn('Invalid getWishlist data', data);
      }
    })();
  }, [customer]);
};
