import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = {
      gridArea: 'menu',
      display: 'grid',
      flex: 1,
      gridTemplateColumns: ['auto', `repeat(3, 1fr)`, `repeat(3, 1fr)`],
      columnGap: 0,
      rowGap: [12, 10],
    };

    this.menuItem = {
      pr: 4,
    };

    this.name = {
      mb: 10,
      fontSize: 1,
    };

    this.list = {
      display: 'grid',
      gridGap: 5,
      pl: 0,
    };

    this.link = {
      variant: 'text.sm',
      lineHeight: '20px',
      position: 'relative',
      letterSpacing: '0.08em',
      ':after': {
        content: '""',
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        maxWidth: 0,
        height: '1px',
        bg: 'currentColor',
        transition: 'max-width 0.2s ease-in-out',
      },
      ':hover:after': {
        maxWidth: '100%',
      },
    };
  })()
);
