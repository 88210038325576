import store, { useRecoilValueLoadable } from '@store';

export const useJunipStars = ({ legacyResourceId }) => {
  const { state, contents } = useRecoilValueLoadable(
    store.getJunipBottomLine$(legacyResourceId)
  );

  return {
    started: state === 'loading',
    finished: state === 'hasValue',
    errors: state === 'hasError' ? [contents.message] : [],
    success: state === 'hasValue',

    // state
    stars: state === 'hasValue' ? contents : null,
  };
};
