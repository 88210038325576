import { useEffect, useCallback } from 'react';

import { getValidId } from '@utils';
import { useCustomer } from '@backpackjs/storefront';

export const useFriendBuy = () => {
  const customer = useCustomer();

  const getCustomerAuthentication = useCallback(async () => {
    const id = getValidId(customer?.id)?.split('/').pop();
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'getCustomerAuthentication',
        customerId: id,
        customerEmail: customer?.email,
      }),
    };

    const response = await fetch('/api/friendbuy', options);
    const { data } = await response.json();
    const { authString, signature } = { ...data };

    if (authString && signature) {
      window.friendbuyAPI.push(['auth', authString, signature]);
    }
  }, [customer?.email]);

  useEffect(() => {
    if (customer && typeof window.friendbuyAPI !== 'undefined') {
      const id = getValidId(customer?.id)?.split('/').pop();
      window.friendbuyAPI.push([
        'track',
        'customer',
        {
          id,
          email: customer.email,
          firstName: customer.firstName,
          lastName: customer.lastName,
        },
      ]);
      getCustomerAuthentication();
    }
  }, [customer?.email]);
};
