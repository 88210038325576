import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      mr: 'auto',
      width: '100%',
      justifyContent: 'space-between',
      columnGap: 10,
      maxHeight: '48px',
    };

    this.quantityButton = {
      variant: 'buttons.outline',
      minWidth: '48px',
      fontWeight: 400,
      fontSize: 25,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      p: 0,
      border: (t) => `1px solid ${t.colors.lightGray}`,
      ':disabled': {
        bg: 'transparent',
        borderColor: 'lightGray',
        cursor: 'no-drop',
      },
    };

    this.svg = {
      width: '18px',
    };

    this.quantityWrapper = {
      alignItems: 'center',
    };

    this.quantity = {
      minWidth: 56,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 20,
      lineHeight: 28,
    };

    // todo: nested variants should really be button variants that modify primary
    // so that they can be used through a project and not just here
    this.button = {
      variant: 'primary',
      '@media only screen and (min-width: 480px)': {
        '&:hover': {
          bg: 'white',
        },
      },
      borderRadius: '3px',
      flexGrow: 1,
      p: 0,
      minWidth: 'auto',
      outline: 'none',
      whiteSpace: ['nowrap', 'initial'],
      position: 'relative',

      available: {
        cursor: 'pointer',

        oneTime: {
          bg: 'black',
        },

        subscribe: {
          border: (t) => `1px solid ${t.colors.seafoam}`,
          bg: 'seafoam',
          '@media only screen and (min-width: 480px)': {
            '&:hover': {
              color: 'seafoam',
            },
          },
        },

        kit: {
          bg: 'white',
          color: 'black',
          '@media only screen and (min-width: 480px)': {
            '&:hover': {
              bg: 'black',
              color: 'white',
            },
          },
        },
      },

      unavailable: {
        cursor: 'default',
        oneTime: {
          bg: 'gray',
          border: (t) => `1px solid ${t.colors.gray}`,
          '@media only screen and (min-width: 480px)': {
            '&:hover': {
              bg: 'gray',
              color: 'background',
              border: (t) => `1px solid ${t.colors.gray}`,
            },
          },
        },

        subscribe: {
          bg: 'gray',
          border: (t) => `1px solid ${t.colors.seafoam}`,
          '@media only screen and (min-width: 480px)': {
            '&:hover': {
              bg: 'gray',
              color: 'background',
            },
          },
        },
      },
    };
  })()
);
