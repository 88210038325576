export default {
  label: 'No Index Page',
  name: 'noIndex',
  component: 'group',
  fields: [
    {
      label: 'Handles',
      name: 'handles',
      component: 'group-list',
      description: 'No-Index/No-Follow handle list.',
      itemProps: {
        label: '{{item.handle}}',
      },
      fields: [
        {
          label: 'Handle',
          name: 'handle',
          description: 'The handle of the page you want to no-index/no-follow.',
          component: 'text',
        },
      ],
    },
  ],
};
