import { useEffect } from 'react';
import { Box } from 'theme-ui';
import PropTypes from 'prop-types';
import {
  useCart,
  useCartItems,
  useCartUpdateNote,
} from '@backpackjs/storefront';

import { LineItem } from '../LineItem';
import { themed } from './LineItems.theme';

export const LineItems = themed(
  ({ theme: themes, inSidebar = false, ...rawProps }) => {
    const { inputRef, ...props } = rawProps;
    const cart = useCart();
    const cartItems = useCartItems({ sortByAddedAt: true });
    const { cartUpdateNote } = useCartUpdateNote();

    useEffect(() => {
      if (!cart) return;
      const totalKitItems = cart.lines.reduce((acc, item) => {
        if (item.attributes?.some((attr) => attr.key?.startsWith('kit_item'))) {
          return acc + item.quantity;
        }
        return acc;
      }, 0);
      if (totalKitItems > 0) {
        cartUpdateNote({ note: `kit_item: ${totalKitItems}` });
      } else if (cart.note?.length) {
        cartUpdateNote({ note: ' ' });
      }
    }, [cart?.updatedAt]);

    const theme = inSidebar ? themes.sidebar : themes.page;

    return (
      <Box
        key={LineItems.displayName}
        data-comp={LineItems.displayName}
        {...props}
        sx={{
          ...props.sx,
          ...theme,
        }}
      >
        {cartItems.map((item, index) => (
          <LineItem
            key={item.id}
            item={item}
            isLast={index === cartItems.length - 1}
            inSidebar={inSidebar}
          />
        ))}
      </Box>
    );
  }
);

LineItems.displayName = 'LineItems';
LineItems.propTypes = {
  inSidebar: PropTypes.bool,
};
