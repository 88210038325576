import { useMemo, Children, cloneElement } from 'react';
import { Box } from 'theme-ui';

import { useSidebar } from '@hooks';
import store, { useRecoilValue } from '@store';
import { useSearch } from '@snippets/Search/hooks';

import { themed } from './Footer.theme';

export const Footer = themed(
  ({ theme, setHeight, open, children, inputRef, ...props }) => {
    const [{ isSearch }] = useSidebar();
    const [{ totalResults }] = useSearch();
    const upsellProductsNotInCart = useRecoilValue(
      store.upsellProductsNotInCart
    );
    const showUpsell = !!upsellProductsNotInCart.length;
    const showSearchFooter = isSearch && !!totalResults;

    const childrenWithRefs = useMemo(
      () =>
        Children.map(children, (child) =>
          cloneElement(child, {
            ref: (node) => {
              if (node) {
                const cartTotalsHeight = node?.getBoundingClientRect()?.height;
                setHeight(cartTotalsHeight);
              }
            },
          })
        ),
      [Children.count(children), open, showUpsell, showSearchFooter]
    );

    return (
      <Box
        data-comp={Footer.displayName}
        {...props}
        ref={inputRef}
        sx={theme.footer}
      >
        {childrenWithRefs}
      </Box>
    );
  }
);

Footer.displayName = 'Footer';
