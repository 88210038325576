import { useCallback } from 'react';

export const useTrackClickIntelliSuggest = (product) => {
  const intellisuggestTrackClick = useCallback(() => {
    if (!product.intellisuggestData) return;
    if (document.images) {
      const apiUrl = `${window.location.protocol || 'http:'}//${
        process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID
      }.a.searchspring.io/api/`;

      const imgTag = new Image();
      imgTag.src = `${apiUrl}track/track.json?d=${
        product.intellisuggestData
      }&s=${product.intellisuggestSignature}&u=${encodeURIComponent(
        `${process.env.SITE_URL}/products/${product.handle}`
      )}&r=${encodeURIComponent(document.referrer)}`;
    }
  }, [product]);

  return { intellisuggestTrackClick };
};
