import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = (section, contentMaxWidth) => ({
      py: [
        section?.mobile?.py === 'none' ? 14 : 0,
        section?.desktop?.py === 'none' ? 18 : 0,
      ],
      maxWidth: contentMaxWidth || 535,
    });

    this.superHeading = {
      variant: 'text.h6',
      mb: [4],
    };

    this.heading = (enableUppercaseHeading) => ({
      variant: 'text.h2',
      mb: [8],
      textTransform: enableUppercaseHeading ? 'uppercase' : 'normal',
      letterSpacing: enableUppercaseHeading ? '0.08em' : '0',
    });

    this.body = {
      variant: 'text.base',
    };
  })()
);
