import { color, section } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Two Tile',
    key: 'two-tile',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Display Type (Mobile)',
        direction: 'horizontal',
        variant: 'radio',
        name: 'displayTypeMobile',
        component: 'radio-group',
        options: [
          {
            value: 'slider',
            label: 'Slider',
          },
          {
            value: 'stacked',
            label: 'Stacked',
          },
        ],
        defaultValue: 'slider',
      },
      {
        name: 'tiles',
        label: 'Tiles',
        description: 'Max of 2 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        validate: {
          maxItems: 2,
        },
        defaultItem: {
          heading: 'New Tile',
          description: '',
          alt: 'Tile image',
          media: {
            image: {
              alt: 'Tile image',
              imageDt: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_7_ab44ee2e-190d-4525-b8d4-39f1f79dead9.png?v=1653036799',
              },
              imageMob: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_512f8212-6775-44dd-a6ca-9ebd8cffade5.png?v=1652881847',
              },
            },
            video: {
              titleMb: 'Tile video',
              videoMb:
                'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
              titleDt: 'Tile video',
              videoDt:
                'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
            },
          },
          cta: {
            text: '',
            url: '',
          },
        },
        fields: [
          {
            label: 'Media settings',
            name: 'media',
            description: 'Select Media Type (image - video)',
            component: 'group',
            fields: [
              {
                label: 'Image Settings',
                name: 'image',
                description: 'Image, image position',
                component: 'group',
                fields: [
                  {
                    name: 'alt',
                    component: 'text',
                    description: 'Brief description of image',
                    label: 'Alt',
                  },
                  {
                    name: 'imageDt',
                    label: 'Image (desktop)',
                    component: 'image',
                  },
                  {
                    name: 'imageMob',
                    label: 'Image (mobile)',
                    component: 'image',
                  },
                ],
                defaultValue: {
                  alt: 'Tile image',
                  imageDt: {
                    src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_7_ab44ee2e-190d-4525-b8d4-39f1f79dead9.png?v=1653036799',
                  },
                  imageMob: {
                    src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_512f8212-6775-44dd-a6ca-9ebd8cffade5.png?v=1652881847',
                  },
                },
              },
              {
                label: 'Video settings',
                name: 'video',
                description: 'video, video position',
                component: 'group',
                fields: [
                  {
                    label: 'Title (mobile)',
                    name: 'titleMb',
                    component: 'text',
                    description: 'Title of the video (mobile)',
                  },
                  {
                    name: 'videoMb',
                    description: 'Video url must end with .mp4',
                    label: 'video url (mobile)',
                    component: 'text',
                  },
                  {
                    label: 'Title (desktop)',
                    name: 'titleDt',
                    component: 'text',
                    description: 'Title of the video (desktop)',
                  },
                  {
                    name: 'videoDt',
                    description:
                      'Video url must end with .mp4 \n For better results on desktop use minimum resolution of 1280px',
                    label: 'video url (desktop)',
                    component: 'text',
                  },
                ],
                defaultValue: {
                  titleMb: 'Tile video',
                  videoMb:
                    'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
                  titleDt: 'Tile video',
                  videoDt:
                    'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
                },
              },
              {
                label: 'Select Media Type (mobile)',
                description: 'Allows select between image or video for mobile',
                name: 'mediaTypeMb',
                component: 'radio-group',
                options: [
                  { label: 'Image', value: 'image' },
                  { label: 'Video', value: 'video' },
                ],
                defaultValue: 'image',
              },
              {
                label: 'Select Media Type (desktop)',
                description: 'Allows select between image or video for desktop',
                name: 'mediaTypeDt',
                component: 'radio-group',
                direction: 'horizontal',
                options: [
                  { label: 'Image', value: 'image' },
                  { label: 'Video', value: 'video' },
                ],
                defaultValue: 'image',
              },
            ],
          },
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            name: 'description',
            label: 'Description',
            component: 'markdown',
          },
          {
            label: 'Link',
            name: 'cta',
            component: 'link',
          },
        ],
        defaultValue: [
          {
            heading: 'New Releases',
            description: '',
            cta: {
              text: 'Bundles',
              url: '/collections/shop-all',
            },
            alt: 'Tile image',
            media: {
              image: {
                alt: 'Tile image',
                imageDt: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_7_ab44ee2e-190d-4525-b8d4-39f1f79dead9.png?v=1653036799',
                },
                imageMob: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_512f8212-6775-44dd-a6ca-9ebd8cffade5.png?v=1652881847',
                },
              },
              video: {
                titleMb: 'Tile video',
                videoMb:
                  'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
                titleDt: 'Tile video',
                videoDt:
                  'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
              },
              mediaTypeMb: 'image',
              mediaTypeDt: 'image',
            },
          },
          {
            heading: 'Best Sellers',
            description: '',
            alt: 'Tile image',
            media: {
              image: {
                alt: 'Tile image',
                imageDt: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_8.png?v=1652881937',
                },
                imageMob: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_8.png?v=1652881937',
                },
              },
              video: {
                titleMb: 'Tile video',
                videoMb:
                  'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
                titleDt: 'Tile video',
                videoDt:
                  'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
              },
              mediaTypeMb: 'image',
              mediaTypeDt: 'image',
            },
            cta: {
              text: 'Shop Now',
              url: '/collections/shop-all',
            },
          },
        ],
      },
      {
        label: 'Image Settings',
        name: 'image',
        component: 'group',
        fields: [
          {
            label: 'Aspect Ratio',
            component: 'select',
            name: 'ratio',
            options: [
              {
                value: '6/4',
                label: '6:4',
              },
              {
                value: '5/4',
                label: '5:4',
              },
              {
                value: '1/1',
                label: '1:1',
              },
              {
                value: '4/5',
                label: '4:5',
              },
              {
                value: '3/4',
                label: '3:4',
              },
              {
                value: '2/3',
                label: '2:3',
              },
              {
                value: '3/5',
                label: '3:5',
              },
              {
                value: '1/2',
                label: '1:2',
              },
            ],
            defaultValue: '3/4',
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.black,
          desktop: {
            container: 'content',
            mt: 'none',
            py: 's',
          },
          mobile: {
            container: 'content',
            mt: 'none',
            py: 's',
          },
        },
      },
    ],
  };
};
