import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.sidebar = {
      price: {
        variant: 'flex.row.between.center',
        mb: 4,

        title: {
          variant: 'text.label',
        },

        amount: {
          variant: 'text.sm',
        },

        totalAmount: {
          variant: 'text.sm',
          textDecoration: 'line-through',
          ml: 2,
        },
      },
    };
    // cart empty — rendered in /cart
    this.page = {
      price: {
        ...this.sidebar.price,

        title: {
          variant: 'text.sm',
        },

        amount: {
          variant: 'text.sm',
        },

        totalAmount: {
          variant: 'text.sm',
          textDecoration: 'line-through',
          ml: 2,
        },
      },
    };
  })()
);
