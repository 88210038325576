import { useEffect, useRef, useState } from 'react';
import { Box, Button, Container, Flex, Heading } from 'theme-ui';
import { themed } from './Header.theme';

export const Header = themed(
  ({ activeIndex, content, heading, setActiveIndex, tabs, theme, section }) => {
    const ref = useRef();

    const [activeTopNavWidth, setActiveTopNavWidth] = useState(0);
    const [activeTopNavOffsetLeft, setActiveTopNavOffsetLeft] = useState(0);

    useEffect(() => {
      function handleResize() {
        const activeNavItemButton = ref.current?.querySelector(
          `[aria-selected="true"]`
        );

        if (activeNavItemButton) {
          let { offsetWidth: width, offsetLeft } = activeNavItemButton;
          const { paddingLeft, paddingRight } =
            getComputedStyle(activeNavItemButton);

          width = width - parseFloat(paddingLeft) - parseFloat(paddingRight);
          offsetLeft += parseFloat(paddingLeft);
          setActiveTopNavWidth(width);
          setActiveTopNavOffsetLeft(offsetLeft);
        } else {
          setActiveTopNavWidth(0);
        }
      }
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [activeIndex]);

    return (
      <Container
        sx={{
          ...theme.header,
          px: [section?.mobile?.container === 'container' ? 10 : 0, 0, 0],
        }}
      >
        <Box>
          {heading && (
            <Heading
              as="h2"
              sx={{ ...theme.heading, color: content?.headingColor || 'black' }}
            >
              {heading}
            </Heading>
          )}
        </Box>

        {tabs?.length > 0 && (
          <Box>
            <Flex
              role="tablist"
              ref={ref}
              sx={{
                ...theme.buttonList,
                position: 'relative',
                ':after': {
                  borderRadius: 1,
                  content: '""',
                  position: 'absolute',
                  top: 'calc(100% - 2px)',
                  left: 0,
                  transform: `translateX(${
                    activeTopNavOffsetLeft
                      ? `${activeTopNavOffsetLeft}px`
                      : null
                  })`,
                  width: activeTopNavWidth, // should by dynamic
                  height: '2px',
                  bg: 'text',
                  transition:
                    'transform 0.3s ease-in-out, width 0.3s ease-in-out',
                },
              }}
            >
              {tabs.map(({ link, tabName }, index) => {
                const isActive = index === activeIndex;
                const lastItem = index === tabs.length - 1;
                return (
                  <Box
                    key={index}
                    sx={{
                      flex: '0 0 auto',
                    }}
                  >
                    <Button
                      role="tab"
                      variant="buttons.plain"
                      aria-selected={isActive ? 'true' : 'false'}
                      onClick={() => setActiveIndex(index)}
                      sx={{
                        ...theme.button(isActive),
                        mr: lastItem ? 0 : [5, 10],
                      }}
                    >
                      {tabName || link?.text}
                    </Button>
                  </Box>
                );
              })}
            </Flex>
          </Box>
        )}
      </Container>
    );
  }
);

Header.displayName = 'Header';
