import { useCallback, useEffect, useState } from 'react';

const fetchHandleById = async (id) => {
  if (!id) return null;
  const endpoint = `/json/product-id-to-handle.json`;
  const response = await fetch(endpoint);
  const map = await response.json();
  const handle = map[id];
  if (!handle) {
    throw new Error(
      `fetchHandleById:error: No product handle found for id: ${id}`
    );
  }
  return handle;
};

const fetchProductFromHandle = async (handle) => {
  if (!handle) return null;
  const endpoint = `/json/products/${handle}.json`;
  const response = await fetch(endpoint);
  const product = await response.json();
  if (!product) {
    throw new Error(
      `fetchProductFromHandle:error: No product found for handle: ${handle}`
    );
  }
  return product;
};

export const useProductById = ({ id }) => {
  const [product, setProduct] = useState(null);

  const fetchProductById = useCallback(async (productId) => {
    try {
      const handle = await fetchHandleById(productId);
      const fetchedProduct = await fetchProductFromHandle(handle);
      setProduct(fetchedProduct);
    } catch (error) {
      console.error(error.message);
      setProduct(null);
    }
  }, []);

  useEffect(() => {
    fetchProductById(id);
  }, [id]);

  return product;
};
