import { button, section } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Video block',
    key: 'videoBlock',
    fields: [
      {
        label: 'Video settings',
        name: 'video',
        description: 'Video, video position',
        component: 'group',
        fields: [
          {
            label: 'Title',
            name: 'title',
            component: 'text',
            description: 'Title of the video',
          },
          {
            name: 'src',
            description: 'Video url must end with .mp4 unless Vimeo link',
            label: 'Video URL',
            component: 'text',
          },
          {
            name: 'videoDtHeight',
            description: 'Set the height of the video',
            label: 'Video height (Desktop)',
            component: 'number',
            defaultValue: 650,
          },
          {
            name: 'videoMobHeight',
            description: 'Set the height of the video',
            label: 'Video height (Mobile)',
            component: 'number',
            defaultValue: 280,
          },
          {
            name: 'coverDtPhoto',
            label: 'Cover photo (Desktop)',
            component: 'image',
          },
          {
            label: 'Alt cover photo (Desktop)',
            name: 'altDtCoverPhoto',
            component: 'text',
            description: 'Brief description of cover photo',
          },
          {
            name: 'coverMobPhoto',
            label: 'Cover photo (Mobile)',
            component: 'image',
          },
          {
            label: 'Alt cover photo (Mobile)',
            name: 'altMobCoverPhoto',
            component: 'text',
            description: 'Brief description of cover photo',
          },
        ],
        defaultValue: {
          title: 'Video',
          src: 'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
          coverDtPhoto: {
            src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/Screen_Shot_2022-03-27_at_11.53_1.png?v=1652965943',
          },
          coverMobPhoto: {
            src: 'https://cdn.shopify.com/s/files/1/1119/6322/products/rvs_flora_arvo_front-op_350x_crop_center@2x.progressive.jpg?v=1643325989',
          },
          altDtCoverPhoto: 'Cover photo',
          altMobCoverPhoto: 'Cover photo',
          videoDtHeight: 650,
          videoMobHeight: 280,
        },
      },
      {
        label: 'CTA text',
        name: 'ctaText',
        component: 'text',
        defaultValue: 'Watch now',
      },
      {
        name: 'ctaStyle',
        label: 'CTA Style',
        component: 'select',
        defaultValue: 'primary',
        options: button.options,
      },
      section,
    ],
  };
};
