import { Link } from '@snippets';

export function Button({ link, ctaStyle, buttonStylesOverride }) {
  const {
    color,
    colorHover,
    backgroundColor,
    backgroundColorHover,
    borderColor,
    borderColorHover,
  } = { ...buttonStylesOverride };

  return (
    <Link
      href={link?.url}
      variant={`buttons.${ctaStyle || 'primary'}`}
      sx={{
        display: 'inline-block',
        ...(color && { color }),
        ...(backgroundColor && { backgroundColor }),
        ...(borderColor && { borderColor }),
        '&:hover': {
          ...(colorHover && { color: colorHover }),
          ...(backgroundColorHover && {
            backgroundColor: backgroundColorHover,
          }),
          ...(borderColorHover && { borderColor: borderColorHover }),
        },
        '@media only screen and (max-width: 768px)': {
          ...(color && { color }),
          ...(backgroundColor && { backgroundColor }),
          ...(borderColor && { borderColor }),
          '&:hover': {
            ...(colorHover && { color: colorHover }),
            ...(backgroundColorHover && {
              backgroundColor: backgroundColorHover,
            }),
            ...(borderColorHover && { borderColor: borderColorHover }),
          },
        },
      }}
    >
      {link?.text}
    </Link>
  );
}

Button.displayName = 'Button';
