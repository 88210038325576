import { useMemo } from 'react';
import { Alert, Box, Button, Flex, Field, Paragraph, Input } from 'theme-ui';
import {
  useCustomerRecoverPassword,
  useCartEmail,
} from '@backpackjs/storefront';
import { BaseInput } from '@snippets';

export const RecoverPasswordForm = ({ setAction, settings }) => {
  const cartEmail = useCartEmail();
  const {
    recoverPassword,
    started,
    finished,
    errors,
    success,
    reset,
    resetTimer,
  } = useCustomerRecoverPassword();

  const buttonText = useMemo(() => {
    if (finished) {
      resetTimer.current = setTimeout(() => {
        reset && reset();
        clearTimeout(resetTimer.current);
      }, 2000);
    }

    return finished
      ? success
        ? 'Email sent'
        : 'Failed'
      : // not finished yet
      started
        ? 'Sending email...'
        : settings?.account?.forgot?.buttonText || 'Reset Password';
  }, [started, success, finished]);

  const recoverPasswordHandler = async (event) => {
    event.preventDefault();
    const { email } = event.target;
    const emailSent = await recoverPassword({ email: email.value });
    console.log('recover password email sent', emailSent);
  };

  return (
    <Flex
      data-comp="RecoverPasswordForm"
      variant="forms.account.form"
      sx={{
        height: '100%',
      }}
    >
      <Box>
        {/* Heading */}
        {settings?.account?.forgot?.heading && (
          <Paragraph
            variant="text.h5"
            sx={{
              textAlign: 'center',
            }}
          >
            {settings?.account?.forgot?.heading}
          </Paragraph>
        )}

        {/* Sub heading */}
        {settings?.account?.forgot?.subHeading && (
          <Paragraph
            variant="text.base"
            sx={{
              textAlign: 'center',
              mt: 8,
            }}
          >
            {settings?.account?.forgot?.subHeading}
          </Paragraph>
        )}
      </Box>

      <Flex
        variant="flex.column"
        as="form"
        onSubmit={recoverPasswordHandler}
        sx={{
          mt: 11,
          mb: 12,
          justifyContent: 'center',
          minWidth: ['initial', '315px'],
        }}
      >
        <BaseInput
          variant="account.input"
          name="email"
          label="Email*"
          type="email"
          defaultValue={cartEmail}
          required
          as={Input}
        />

        {errors?.length
          ? errors.map((err) => (
            <Alert key={err} variant="alerts.error" mb={6}>
              {err}
            </Alert>
          ))
          : null}

        <Button
          variant="primary"
          disabled={started || (finished && success)}
          sx={{
            mt: 9,
            cursor: started || (finished && success) ? 'default' : 'pointer',
            mx: 'auto',
          }}
        >
          {buttonText}
        </Button>

        <Paragraph
          variant="text.14"
          onClick={() => setAction('login')}
          sx={{
            mt: 10,
            width: 'fit-content',
            cursor: 'pointer',
            textAlign: 'center',
            mx: 'auto',
            '::after': {
              content: '""',
              display: 'block',
              height: '1px',
              backgroundColor: 'black',
              width: '100%',
              transition: 'width .25s ease-in-out',
            },
            ':hover::after': {
              width: 0,
            },
          }}
        >
          {settings?.account?.forgot?.return || 'Back to login'}
        </Paragraph>
      </Flex>
    </Flex>
  );
};
