import { useCallback, useState } from 'react';
import { Button, Paragraph, Flex, Spinner } from 'theme-ui';
import { useModal, useAddToCart } from '@hooks';
import { BackInStockModal } from '@templates/product/Main/Details/AddToCart';
import { themed } from './QuickAdd.theme';

export const QuickAdd = themed(
  ({
    theme,
    isSoldOut,
    fullProduct,
    selectedVariant,
    isQuickAddActive,
    isHovered,
  }) => {
    const [isAdding, setIsAdding] = useState(false);

    const [, { openModal }] = useModal();
    const handleOutOfStock = useCallback(() => {
      openModal(
        <BackInStockModal
          legacyResourceId={selectedVariant?.legacyResourceId}
          selectedOptionsMap={selectedVariant?.selectedOptionsMap}
          title={selectedVariant?.product?.title}
        />
      );
    }, [selectedVariant?.id]);

    const isNonReplenish =
      selectedVariant?.product?.tags?.includes('non-replen');

    const isForcedPreOrder =
      selectedVariant?.product?.tags?.includes('allow-preorder');

    const [, { addToCart }] = useAddToCart({
      product: fullProduct,
      selectedVariant,
    });

    const buttonText = () => {
      if (isSoldOut) {
        if (isNonReplenish) return 'Sold Out';
        return 'Notify Me';
      }
      return isForcedPreOrder ? 'Pre-Order' : 'Add To Cart';
    };

    const handleAddToCart = useCallback(async () => {
      if (isAdding) return;
      setIsAdding(true);

      await addToCart();

      setIsAdding(false);
    }, [isSoldOut, isAdding, addToCart]);

    return (
      <Button
        data-comp={QuickAdd.displayName}
        sx={{
          ...theme.button,
          '&::after': {
            height: isSoldOut && isNonReplenish ? '0px' : '1px',
          },
          '& p': {
            borderBottom: isSoldOut && isNonReplenish ? 'none' : '1px solid',
          },
          cursor: isSoldOut && isNonReplenish ? 'not-allowed' : 'pointer',
          opacity: [
            isQuickAddActive || isSoldOut ? 0.8 : 0,
            isHovered || isSoldOut ? 0.8 : 0,
          ],
          display: [isQuickAddActive || isSoldOut ? 'flex' : 'none', 'flex'],
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (!isSoldOut) {
            handleAddToCart();
          }

          if (isSoldOut && !isNonReplenish) {
            handleOutOfStock();
          }
        }}
      >
        {isAdding ? (
          <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Spinner width="20" height="20" />
          </Flex>
        ) : (
          <Paragraph sx={theme.buttonText}>{buttonText()}</Paragraph>
        )}
      </Button>
    );
  }
);

QuickAdd.displayName = 'QuickAdd';
