import Script from 'next/script';

export function FueledGrinScript() {
  return (
    <Script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          (function() { Grin = window.Grin || (window.Grin = []); var s = document.createElement('script');

          s.type = 'text/javascript';

          s.async = true;

          s.src = 'https://d38xvr37kwwhcm.cloudfront.net/js/grin-sdk.js'; var x = document.getElementsByTagName('script')[0];

          x.parentNode.insertBefore(s, x); })();
        `,
      }}
    />
  );
}
