import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.button = {
      minWidth: 'unset',
      p: 0,
      m: 0,
      bg: 'transparent',
      border: 0,
      borderRadius: 0,
      color: 'darkGray',
      display: 'flex',
      alignItems: 'center',
      ':hover': {
        bg: 'transparent',
      },
    };

    this.icon = {
      width: 24,
      height: 24,
      ':hover': {
        filter:
          'invert(12%) sepia(2%) saturate(0%) hue-rotate(142deg) brightness(89%) contrast(88%)',
      },
    };
  })()
);
