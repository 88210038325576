import { useEffect, useRef, createRef, useState, useCallback } from 'react';
import {
  Container,
  Box,
  Flex,
  Grid,
  Button,
  Paragraph,
  Heading,
  Input,
} from 'theme-ui';
import axios from 'axios';
import Head from 'next/head';
import { useCustomer } from '@backpackjs/storefront';

import { Markdown, Section } from '@snippets';
import { convertAlignToFlex, isBrowser, withInView, getValidId } from '@utils';

import { FormField } from './FormField';
import { useForm } from './useForm';
import { themed } from './FormBuilder.theme';
import { Schema } from './FormBuilder.schema';

async function validateUniqSubmission({ email, formId, formApiToken }) {
  if (!email || !formId || !formApiToken)
    return { success: false, error: 'error' };

  const endpoint = `https://api.getform.io/v1/forms/${formId}?token=${formApiToken}&query=${email}`;

  const response = await fetch(endpoint);
  const responseJson = await response.json();

  if (responseJson.success) {
    const submissions = responseJson?.data?.submissions;
    return { success: true, unique: submissions?.length === 0 };
  }

  return { success: false, error: 'error' };
}

export const FormBuilder = withInView(
  themed(({ theme, cms }) => {
    const customer = useCustomer();
    const captchaRef = useRef(null);

    const {
      alignment,
      alignmentMob,
      duplicateSubmissionErrorMessage,
      endpoint,
      formId,
      formApiToken,
      heading,
      fields,
      section,
      submitText,
    } = cms;
    const { parsedFields } = useForm({ fields });

    const textAlign = [alignmentMob, alignment];

    const formRef = useRef(
      parsedFields.reduce((carry, ref) => {
        if (ref?._template === 'label') {
          return carry;
        }
        if (ref?.options?.length > 1 && ref?._template !== 'select') {
          const optionRefs = {};
          ref.options.forEach((value) => {
            optionRefs[`${ref.name}.${value}`] = createRef();
          });
          return {
            ...carry,
            ...optionRefs,
          };
        }
        return {
          ...carry,
          [ref.name]: createRef(),
        };
      }, {})
    );

    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [captchaLoaded, setCaptchaLoaded] = useState(false);

    const renderCaptcha = isBrowser && window?.grecaptcha?.render;
    const captchaReady = typeof renderCaptcha === 'function';
    const recaptchaEnabled =
      cms.recaptchaEnabled && !!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

    const handleSubmit = useCallback(
      async (e) => {
        e.preventDefault();
        try {
          setErrors([]);
          setLoading(true);
          if (!recaptchaEnabled || !captchaLoaded) return;
          // Check if captcha is verified if captcha was originally rendered
          const captchaResponse = await window.grecaptcha?.getResponse();
          if (!captchaResponse) {
            setErrors(['Please verify that you are not a robot']);
            setLoading(false);
            return;
          }

          const formData = new FormData();

          let emailValue = null;

          Object.keys(formRef.current).forEach((keyName) => {
            const ref = formRef.current[keyName]?.current;
            let value = ref?.value;
            if (
              ref?.getAttribute('type') === 'checkbox' ||
              ref?.getAttribute('type') === 'radio'
            ) {
              const checked = ref?.checked;
              value = checked;
              if (!checked) {
                return;
              }
            }

            if (ref?.getAttribute('type') === 'email') {
              emailValue = value;
            }

            if (ref?.getAttribute('type') === 'file') {
              const files = ref?.files;
              Object.keys(files).forEach((key) => {
                const file = files[key];
                formData.append(`${keyName}[]`, file);
              });
              return;
            }

            formData.append(keyName, value);
          });

          if (customer?.id) {
            formData.append('customerId', getValidId(customer.id));
          }

          const response = await validateUniqSubmission({
            email: emailValue,
            formId,
            formApiToken,
          });

          if (response?.success && !response?.unique) {
            setErrors([duplicateSubmissionErrorMessage]);
            setLoading(false);
            return;
          }

          axios
            .post(endpoint, formData, {
              'content-type': 'multipart/form-data',
            })
            .then((data) => {
              if (data.status === 200) {
                setFormSubmitted(true);
                setErrors([]);
                setLoading(false);

                const formBuilderData = {
                  event: 'getform_submission',
                  formType: heading,
                  formData: {},
                };

                for (const entry of formData.entries()) {
                  formBuilderData.formData[entry[0]] = entry[1];
                }

                window.dataLayer.push(formBuilderData);
              }
            });
        } catch (error) {
          console.error(error.message);
        }
      },
      [recaptchaEnabled, captchaLoaded, customer]
    );

    // Render captcha if recaptcha is enabled and captcha is ready
    // Must add NEXT_PUBLIC_RECAPTCHA_SITE_KEY to env variables
    // See: https://developers.google.com/recaptcha/intro
    useEffect(() => {
      try {
        if (!captchaReady || !recaptchaEnabled) return;
        renderCaptcha('form-captcha-widget', {
          sitekey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
        });
      } catch (error) {
        console.error(error);
      }
    }, [captchaReady, recaptchaEnabled]);

    // Observe when captcha is loaded to permit form submission
    useEffect(() => {
      if (!captchaRef.current || !recaptchaEnabled) return;
      const observer = new MutationObserver(() => {
        setCaptchaLoaded(true);
        observer.disconnect();
      });
      observer.observe(captchaRef.current, {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
      });
      return () => observer.disconnect();
    }, [recaptchaEnabled]);

    return (
      <Section section={section} cms={cms}>
        <Head>
          <script src="https://www.google.com/recaptcha/api.js" />
        </Head>

        <Container data-comp={FormBuilder.displayName}>
          {heading && (
            <Heading sx={{ ...theme.heading, textAlign }}>{heading}</Heading>
          )}

          {formSubmitted ? (
            <Box sx={theme.successMessageBox}>
              <Markdown
                text={cms?.formSuccessMessage || 'Submission sent!'}
                sx={theme.successMessage}
              />
            </Box>
          ) : (
            <Grid
              as="form"
              sx={theme.form}
              action={endpoint}
              method="POST"
              encType="multipart/form-data"
              onSubmit={handleSubmit}
            >
              <Input
                data-comp="Honeypot"
                name="_honeypot"
                sx={{
                  display: 'none !important',
                }}
                type="hidden"
              />

              {parsedFields?.map((field, index) => (
                <FormField field={field} index={index} ref={formRef} />
              ))}

              <Flex
                sx={{
                  ...theme.submitWrapper,
                  alignItems: [
                    convertAlignToFlex(alignmentMob),
                    convertAlignToFlex(alignment),
                  ],
                }}
              >
                {recaptchaEnabled && (
                  <Flex
                    ref={captchaRef}
                    sx={{
                      ...theme.recaptcha,
                      justifyContent: [
                        convertAlignToFlex(alignmentMob),
                        convertAlignToFlex(alignment),
                      ],
                    }}
                  >
                    <div id="form-captcha-widget" />
                  </Flex>
                )}

                <Button
                  type="text"
                  sx={{
                    ...theme.submit,
                    opacity: !endpoint || loading ? 0.2 : 1,
                  }}
                  disabled={!endpoint || loading}
                >
                  {submitText || 'Submit'}
                </Button>

                <Flex sx={theme.errors}>
                  {errors?.map((error) => (
                    <Markdown key={error} text={error} sx={theme.error} />
                  ))}
                </Flex>
              </Flex>
            </Grid>
          )}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

FormBuilder.displayName = 'FormBuilder';
FormBuilder.Schema = Schema;
