export const Schema = () => {
  return {
    label: 'Product badge',
    key: 'productBadge',
    fields: [
      {
        label: 'Badge text',
        name: 'badge',
        component: 'text',
        defaultValue: 'Favorite',
      },
    ],
  };
};
