import { useEffect, useState } from 'react';
import { Box, Heading } from 'theme-ui';
import { Markdown } from '@snippets/Markdown';

import { Role } from './Role';
import { themed } from './RoleList.theme';

export const RoleList = themed(({ theme, cms }) => {
  const { heading, defaultheading, defaultcopy } = cms;
  const [currjobs, setJobBoard] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        'https://boards-api.greenhouse.io/v1/boards/alohacollection/departments?render_as=tree'
      );
      const roleBoard = await response.json();

      if (roleBoard) {
        setJobBoard(roleBoard.departments);
      }
    })();
  }, []);

  const positions =
    currjobs?.filter(
      (d) => d.jobs.length || d.children?.filter((c) => c?.jobs.length).length
    ) || [];

  return (
    <Box data-comp={RoleList.displayName}>
      {positions?.length ? (
        <>
          <Heading sx={theme.heading}>{heading}</Heading>
          {positions.map((dept) =>
            dept.jobs.map((job, index) => (
              <Role index={index} dept={dept} theme={theme} {...job} />
            ))
          )}
        </>
      ) : (
        <>
          <Heading sx={theme.heading}>{defaultheading}</Heading>
          <Markdown>{defaultcopy}</Markdown>
        </>
      )}
    </Box>
  );
});

RoleList.displayName = 'RoleList';
