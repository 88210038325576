import { store } from '@backpackjs/storefront';
import { updateLocalStorage } from './effects';

// const wishlistInit = store.recoil.atom({
//   key: 'wishlist/wishlistInit',
//   default: false,
// });

const wishlist = store.recoil.atom({
  key: 'wishlist/wishlist',
  default: [],
  effects_UNSTABLE: [updateLocalStorage('WISHLIST')],
});

const anonymousWishlist = store.recoil.atom({
  key: 'wishlist/anonymousWishlist',
  default: [],
  effects_UNSTABLE: [updateLocalStorage('WISHLIST_ANONYMOUS')],
});

const wishlistEmail = store.recoil.atom({
  key: 'wishlist/wishlistEmail',
  default: null,
  effects_UNSTABLE: [updateLocalStorage('WISHLIST_EMAIL')],
});

const wishlistId = store.recoil.atom({
  key: 'wishlist/wishlistId',
  default: null,
  effects_UNSTABLE: [updateLocalStorage('WISHLIST_ID')],
});

const variantIsWishlisted = store.recoil.selectorFamily({
  key: 'wishlist/variantIsWishlisted',
  get:
    (wishlistVariant) =>
    ({ get }) => {
      if (!wishlistVariant) return false;

      const _wishlist = get(wishlist);

      const _selectedResourceId =
        parseInt(wishlistVariant.legacyResourceId, 10) || null;

      const variantInWishList = ({ epi }) => epi === _selectedResourceId;
      const _variantIsWishlisted =
        _wishlist?.length && _selectedResourceId
          ? _wishlist.find(variantInWishList)
          : false;

      return _variantIsWishlisted;
    },
});

export default {
  // wishlistInit,
  wishlist,
  anonymousWishlist,
  wishlistEmail,
  wishlistId,
  variantIsWishlisted,
};
