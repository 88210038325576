import { useMemo } from 'react';
import { Button, Paragraph, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import {
  useCart,
  useCartCheckout,
  useCustomer,
  useCartCount,
  useSettings,
} from '@backpackjs/storefront';

import { Ctas } from './Ctas';
import { Prices } from './Prices';
import { themed } from './Totals.theme';

export const Totals = themed(
  ({ theme: themes, inSidebar = false, inputRef, ...props }) => {
    const cart = useCart();
    const cartCount = useCartCount();
    const customer = useCustomer();
    const settings = useSettings();
    const { cartCheckout } = useCartCheckout();
    const {
      shippingText,
      cartThreshold,
      addOnThreshold = 20,
      disclaimerText,
      enableDisclaimerOnSidebar,
      enableDisclaimerOnPage,
    } = { ...settings?.cart };

    const threshold = useMemo(() => {
      const { estimatedCost } = cart || {};
      const subtotalAmount = estimatedCost?.subtotalAmount?.amount || 0;

      const isThresholdExempt = customer?.tags?.find(
        (tag) => tag === 'aloha-staff'
      );

      const enableAddOnThreshold = cart?.lines?.some((line) => {
        const tags = line?.variant?.product?.tags;
        return tags?.some((tag) => tag === 'add-on');
      });

      if (!cartThreshold || !estimatedCost || isThresholdExempt) return false;

      const thresholdValue = enableAddOnThreshold
        ? addOnThreshold
        : cartThreshold;

      const diff = thresholdValue - subtotalAmount;
      return subtotalAmount >= thresholdValue
        ? false
        : diff % 1 != 0
        ? diff.toFixed(2)
        : diff;
    }, [cartThreshold, addOnThreshold, cart]);

    const theme = inSidebar ? themes.sidebar : themes.page;
    const buttonText = cartCount > 0 ? 'Checkout' : 'Shop Now';

    return cartCount > 0 ? (
      <Flex
        data-comp={Totals.displayName}
        {...props}
        ref={inputRef}
        sx={{
          ...props.sx,
          ...theme.wrapper,
        }}
      >
        <Paragraph sx={theme.summary}>Order Summary</Paragraph>

        <Prices cart={cart} inSidebar={inSidebar} />

        <Paragraph
          sx={{
            ...theme.shipping,
            display: inSidebar ? 'block' : 'none',
            mb: enableDisclaimerOnSidebar ? 0 : 3,
          }}
        >
          {shippingText || 'Shipping calculated at checkout'}
        </Paragraph>

        {threshold ? (
          <Paragraph sx={theme.threshold}>
            {`Add $${threshold} more to your cart to continue!`}
          </Paragraph>
        ) : null}
        {!inSidebar && enableDisclaimerOnPage && (
          <Paragraph
            sx={{
              variant: 'text.sm',
              mt: 4,
            }}
          >
            {disclaimerText}
          </Paragraph>
        )}
        {inSidebar && enableDisclaimerOnSidebar && (
          <Paragraph
            sx={{
              variant: 'text.sm',
              mt: 4,
            }}
          >
            {disclaimerText}
          </Paragraph>
        )}
        {/* Checkout button */}
        <Button
          onClick={cartCheckout}
          disabled={!!threshold}
          sx={theme.checkout}
        >
          {buttonText}
        </Button>

        {/* View cart, Clear Cat  */}
        {/* <Ctas inSidebar={inSidebar} /> */}
      </Flex>
    ) : null;
  }
);

Totals.displayName = 'Totals';
Totals.propTypes = {
  inSidebar: PropTypes.bool,
};
