import { Container, Paragraph } from 'theme-ui';

import dynamic from 'next/dynamic';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './Brands.schema';
import { themed } from './Brands.theme';

const BrandsGrid = dynamic(
  () => import('./BrandsGrid').then((module) => module.BrandsGrid),
  { ssr: false, suspense: false }
);

export const Brands = withInView(
  themed(({ theme, cms, inView }) => {
    const { section, content } = cms;

    return (
      <Section section={section} cms={cms}>
        <Container data-comp={Brands.displayName} sx={theme.wrapper}>
          {inView && content?.title && (
            <Paragraph sx={theme.title}>{content.title}</Paragraph>
          )}
          {inView && !!content?.brandItems?.length && (
            <BrandsGrid brands={content?.brandItems} />
          )}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

Brands.displayName = 'Brands';
Brands.Schema = Schema;
