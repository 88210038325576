import { align } from '@settings/common';

export const detailContentBlock = {
  label: 'Detail Content Block',
  name: 'detailContentBlock',
  component: 'group',
  fields: [
    {
      label: 'Enable',
      name: 'enabled',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Text Settings',
      name: 'text',
      component: 'group',
      description: 'Heading, subheading, body, link, color',
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Subheading',
          name: 'subheading',
          component: 'text',
        },
        {
          label: 'Body',
          name: 'body',
          component: 'markdown',
        },
        {
          label: 'Link',
          name: 'link',
          component: 'link',
          description: 'Appears on top right of header',
        },
        {
          label: 'Color',
          name: 'color',
          component: 'color',
        },
      ],
    },
    {
      label: 'Content Settings',
      name: 'content',
      description: 'Content alignment',
      component: 'group',
      fields: [
        {
          label: 'Content Alignment (desktop)',
          name: 'alignment',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          options: align.options,
        },
        {
          label: 'Content Alignment (mobile)',
          name: 'alignmentMob',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          options: align.options,
        },
      ],
    },
    {
      label: 'Background Settings',
      name: 'background',
      component: 'group',
      description: 'Background color, image, overlay color, overlay opacity',
      fields: [
        {
          label: 'Background Color',
          name: 'bgColor',
          component: 'color',
        },
        {
          label: 'Image Alt',
          name: 'alt',
          component: 'text',
          description: 'Brief description of image',
        },
        {
          label: 'Image',
          name: 'image',
          component: 'image',
          description: 'Overrides background color',
        },
        {
          label: 'Overlay Color',
          name: 'overlayColor',
          component: 'color',
          defaultValue: '#000000',
        },
        {
          label: 'Overlay Opacity',
          name: 'overlayOpacity',
          component: 'number',
          defaultValue: 30,
        },
      ],
    },
    {
      label: 'Section Settings',
      name: 'section',
      component: 'group',
      description: 'height',
      fields: [
        {
          label: 'Height (desktop)',
          name: 'heightDt',
          component: 'number',
        },
        {
          label: 'Height (mobile)',
          name: 'heightMb',
          component: 'number',
        },
      ],
    },
  ],
};
