import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      width: '100%',
      p: 8,
      borderTop: (t) => `1px solid ${t.colors.lightGray}`,
    };

    this.button = {
      variant: 'buttons.outline',
      mb: 1,
    };
  })()
);
