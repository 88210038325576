import { Flex } from 'theme-ui';
import { themed } from './BaseInput.theme';

export const BaseInput = themed(
  ({
    theme,
    label,
    name,
    required,
    type,
    value,
    minLength,
    onChange,
    containerSx,
    inputRef,
    ...props
  }) => {
    return (
      <Flex
        variant="flex.column"
        sx={{
          ...theme.wrap,
          ...containerSx,
        }}
      >
        <label htmlFor={name} sx={theme.label}>
          {label}
        </label>
        <input
          ref={inputRef}
          name={name}
          required={required}
          onChange={onChange}
          type={type}
          value={value}
          minLength={minLength}
          sx={theme.input}
          {...props}
        />
      </Flex>
    );
  }
);
