import Head from 'next/head';

export function ArticleHead({ article = {} }) {
  const wordCount =
    article?.sections.reduce((acc, section) => {
      const sectionWordCount =
        section?.body?.split(/\s+/).filter((word) => word.length > 0).length ||
        0;
      return acc + sectionWordCount;
    }, 0) || 0;

  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `{
            "@context": "https://schema.org",
            "@type": "Article",
            "publisher": {
              "@type": "Organization",
              "name": ${JSON.stringify(
                process.env.NEXT_PUBLIC_SITE_TITLE || ''
              )},
              "url": ${JSON.stringify(process.env.SITE_URL || '')},
              "logo": ${JSON.stringify(
                process.env.NEXT_PUBLIC_SITE_LOGO || ''
              )},
            },
            "wordCount": ${wordCount},
            "author" {
              "@type": "Organization",
              "name": "ALOHA Collection"
            },
            "datePublished": ${JSON.stringify(article?.publishedAt || '')},
            "dateModified": ${JSON.stringify(article?.createdAt || '')},
            "image": {
              "@type": "ImageObject",
              "height": ${JSON.stringify(article.seo?.image?.height || 800)},
              "width": ${JSON.stringify(article.seo?.image?.width || 1200)},
              "url": ${JSON.stringify(article.seo?.image || '')}
            },
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": ${JSON.stringify(article?.handle || '')},
              "url": ${JSON.stringify(article?.handle || '')},
              "name: ${JSON.stringify(article.seo?.title || '')},
              "breadcrumb" {
                "@type":"BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position" 1,
                    "item": {
                      "@id": ${JSON.stringify(process.env.SITE_URL || '')},
                      "name": "Home"
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position" 2,
                    "item": {
                      "@id": ${JSON.stringify(article?.blog.handle || '')},
                      "name": ${JSON.stringify(article?.blog.title || '')}
                    }
                  },
                  {
                    "@type": "ListItem",
                    "position" 3,
                    "item": {
                      "@id": ${JSON.stringify(article?.handle || '')},
                      "name": ${JSON.stringify(article?.title || '')}
                    }
                  }
                ]
              }
            },
            "isPartOf": {
              "@type": "Website",
              "@id": ${JSON.stringify(process.env.SITE_URL || '')},
              "name": ${JSON.stringify(
                process.env.NEXT_PUBLIC_SITE_TITLE || ''
              )},
              "url": ${JSON.stringify(process.env.SITE_URL || '')},
              "inLanguage": "US"
            },
            "description": ${JSON.stringify(article.seo?.description || '')},
            "headline": ${JSON.stringify(article?.title || '')}
          }`,
        }}
      />
    </Head>
  );
}

ArticleHead.displayName = 'ArticleHead';
