import account from './account';
import analytics from './analytics';
import cart from './cart';
import footer from './footer';
import header from './header';
import product from './product';
import search from './search';
import social from './social';
import collection from './collection';
import noIndex from './noIndex';
import siteWideSale from './sitewide-sale';

export default [
  account,
  analytics,
  cart,
  collection,
  footer,
  header,
  noIndex,
  product,
  search,
  siteWideSale,
  social,
];
