import store, { useRecoilValue } from '@store';
import { useProductInventoryByHandle } from '@backpackjs/storefront';

export const useSelectedOptionIsAvailable = (product) => {
  return (queriedOption) => {
    const variant = useRecoilValue(store.optionIsAvailable$(queriedOption));
    const { inventory, ...inventoryStatus } = useProductInventoryByHandle({
      handle: variant?.product?.handle || 'fallback',
      withQuantity: true,
    });

    // use live inventory data
    const variantInventory = inventory
      ? inventory?.variants?.[variant?.id]
      : null;

    const isDigitalProduct =
      product?.handle === 'aloha-gift-card' ||
      product?.handle === 'maui-donation';

    const isSoldOut =
      !variantInventory ||
      (variantInventory.quantityAvailable <= 0 && !isDigitalProduct);

    return inventoryStatus?.finished ? !isSoldOut : true;
  };
};
