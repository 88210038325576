import { section, color, button } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Super Slider',
    key: 'super-slider',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Trending In',
      },
      {
        label: 'Media Settings',
        name: 'media',
        component: 'group',
        description: 'media placement',
        fields: [
          {
            label: 'Media Placement (desktop)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'location',
            options: [
              {
                value: 'left',
                label: 'Left',
              },
              {
                value: 'right',
                label: 'Right',
              },
            ],
            defaultValue: 'right',
          },
          {
            label: 'Media Placement (mobile)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'locationMob',
            options: [
              {
                value: 'top',
                label: 'Top',
              },
              {
                value: 'bottom',
                label: 'Bottom',
              },
            ],
            defaultValue: 'bottom',
          },
        ],
      },
      {
        label: 'Tabs',
        name: 'tabs',
        component: 'group-list',
        itemProps: {
          label: '{{item.tabName}}',
        },
        fields: [
          {
            label: 'Tab Name',
            name: 'tabName',
            component: 'text',
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Button Style',
            name: 'ctaStyle',
            component: 'select',
            options: button.options,
          },
          {
            label: 'Button Style Overrides',
            name: 'buttonStylesOverride',
            component: 'group',
            fields: [
              {
                label: 'Color',
                name: 'color',
                component: 'color',
              },
              {
                label: 'Background Color',
                name: 'backgroundColor',
                component: 'color',
              },
              {
                label: 'Border Color',
                name: 'borderColor',
                component: 'color',
              },
              {
                label: 'Color - Hover',
                name: 'colorHover',
                component: 'color',
              },
              {
                label: 'Background Color - Hover',
                name: 'backgroundColorHover',
                component: 'color',
              },
              {
                label: 'Border Color - Hover',
                name: 'borderColorHover',
                component: 'color',
              },
            ],
          },
          {
            label: 'Hide Button',
            name: 'hideButton',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
          {
            label: 'Media Settings',
            name: 'media',
            component: 'group',
            description: 'media type, image, video',
            fields: [
              {
                label: 'Select Media Type',
                description: 'Allows select between image or video for mobile',
                name: 'mediaType',
                component: 'radio-group',
                direction: 'horizontal',
                options: [
                  { label: 'Image', value: 'image' },
                  { label: 'Video', value: 'video' },
                ],
                defaultValue: 'image',
              },
              {
                label: 'Image Settings',
                name: 'image',
                description: 'Image, position, placement',
                component: 'group',
                fields: [
                  {
                    label: 'Alt',
                    name: 'alt',
                    component: 'text',
                    description: 'Brief description of image',
                  },
                  {
                    name: 'image',
                    label: 'Image',
                    component: 'image',
                  },
                  {
                    label: 'Image (mobile)',
                    name: 'imageMob',
                    component: 'image',
                  },
                ],
              },
              {
                label: 'Video settings',
                name: 'video',
                description: 'Video, video poster',
                component: 'group',
                fields: [
                  {
                    name: 'video',
                    description:
                      'Video url must end with .mp4 unless Vimeo\nClose then reopen modal to see change',
                    label: 'Video url',
                    component: 'text',
                  },
                  {
                    name: 'videoPoster',
                    label: 'Video poster',
                    component: 'image',
                  },
                ],
                defaultValue: {
                  video:
                    'https://cdn.shopify.com/videos/c/o/v/8bf3cb57950f47c0979068f4ae8aef90.mp4',
                  videoPoster: {
                    src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_39.png?v=1655219656',
                  },
                },
              },
            ],
          },
          {
            name: 'products',
            label: 'Products',
            component: 'group-list',
            itemProps: {
              label: '{{item.product.handle}}',
            },
            defaultItem: {
              product: { handle: 'mini-pouch-hana-hou-watermelon' },
            },
            fields: [
              {
                name: 'product',
                component: 'productSearch',
                label: 'Product',
              },
            ],
            defaultValue: [
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
            ],
          },
        ],
        defaultValue: [
          {
            tabName: 'New Arrivals',
            link: {
              url: '/collections/new-arrivals',
              title: 'New Arrivals',
            },
            media: {
              mediaType: 'image',
              image: {
                alt: 'Tech',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/interactive-media-5.png?v=1712607602',
                },
                imageMob: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/interactive-media-5.png?v=1712607602',
                },
              },
            },
            products: [
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
            ],
          },
          {
            tabName: 'Best Sellers',
            link: {
              url: 'best-sellers',
              title: 'Best Sellers',
            },
            media: {
              mediaType: 'video',
              video: {
                video:
                  'https://cdn.shopify.com/videos/c/o/v/8bf3cb57950f47c0979068f4ae8aef90.mp4',
                videoPoster: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_39.png?v=1655219656',
                },
              },
            },
            products: [
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
            ],
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
