import { section } from '@settings/common';

const answer =
  'Nulla vitae elit libero, a pharetra augue. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Sed posuere consectetur est at lobortis. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec ullamcorper nulla non metus auctor.';

export const Schema = () => {
  return {
    label: 'FAQ',
    key: 'faq',
    fields: [
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Title, FAQ items',
        component: 'group',
        fields: [
          {
            name: 'title',
            label: 'Title',
            description: 'Title above FAQ items',
            component: 'text',
          },
          {
            name: 'alignment',
            label: 'Title Alignment',
            component: 'select',
            options: ['left', 'center', 'right'],
          },
          {
            name: 'faqItems',
            label: 'FAQ Items',
            component: 'group-list',
            itemProps: {
              label: '{{item.question}}',
            },
            defaultItem: {
              question: 'question Tortor Vulputate Nibh Bibendum',
              answer:
                'Nulla vitae elit libero, a pharetra augue. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Nullam id dolor id nibh ultricies vehicula ut id elit. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Sed posuere consectetur est at lobortis. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec ullamcorper nulla non metus auctor.',
            },
            fields: [
              {
                name: 'question',
                label: 'Question',
                component: 'text',
              },
              {
                name: 'answer',
                label: 'Answer',
                component: 'markdown',
              },
            ],
            defaultValue: [
              {
                question: 'question Vestibulum Dapibus Ullamcorper Commodo',
                answer,
              },
              {
                question: 'question Tortor Vulputate Nibh Bibendum',
                answer,
              },
              {
                question: 'question Euismod Egestas Ligula Mattis Dolor',
                answer,
              },
              {
                question: 'question Vestibulum Dapibus Ullamcorper Commodo',
                answer,
              },
              {
                question: 'question Tortor Vulputate Nibh Bibendum',
                answer,
              },
              {
                question: 'question Euismod Egestas Ligula Mattis Dolor',
                answer,
              },
            ],
          },
        ],
        defaultValue: {
          title: 'frequently asked questions',
          faqItems: [
            {
              question: 'question Vestibulum Dapibus Ullamcorper Commodo',
              answer,
            },
            {
              question: 'question Tortor Vulputate Nibh Bibendum',
              answer,
            },
            {
              question: 'question Euismod Egestas Ligula Mattis Dolor',
              answer,
            },
            {
              question: 'question Vestibulum Dapibus Ullamcorper Commodo',
              answer,
            },
            {
              question: 'question Tortor Vulputate Nibh Bibendum',
              answer,
            },
            {
              question: 'question Euismod Egestas Ligula Mattis Dolor',
              answer,
            },
          ],
        },
      },
      section,
    ],
  };
};
