import { useState, useEffect } from 'react';

const isServer = typeof window === 'undefined';

const validItemValue = (str) => {
  try {
    const item = JSON.parse(str);
    return item;
  } catch (error) {
    return str;
  }
};

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => initialValue);

  const initialize = () => {
    if (isServer) {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? validItemValue(item) : initialValue;
    } catch (error) {
      console.error('useLocalStorage:initialize:error', error.message);
      return initialValue;
    }
  };

  useEffect(() => {
    setStoredValue(initialize());
  }, []);

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      if (!isServer) {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      console.error('useLocalStorage:setValue:error', error.message);
    }
  };
  return [storedValue, setValue];
};
