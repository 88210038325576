import Head from 'next/head';

export function PageHead({ page = {} }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `{
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": ${JSON.stringify(process.env.NEXT_PUBLIC_SITE_TITLE || 'Home')},
                  "item": ${JSON.stringify(process.env.SITE_URL || '')}
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": ${JSON.stringify(page.title || '')},
                  "item": ${JSON.stringify(`${process.env.SITE_URL}/pages/${page.handle}`)}
                }
              ]
            }`,
        }}
      />
    </Head>
  );
}

PageHead.displayName = 'PageHead';
