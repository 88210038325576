import { useState, useEffect, useCallback } from 'react';
import { NavigationButton } from './NavigationButton';
import { themed } from './Navigation.theme';

export const Navigation = themed(({ theme, swiper, sliderRef }) => {
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const setStartAndEndOnSwiperChange = useCallback(() => {
    if (!swiper) return;
    setIsStart(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
    swiper.on('slideChange', () => {
      setIsStart(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    });
  }, [swiper]);

  useEffect(() => {
    setStartAndEndOnSwiperChange();
  }, [swiper]);

  return (
    <>
      <NavigationButton
        onClick={() => swiper.slidePrev()}
        src="/svgs/chevron/left.svg#left"
        alt="Previous product"
        swiper={swiper}
        sliderRef={sliderRef}
        inactive={isStart}
      />

      <NavigationButton
        onClick={() => swiper.slideNext()}
        src="/svgs/chevron/right.svg#right"
        alt="Next product"
        swiper={swiper}
        isNext
        sliderRef={sliderRef}
        inactive={isEnd}
      />
    </>
  );
});

Navigation.displayName = 'CartUpsellNavigation';
