import { useCallback, useState } from 'react';
import { Container, Button, Spinner, Box, Flex } from 'theme-ui';
import dynamic from 'next/dynamic';

import { useProduct } from '@backpackjs/storefront';

import { useProductRecommendations } from '@hooks';
import { pxWithFullBleed, convertAlignToFlex } from '@utils/common';
import { withInView } from '@utils';
import { Section, Link } from '@snippets';

import { useProductRecsBeacon } from './useProductRecsBeacon';
import { useVisibleProductRecItems } from './useVisibleProductRecItems';

import { Schema } from './ProductRecs.schema';
import { themed } from './ProductRecs.theme';

const Header = dynamic(
  () => import('./Header').then((module) => module.Header),
  { ssr: false, suspense: false }
);
const Slider = dynamic(
  () => import('./Slider').then((module) => module.Slider),
  { ssr: false, suspense: false }
);

export const ProductRecs = withInView(
  themed(({ theme, cms, inView }) => {
    const { heading, textAlign, limit, ctas, section, tag } = cms;
    const product = useProduct();
    const [swiper, setSwiper] = useState(null);

    const { recommendations, profileData, profileTag } =
      useProductRecommendations({
        tag: tag || 'cross-sell',
        limit,
        productId: product?.legacyResourceId || '',
      });

    const { visibleProducts, newVisibleProduct } = useVisibleProductRecItems({
      profileData,
      swiper,
    });
    const { sendBeaconEventsOnClick } = useProductRecsBeacon({
      profileData,
      profileTag,
      visibleProducts,
      newVisibleProduct,
      inView,
    });

    const handleProfileProductClick = useCallback(
      (event, index) => {
        sendBeaconEventsOnClick(event, profileData?.results?.[index] || null);
      },
      [profileData?.results, profileTag]
    );

    const handleProfileNavigationClick = useCallback(
      (event, index) => {
        sendBeaconEventsOnClick(event, profileData?.results?.[index] || null);
      },
      [profileData?.results, profileTag]
    );

    if (recommendations && !recommendations.length) return null;

    return (
      <Section section={section} cms={cms}>
        <Container data-comp={ProductRecs.displayName} sx={theme.wrapper}>
          <Header heading={heading} textAlign={textAlign} section={section} />

          <Box sx={theme.content}>
            {inView && recommendations?.length > 0 ? (
              <Slider
                products={recommendations}
                swiper={swiper}
                setSwiper={setSwiper}
                onItemClick={handleProfileProductClick}
                onNavigationClick={handleProfileNavigationClick}
              />
            ) : (
              <Box
                sx={{
                  width: '100%',
                  height: '500px',
                }}
              >
                <Spinner sx={theme.spinner} />
              </Box>
            )}
          </Box>

          {ctas?.[0]?.cta?.text && (
            <Flex
              sx={{
                ...pxWithFullBleed(section),
                justifyContent: convertAlignToFlex(ctas[0].align),
              }}
            >
              <Link
                href={ctas[0].cta.url}
                variant={`buttons.${ctas[0].style || 'primary'}`}
                sx={{
                  mt: [10, 12],
                }}
              >
                {ctas[0].cta.text}
              </Link>
            </Flex>
          )}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ProductRecs.displayName = 'ProductRecs';
ProductRecs.Schema = Schema;
