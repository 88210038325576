import { Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import { useCartEmpty } from '@backpackjs/storefront';

import { Empty } from './Empty';
import { LineItems } from './LineItems';

import { themed } from './ItemsOrEmpty.theme';

export const ItemsOrEmpty = themed(
  ({ theme, inSidebar = true, ...rawProps }) => {
    const { inputRef, ...props } = rawProps;
    const cartEmpty = useCartEmpty();

    return (
      <Flex
        data-comp={ItemsOrEmpty.displayName}
        {...props}
        sx={{
          ...props.sx,
          ...theme.wrapper,
        }}
      >
        {cartEmpty ? (
          <Empty inSidebar={inSidebar} />
        ) : (
          <LineItems inSidebar={inSidebar} />
        )}
      </Flex>
    );
  }
);

ItemsOrEmpty.displayName = 'ItemsOrEmpty';
ItemsOrEmpty.propTypes = {
  theme: PropTypes.object,
  inSidebar: PropTypes.bool,
};
