import { Box, Button, Heading, Flex } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { Link } from '@snippets';

import { themed } from './Menu.theme';

export const Menu = themed(({ theme }) => {
  const settings = useSettings();
  const menu = Array.isArray(settings?.footer?.menu)
    ? settings.footer.menu
    : [];

  return (
    <Box data-comp={Menu.displayName} sx={theme.grid}>
      {menu?.map((menuItem, index) => {
        const items = menuItem?.subMenuItems;
        return (
          <Box key={index} sx={theme.menuItem}>
            <Heading as="h6" variant="label" sx={theme.name}>
              {menuItem?.name}
            </Heading>

            {items?.length > 0 && (
              <Box as="ul" sx={theme.list}>
                {items.map((subMenuItem, index) => {
                  const isAccessibility =
                    subMenuItem?.cta?.text === 'Accessibility';

                  return (
                    <Flex as="li" key={index}>
                      {!isAccessibility ? (
                        <Link
                          prefetch={false}
                          href={subMenuItem.cta?.url}
                          sx={theme.link}
                        >
                          {subMenuItem?.cta?.text}
                        </Link>
                      ) : (
                        <Button
                          variant="buttons.plain"
                          id="jc-accessibility-open"
                          sx={theme.link}
                          onClick={() => {
                            const widget = document.getElementById(
                              'accessiblyAppDialog'
                            );

                            if (!widget) {
                              if (window?.Accessibly) window.Accessibly.init();
                              document
                                .getElementById('accessiblyAppDialogButton')
                                ?.click();
                              document.getElementById(
                                'accessiblyAppDialogButton'
                              ).style.display = 'none';
                              return;
                            }

                            const classList = [...widget.classList];
                            const widgetHidden = classList.includes(
                              'accessibly-app-hidden'
                            );

                            if (widgetHidden) {
                              widget.classList.remove('accessibly-app-hidden');
                            }
                          }}
                        >
                          {subMenuItem?.cta?.text}
                        </Button>
                      )}
                    </Flex>
                  );
                })}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
});

Menu.displayName = 'Menu';
