import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrap = {
      fontFamily: 'body',
    };
    this.heading = {
      variant: 'text.h2',
      textTransform: 'uppercase',
      fontWeight: 700,
      mr: 'auto',
      textAlign: 'center',
      mb: 4,
    };

    this.subheading = {
      textAlign: 'center',
    };

    this.swiper = {
      width: '100%',
      position: 'relative',
      cursor: 'grab',
      my: 14,
      overflow: 'hidden',
      '.swiper-container, .swiper-wrapper': {
        width: '100%',
        height: 'auto !important',
        overflow: 'visible',
      },
    };

    this.image = {
      borderRadius: '6px',
    };

    this.swiperBtn = {
      display: ['none', 'flex'],
      width: '56px',
      height: '56px',
      backgroundColor: 'white',
      border: '1px solid',
      borderColor: 'lightGray',
      borderRadius: '50%',
      ':after': {
        color: 'black',
        fontSize: '14px',
      },
    };
    this.swiperPrev = {
      ...this.swiperBtn,
      left: '50px',
      transform: 'translate(-50%, -50%)',
    };
    this.swiperNext = {
      ...this.swiperBtn,
      right: '50px',
      transform: 'translate(50%, -50%)',
    };
    this.name = {
      mt: 9,
    };
    this.bottom = {
      variant: 'flex.row.center',
    };
    this.button = {
      pointerEvents: 'auto',
    };
  })()
);
