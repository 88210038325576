import { Box, Heading, Spinner } from 'theme-ui';
import { Picture, Link } from '@snippets';
import { useDataLayerActions } from '@hooks';
import { themed } from './Slide.theme';

export const Slide = themed(
  ({
    imageRatio,
    overlayColor,
    overlayOpacity,
    activeHoveredIndex,
    setActiveHoveredIndex,
    theme,
    tile,
    index,
  }) => {
    const { sendClickSubnavItemEvent } = useDataLayerActions();
    const { image, link } = tile;
    const { text, url } = { ...link };

    return (
      <Box sx={theme.slide(imageRatio)}>
        <Link
          href={url}
          tabIndex="-1"
          onMouseEnter={() => {
            setActiveHoveredIndex(index);
          }}
          onMouseLeave={() => {
            setActiveHoveredIndex(null);
          }}
          onClick={() => sendClickSubnavItemEvent({ text, url })}
          sx={theme.link}
        >
          <Picture
            alt={text}
            sx={theme.image}
            images={[
              {
                src: image,
                width: 350,
                ratio: 1,
              },
            ]}
          >
            {!image && <Spinner sx={theme.spinner} />}
          </Picture>
        </Link>

        {/* Title */}
        <Box sx={theme.content(overlayColor, overlayOpacity)}>
          <Heading as="h3" sx={theme.heading}>
            {text}
          </Heading>
        </Box>

        <Box
          sx={theme.overlay(
            index !== activeHoveredIndex && activeHoveredIndex !== null
          )}
        />
      </Box>
    );
  }
);
