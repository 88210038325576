import { Container, Box } from 'theme-ui';

import { Section } from '@snippets';

import { themed } from './Parallax.theme';
import { Schema } from './Parallax.schema';

export const Parallax = themed(({ cms, theme }) => {
  const {
    backgroundPosition,
    backgroundSizeType,
    backgroundSizeValue,
    backgroundSizeValueMob,
    image,
    imageMob,
    section,
  } = cms;

  const mobileImageSrc = imageMob?.src || image?.src;
  const desktopImageSrc = image?.src;

  return (
    <Section section={section} cms={cms}>
      <Container data-comp={Parallax.displayName} sx={theme.wrapper}>
        <Box sx={theme.parallax}>
          <Box
            sx={{
              ...theme.image,
              backgroundImage: [
                `url(${
                  mobileImageSrc ||
                  'https://cdn.shopify.com/s/files/1/1119/6322/files/transparent-anchor.png?v=1703201853'
                })`,
                `url(${
                  desktopImageSrc ||
                  'https://cdn.shopify.com/s/files/1/1119/6322/files/transparent-anchor.png?v=1703201853'
                })`,
              ],
              backgroundSize:
                backgroundSizeType !== 'cover'
                  ? [
                      `${backgroundSizeValueMob || 100}${
                        backgroundSizeType || '%'
                      }`,
                      `${backgroundSizeValue || 100}${
                        backgroundSizeType || '%'
                      }`,
                    ]
                  : 'cover',
              backgroundPosition: backgroundPosition || 'right',
            }}
          />
        </Box>
      </Container>
    </Section>
  );
});

Parallax.displayName = 'Parallax';
Parallax.Schema = Schema;
