import { useCallback, useEffect, useRef, useState } from 'react';
import { useCart } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';

import { mapLineItem } from './utils';

const IGNORED_PATHS = ['account', 'cart', 'collections', 'products'];
const LOGGED_OUT_ACCOUNT_PATHS = [
  '/account/login',
  '/account/register',
  '/account/reset',
  '/account/activate',
];

export function useDataLayerCustomer({ baseEcommerce, DEBUG, userProperties }) {
  const asPathRef = useRef(null);
  const cart = useCart();
  const { asPath } = useRouter();

  const [delayForContextIsFinished, setDelayForContextIsFinished] =
    useState(false);
  const [userDataEventTriggered, setUserDataEventTriggered] = useState(false);

  const userDataEvent = useCallback(
    ({ ecommerce, userProperties: _userProperties }) => {
      const event = {
        event: 'dl_user_data',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        ecommerce: {
          ...ecommerce,
          cart_total: cart?.estimatedCost?.totalAmount?.amount || '0.0',
          cart_contents: {
            products: cart?.lines?.map(mapLineItem) || [],
          },
        },
      };

      window.dispatchEvent(
        new CustomEvent('dl_user_data', {
          detail: event,
        })
      );
      if (DEBUG) console.log(`DataLayer:dispatch:${event.event}`, event);
      setUserDataEventTriggered(true);
      setDelayForContextIsFinished(false);
    },
    [cart?.updatedAt]
  );

  // Force delay on user data event
  useEffect(() => {
    setTimeout(() => setDelayForContextIsFinished(true), 1000);
  }, [asPath]);

  // Trigger 'dl_user_data' event on path change after base data is ready,
  // excluding account and paths that trigger event directly before their respective events
  useEffect(() => {
    if (
      !delayForContextIsFinished ||
      !baseEcommerce ||
      !userProperties ||
      IGNORED_PATHS.includes(asPath.split('/')[1]) ||
      asPath.startsWith('/pages/search') ||
      asPath === asPathRef.current
    )
      return undefined;

    userDataEvent({ ecommerce: baseEcommerce, userProperties });

    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [asPath, !!baseEcommerce, delayForContextIsFinished, !!userProperties]);

  // Trigger 'dl_user_data' event on account pages after base data is ready,
  // excluding after login/register events
  useEffect(() => {
    if (
      !delayForContextIsFinished ||
      !baseEcommerce ||
      !userProperties ||
      !asPath.startsWith('/account') ||
      asPath === asPathRef.current
    )
      return undefined;

    const prevPath = sessionStorage.getItem('PREVIOUS_PATH');
    const currentPath = sessionStorage.getItem('CURRENT_PATH');
    const prevPathInlcRefresh = sessionStorage.getItem(
      'PREVIOUS_PATH_INLC_REFRESH'
    );

    // On login or register, prevent event as it triggers directly from the login/register events
    const isFromLoginOrRegisterEvent =
      (prevPath.startsWith('/account/login') ||
        prevPath.startsWith('/account/register')) &&
      currentPath !== prevPathInlcRefresh;
    const isLoggedOutAccountPath = LOGGED_OUT_ACCOUNT_PATHS.some((path) =>
      asPath.startsWith(path)
    );
    if (isFromLoginOrRegisterEvent && !isLoggedOutAccountPath) return undefined;

    // On log out, trigger event with guest visitor type
    const isFromLogoutEvent =
      asPath.startsWith('/account/login') && prevPath.startsWith('/account');
    const prevPathIsLoggedOutAccountPath = LOGGED_OUT_ACCOUNT_PATHS.some(
      (path) => prevPath.startsWith(path)
    );
    if (isFromLogoutEvent && !prevPathIsLoggedOutAccountPath) {
      userDataEvent({
        ecommerce: baseEcommerce,
        userProperties: { visitor_type: 'guest', user_consent: '' },
      });
    } else {
      // Else trigger event for all other instances
      userDataEvent({ ecommerce: baseEcommerce, userProperties });
    }

    asPathRef.current = asPath;
    return () => {
      asPathRef.current = null;
    };
  }, [asPath, !!baseEcommerce, delayForContextIsFinished, !!userProperties]);

  return { userDataEvent, userDataEventTriggered };
}
