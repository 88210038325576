import { layout } from '@theme/theme.layout';

// Converts text align value to flex
const convertAlignToFlex = (alignment) => {
  return alignment
    ? alignment === 'left'
      ? 'flex-start'
      : alignment === 'center'
      ? 'center'
      : 'flex-end'
    : 'center';
};

// Parses flex position value (e.g. "flex-start center") into two values
const parseContentPosition = (position) => {
  return position
    ? position.split(' ').reduce((obj, pos, index) => {
        index === 0 ? (obj.alignItems = pos) : (obj.justifyContent = pos);
        return obj;
      }, {})
    : {};
};

// Adds horizontal padding to a specific element when parent section is full bleed
const pxWithFullBleed = (section) => {
  const isFullBleedMobile = section?.mobile?.container === 'container';
  const isFullBleedDesktop = section?.desktop?.container === 'container';

  return {
    px: [
      isFullBleedMobile ? layout.padded?.px?.[0] || 0 : 0,
      isFullBleedDesktop ? layout.padded?.px?.[1] || 0 : 0,
      isFullBleedDesktop ? layout.padded?.px?.[2] || null : 0,
      isFullBleedDesktop ? layout.padded?.px?.[3] || null : 0,
    ],
  };
};

const calcResponsiveHeight = ({ maxHeight, viewport }) => {
  if (typeof maxHeight !== 'number' || typeof viewport !== 'number') {
    return;
  }
  return `${maxHeight / (viewport / 100)}vw`;
};

/**
 * Function to convert hex color to rgba
 * @param {string} hex  hex color code
 * @param {number} alpha  alpha value
 * @returns
 */
const hexToRgbA = (hex, alpha = 1) => {
  if (!hex) return null;
  let color;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    color = hex.substring(1).split('');
    if (color.length == 3) {
      color = [color[0], color[0], color[1], color[1], color[2], color[2]];
    }
    color = `0x${color.join('')}`;
    return `rgba(${[(color >> 16) & 255, (color >> 8) & 255, color & 255].join(
      ','
    )},${alpha})`;
  }
  throw new Error('Bad Hex');
};

export {
  convertAlignToFlex,
  parseContentPosition,
  pxWithFullBleed,
  calcResponsiveHeight,
  hexToRgbA,
};
