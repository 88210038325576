import { useEffect, useCallback, useState } from 'react';

import store, { useRecoilValue } from '@store';

export function useProductIntelliSuggest({ sku }) {
  const shopperId = useRecoilValue(store.shopperId);

  const [mounted, setMounted] = useState(false);

  const setMountAfterDelayForCustomerId = useCallback(() => {
    setTimeout(() => {
      setMounted(true);
    }, 3000);
  }, []);

  const sendIntelliSuggestEventOnProductView = useCallback(() => {
    try {
      if (!sku || !mounted || !window.IntelliSuggest) return;
      if (window.__brumate_product_sku && window.__brumate_product_sku === sku)
        return;
      window.__brumate_product_sku = sku;
      IntelliSuggest.init({
        siteId: process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID,
        context: `Product/${sku}`,
        seed: [sku],
      });
      if (shopperId) {
        IntelliSuggest.setShopperId(shopperId);
      }
      IntelliSuggest.viewItem({ sku });
    } catch (error) {
      console.error(error.message);
    }
  }, [sku, shopperId, mounted]);

  useEffect(() => {
    setMountAfterDelayForCustomerId();
  }, []);
  useEffect(() => {
    sendIntelliSuggestEventOnProductView();
  }, [sku, mounted]);
}
