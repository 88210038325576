import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = {
      textAlign: 'center',
      mb: [5, 10],
    };

    this.heading = {
      fontSize: ['22px', '28px'],
      lineHeight: ['24px', '38px'],
      mb: 5,
    };

    this.body = {
      '*': {
        fontSize: [1, 5],
        lineHeight: [18, 22],
      },
      p: {
        mt: 0,
      },
    };
  })()
);
