import { useState } from 'react';
import { Container, Box } from 'theme-ui';
import dynamic from 'next/dynamic';

import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './ProductsSlider.schema';
import { themed } from './ProductsSlider.theme';

const Header = dynamic(
  () => import('./Header').then((module) => module.Header),
  { ssr: false, suspense: false }
);
const Slider = dynamic(
  () => import('./Slider').then((module) => module.Slider),
  { ssr: false, suspense: false }
);

export const ProductsSlider = withInView(
  themed(({ theme, cms, inView }) => {
    const { groups, content, section } = cms;
    const [activeIndex, setActiveIndex] = useState(0);

    return (
      <Section section={section} cms={cms}>
        <Container data-comp={ProductsSlider.displayName} sx={theme.wrapper}>
          <Box sx={theme.header}>
            {inView && (
              <Header
                activeIndex={activeIndex}
                content={content}
                groups={groups}
                setActiveIndex={setActiveIndex}
              />
            )}
          </Box>

          <Box sx={theme.slider}>
            {inView && (
              <Slider
                activeIndex={activeIndex}
                content={content}
                ctaStyle={content?.ctaStyle}
                groups={groups}
              />
            )}
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

ProductsSlider.displayName = 'ProductsSlider';
ProductsSlider.Schema = Schema;
