import { Flex } from 'theme-ui';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useMenuDrawer } from '../MenuDrawer';

import { PromoBarMessage } from './PromoBarMessage';
import { usePromobar } from './usePromobar';
import { themed } from './Promo.theme';

SwiperCore.use([EffectFade]);
SwiperCore.use([Autoplay]);

export const PromoBar = themed(({ theme, ...props }) => {
  const [
    {
      promoBarRef,
      swiperRef,
      currentColor,
      bgColor,
      isDisabled,
      singleMessage,
      promoBar,
      closed,
    },
    { updateActiveIndex, setSwiper },
  ] = usePromobar();

  const [, { unHoverDrawer: unHoverMainDrawer }] = useMenuDrawer();

  return isDisabled ? null : (
    <Flex
      data-comp={PromoBar.displayName}
      ref={promoBarRef}
      sx={{
        ...theme.wrapper,
        color: currentColor,
        bg: bgColor,
      }}
      onMouseEnter={() => {
        unHoverMainDrawer();
      }}
    >
      {/* Single message (no swiper), multiple swiper */}
      {singleMessage ? (
        <PromoBarMessage {...promoBar.messages[0]} isActive={!closed} />
      ) : (
        <Swiper
          data-comp="PromoBarMessagesSlider"
          init
          autoplay={{
            enabled: true,
            delay: promoBar.duration * 1000,
            disableOnInteraction: false,
          }}
          ref={swiperRef}
          watchSlidesProgress
          fadeEffect={{ crossFade: true }}
          speed={promoBar.speed * 1000}
          effect="fade"
          onSwiper={setSwiper}
          onSlideChange={updateActiveIndex}
          loop
          sx={theme.swiper}
        >
          {promoBar.messages.map((message, index) => (
            <SwiperSlide key={index + message?.copy?.slice(0, 10)}>
              {({ isActive }) => {
                return (
                  <PromoBarMessage
                    {...message}
                    isActive={!closed && isActive}
                  />
                );
              }}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Flex>
  );
});

PromoBar.displayName = 'PromoBar';
