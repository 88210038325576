import { Container, Heading } from 'theme-ui';

import { Section, RewardsEarningTilesWidget } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './RewardsEarningTiles.schema';
import { themed } from './RewardsEarningTiles.theme';

export const RewardsEarningTiles = withInView(
  themed(({ theme, cms, inView }) => {
    const { section, heading } = cms;

    return (
      <Section section={section} cms={cms}>
        <Container data-comp={RewardsEarningTiles.displayName}>
          {heading && <Heading sx={theme.heading}>{heading}</Heading>}
          <RewardsEarningTilesWidget />
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

RewardsEarningTiles.displayName = 'RewardsEarningTiles';
RewardsEarningTiles.Schema = Schema;
