import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.mobileWrapper = {
      position: 'absolute',
      bottom: '5px',
      right: '5px',
    };

    this.mobileButton = {
      minWidth: 'unset',
      p: 0,
      m: 0,
      bg: 'transparent',
      border: 0,
      color: 'darkGray',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.20)',
      borderRadius: '100%',
    };

    this.icon = {
      width: 24,
      height: 24,
    };
  })()
);
