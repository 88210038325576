import { store } from '@backpackjs/storefront';

const collection = store.recoil.atom({
  key: 'collection',
  default: {
    results: [],
  },
});

const activeFilters = store.recoil.atom({
  key: 'collection/activeFilters',
  default: {},
});

const filters = store.recoil.atom({
  key: 'collection/filters',
  default: [],
  dangerouslyAllowMutability: true,
});

const filterIsOpen = store.recoil.atom({
  key: 'collection/filterIsOpen',
  default: false,
});

const filtersMap = store.recoil.atom({
  key: 'collection/filtersMap',
  default: {},
  dangerouslyAllowMutability: true,
});

const selectedSort = store.recoil.atom({
  key: 'collection/selectedSort',
  default: 'default',
});

export default {
  collection,
  activeFilters,
  filters,
  filterIsOpen,
  filtersMap,
  selectedSort,
};
