import { useGotoRecoilSnapshot } from 'recoil'; // todo expose on @backpackjs/storefront
import store, { useRecoilCallback, useRecoilValue } from '@store';

export const useModal = () => {
  const gotoSnapshot = useGotoRecoilSnapshot();
  const modal = useRecoilValue(store.modal);
  const extraSx = useRecoilValue(store.extraSx);

  const closeModal = useRecoilCallback(({ snapshot }) => async () => {
    const release = snapshot.retain();
    try {
      const updatedState = snapshot
        .map(({ set }) => set(store.modal, null))
        .map(({ set }) => set(store.overlay, false));

      // update state
      gotoSnapshot(updatedState);
    } finally {
      release();
    }
  });

  const openModal = useRecoilCallback(
    ({ snapshot }) =>
      async (modalChildren, extraSx = {}) => {
        const release = snapshot.retain();
        try {
          const updatedState = snapshot
            .map(({ set }) => set(store.modal, modalChildren))
            .map(({ set }) => set(store.overlay, true))
            .map(({ set }) => set(store.extraSx, extraSx));

          // update state
          gotoSnapshot(updatedState);
        } finally {
          release();
        }
      }
  );

  return [
    // state
    {
      modal,
      extraSx,
    },
    // actions
    {
      closeModal,
      openModal,
    },
  ];
};
