import { Container, Heading, Box, Flex } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, A11y } from 'swiper';

import { Link, Picture, Section } from '@snippets';

import { Schema } from './Navigation.schema';

import { themed } from './Navigation.theme';

export const Navigation = themed(({ theme, cms }) => {
  const { links, heading, section } = cms;

  const defaultParams = {
    centeredSlides: false,
    freeMode: false,
    loop: false,
    navigation: false,
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 0,
    watchOverflow: true,
    pagination: {
      type: 'bullets',
      clickable: true,
    },
  };

  const enableSlider = links?.length > 3;

  return (
    <Section section={section} cms={cms}>
      <Container data-comp={Navigation.displayName}>
        {heading && (
          <Heading variant="text.h2" sx={theme.heading}>
            {heading}
          </Heading>
        )}

        {enableSlider && (
          <Swiper
            {...defaultParams}
            modules={[Pagination, A11y]}
            sx={{
              display: ['block', null, 'none'],
              pt: 10,
              pb: 12,
              '.swiper-pagination-bullets': {
                bottom: '-5px',
              },
            }}
          >
            {links?.map(({ link, image }) => {
              return (
                <SwiperSlide>
                  <Flex
                    variant="flex.column.center"
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <Link href={link?.url} tabIndex="-1" sx={theme.link}>
                      <Box sx={theme.linkBackground} />

                      <Picture
                        alt={link?.text}
                        images={[
                          {
                            src: image?.src,
                            ratio: 1,
                          },
                        ]}
                        sx={theme.linkImageWrapper}
                        imageSx={theme.linkImage}
                      />

                      <Heading
                        as="h3"
                        variant="text.textLink"
                        sx={theme.linkHeading}
                      >
                        {link?.text}
                      </Heading>
                    </Link>
                  </Flex>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}

        <Flex
          variant="flex.row.between"
          sx={{
            ...theme.links,
            display: enableSlider ? ['none', null, 'flex'] : 'flex',
          }}
        >
          {links?.map(({ link, image }) => {
            return (
              <Flex
                variant="flex.column"
                sx={{
                  position: 'relative',
                }}
              >
                <Link href={link?.url} tabIndex="-1" sx={theme.link}>
                  <Box sx={theme.linkBackground} />

                  <Picture
                    alt={link?.text}
                    images={[
                      {
                        src: image?.src,
                        ratio: 1,
                      },
                    ]}
                    sx={theme.linkImageWrapper}
                    imageSx={theme.linkImage}
                  />

                  <Heading
                    as="h3"
                    variant="text.textLink"
                    sx={theme.linkHeading}
                  >
                    {link?.text}
                  </Heading>
                </Link>
              </Flex>
            );
          })}
        </Flex>
      </Container>
    </Section>
  );
});

Navigation.displayName = 'Navigation';
Navigation.Schema = Schema;
