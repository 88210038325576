export const formatAmount = ({ amount = 0, currency = 'USD' }) => {
  if (amount !== 0 && !amount) return null;
  let total = typeof amount === 'string' ? amount : amount.toFixed(2);
  if (total.endsWith('.00')) {
    total = total.slice(0, -3);
  } else if (total.endsWith('.0')) {
    total = total.slice(0, -2);
  }
  let locale;
  if (currency === 'EUR') {
    locale = `${total.replaceAll('.', ',')} €`;
  } else if (currency === 'GBP') {
    locale = `£${total}`;
  } else {
    locale = `$${total}`;
  }
  return locale;
};

export const formatAmountItem = (item, qty) => {
  if (!item) return null;

  const total = item.amount;
  const currency = item.currencyCode;

  return qty
    ? {
        ...item,
        localeTotal: formatAmount({ amount: total, currency }),
        locale: formatAmount({
          amount: total / qty,
          currency,
        }),
      }
    : {
        ...item,
        locale: formatAmount({ amount: total, currency }),
      };
};
