import { useMemo } from 'react';
import { useBreakpointValue } from '@hooks';
import { Grid } from 'theme-ui';

import { VideoPlayer, VimeoPlayer } from '@snippets';
import { themed } from './Video.theme';

export const Video = themed(
  ({
    theme,
    videoSources = [],
    videoTitles = [],
    posterSources = [],
    videoOverlay,
    overlayColor,
    overlayOpacity,
    autoPlay = true,
    videoStyles = {},
    muted = true,
    isModalVideo = false,
    setVideoError,
    ...props
  }) => {
    const videoSource = useBreakpointValue(videoSources);
    const videoTitle = useBreakpointValue(videoTitles);
    const videoPoster = useBreakpointValue(posterSources);

    const isVimeoVideo = useMemo(
      () => videoSource?.includes('vimeo'),
      [videoSource]
    );

    if (!videoSource) return null;

    return isVimeoVideo ? (
      <Grid sx={theme.vimeoContainer}>
        <VimeoPlayer
          source={videoSource}
          title={videoTitle}
          videoPoster={videoPoster}
          isModalVideo={isModalVideo}
          videoStyles={videoStyles}
          {...props}
        />
      </Grid>
    ) : (
      <VideoPlayer
        styles={theme.video({
          overlayActive: videoOverlay,
          overlayColor,
          overlayOpacity,
        })}
        muted={muted}
        source={videoSource}
        poster={videoPoster}
        title={videoTitle}
        autoPlay={autoPlay}
        videoStyles={videoStyles}
        isModalVideo={isModalVideo}
        setVideoError={setVideoError}
        {...props}
      />
    );
  }
);

Video.displayName = 'Video';
