/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { section } from '@settings/common';

export function Schema({ product }) {
  if (!product) return null;

  return {
    label: 'Instagram Product Slider',
    key: 'instaProductSlider',
    fields: [
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
        defaultValue: 'INSTASHOP',
      },
      {
        name: 'description',
        label: 'Description',
        component: 'markdown',
        defaultValue: 'Follow Us @alohacollection On Instagram',
      },
      section,
    ],
  };
}
