import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrap = {
      border: '1px solid',
      borderColor: 'mediumGray',
      borderRadius: 4,
      px: 8,
      py: 10,
      pb: 4,
      mb: 10,
      position: 'relative',
      backgroundColor: 'white',
      ':hover, :focus': {},
    };
    this.label = {
      variant: 'text.label',
      position: 'absolute',
      top: 2,
      left: 8,
    };
    this.input = {
      border: 'none',
      variant: 'text.base',
      // p: 8,
      p: 0,
      '::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      'input[type=number]': {
        '-moz-appearance': 'textfield',
      },
      backgroundColor: 'transparent',
      ':hover, :focus': {
        outline: 'none',
      },
      '&::placeholder': {
        fontSize: '12px',
      },
    };
  })()
);
