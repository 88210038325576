import { Box, Flex, Paragraph, Progress } from 'theme-ui';
import { useCart, useSettings } from '@backpackjs/storefront';
import MarkdownJsx from 'markdown-to-jsx';

import store, { useRecoilValue } from '@store';

import { Picture } from '@snippets';

import { themed } from './FreeShippingMeter.theme';

export const FreeShippingMeter = themed(({ theme, inputRef, ...props }) => {
  const cart = useCart();
  const settings = useSettings();
  const freeShipping = settings?.cart?.freeShipping || {};
  const { enabled } = freeShipping;

  const total = cart?.lines?.reduce((carry, line) => {
    const subtotalAmount = line?.estimatedCost?.subtotalAmount?.amount;
    if (line?.variant?.product?.tags.includes('no-tiered-discounts'))
      return carry;
    return carry + parseFloat(subtotalAmount);
  }, 0);

  const promos = useRecoilValue(store.cartPromos);
  const promoMessages = useRecoilValue(store.cartPromoMessages);

  if (!enabled) return null;

  return (
    <Flex
      data-comp={FreeShippingMeter.displayName}
      sx={{
        variant:
          promos?.length > 1 ? 'flex.column.center.end' : 'flex.column.center',
        bg: 'lightestGray',
        height: promos?.length > 1 ? '97px' : '72px',
        px: 10,
        pb: '5',
      }}
    >
      <Flex
        variant="flex.column"
        sx={{
          width: '100%',
        }}
      >
        <Paragraph
          sx={{
            fontSize: '12px',
            lineHeight: '18px',
            textAlign: 'center',
            minHeight: '18px',
            pb: 1,
            my: 2,
            flex: 1,
          }}
        >
          <Flex variant="flex.column.center.start">
            {promoMessages?.unlockMessage && (
              <MarkdownJsx>{promoMessages.unlockMessage}</MarkdownJsx>
            )}
            {promoMessages?.lockMessage && (
              <MarkdownJsx>{promoMessages.lockMessage}</MarkdownJsx>
            )}
          </Flex>
        </Paragraph>

        <Flex
          variant="flex.row"
          sx={{
            width: '100%',
          }}
        >
          {promos?.map((promo, index) => {
            const previousPromo =
              index > 0 ? promos[index - 1] : { active: true, threshold: 0 };
            const isActive = promo.active;
            const maxValueForPromo = promo.threshold - previousPromo.threshold;

            const currentTotalForPromo = isActive
              ? promo.threshold
              : previousPromo.active
              ? total - previousPromo.threshold
              : 0;

            return (
              <Flex
                key={index}
                variant="flex.column.center"
                sx={{
                  width: '100%',
                }}
              >
                <Progress
                  max={Number(maxValueForPromo)}
                  value={currentTotalForPromo}
                  sx={{
                    height: '12px',
                    borderRadius: '6px',
                    bg: 'lightGray',
                    color: 'seafoam',
                    borderColor: 'black',
                  }}
                />
                {promos?.length > 1 && (
                  <Box
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <Picture
                      alt="Icon"
                      images={[
                        {
                          src: promo?.icon?.src,
                          width: 40,
                          ratio: 1,
                        },
                      ]}
                      imageSx={{
                        objectFit: 'contain',
                      }}
                      sx={{
                        mt: 2,
                        width: '20px',
                        height: '20px',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                )}
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
});

FreeShippingMeter.displayName = 'FreeShippingMeter';
