import { section } from '@settings/common';

export function Schema() {
  return {
    label: 'Account Login',
    key: 'accountLogin',
    fields: [
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
        defaultValue:
          'Please log in to your store account in order to apply for our affiliate program.',
      },
      {
        name: 'body',
        label: 'Body',
        component: 'markdown',
        defaultValue:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      },
      section,
    ],
  };
}
