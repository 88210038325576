import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.slider = {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };

    this.thumbsSwiper = (itemsLength) => ({
      width: '100%',
      bottom: ['30%', '20%'],
      mt: 12,
      cursor: 'pointer',
      maxWidth: [null, `${itemsLength + 30}%`],
      px: 10,
      '.swiper-wrapper': {
        justifyContent: 'center',
      },
    });

    this.logoLink = {
      display: 'flex',
      justifyContent: 'center',
      mx: [5, 10],
    };
  })()
);
