import { useCallback, useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';

import { mapProductPageVariant } from './utils';

export function useDataLayerProduct({
  baseEcommerce,
  DEBUG,
  userDataEvent,
  userProperties,
}) {
  const productHandleRef = useRef(null);

  const [viewedProductVariant, setViewedProductVariant] = useState(null);

  const viewProductEvent = useCallback(
    ({ ecommerce, variant, userProperties: _userProperties }) => {
      if (!variant) return;

      const previousPath = sessionStorage.getItem('PREVIOUS_PATH');
      const list = previousPath?.startsWith('/collections') ? previousPath : '';
      const event = {
        event: 'dl_view_item',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        ecommerce: {
          ...ecommerce,
          detail: {
            actionField: { list, action: 'detail' },
            products: [variant].map(mapProductPageVariant),
          },
        },
      };

      window.dispatchEvent(
        new CustomEvent('dl_view_item', {
          detail: event,
        })
      );
      if (DEBUG) console.log(`DataLayer:dispatch:${event.event}`, event);
    },
    []
  );

  // Subscribe to PubSub topic for 'dl_view_item' event
  useEffect(() => {
    const viewProduct = PubSub.subscribe(
      'VIEW_PRODUCT_PAGE',
      async (event, variant) => {
        setViewedProductVariant(variant);
      }
    );
    return () => {
      if (viewProduct) {
        PubSub.unsubscribe(viewProduct);
      }
    };
  }, []);

  // Trigger 'dl_user_data' and 'dl_view_item' events on product change after base data is ready
  useEffect(() => {
    const pageHandle = window.location.pathname.split('/').pop();
    if (
      !baseEcommerce ||
      !userProperties ||
      !viewedProductVariant ||
      productHandleRef.current === pageHandle
    )
      return;
    userDataEvent({ ecommerce: baseEcommerce, userProperties });
    viewProductEvent({
      ecommerce: baseEcommerce,
      variant: viewedProductVariant,
      userProperties,
    });
    productHandleRef.current = pageHandle;
  }, [!!baseEcommerce, viewedProductVariant?.product?.id, !!userProperties]);
}
