import { Box } from 'theme-ui';
import { useCustomer } from '@backpackjs/storefront';

import { Link, Svg } from '@snippets';

import { themed } from '../Menu.theme';

export const CtaAccount = themed(({ theme, ...rawProps }) => {
  const { inputRef, ...props } = rawProps;
  const customer = useCustomer();

  return (
    <Box
      data-comp={CtaAccount.displayName}
      {...props}
      sx={{
        ...props.sx,
        ...theme.cta,
      }}
    >
      <Link
        href={customer ? '/account' : '/account/login'}
        aria-label="Go to login page"
      >
        <Svg
          alt={customer ? 'View account' : 'Login'}
          src="/svgs/cta/login.svg#login"
          viewBox="0 0 24 24"
          sx={theme.cta.icon}
        />
      </Link>
    </Box>
  );
});

CtaAccount.displayName = 'CtaAccount';
