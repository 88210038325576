import { useEffect, useRef, useState } from 'react';
import { Box, Container, Heading, Spinner, Flex } from 'theme-ui';

import { Section, Markdown } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './InstaSlider.schema';
import { themed } from './instaSlider.theme';

export const InstaSlider = withInView(
  themed(({ theme, cms, inView }) => {
    const { section, heading, description, stylesHTML } = cms;
    const scriptRef = useRef(null);
    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
      if (!inView || !scriptRef?.current || hasLoaded) return undefined;
      const script = document.createElement('script');

      script.src = 'https://foursixty.com/media/scripts/fs.slider.v2.5.js';
      script.async = true;
      script.dataset.feedId = 'alohacollection';
      script.setAttribute('data-shopify-add-to-cart', 'false');
      script.dataset.theme = 'slider_v2_5';
      script.dataset.cellSize = '24%';
      script.onload = () => setHasLoaded(true);
      setTimeout(() => {
        scriptRef.current?.appendChild(script);
      }, 2000);

      return () => {
        scriptRef.current?.removeChild(script);
      };
    }, [scriptRef, inView]);

    useEffect(() => {
      if (!hasLoaded) return;
      const slider = scriptRef.current?.querySelector(
        '.fs-wrapper.fs-slider_v2_5-feed.fs-timeline'
      );
      if (slider) {
        setTimeout(() => {
          slider.scrollLeft = window.screen.width / 4;
        }, 700);
      }
    }, [hasLoaded]);

    return (
      <Section section={section} cms={cms}>
        <Container data-comp={InstaSlider.displayName} sx={theme.wrap}>
          <Box sx={theme.wrapper}>
            <Box sx={theme.top}>
              <Heading sx={theme.heading}>{heading}</Heading>
              {description && (
                <Markdown text={description} textSx={theme.description} />
              )}
            </Box>

            <Box
              sx={{
                position: 'relative',
                minHeight: ['160px', '200px', '250px', '320px', '360px'],
              }}
            >
              <Box
                ref={scriptRef}
                dangerouslySetInnerHTML={{ __html: stylesHTML }}
              />

              {!hasLoaded && (
                <Spinner
                  sx={{
                    width: '32px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              )}
            </Box>
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true, rootMargin: '400px' }
);

InstaSlider.displayName = 'InstaSlider';
InstaSlider.Schema = Schema;
