import { section } from '@settings/common';

export const Schema = () => {
  return {
    label: 'FriendBuy - Custom Widget',
    key: 'friendbuy-custom-widget',
    fields: [
      {
        name: 'friendbuyClassName',
        label: 'Class Name',
        description:
          'Class Name is needed to insert friendbuy widget into section. Ex: .friendbuyfriendspage',
        component: 'text',
      },
      section,
    ],
  };
};
