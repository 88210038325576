import { useMemo } from 'react';
import { Alert, Box, Button, Flex, Input, Paragraph, Text } from 'theme-ui';
import { useCustomerLogin, useCartEmail } from '@backpackjs/storefront';

import { Link, BaseInput } from '@snippets';
import { useDataLayerActions } from '@hooks';

export function LoginForm({
  disableContactLink,
  setAction,
  settings,
  isRegister,
}) {
  const cartEmail = useCartEmail(); // may have email in a prev persisted cart
  const {
    customerLogin,
    started,
    finished,
    errors,
    success,
    reset,
    resetTimer,
  } = useCustomerLogin();
  const { sendLogInEvent } = useDataLayerActions();

  const buttonText = useMemo(() => {
    if (finished) {
      resetTimer.current = setTimeout(() => {
        reset && reset();
        clearTimeout(resetTimer.current);
      }, 2000);
    }

    return finished
      ? success
        ? 'Signed In'
        : 'Failed'
      : // not finished yet
      started
      ? 'Signing in...'
      : settings?.account?.login?.buttonText || 'Sign In';
  }, [started, success, finished]);

  const loginHandler = async (event) => {
    event.preventDefault();
    const { email, password } = event.target;

    await customerLogin({
      email: email.value,
      password: password.value,
    });

    sendLogInEvent();
  };

  return (
    <Flex variant="forms.account.form" data-comp="LoginForm">
      <Box sx={{ textAlign: 'center' }}>
        {/* Heading */}
        {settings?.account?.login?.heading && (
          <Paragraph variant="text.h5">
            {settings?.account?.login?.heading}
          </Paragraph>
        )}
        {/* Sub heading */}
        {settings?.account?.login?.subHeading && (
          <Paragraph
            variant="text.13"
            sx={{
              mt: 8,
            }}
          >
            {settings?.account?.login?.subHeading}
          </Paragraph>
        )}
      </Box>
      {/* Form */}
      <Flex
        variant="flex.column"
        as="form"
        onSubmit={loginHandler}
        sx={{
          my: 14,
          minWidth: ['initial', '315px'],
          width: ['100%', 'initial'],
        }}
      >
        <BaseInput
          variant="account.input"
          name="email"
          label="Email"
          type="email"
          placeholder="Enter your email address"
          defaultValue={cartEmail}
          required
          as={Input}
        />

        <BaseInput
          variant="account.input"
          name="password"
          label="Password"
          type="password"
          minLength="5"
          placeholder="Enter your password"
          required
          as={Input}
        />

        {errors?.length
          ? errors.map((err) => (
              <Alert key={err} variant="alerts.error" mb={6}>
                Invalid username or password
              </Alert>
            ))
          : null}

        <Button
          variant="primary"
          disabled={started || (finished && success)}
          sx={{
            m: '0 auto',
            mt: 10,
            cursor: started || (finished && success) ? 'default' : 'pointer',
            width: ['100%', 'initial'],
          }}
        >
          {buttonText}
        </Button>
      </Flex>
      {/* Forgot password  */}
      {settings?.account?.login?.resetText && !isRegister && (
        <Box
          sx={{
            mb: 10,
            fontSize: '14px',
          }}
        >
          <Paragraph
            variant="text.14.1"
            sx={{
              cursor: 'pointer',
              mb: 2,
              width: 'fit-content',
              textDecoration: 'underline',
            }}
            onClick={() => setAction('forgot')}
          >
            {settings.account.login.resetText}
          </Paragraph>
        </Box>
      )}
      {/* sign up link  */}
      {settings?.account?.login?.createLink && (
        <Flex
          sx={{
            display: ['flex', 'flex', 'none'],
            alignItems: 'center',
            fontFamily: 'body',
          }}
        >
          <Text
            sx={{
              fontSize: '14px',
            }}
          >
            {settings.account.login.createText &&
              `${settings.account.login.createText}`}
          </Text>

          <Link href="/account/register">
            <Paragraph
              sx={{
                ml: settings?.account?.login?.createText ? 4 : 0,
                cursor: 'pointer',
                display: 'inline',
                fontSize: '14px',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {settings.account.login.createLink}
            </Paragraph>
          </Link>
        </Flex>
      )}
      {/* /!* Contact us link *!/  */}
      {settings?.account?.login?.helpLink?.url && !disableContactLink && (
        <Box sx={{ my: 8, textAlign: 'center' }}>
          <Text variant="text.14" sx={{}}>
            {settings?.account?.login?.helpText &&
              `${settings.account.login.helpText}`}
          </Text>

          <Link href={settings.account.login.helpLink.url}>
            <Paragraph
              sx={{
                ml: settings?.account?.login?.helpText ? 4 : 0,
                display: 'inline',
                cursor: 'pointer',
                fontWeight: 600,
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {settings.account.login.helpLink?.text}
            </Paragraph>
          </Link>
        </Box>
      )}
    </Flex>
  );
}
