import { Grid } from 'theme-ui';

import { CtaSearch } from './CtaSearch';
import { CtaAccount } from './CtaAccount';
import { CtaCart } from './CtaCart';
import { themed } from '../Menu.theme';
import { CtaWishlist } from './CtaWishlist';

export const Ctas = themed(({ theme }) => {
  return (
    <Grid data-comp={Ctas.displayName} sx={theme.ctas}>
      {/* Search toggle (desktop only) */}
      <CtaSearch />

      <CtaWishlist />

      <CtaAccount
        sx={{
          display: ['none', null, 'block'],
        }}
      />

      <CtaCart />
    </Grid>
  );
});

Ctas.displayName = 'Ctas';
