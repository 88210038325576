import { useEffect } from 'react';
import Script from 'next/script';

const tiktokPixelId = 'CCL05UJC77U01DQBOCVG';

function Libs() {
  return (
    <>
      <Script
        type="text/javascript"
        src="//cdn.searchspring.net/intellisuggest/is.min.js"
      />
      <Script
        type="text/javascript"
        src="https://config.gorgias.chat/bundle-loader/01GYCC4ATHW6JRHV05VM06R9Z4"
      />
      <Script
        id="convert-bundle-loader"
        type="text/javascript"
        src="https://bundle.dyn-rev.app/loader.js?g_cvt_id=cb99d666-31fd-4c9e-9da5-244883a58dc1"
      />
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          window["friendbuyAPI"] = friendbuyAPI = window["friendbuyAPI"] || [];
          friendbuyAPI.merchantId = "${process.env.NEXT_PUBLIC_FRIENDBUY_MERCHANT_ID}";
          friendbuyAPI.push(["merchant", friendbuyAPI.merchantId]);
          (function(f, r, n, d, b, u, y) {
            while ((u = n.shift())) {
              (b = f.createElement(r)), (y = f.getElementsByTagName(r)[0]);
              b.async = 1;
              b.src = u;
              y.parentNode.insertBefore(b, y);
            }
          })(document, "script", [
            "https://static.fbot.me/friendbuy.js",
            "https://campaign.fbot.me/" + friendbuyAPI.merchantId + "/campaigns.js",
          ]);`,
        }}
      />
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          (function() {      
            var _heatmap_paq = window._heatmap_paq || [];
            var heatUrl = window.heatUrl = "https://dashboard.heatmap.com/";
            function heatLoader(url, item) {
              if(typeof handleSinglePagedWebsite !== 'undefined' && item == 'prep') return true;
              var s = document.createElement("script"); s.type = "text/javascript"; 
              s.src = url; s.async = false; s.defer = true; document.head.appendChild(s);
            }
            heatLoader(heatUrl+"preprocessor.min.js?sid=${process.env.NEXT_PUBLIC_HEATMAP_SITE_ID}", "prep");
            setTimeout(function() {
              if(typeof _heatmap_paq !== "object" || _heatmap_paq.length == 0) {     
                _heatmap_paq.push(["setTrackerUrl", heatUrl+"heatmap.php"]);
                heatLoader(heatUrl+"heatmap-light.min.js?sid=${process.env.NEXT_PUBLIC_HEATMAP_SITE_ID}", "heat");
              }
            }, 1000);
          })();`,
        }}
      />
      <Script
        type="text/javascript"
        src="https://cdn.accessibly.app/accessibility-widget-v2.min.js"
      />
      <Script
        id="postscript-sdk"
        strategy="afterInteractive"
        type="text/javascript"
        src={`https://sdk.postscript.io/sdk.bundle.js?shopId=${process.env.NEXT_PUBLIC_POSTSCRIPT_SHOP_ID}`}
      />
      <style>{`
        #accessiblyOtmButton {
          top: auto;
          left: auto;
          bottom: 15px;
          right: 15px;
          width: 64px !important;
          height: 64px !important;
        }
      `}</style>
    </>
  );
}

export default Libs;
