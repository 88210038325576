import { useMemo } from 'react';
import { Box, Paragraph } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { themed } from './Badge.theme';

export const Badge = themed(({ theme, tags, styles }) => {
  const settings = useSettings();
  const { badgeColors } = { ...settings?.product?.badges };

  const badgeColorsMap = useMemo(() => {
    if (!badgeColors) return {};
    return badgeColors.reduce((acc, badge) => {
      return { ...acc, [badge.tag?.trim()]: badge };
    }, {});
  }, [badgeColors]);

  const badge = useMemo(() => {
    const isForcedPreOrder = tags?.find((tag) =>
      tag.startsWith('allow-preorder')
    );
    if (isForcedPreOrder) {
      return 'Pre Order Item';
    }

    const isForcedSaleBadge = tags?.find((tag) =>
      tag.startsWith('badge::Sale')
    );

    if (isForcedSaleBadge) {
      return 'Sale';
    }

    const tagBadge = tags?.find((tag) => tag.startsWith('badge::'));
    if (tagBadge) {
      return tagBadge.replace('badge::', '');
    }
    return null;
  }, [tags?.length]);

  if (!badge) return null;

  return (
    <Box
      data-comp={Badge.displayName}
      sx={{
        ...theme.wrapper(
          badgeColorsMap[badge] || {
            bgColor: '#CBEBEA',
            borderColor: '#CBEBEA',
          }
        ),
        ...styles,
      }}
    >
      <Paragraph sx={theme.label(badgeColorsMap[badge] || {})}>
        {badge?.toLowerCase()}
      </Paragraph>
    </Box>
  );
});

Badge.displayName = 'Badge';
