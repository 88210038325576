import React from 'react';
import { Box, Heading, Spinner } from 'theme-ui';
import { Picture, Markdown, Link } from '@snippets';
import { themed } from './Slide.theme';

export const Slide = themed(({ theme, tile, tileContentPosition }) => {
  const { image, heading, description, cta } = tile;
  return (
    <Box sx={theme.slide(cta?.url)}>
      <Link href={cta?.url} tabIndex="-1">
        <Picture
          alt={heading}
          sx={theme.image}
          images={[
            {
              src: image,
              width: 350,
              ratio: 1,
            },
          ]}
        >
          {!image && <Spinner sx={theme.spinner} />}
        </Picture>
      </Link>

      {/* Title */}
      <Box sx={theme.content(tileContentPosition)}>
        <Heading sx={theme.heading}>{heading}</Heading>

        <Markdown text={description} sx={theme.description} />
      </Box>
    </Box>
  );
});
