import { section } from '@settings/common';

export function Schema({ page }) {
  if (page?.handle !== 'buddypass') return null;

  return {
    label: 'FriendBuy - Friend LP',
    key: 'friend-l-p',
    fields: [section],
  };
}
