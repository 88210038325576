import { position, section } from '@settings/common';

// Random image for demo purposes only
const images = [
  'https://images.unsplash.com/photo-1485965120184-e220f721d03e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80',
  'https://images.unsplash.com/photo-1559348349-86f1f65817fe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80',
  'https://images.unsplash.com/photo-1523357585206-175e971f2ad9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2000&q=80',
  'https://images.unsplash.com/photo-1528629297340-d1d466945dc5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80',
  'https://images.unsplash.com/photo-1571333250176-3c316199994b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80',
];

export const Schema = () => {
  const randomImage = images[Math.floor(Math.random() * images.length)];

  return {
    label: 'Half Collection',
    key: 'half-collection',
    fields: [
      {
        label: 'Media settings',
        name: 'media',
        description: 'Select Media Type (image - video)',
        component: 'group',
        fields: [
          {
            label: 'Image Settings',
            name: 'image',
            description: 'Image, position, placement, aspect ratio',
            component: 'group',
            fields: [
              {
                label: 'Alt',
                name: 'alt',
                component: 'text',
                description: 'Brief description of image',
              },
              {
                name: 'image',
                label: 'Image (desktop)',
                component: 'image',
              },
              {
                name: 'position',
                component: 'select',
                label: 'Image Position (desktop)',
                options: position.object,
              },
              {
                label: 'Aspect Ratio (desktop)',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                name: 'ratio',
                options: [
                  {
                    value: '80%',
                    label: '5:4',
                  },
                  {
                    value: '100%',
                    label: '1:1',
                  },
                  {
                    value: '120%',
                    label: '5:6',
                  },
                ],
              },
              {
                label: 'Image (mobile)',
                name: 'imageMob',
                component: 'image',
              },
              {
                name: 'positionMob',
                component: 'select',
                label: 'Image Position (mobile)',
                options: position.object,
              },
              {
                label: 'Aspect Ratio (mobile)',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                name: 'ratioMob',
                options: [
                  {
                    value: '80%',
                    label: '5:4',
                  },
                  {
                    value: '100%',
                    label: '1:1',
                  },
                  {
                    value: '120%',
                    label: '5:6',
                  },
                ],
              },
            ],
            defaultValue: {
              ratio: '100%',
              ratioMob: '100%',
              alt: `Half Media image`,
              image: {
                src: randomImage,
              },
              position: 'center center',
              imageMob: {
                src: randomImage,
              },
              positionMob: 'center center',
            },
          },
          {
            label: 'Video settings',
            name: 'video',
            description: 'video, video position',
            component: 'group',
            fields: [
              {
                label: 'Title (mobile)',
                name: 'titleMb',
                component: 'text',
                description: 'Title of the video (mobile)',
              },
              {
                name: 'videoMb',
                description: 'Video url must end with .mp4',
                label: 'video url (mobile)',
                component: 'text',
              },
              {
                name: 'localVideoMobile',
                label: 'Upload Video (mobile)',
                component: 'image',
              },
              {
                label: 'Title (desktop)',
                name: 'titleDt',
                component: 'text',
                description: 'Title of the video (desktop)',
              },
              {
                name: 'videoDt',
                description:
                  'Video url must end with .mp4 \n For better results on desktop use minimum resolution of 1280px',
                label: 'video url (desktop)',
                component: 'text',
              },
              {
                name: 'localVideoDesktop',
                label: 'Upload Video (desktop)',
                component: 'image',
              },
            ],
            defaultValue: {
              titleMb: 'Half video',
              videoMb: 'https://placehold.co/1920x1080.mp4?text=Hello+World',
              titleDt: 'Half video',
              videoDt: 'https://placehold.co/1920x1080.mp4?text=Hello+World',
            },
          },
          {
            label: 'Select Media Type (mobile)',
            description: 'Allows select between image or video for mobile',
            name: 'mediaTypeMb',
            component: 'radio-group',
            options: [
              { label: 'Image', value: 'image' },
              { label: 'Video', value: 'video' },
            ],
            defaultValue: 'image',
          },
          {
            label: 'Select Media Type (desktop)',
            description: 'Allows select between image or video for desktop',
            name: 'mediaTypeDt',
            component: 'radio-group',
            direction: 'horizontal',
            options: [
              { label: 'Image', value: 'image' },
              { label: 'Video', value: 'video' },
            ],
            defaultValue: 'video',
          },
          {
            label: 'Placement (desktop)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'location',
            options: [
              {
                value: 'left',
                label: 'Left',
              },
              {
                value: 'right',
                label: 'Right',
              },
            ],
          },
          {
            label: 'Placement (mobile)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'locationMob',
            options: [
              {
                value: 'top',
                label: 'Top',
              },
              {
                value: 'bottom',
                label: 'Bottom',
              },
            ],
          },
        ],
        defaultValue: {
          image: {
            ratio: '100%',
            ratioMob: '100%',
            alt: `Half Media image`,
            image: {
              src: randomImage,
            },
            position: 'center center',
            imageMob: {
              src: randomImage,
            },
            positionMob: 'center center',
          },
          video: {
            titleMb: 'Half video',
            videoMb:
              'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
            titleDt: 'Half video',
            videoDt:
              'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
          },
          mediaTypeDt: 'image',
          mediaTypeMb: 'image',
          location: 'right',
          locationMob: 'top',
        },
      },
      {
        label: 'Collection Settings',
        name: 'content',
        description: 'Heading, superheading, body, products',
        component: 'group',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Superheading',
            name: 'superheading',
            component: 'text',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
          {
            name: 'products',
            label: 'Products',
            component: 'group-list',
            itemProps: {
              label: '{{item.product.handle}}',
            },
            defaultItem: {
              product: { handle: 'the-tango' },
            },
            fields: [
              {
                name: 'product',
                component: 'productSearch',
                label: 'Product',
              },
            ],
            defaultValue: [
              {
                product: { handle: 'max-tahaa' },
              },
              {
                product: { handle: 'flora-reversible-tote-arvo' },
              },
              {
                product: { handle: 'kealopiko-olepe-mini-hip-pack' },
              },
              {
                product: { handle: 'max-la-rose-dijon' },
              },
              {
                product: { handle: 'mid-papeete-pouch' },
              },
            ],
          },
          {
            label: 'Hide Lines',
            name: 'hideLines',
            component: 'toggle',
            toggleLabels: {
              true: 'Hide',
              false: 'Show',
            },
            defaultValue: false,
          },
        ],
        defaultValue: {
          heading: 'create your collection',
          superheading: '',
          body: '',
        },
      },
      section,
    ],
  };
};
