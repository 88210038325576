import { useRef } from 'react';
import { Image } from 'theme-ui';

export function PictureImage({
  aboveTheFold,
  alt,
  imageSx,
  inView,
  initialSrc,
  onError = () => {},
  onLoad = () => {},
}) {
  const imageRef = useRef();
  return aboveTheFold || inView ? (
    <Image
      data-comp={PictureImage.displayName}
      ref={imageRef}
      alt={alt}
      src={initialSrc?.src || initialSrc}
      sx={{
        // over-writable..
        height: '100%',
        position: 'absolute',
        width: '100%',
        outline: 'none',
        objectFit: 'cover',
        border: 0,
        p: 0,
        m: 0,
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        ...imageSx,
      }}
      onLoad={() => onLoad(imageRef.current)}
      onError={(event) => {
        console.error('Error loading image with src', event.target.currentSrc);
        onError(event);
      }}
    />
  ) : null; // it won't load any sources
}

PictureImage.displayName = 'PictureImage';
