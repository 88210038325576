import { Box, Grid, Container, Heading } from 'theme-ui';

import { Section } from '@snippets';

import { Slider } from './Slider';
import { Tile } from './Slider/Tile';

import { Schema } from './TwoTile.schema';
import { themed } from './TwoTile.theme';

export const TwoTile = themed(({ cms, theme }) => {
  const { displayTypeMobile, section, heading, tiles, image } = cms;

  return (
    <Section section={section} cms={cms}>
      <Container data-comp={TwoTile.displayName}>
        <Box
          sx={{
            ...theme.header,
            px: [section?.mobile?.container === 'container' ? 10 : 0, 0],
          }}
        >
          {heading && (
            <Heading
              as="h2"
              variant="text.h3"
              sx={{
                ...theme.heading,
                color: section?.color || 'black',
              }}
            >
              {heading}
            </Heading>
          )}
        </Box>

        <Box sx={theme.wrapper(displayTypeMobile)}>
          <Slider image={image} tiles={tiles} />
        </Box>

        {displayTypeMobile === 'stacked' && (
          <Grid sx={theme.grid}>
            {tiles?.map((tile, index) => {
              return (
                <Tile imageRatio={image?.ratio} tile={tile} index={index} />
              );
            })}
          </Grid>
        )}
      </Container>
    </Section>
  );
});

TwoTile.displayName = 'TwoTile';
TwoTile.Schema = Schema;
