import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      py: [15, 18],
    };

    this.title = {
      variant: 'text.h2',
      textAlign: 'center',
      mb: [11, 14],
    };
  })()
);
