import { Box, Spinner } from 'theme-ui';

import { Picture, Video } from '@snippets';

import { themed } from './Tab.theme';

export const Tab = themed(({ tab, theme }) => {
  const { image: imageSettings, mediaType, video } = { ...tab };

  const { alt, image: imageDt, imageMob } = { ...imageSettings };

  return (
    <Box sx={theme.tab} data-comps={Tab.displayName}>
      {mediaType === 'image' ? (
        <Picture
          alt={alt}
          sx={theme.image}
          images={[
            {
              src: imageMob?.src || imageDt?.src,
              width: 768,
              ratio: 1,
            },
            {
              src: imageDt?.src,
              width: 1024,
              ratio: 1,
            },
            {
              src: imageDt?.src,
              width: 1280,
              ratio: 1,
            },
            {
              src: imageDt?.src,
              width: 1440,
              ratio: 1,
            },
          ]}
        >
          {!imageMob?.src && !imageDt?.src && <Spinner sx={theme.spinner} />}
        </Picture>
      ) : (
        <Video videoSources={[video?.video]} />
      )}
    </Box>
  );
});

Tab.displayName = 'Tab';
