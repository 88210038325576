import { section } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Navigation',
    key: 'navigation',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Links',
        name: 'links',
        component: 'group-list',
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          minItems: 3,
          maxItems: 8,
        },
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
        ],
      },
      section,
    ],
  };
};
