import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      position: 'fixed',
      zIndex: '-1',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      pointerEvents: 'none',
    };

    this.parallax = {
      height: '100vh',
    };

    this.image = {
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
    };
  })()
);
