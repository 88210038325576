import { useEffect } from 'react';

export const useScrollToSection = () => {
  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const element = document.querySelector(
        `[data-comp-id="${hash?.replace('#', '')}"]`
      );
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.offsetTop,
      });
    }
  }, []);
};
