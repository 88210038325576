import { useEffect } from 'react';

/*
  sets the CSS variable root { --viewport-height: 123px } on mount and updates on screen resize.
  This allows us to do i.e sx={{ minHeight: var(--viewport-height) }} to get full height instead
  of using sx= {{ minHeight: 100vh }} which is NOT supported on Safari
*/
export const useSetViewportHeightCssVar = () => {
  useEffect(() => {
    const setViewportHeight = () => {
      const doc = document.documentElement;

      // when in preview we make viewport height - the height of the customizer toolbar
      const viewportHeight = `${window.innerHeight}px`;

      doc.style.setProperty('--viewport-height', viewportHeight);
    };

    window.addEventListener('resize', setViewportHeight);
    setViewportHeight();

    return () => {
      window.removeEventListener('resize', setViewportHeight);
    };
  }, []);
};
