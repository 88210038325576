import { color, section } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Icon Row',
    key: 'icon-row',
    fields: [
      {
        label: 'Text Settings',
        name: 'text',
        component: 'group',
        fields: [
          {
            label: 'Text Size',
            name: 'textSize',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              {
                label: 'Regular',
                value: 'regular',
              },
              {
                label: 'Large',
                value: 'large',
              },
            ],
            defaultValue: 'regular',
          },
        ],
      },
      {
        label: 'Icons',
        name: 'icons',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        fields: [
          {
            label: 'Icon',
            name: 'icon',
            component: 'image',
          },
          {
            label: 'Icon Width (px)',
            name: 'iconWidth',
            component: 'number',
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Heading Text Color',
            name: 'headingColor',
            component: 'color',
            colors: color.list,
          },
          {
            label: 'Description',
            name: 'description',
            component: 'markdown',
          },
          {
            label: 'Description Text Color',
            name: 'descriptionColor',
            component: 'color',
            colors: color.list,
          },
        ],
        defaultValue: [
          {
            heading: 'Lorem Ipsum',
            description:
              'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
          },
          {
            heading: 'Lorem Ipsum',
            description:
              'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
          },
          {
            heading: 'Lorem Ipsum',
            description:
              'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
          },
        ],
        defaultItem: {
          heading: 'Lorem Ipsum',
          description:
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
      },
      section,
    ],
  };
};
