import { Link } from '@snippets';
import { useCustomer } from '@backpackjs/storefront';
import { themed } from './Slide.theme';

export const Button = themed(
  ({
    theme,
    heroClickable,
    item,
    onClick,
    openModalButton,
    inputRef,
    ...props
  }) => {
    const customer = useCustomer();

    const { buttonStylesOverride } = { ...item };
    const {
      color,
      colorHover,
      backgroundColor,
      backgroundColorHover,
      borderColor,
      borderColorHover,
    } = { ...buttonStylesOverride };

    if (customer && item.cta?.url === '/account') return null;
    return (
      <Link
        {...props}
        href={!openModalButton ? item.cta?.url : null}
        onClick={openModalButton ? onClick : null}
        newTab={item.cta?.newTab}
        sx={{
          ...props.sx,
          // ...(heroClickable && { pointerEvents: 'none' }), client request to remove
          variant: `buttons.${item.style}`,
          ...(color && { color }),
          ...(backgroundColor && { backgroundColor }),
          ...(borderColor && { borderColor }),
          '&:hover': {
            ...(colorHover && { color: colorHover }),
            ...(backgroundColorHover && {
              backgroundColor: backgroundColorHover,
            }),
            ...(borderColorHover && { borderColor: borderColorHover }),
          },
          '@media only screen and (max-width: 768px)': {
            variant: `buttons.${item.styleMob}`,
            ...(color && { color }),
            ...(backgroundColor && { backgroundColor }),
            ...(borderColor && { borderColor }),
            '&:hover': {
              ...(colorHover && { color: colorHover }),
              ...(backgroundColorHover && {
                backgroundColor: backgroundColorHover,
              }),
              ...(borderColorHover && { borderColor: borderColorHover }),
            },
          },
        }}
      >
        {item.cta?.text}
      </Link>
    );
  }
);
