import { useEffect } from 'react';
import Script from 'next/script';

export function FueledPinterestScript({ withKey: pinterestPixelId }) {
  useEffect(() => {
    const pageVisitListener = () => {
      window.pintrk('track', 'pagevisit');
    };

    const userDataListener = (event) => {
      const userProps = event.detail.user_properties;

      // pinterest
      window.pintrk('set', { em: userProps?.customer_email });
    };

    const viewItemListener = (event) => {
      const [product] = event.detail.ecommerce.detail.products;

      // pinterest
      window.pintrk('track', 'viewproduct', {
        product_id: product.product_id,
        product_name: product.name,
        product_price: product.price,
        product_brand: product.brand,
        product_category: product.category,
      });
    };

    const ViewItemListListener = (event) => {
      const { products } = event.detail.ecommerce;
      const category = products?.[0]?.category;

      // pinterest
      window.pintrk('track', 'viewcategory', {
        category_name: category,
      });
    };

    const addToCartListener = (event) => {
      const [product] = event.detail.ecommerce.add.products;

      // pinterest
      window.pintrk('track', 'addtocart', {
        product_id: product.product_id,
        product_name: product.name,
        product_price: product.price,
        product_brand: product.brand,
        quantity: product.quantity,
        value: product.price * product.quantity,
        currency: event.detail.ecommerce.currency_code,
        product_category: product.category,
      });
    };

    window.addEventListener('dl_route_update', pageVisitListener);
    window.addEventListener('dl_user_data', userDataListener);
    window.addEventListener('dl_view_item_list', ViewItemListListener);
    window.addEventListener('dl_view_item', viewItemListener);
    window.addEventListener('dl_add_to_cart', addToCartListener);

    return () => {
      window.removeEventListener('dl_route_update', pageVisitListener);
      window.removeEventListener('dl_user_data', userDataListener);
      window.removeEventListener('dl_view_item_list', ViewItemListListener);
      window.removeEventListener('dl_view_item', viewItemListener);
      window.removeEventListener('dl_add_to_cart', addToCartListener);
    };
  }, []);

  return (
    <Script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          !function(e){if(!window.pintrk){window.pintrk = function () {
          window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
          n=window.pintrk;n.queue=[],n.version="3.0";var
          t=document.createElement("script");t.async=!0,t.src=e;var
          r=document.getElementsByTagName("script")[0];
          r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
          if(!window?.pintrk?.tagId){window.pintrk('load', '${pinterestPixelId}');window.pintrk('page');}
        `,
      }}
    />
  );
}
