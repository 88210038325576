import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.headingContainer = {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    };

    this.heading = {
      variant: 'text.h4',
      fontSize: [6, '28px'],
      lineHeight: ['22px', '34px'],
      m: 0,
      textAlign: 'center',
      quotes: 'initial',
      maxWidth: ['335px', '725px', '860px'],
    };
  })()
);
