import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.shell = {
      bg: 'background',
      display: ['none', null, 'block'],
      left: 0,
      position: 'absolute',
      top: '100%',
      width: '100%',
      zIndex: -1,
      transition: 'opacity 0.3s ease-in-out',
      backgroundColor: 'lightestGray',
      closed: {
        borderTopColor: 'transparent',
        boxShadow: 'none',
        opacity: 0,
        pointerEvents: 'none',
      },

      open: {
        borderTopColor: 'lightGray',
        boxShadow: 'bottom',
        opacity: 1,
        pointerEvents: 'auto',
      },
    };

    this.drawer = {
      variant: 'layout.drawer',
      backgroundColor: 'lightestGray',
      transition: '0.3s ease-in-out transform',
      maxWidth: '100%',
      zIndex: '2',
    };
  })()
);
