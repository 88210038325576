const plain = {
  position: 'relative',
  minWidth: 'unset',
  p: 0,
  m: 0,
  bg: 'transparent',
  border: 0,
  borderRadius: 0,
  color: 'black',
  fontFamily: 'body',
  textDecoration: 'none',
  cursor: 'pointer',
};

export const buttons = {
  // solid dark
  primary: {
    cursor: 'pointer',
    minWidth: '188px',
    px: 11,
    variant: 'text.buttonText',
    textAlign: 'center',
    lineHeight: '46px',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'black',
    bg: 'black',
    color: 'white',
    transition: 'all 0.2s ease-in-out',
    '@media only screen and (min-width: 480px)': {
      '&:hover': {
        bg: 'transparent',
        color: 'black',
        borderColor: 'black',
      },
    },
    '&[disabled]': {
      backgroundColor: 'lightGray',
      borderColor: 'lightGray',
      cursor: 'not-allowed',
    },
  },
  // solid white
  secondary: {
    variant: 'buttons.primary',
    bg: 'white',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'white',
    lineHeight: 48,
    color: 'black',
    '@media only screen and (min-width: 480px)': {
      '&:hover': {
        bg: 'transparent',
        color: 'white',
        borderColor: 'white',
      },
    },
  },
  salePrimary: {
    cursor: 'pointer',
    minWidth: '188px',
    px: 11,
    variant: 'text.buttonText',
    textAlign: 'center',
    lineHeight: '46px',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'yellow',
    bg: 'yellow',
    color: 'black',
    transition: 'all 0.2s ease-in-out',
    '@media only screen and (min-width: 480px)': {
      '&:hover': {
        bg: 'yellow',
        color: 'black',
        borderColor: 'black',
      },
    },
  },
  saleOutline: {
    variant: 'buttons.salePrimary',
    bg: 'transparent',
    borderColor: 'yellow',
    color: 'yellow',
    '@media only screen and (min-width: 480px)': {
      '&:hover': {
        bg: 'yellow',
        borderColor: 'yellow',
        color: 'black',
      },
    },
  },
  // dark outline
  outline: {
    variant: 'buttons.primary',
    bg: 'transparent',
    borderColor: 'black',
    color: 'black',
    '@media only screen and (min-width: 480px)': {
      '&:hover': {
        bg: 'black',
        borderColor: 'black',
        color: 'white',
      },
    },
  },
  // white outline
  outlineWhite: {
    variant: 'buttons.primary',
    bg: 'transparent',
    borderColor: 'white',
    color: 'white',
    '@media only screen and (min-width: 480px)': {
      '&:hover': {
        bg: 'white',
        borderColor: 'white',
        color: 'black',
      },
    },
  },
  underlineLinkWhite: {
    ...plain,
    variant: 'text.link.2',
    cursor: 'pointer',
    pb: 0,
    letterSpacing: 0,
    color: 'white',
    '@media only screen and (min-width: 480px)': {
      '&:hover': {
        opacity: 0.6,
      },
    },
  },
  underlineLink: {
    ...plain,
    variant: 'text.link.2',
    cursor: 'pointer',
    pb: 0,
    letterSpacing: 0,
    '@media only screen and (min-width: 480px)': {
      '&:hover': {
        opacity: 0.6,
      },
    },
  },
  underlineLinkWithArrow: {
    ...plain,
    variant: 'text.link.1',
    cursor: 'pointer',
    overflow: 'visible',
    mr: '20px!important',
    ':after': {
      content: '""',
      position: 'absolute',
      left: 'calc(100% - 10px);',
      top: 0,
      ml: 5,
      height: '18px',
      width: '24px',
      backgroundImage: `url('/svgs/chevron/right.svg')`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      transition: 'all 0.2s ease-in-out',
      transform: 'translateX(0)',
    },
    '&:before': {
      left: 0,
      width: '100%',
    },
    '@media only screen and (min-width: 480px)': {
      '&:hover': {
        '&:before': {
          transform: 'none',
        },
        ':after': {
          transform: 'translateX(2px)',
        },
      },
    },
  },
  link: {
    1: {
      ...plain,
      variant: 'text.link.1',
      cursor: 'pointer',
    },
    2: {
      ...plain,
      variant: 'text.link.2',
      cursor: 'pointer',
    },
    text: {
      ...plain,
      variant: 'text.textLink',
      cursor: 'pointer',
    },
  },
  plain,
};
