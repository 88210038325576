import Script from 'next/script';
import { Section } from '@snippets';
import { Schema } from './ShortstackEmbed.schema';

export function ShortstackEmbed({ cms }) {
  const { campaignId, src, section } = cms;

  return (
    <>
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `(!function(){var e=document.getElementById("campaign-embed-code");e||((e=document.createElement("SCRIPT")).src="https://d1m2uzvk8r2fcn.cloudfront.net/scripts/embed-code/1640204595.min.js",e.id="campaign-embed-code",document.body.appendChild(e))}());`,
        }}
      />

      <Section section={section} cms={cms}>
        <iframe
          title="shortstack-campaign"
          allow="autoplay"
          sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-popups-to-escape-sandbox"
          id={`${campaignId}`}
          className="embed_frame"
          loading="lazy"
          src={`${src}`}
          style={{ width: '100%', height: '2065.998291015625px' }}
          frameBorder="0"
          allowpaymentrequest=""
          allowFullScreen=""
          data-hj-allow-iframe=""
        />
      </Section>
    </>
  );
}

ShortstackEmbed.displayName = 'ShortstackEmbed';
ShortstackEmbed.Schema = Schema;
