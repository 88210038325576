import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.slide = (imageRatio) => ({
      position: 'relative',
      width: '100%',
      borderRadius: '6px',
      aspectRatio: imageRatio || '1/1',
      overflow: 'hidden',
      ':hover': {
        '@media only screen and (min-width: 769px)': {
          img: {
            transform: 'scale(1.075)',
          },
          button: {
            opacity: 1,
          },
          '.tile-content': {
            transform: 'translateY(-40px)',
          },
        },
      },
      '.tile-content': {
        transition: '0.4s ease-in-out transform',
      },
    });

    this.link = {
      height: '100%',
    };

    this.image = {
      borderRadius: '6px',
      overflow: 'hidden',
      height: '100%',
      img: {
        height: '100%',
        transition: '0.5s ease-in-out transform',
      },
    };

    this.content = () => ({
      position: 'absolute',
      transform: 'translateZ(1px)',
      zIndex: 1,
      bottom: 0,
      left: 0,
      right: 0,
      px: [6, 8],
      py: 11,
      color: 'white',
      borderRadius: '6px',
      pointerEvents: 'none',
      background:
        'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%)',
    });

    this.heading = {
      fontSize: ['22px', '28px'],
      lineHeight: '1',
      letterSpacing: '0.03em',
      fontWeight: 500,
    };

    this.body = {
      // variant: 'text.base',
      fontSize: [3, 5],
      mt: 0,
      '*': {
        p: '0 !important',
        mt: 2,
      },
    };

    this.button = {
      fontSize: 1,
      lineHeight: 1,
      maxWidth: 'auto',
      minWidth: 'auto',
      p: 0,
      px: 5,
      height: '30px',
      letterSpacing: 0,
      border: '1px solid white',
      borderRadius: '4px',
      position: 'absolute !important',
      bottom: 11,
      left: [6, 8],
      opacity: 0,
      transition: '0.4s ease-in-out opacity',
      zIndex: 2,
    };
  })()
);
