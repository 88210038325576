import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.buttonPrev = {
      display: ['none', 'flex'],
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 'calc(50% - 56px)',
      transform: 'translateY(-50%)',
      zIndex: 1,
      left: [0, '25px'],
      right: 'auto',
      transition: 'opacity 0.2s ease-in-out',
      border: '1px solid lightGray',
      borderRadius: '100%',
      width: '56px',
      height: '56px',
      backgroundColor: 'white',
    };

    this.buttonNext = {
      ...this.buttonPrev,
      left: 'auto',
      right: [0, '25px'],
    };

    this.icon = {
      width: '30px',
      userSelect: 'none',
    };
  })()
);
