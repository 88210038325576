import { Container, Box, Flex } from 'theme-ui';

import { ProductItem, Link } from '@snippets';

import { themed } from './Grid.theme';

export const Grid = themed(
  ({
    theme,
    collection,
    cta,
    ctaStyle,
    ctaAlign,
    ctaAlignMobile,
    gridDisplay,
    gridDisplayDt,
  }) => {
    return (
      <Box>
        <Container sx={theme.grid(gridDisplay, gridDisplayDt)}>
          {collection?.products?.slice(0, 24).map(({ product }, index) => {
            if (!product?.handle) return null;
            return (
              <ProductItem
                key={`${product.handle}-${index}`}
                product={product}
                index={index}
                displayQuickAdd
              />
            );
          })}
        </Container>
        <Flex
          sx={{
            display: ['flex', null, ctaAlign === 'bottom' ? null : 'none'],
            ...theme.bottom(ctaAlignMobile),
          }}
        >
          {cta?.text && (
            <Link
              variant={`buttons.${ctaStyle}`}
              href={cta.url}
              sx={{
                ...theme.button,
                ...(ctaStyle?.indexOf('Link') > -1 && {
                  fontSize: '12px',
                  lineHeight: ['13px', '18px'],
                }),
              }}
            >
              {cta.text}
            </Link>
          )}
        </Flex>
      </Box>
    );
  }
);

Grid.displayName = 'Grid';
