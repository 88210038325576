import PropTypes from 'prop-types';
import { Box } from 'theme-ui';

export function LoadingAnimation({ type = 'dark', ...props }) {
  const activeColor = type === 'light' ? 'white' : '#2D2D2D';
  const animation =
    type === 'light'
      ? 'dot-flashing-light 0.65s infinite linear alternate'
      : 'dot-flashing 0.65s infinite linear alternate';
  return (
    <Box
      data-comp={LoadingAnimation.displayName}
      sx={{
        position: 'relative',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        bg: '#C4C4C4',
        color: '#C4C4C4',
        animation,
        animationDelay: '0.425s',
        ':before, :after': {
          content: '""',
          display: 'inline-block',
          position: 'absolute',
          top: 0,
        },
        ':before': {
          left: '-14px',
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          bg: activeColor,
          color: activeColor,
          animation,
          animationDelay: '0s',
        },
        ':after': {
          left: '14px',
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          bg: '#C4C4C4',
          color: '#C4C4C4',
          animation,
          animationDelay: '0.85s',
        },
        '@keyframes dot-flashing': {
          '0%': {
            bg: '#2D2D2D',
          },
          '50%, 100%': {
            bg: '#C4C4C4',
          },
        },
        '@keyframes dot-flashing-light': {
          '0%': {
            bg: 'white',
          },
          '50%, 100%': {
            bg: '#C4C4C4',
          },
        },
      }}
      {...props}
    />
  );
}

LoadingAnimation.displayName = 'LoadingAnimation';

LoadingAnimation.propTypes = {
  type: PropTypes.oneOf(['dark', 'light']),
};
