import { Box } from 'theme-ui';

import { useProduct } from '@backpackjs/storefront';

import { useSelectedVariant } from '@hooks';

import { themed } from './JunipProductReviewsWidget.theme';

export const JunipProductReviewsWidget = themed(({ theme, cms }) => {
  const product = useProduct();
  const [selectedVariant] = useSelectedVariant();

  return selectedVariant && product ? (
    <Box
      id="JunipProductReviews"
      data-comp="JunipProductReviews"
      className="junip junip-product-review"
      data-product-id={product?.legacyResourceId}
      sx={{
        ...theme.widget,
        width: '100%',
        minHeight: cms?.sectionMinHeights || 'initial',
      }}
    />
  ) : null;
});

JunipProductReviewsWidget.displayName = 'JunipProductReviewsWidget';
