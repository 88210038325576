import { Box } from 'theme-ui';

import { Section, Picture } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './ReferralsBP.schema';

export const ReferralsBP = withInView(
  ({ cms, inView }) => {
    const { section, image, alt } = cms;

    return (
      <Section section={section} cms={cms}>
        <Box
          data-comp={ReferralsBP.displayName}
          sx={{
            position: 'relative',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {image?.src && (
            <Picture
              alt={alt}
              images={[
                {
                  src: image.src,

                  ratio: 1,
                },
              ]}
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                mb: 8,
              }}
            />
          )}

          <Box
            id="referrals-section-widget"
            sx={{
              maxWidth: '750px',
              width: '100%',
              px: 10,
              mx: 'auto',
            }}
          />
        </Box>
      </Section>
    );
  },
  { triggerOnce: true }
);

ReferralsBP.displayName = 'ReferralsBP';
ReferralsBP.Schema = Schema;
