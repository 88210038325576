import { Paragraph, Flex } from 'theme-ui';
import PropTypes from 'prop-types';
import { useCartEmpty, useSettings } from '@backpackjs/storefront';

import { Link } from '@snippets';
import { useSidebar } from '@hooks';

import { themed } from './Empty.theme';

export const Empty = themed(({ theme: themes, inSidebar, ...props }) => {
  const cartEmpty = useCartEmpty();
  const [, { closeSidebar }] = useSidebar();

  const settings = useSettings();
  const emptySettings = { ...settings?.cart?.empty };

  const theme = inSidebar ? themes.sidebar : themes.page;

  return (
    <Flex
      data-comp={Empty.displayName}
      sx={{
        ...props.sx,
        ...theme,
      }}
    >
      <Paragraph sx={theme.text}>
        {emptySettings?.message || 'Your cart is empty'}
      </Paragraph>

      {emptySettings?.cta?.text && (
        <Link
          href={emptySettings.cta.url}
          newTab={emptySettings.cta.newTab}
          onClick={() => {
            if (!cartEmpty || !inSidebar) return;
            closeSidebar();
          }}
        >
          <Paragraph sx={theme.link}>{emptySettings.cta.text}</Paragraph>
        </Link>
      )}
    </Flex>
  );
});

Empty.displayName = 'Empty';
Empty.propTypes = {
  inSidebar: PropTypes.bool,
};
