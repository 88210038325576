import { Container } from 'theme-ui';

import { Section, Markdown } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './Text.schema';
import { themed } from './Text.theme';

export const Text = withInView(
  themed(({ theme, cms, inView }) => {
    const { section, content } = cms;

    const textAlign = [content?.alignmentMob, content?.alignment];

    const headingColor = [
      content?.headingColorMob || 'currentColor',
      content?.headingColor || 'currentColor',
    ];
    const color = [
      content?.colorMob || 'currentColor',
      content?.color || 'currentColor',
    ];

    const { contentMaxWidth, enableUppercaseHeading } = { ...content };

    return (
      <Section section={section} cms={cms}>
        <Container
          data-comp={Text.displayName}
          sx={theme.wrapper(section, contentMaxWidth)}
        >
          {inView && content?.superheading && (
            <Markdown
              sx={{ ...theme.superHeading, textAlign, color: headingColor }}
              text={content?.superheading}
            />
          )}
          {inView && content?.heading && (
            <Markdown
              sx={{
                ...theme.heading(enableUppercaseHeading),
                textAlign,
                color: headingColor,
              }}
              text={content?.heading}
            />
          )}
          {inView && content?.body && (
            <Markdown
              sx={{ ...theme.body, textAlign, color }}
              text={content?.body}
            />
          )}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

Text.displayName = 'Text';
Text.Schema = Schema;
