import { useEffect, useRef, useState } from 'react';
import { Flex, Box } from 'theme-ui';
import store, { useSetRecoilState, useRecoilValue } from '@store';

import { NavItem } from './NavItem';
import { useMenu } from './useMenu';
import { themed } from './Menu.theme';
import { useMenuDrawer } from '../MenuDrawer';

export const Nav = themed(({ theme }) => {
  const { desktop } = useMenu();

  const [{ hovered }, { unHoverDrawer: closeMainDrawer }] = useMenuDrawer();
  const [activeTopNavWidth, setActiveTopNavWidth] = useState(0);
  const [activeTopNavOffsetLeft, setActiveTopNavOffsetLeft] = useState(0);

  const ref = useRef();

  const setMainMenuClicked = useSetRecoilState(store.mainNavClicked);

  useEffect(() => {
    closeMainDrawer();
    const checkIfClickedOutside = (e) => {
      if (
        e.target.attributes['data-comp']?.nodeValue === 'SubNavLink' &&
        e.target.localName !== 'a'
      )
        return;
      if (ref.current && !ref.current.contains(e.target)) {
        setMainMenuClicked(false);
        closeMainDrawer();
      }
    };
    document.addEventListener('click', checkIfClickedOutside);

    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      const activeNavItemButton = ref.current?.querySelector(
        `[data-main-nav-item="${hovered?.link?.text}"] button`
      );
      const activeNavItemLink = ref.current?.querySelector(
        `[data-main-nav-item="${hovered?.link?.text}"] a`
      );
      const activeItem = activeNavItemButton || activeNavItemLink;

      if (activeItem) {
        let { offsetWidth: width, offsetLeft } = activeItem;
        const { paddingLeft, paddingRight } = getComputedStyle(activeItem);

        width = width - parseFloat(paddingLeft) - parseFloat(paddingRight);
        offsetLeft += parseFloat(paddingLeft);
        setActiveTopNavWidth(width);
        setActiveTopNavOffsetLeft(offsetLeft);
      } else {
        setActiveTopNavWidth(0);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [hovered]);

  if (!desktop?.menu) return null;

  return (
    <Flex
      data-comp={Nav.displayName}
      as="nav"
      role="navigation"
      aria-label="Main menu"
      sx={theme.nav}
      ref={ref}
    >
      <Box
        as="ul"
        sx={{
          ...theme.navList,
          position: 'relative',
          ':after': {
            borderRadius: 1,
            content: '""',
            position: 'absolute',
            top: 'calc(100% - 2px)',
            left: 0,
            transform: `translateX(${
              activeTopNavOffsetLeft ? `${activeTopNavOffsetLeft}px` : null
            })`,
            width: activeTopNavWidth, // should by dynamic
            height: '2px',
            bg: 'text',
            transition: 'transform 0.3s ease-in-out, width 0.3s ease-in-out',
          },
        }}
      >
        {desktop?.menu?.map((item, index) => (
          <Box
            key={`${item.id}-${index}`}
            data-main-nav-item={item.link?.text}
            as="li"
            sx={theme.listItem}
          >
            <NavItem item={item} index={index} />
          </Box>
        ))}
      </Box>
    </Flex>
  );
});

Nav.displayName = 'Nav';
