import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.videoWrapper = {};
    this.playButtonStyle = {
      height: 48,
      width: 152,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ml: 11,
      mb: 11,
      ':hover': {
        transition: 'none',
      },
    };
  })()
);
