import Head from 'next/head';

export function CollectionHead({ collection = {} }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `{
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": ${JSON.stringify(process.env.NEXT_PUBLIC_SITE_TITLE || '')},
                    "item": ${JSON.stringify(process.env.SITE_URL || '')}
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": ${JSON.stringify(collection.title || '')},
                    "item": ${JSON.stringify(`${process.env.SITE_URL}/collections/${collection.handle}`)}
                  }
                ]
              }`,
        }}
      />
    </Head>
  );
}

CollectionHead.displayName = 'CollectionHead';
