import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.badge = {
      variant: 'text.label',
      fontSize: '13px',
      p: '6px 30px 6px 14px',
      textTransform: 'uppercase',
      position: 'absolute',
      zIndex: 99,
      top: 10,
      left: 0,
      background: `url('/svgs/badge_back.svg') right`,
      backgroundSize: 'cover',
    };
  })()
);
