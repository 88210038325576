import { useCallback } from 'react';
import { useCartItems, useRouter, useCustomer } from '@backpackjs/storefront';

import { useLocalStorage, useSearchspring } from '@hooks';
import store, { useRecoilState, useRecoilValue } from '@store';
import { getValidId } from '@utils';

export const useSearchResults = () => {
  const { requestSearchspring } = useSearchspring();
  const customer = useCustomer();
  const router = useRouter();
  const cartItems = useCartItems();
  const [lastViewed] = useLocalStorage('lastViewed');

  const [searchData, setSearchData] = useRecoilState(store.searchData);
  const [searchPageData, setSearchPageData] = useRecoilState(
    store.searchPageData
  );
  const [currentResultsPage] = useRecoilState(store.searchCurrentResultsPage);
  const selectedSort = useRecoilValue(store.searchSelectedSort);

  const customerId = customer?.id || '';
  const cart = cartItems?.map((item) => item.variant.sku).join(',') || '';

  const { asPath } = router;

  // Get search results from query from ss
  const getSearchResults = useCallback(
    async ({
      query,
      isAutocomplete,
      isPage,
      resultsPerPage,
      tag,
      bgFilters,
      selectedFilters,
    }) => {
      try {
        if (isPage && !query && !tag) {
          setSearchPageData(null);
          return null;
        }

        if (!isPage && !query) {
          setSearchData(null);
          return null;
        }

        const shopper = getValidId(customerId);

        const data = await requestSearchspring({
          action: 'getSearchResults',
          params: {
            query: query || '',
            bgFilters,
            resultsPerPage: isPage ? resultsPerPage || 60 : 10, // limit # of results shown; integer from 1-100
            page: isPage ? currentResultsPage : 1,
            isAutocomplete: isPage ? false : isAutocomplete,
            domain: `${process.env.SITE_URL}/${asPath?.split('?')[0]}`, // full url of current page
            cart, // comma-separated list of product skus in the user's cart
            lastViewed, // comma-separated list of 5 most recent product ids or skus
            ...(isPage && !query && tag ? { tag } : null), // tag in url for landing pages
            ...(shopper ? { shopper } : null), // customer id (if logged in)
            ...(selectedSort ? { sort: selectedSort } : null), // sort order
            ...(isPage && selectedFilters
              ? { filters: selectedFilters }
              : null), // filters
          },
        });

        let _data = data;
        const banners = data?.merchandising?.content?.inline;
        if (isPage && banners?.length) {
          const resultsLength = _data.results?.length;
          const results = banners.reduce(
            (arr, item, index) => {
              const pos = item.config.position.index;
              if (resultsLength + index <= pos) return arr;
              return [
                ...arr.slice(0, pos),
                {
                  isBanner: true,
                  value: item.value,
                  id: `banner-index-${index}`,
                },
                ...arr.slice(pos),
              ];
            },
            [..._data.results]
          );
          _data = { ..._data, results };
        }

        if (isPage) {
          setSearchPageData(_data || null);
        } else {
          setSearchData(_data || null);
        }

        return _data || null;
      } catch (error) {
        console.error(`Error with getSearchResults: ${error.message}`);
        throw error;
      }
    },
    [customerId, asPath, cart, lastViewed, currentResultsPage, selectedSort]
  );

  return {
    results: searchData?.results || [],
    totalResults: searchData?.pagination?.totalResults || 0,
    pageResults: searchPageData?.results || [],
    totalPageResults: searchPageData?.pagination?.totalResults || 0,
    merchandising: searchData?.merchandising || null,
    getSearchResults,
    setSearchData,
    setSearchPageData,
    searchTerm:
      searchData?.breadcrumbs?.find(
        ({ filterLabel }) => filterLabel === 'Search'
      )?.filterValue || '',
    searchPageTerm:
      searchPageData?.breadcrumbs?.find(
        ({ filterLabel }) => filterLabel === 'Search'
      )?.filterValue || '',
  };
};

// Example data from api
/*
{
    "pagination": {
        "totalResults": 1012,
        "begin": 1,
        "end": 10,
        "currentPage": 1,
        "totalPages": 102,
        "previousPage": 0,
        "nextPage": 2,
        "perPage": 10,
        "defaultPerPage": 40
    },
    "sorting": {
        "options": [
            {
                "field": "ss_days_since_published",
                "direction": "asc",
                "label": "Newest"
            },
            {
                "field": "title",
                "direction": "asc",
                "label": "Name: A - Z"
            },
            {
                "field": "title",
                "direction": "desc",
                "label": "Name: Z - A"
            },
            {
                "field": "ss_price",
                "direction": "asc",
                "label": "Price: Low to High"
            },
            {
                "field": "ss_price",
                "direction": "desc",
                "label": "Price: High to Low"
            }
        ]
    },
    "resultLayout": "grid",
    "results": [
        {
            "brand": "ALOHA Collection",
            "handle": "sun-reversible-tote-rooftop",
            "id": "c738094c80a36c99df177db7d35cbc05",
            "imageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/RVS_sun_rooftop_front-op_600x600.jpg?v=1676411445",
            "intellisuggestData": "eJwEwEEOwiAQBdB_oUkYBWQuAQbQrQFGtElXLJoev-9_7uvw0Ie6YJsntU7J8ugUehdy3xamzDbuIkB-l1peMVKu6UkfSgUMhgEbvuG3Nr0CAAD__3lgFOM",
            "intellisuggestSignature": "fb657635a4668a1b6189ec2f8fe8843f6ce27d750dba03e2f8852fac6eb4da9b",
            "msrp": "0",
            "name": "Reversible Tote | Sun",
            "price": "50",
            "product_type_unigram": "sun",
            "sku": "RVSTSUNN-RTOP-_-OS",
            "ss_available": "1",
            "ss_has_image": "1",
            "ss_id": "40501719367703",
            "ss_instock_pct": "100",
            "ss_is_published": "1",
            "thumbnailImageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/RVS_sun_rooftop_front-op_600x600.jpg?v=1676411445",
            "uid": "6779734917143",
            "url": "/products/sun-reversible-tote-rooftop"
        },
        {
            "brand": "ALOHA Collection",
            "handle": "sail-reversible-tote-adriatic",
            "id": "b6fe8fafae1f21330ee548dc6079001b",
            "imageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/RVS_sail_adriatic_front-op_600x600.jpg?v=1676506280",
            "intellisuggestData": "eJzKqMgpKjNjSDFPMbUwSTTTTTExTdE1MUxO0rVISrLUNU1NtEizTEtMNra0ZGAICgsOCXb09NF1dAny1I3X9Q9mMGQwYjBgMDQwNGJIL8pMAQQAAP__cukUpA",
            "intellisuggestSignature": "be57e5a54095cb4cb8f0e48c571f797668b6c3dd5effaa36b2b5f833dd8a0da1",
            "msrp": "0",
            "name": "Reversible Tote | Sail",
            "price": "50",
            "product_type_unigram": "sail",
            "sku": "RVSTSAIL-ADRI-_-OS",
            "ss_available": "1",
            "ss_has_image": "1",
            "ss_id": "40503137763351",
            "ss_instock_pct": "100",
            "ss_is_published": "1",
            "thumbnailImageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/RVS_sail_adriatic_front-op_600x600.jpg?v=1676506280",
            "uid": "6779734884375",
            "url": "/products/sail-reversible-tote-adriatic"
        },
        {
            "brand": "ALOHA Collection",
            "handle": "small-sun-rooftop",
            "id": "5e38b8c54c6f05ecae497777c01555dd",
            "imageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_sun_rooftop-op_600x600.jpg?v=1676410505",
            "intellisuggestData": "eJwEwDsOAiEQBuD_QpMw8pApPYBgBGsDjKjJVhSbPf5-v2Nbe4Be1UfXAqnzSo5Hp9i7kP-0OGW2YUWAcr_V8kqJnjU_6E25gGFhwIYv-K6_ngEAAP__djMUyw",
            "intellisuggestSignature": "83532c4071aa50c96fe57a6d2ae6d524620378edbe59ca3f552963a2e0f1adb6",
            "msrp": "0",
            "name": "Small Pouch | Sun",
            "price": "34",
            "product_type_unigram": "sun",
            "sku": "SMATSUNN-RTOP-_-OS",
            "ss_available": "1",
            "ss_has_image": "1",
            "ss_id": "40501665660951",
            "ss_instock_pct": "100",
            "ss_is_published": "1",
            "thumbnailImageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_sun_rooftop-op_600x600.jpg?v=1676410505",
            "uid": "6776481284119",
            "url": "/products/small-sun-rooftop"
        },
        {
            "brand": "ALOHA Collection",
            "handle": "small-sun-isle",
            "id": "10de7ffd61f515ae80720802cfd66a3a",
            "imageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_sun_isle-op_600x600.jpg?v=1676336064",
            "intellisuggestData": "eJzKqMgpKjNjSDFPMbUwSTTTTTExTdE1MUxO0rVISrLUNU1NtEizTEtMNra0ZGAI9nUMCQ7189P1DPZx1Y3X9Q9mMGQwYTBgMDQwNGJIL8pMAQQAAP__dBAUtA",
            "intellisuggestSignature": "98e18f141fc6d84cb6b35657aaac7f6b9d02c0e76ca913e6fb2ab5cde1519171",
            "msrp": "0",
            "name": "Small Pouch | Sun",
            "price": "34",
            "product_type_unigram": "sun",
            "sku": "SMATSUNN-ISLE-_-OS",
            "ss_available": "1",
            "ss_has_image": "1",
            "ss_id": "40500657946647",
            "ss_instock_pct": "100",
            "ss_is_published": "1",
            "thumbnailImageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_sun_isle-op_600x600.jpg?v=1676336064",
            "uid": "6776481218583",
            "url": "/products/small-sun-isle"
        },
        {
            "brand": "ALOHA Collection",
            "handle": "small-shade-seawall",
            "id": "c23066918634fe22234da51911e8d069",
            "imageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_shade_seawall-op_600x600.jpg?v=1676328088",
            "intellisuggestData": "eJzKqMgpKjNjSDFPMbUwSTTTTTExTdE1MUxO0rVISrLUNU1NtEizTEtMNra0ZGAI9nUMCfZwcdUNdg330Y3X9Q9mMGQwZTBgMDQwNGJIL8pMAQQAAP__cdoUow",
            "intellisuggestSignature": "4e80697092b09a4eb1a8e3aa8f2ab8ceba75b86969d1bb4b880331715e5d2e69",
            "msrp": "0",
            "name": "Small Pouch | Shade",
            "price": "34",
            "product_type_unigram": "shade",
            "sku": "SMATSHDE-SEWL-_-OS",
            "ss_available": "1",
            "ss_has_image": "1",
            "ss_id": "40500620918807",
            "ss_instock_pct": "100",
            "ss_is_published": "1",
            "thumbnailImageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_shade_seawall-op_600x600.jpg?v=1676328088",
            "uid": "6776481153047",
            "url": "/products/small-shade-seawall"
        },
        {
            "brand": "ALOHA Collection",
            "handle": "small-shade-rooftop",
            "id": "76a8e3fb9186ac2237a996b0ee5d4ea6",
            "imageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_shade_rooftop-op_600x600.jpg?v=1676330689",
            "intellisuggestData": "eJzKqMgpKjNjSDFPMbUwSTTTTTExTdE1MUxO0rVISrLUNU1NtEizTEtMNra0ZGAI9nUMCfZwcdUNCvEP0I3X9Q9mMGQwYzBgMDQwNGJIL8pMAQQAAP__ctYUrg",
            "intellisuggestSignature": "8ea36a158b2be43d34303b0ccecc6997b3580e3a94e429420972afcef6d42c1a",
            "msrp": "0",
            "name": "Small Pouch | Shade",
            "price": "34",
            "product_type_unigram": "shade",
            "sku": "SMATSHDE-RTOP-_-OS",
            "ss_available": "1",
            "ss_has_image": "1",
            "ss_id": "40500635631639",
            "ss_instock_pct": "100",
            "ss_is_published": "1",
            "thumbnailImageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_shade_rooftop-op_600x600.jpg?v=1676330689",
            "uid": "6776481120279",
            "url": "/products/small-shade-rooftop"
        },
        {
            "brand": "ALOHA Collection",
            "handle": "small-shade-adriatic",
            "id": "103fda0d6170c905a043547017a31df7",
            "imageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_shade_adriatic-op_600x600.jpg?v=1676331535",
            "intellisuggestData": "eJzKqMgpKjNjSDFPMbUwSTTTTTExTdE1MUxO0rVISrLUNU1NtEizTEtMNra0ZGAI9nUMCfZwcdV1dAny1I3X9Q9mMGQwZzBgMDQwNGJIL8pMAQQAAP__b2UUig",
            "intellisuggestSignature": "a165437f20ed03cf3766ff1e40730683d736ffbd9ab688716cc0a4b0c4d66472",
            "msrp": "0",
            "name": "Small Pouch | Shade",
            "price": "34",
            "product_type_unigram": "shade",
            "sku": "SMATSHDE-ADRI-_-OS",
            "ss_available": "1",
            "ss_has_image": "1",
            "ss_id": "40500637990935",
            "ss_instock_pct": "100",
            "ss_is_published": "1",
            "thumbnailImageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_shade_adriatic-op_600x600.jpg?v=1676331535",
            "uid": "6776481087511",
            "url": "/products/small-shade-adriatic"
        },
        {
            "brand": "ALOHA Collection",
            "handle": "small-sea-isle",
            "id": "2dc6d39700e7b79a473b93b39c8528c3",
            "imageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_sea_isle-op_600x600.jpg?v=1676332893",
            "intellisuggestData": "eJwEwLEOAiEMBuD_hZpQBWxHBgYTjUPdDVBREyeGyz3-fd_9v7YMv3iS2DJ5TE6RRyfpXSm9m0ydbZxVAbuXp9VS6Gq3Si96GBiCAA58wmf9_AgAAP__b6kUjg",
            "intellisuggestSignature": "7aaf1f9a46be666325bf97acb5772492e258cd57d482cf0ce806e53982950eb6",
            "msrp": "0",
            "name": "Small Pouch | Sea",
            "price": "34",
            "product_type_unigram": "sea",
            "sku": "SMATSEAA-ISLE-_-OS",
            "ss_available": "1",
            "ss_has_image": "1",
            "ss_id": "40500640481303",
            "ss_instock_pct": "100",
            "ss_is_published": "1",
            "thumbnailImageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_sea_isle-op_600x600.jpg?v=1676332893",
            "uid": "6776481021975",
            "url": "/products/small-sea-isle"
        },
        {
            "brand": "ALOHA Collection",
            "handle": "small-sail-sandstone",
            "id": "d11c74d093cbabf4a067a95893a9566e",
            "imageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_sail_sandstone-op_600x600.jpg?v=1676411923",
            "intellisuggestData": "eJwEwL0OgyAQB_D_C13CtUC5kbFJP4ZzN8CJmjgxGB_f33Yd44ywl4XkSyTzwchzq5RqFQpLSV16aU8RQL950vz-kOaf0kx_BUPgwI4fWMdudwAAAP__cfcUpg",
            "intellisuggestSignature": "de22d29bde9ed3792ccdc8b8b594e1ace4db3401d11a64546c86e2698801b969",
            "msrp": "0",
            "name": "Small Pouch | Sail",
            "price": "34",
            "product_type_unigram": "sail",
            "sku": "SMATSAIL-SANS-_-OS",
            "ss_available": "1",
            "ss_has_image": "1",
            "ss_id": "40501749153815",
            "ss_instock_pct": "100",
            "ss_is_published": "1",
            "thumbnailImageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_sail_sandstone-op_600x600.jpg?v=1676411923",
            "uid": "6776480989207",
            "url": "/products/small-sail-sandstone"
        },
        {
            "brand": "ALOHA Collection",
            "handle": "small-sail-adriatic",
            "id": "fbee04381411d177561cdf7ada80dd1c",
            "imageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_sail_adriatic-op_600x600.jpg?v=1676504978",
            "intellisuggestData": "eJwEwLEKwyAQBuD_hQ68Vq03Cl2ElkLNHtSLSSCTQ8jj59uuY5we-lIXbPGk1ilZbpVCrUJuKaFLL-0pAuRvnHJMH4rvf6KZfhkMNjBgww-sY9c7AAD__4P0FLk",
            "intellisuggestSignature": "b9f2cf704539c4c3fc9a0b3a6131e0ed36287d12cd7fb203a7003958710564f0",
            "msrp": "0",
            "name": "Small Pouch | Sail",
            "price": "34",
            "product_type_unigram": "sail",
            "sku": "SMATSAIL-ADRI-_-OS",
            "ss_available": "1",
            "ss_has_image": "1",
            "ss_id": "40503039000599",
            "ss_instock_pct": "100",
            "ss_is_published": "1",
            "thumbnailImageUrl": "https://cdn.shopify.com/s/files/1/1119/6322/products/sm_sail_adriatic-op_600x600.jpg?v=1676504978",
            "uid": "6776480923671",
            "url": "/products/small-sail-adriatic"
        }
    ],
    "facets": [
        {
            "field": "collection_name",
            "label": "Collection",
            "type": null,
            "multiple": "multiple-union",
            "collapse": 0,
            "facet_active": 0,
            "values": [
                {
                    "active": false,
                    "type": "value",
                    "value": "Big  ALOHA  Friday  Sale  2022",
                    "label": "Big  ALOHA  Friday  Sale  2022",
                    "count": 502
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "SHOP  ALL",
                    "label": "SHOP  ALL",
                    "count": 465
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Partner  Discount  Collection",
                    "label": "Partner  Discount  Collection",
                    "count": 460
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "All  Pouches",
                    "label": "All  Pouches",
                    "count": 257
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Under  $50",
                    "label": "Under  $50",
                    "count": 252
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ALOHA  Botanics",
                    "label": "ALOHA  Botanics",
                    "count": 215
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ALOHA  Classics",
                    "label": "ALOHA  Classics",
                    "count": 206
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Evergreen",
                    "label": "Evergreen",
                    "count": 171
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ʻŌlelo  Hawaiʻi",
                    "label": "ʻŌlelo  Hawaiʻi",
                    "count": 133
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "New  Arrivals",
                    "label": "New  Arrivals",
                    "count": 125
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Men's",
                    "label": "Men's",
                    "count": 112
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Totes",
                    "label": "Totes",
                    "count": 93
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Black  +  White",
                    "label": "Black  +  White",
                    "count": 90
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Small  Pouches",
                    "label": "Small  Pouches",
                    "count": 87
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Mid  Pouches",
                    "label": "Mid  Pouches",
                    "count": 77
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Endless  Summer  Sale",
                    "label": "Endless  Summer  Sale",
                    "count": 76
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ALOHA  Last  Chance",
                    "label": "ALOHA  Last  Chance",
                    "count": 71
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ALOHA  Holiday",
                    "label": "ALOHA  Holiday",
                    "count": 67
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "All  Collaborations",
                    "label": "All  Collaborations",
                    "count": 67
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Mini  Pouches",
                    "label": "Mini  Pouches",
                    "count": 60
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Under  $25",
                    "label": "Under  $25",
                    "count": 58
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "APPAREL  +  ACCESSORIES",
                    "label": "APPAREL  +  ACCESSORIES",
                    "count": 56
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Day  Trippers",
                    "label": "Day  Trippers",
                    "count": 54
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Muʻumuʻu  Month",
                    "label": "Muʻumuʻu  Month",
                    "count": 54
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Splash-Proof",
                    "label": "Splash-Proof",
                    "count": 46
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Accessories",
                    "label": "Accessories",
                    "count": 45
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Samudra",
                    "label": "Samudra",
                    "count": 42
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Black  and  White",
                    "label": "Black  and  White",
                    "count": 38
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Fan  Favorites",
                    "label": "Fan  Favorites",
                    "count": 38
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ALOHA  Abroad",
                    "label": "ALOHA  Abroad",
                    "count": 34
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Hip  Pack",
                    "label": "Hip  Pack",
                    "count": 31
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "MAX  Pouches",
                    "label": "MAX  Pouches",
                    "count": 31
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Lost  Tropics",
                    "label": "Lost  Tropics",
                    "count": 30
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Fall  Edit",
                    "label": "Fall  Edit",
                    "count": 29
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Reversible  Totes",
                    "label": "Reversible  Totes",
                    "count": 24
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Big  Island",
                    "label": "Big  Island",
                    "count": 23
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Neutrals",
                    "label": "Neutrals",
                    "count": 21
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Stop  One:  Minuit  à  Paris",
                    "label": "Stop  One:  Minuit  à  Paris",
                    "count": 21
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Minuit  à  Paris  Suitcase",
                    "label": "Minuit  à  Paris  Suitcase",
                    "count": 20
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Tara's  Suitcase",
                    "label": "Tara's  Suitcase",
                    "count": 19
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Tara's  Suitcase  2",
                    "label": "Tara's  Suitcase  2",
                    "count": 19
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "APPAREL",
                    "label": "APPAREL",
                    "count": 18
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Lost  Tropics  Suitcase",
                    "label": "Lost  Tropics  Suitcase",
                    "count": 18
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ALOHA  Summer",
                    "label": "ALOHA  Summer",
                    "count": 17
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Men's  Suitcase  Collection",
                    "label": "Men's  Suitcase  Collection",
                    "count": 17
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Nani's  Suitcase",
                    "label": "Nani's  Suitcase",
                    "count": 17
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ALOHA  Classics  Suitcase",
                    "label": "ALOHA  Classics  Suitcase",
                    "count": 16
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Home  For  the  Holidays",
                    "label": "Home  For  the  Holidays",
                    "count": 16
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "TAHITI",
                    "label": "TAHITI",
                    "count": 16
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Animal  Prints",
                    "label": "Animal  Prints",
                    "count": 15
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Painted  Birds",
                    "label": "Painted  Birds",
                    "count": 15
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Pineapples",
                    "label": "Pineapples",
                    "count": 15
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "End  of  the  Road",
                    "label": "End  of  the  Road",
                    "count": 14
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Holo  Holos",
                    "label": "Holo  Holos",
                    "count": 14
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Tahiti  Suitcase  Collection",
                    "label": "Tahiti  Suitcase  Collection",
                    "count": 14
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ALL  COLLABS",
                    "label": "ALL  COLLABS",
                    "count": 13
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ALOHA  Metallics",
                    "label": "ALOHA  Metallics",
                    "count": 13
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ALOHA  Moms",
                    "label": "ALOHA  Moms",
                    "count": 13
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Active",
                    "label": "Active",
                    "count": 13
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Beach",
                    "label": "Beach",
                    "count": 13
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Pop  of  Color",
                    "label": "Pop  of  Color",
                    "count": 13
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Waipiʻo",
                    "label": "Waipiʻo",
                    "count": 13
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ALOHA  Favorites  Gift  Guide",
                    "label": "ALOHA  Favorites  Gift  Guide",
                    "count": 12
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ALOHA  Favorites  Holiday  Gift  Guide",
                    "label": "ALOHA  Favorites  Holiday  Gift  Guide",
                    "count": 12
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ALOHA  Mom's  Gift  Guide",
                    "label": "ALOHA  Mom's  Gift  Guide",
                    "count": 12
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Coldies",
                    "label": "Coldies",
                    "count": 12
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Frequent  Flyer  Gift  Guide",
                    "label": "Frequent  Flyer  Gift  Guide",
                    "count": 12
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "The  Guys'  Gift  Guide",
                    "label": "The  Guys'  Gift  Guide",
                    "count": 12
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Tiare  Ti",
                    "label": "Tiare  Ti",
                    "count": 12
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Tropics",
                    "label": "Tropics",
                    "count": 12
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Under  $40  Gift  Guide",
                    "label": "Under  $40  Gift  Guide",
                    "count": 12
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Valentine's  Day  Gift  Guide",
                    "label": "Valentine's  Day  Gift  Guide",
                    "count": 12
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Beach  Lovers  Gift  Guide",
                    "label": "Beach  Lovers  Gift  Guide",
                    "count": 11
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Outdoor  Enthusiasts",
                    "label": "Outdoor  Enthusiasts",
                    "count": 11
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "_Frequent  Flyer  Gift  Guide",
                    "label": "_Frequent  Flyer  Gift  Guide",
                    "count": 11
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Dopp  Kits",
                    "label": "Dopp  Kits",
                    "count": 10
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Mini  Mini  Duffles",
                    "label": "Mini  Mini  Duffles",
                    "count": 10
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Travel",
                    "label": "Travel",
                    "count": 10
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "With  Love  From  Paradise",
                    "label": "With  Love  From  Paradise",
                    "count": 10
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "$40  and  Under",
                    "label": "$40  and  Under",
                    "count": 9
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "The  Big  Island",
                    "label": "The  Big  Island",
                    "count": 9
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Duffles",
                    "label": "Duffles",
                    "count": 8
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Featured  Collection",
                    "label": "Featured  Collection",
                    "count": 8
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Mizu",
                    "label": "Mizu",
                    "count": 8
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Next  Stop",
                    "label": "Next  Stop",
                    "count": 8
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Pape'ete",
                    "label": "Pape'ete",
                    "count": 8
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Seaside",
                    "label": "Seaside",
                    "count": 8
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Coco  Palms",
                    "label": "Coco  Palms",
                    "count": 7
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Fitness  Lovers",
                    "label": "Fitness  Lovers",
                    "count": 7
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Indigo",
                    "label": "Indigo",
                    "count": 7
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Paradise  on  Repeat",
                    "label": "Paradise  on  Repeat",
                    "count": 7
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Big  Island  Hibiscus",
                    "label": "Big  Island  Hibiscus",
                    "count": 6
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Day  Palms",
                    "label": "Day  Palms",
                    "count": 6
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Fashion  Forward",
                    "label": "Fashion  Forward",
                    "count": 6
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Gifts  For  the  Guys",
                    "label": "Gifts  For  the  Guys",
                    "count": 6
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Gifts  for  Moms",
                    "label": "Gifts  for  Moms",
                    "count": 6
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Staycation",
                    "label": "Staycation",
                    "count": 6
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ʻOHANA  Pareo",
                    "label": "ʻOHANA  Pareo",
                    "count": 6
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Frequent  Flyer",
                    "label": "Frequent  Flyer",
                    "count": 5
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Homebody",
                    "label": "Homebody",
                    "count": 5
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Island  Adventures  //  VOL.  01",
                    "label": "Island  Adventures  //  VOL.  01",
                    "count": 5
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Island  Adventures  //  VOL.  02",
                    "label": "Island  Adventures  //  VOL.  02",
                    "count": 5
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Jungle",
                    "label": "Jungle",
                    "count": 5
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Minimalist",
                    "label": "Minimalist",
                    "count": 5
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Pillow  Covers",
                    "label": "Pillow  Covers",
                    "count": 5
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Puakenikeni",
                    "label": "Puakenikeni",
                    "count": 5
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Snow  Leopard",
                    "label": "Snow  Leopard",
                    "count": 5
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Beach  Babes",
                    "label": "Beach  Babes",
                    "count": 4
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Dawn",
                    "label": "Dawn",
                    "count": 4
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Featured  Collection  #2",
                    "label": "Featured  Collection  #2",
                    "count": 4
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Maximalist",
                    "label": "Maximalist",
                    "count": 4
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Muʻumuʻu  Month  Lookbook",
                    "label": "Muʻumuʻu  Month  Lookbook",
                    "count": 4
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Pareos",
                    "label": "Pareos",
                    "count": 4
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Resort",
                    "label": "Resort",
                    "count": 4
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Shades  of  Indigo",
                    "label": "Shades  of  Indigo",
                    "count": 4
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Watercolor",
                    "label": "Watercolor",
                    "count": 4
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Kealopiko",
                    "label": "Kealopiko",
                    "count": 3
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Kupu  Aʻe",
                    "label": "Kupu  Aʻe",
                    "count": 3
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Le  Voyageur",
                    "label": "Le  Voyageur",
                    "count": 3
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Resort  Lovers",
                    "label": "Resort  Lovers",
                    "count": 3
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Summer  Days",
                    "label": "Summer  Days",
                    "count": 3
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "ʻIWA  Athletic  Company",
                    "label": "ʻIWA  Athletic  Company",
                    "count": 3
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Hawaiian  Time",
                    "label": "Hawaiian  Time",
                    "count": 2
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Kim  Sielbeck",
                    "label": "Kim  Sielbeck",
                    "count": 2
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Neon  Summer",
                    "label": "Neon  Summer",
                    "count": 2
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Nā  Pali  Coast",
                    "label": "Nā  Pali  Coast",
                    "count": 2
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Speaqua",
                    "label": "Speaqua",
                    "count": 2
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Summer  Favorites",
                    "label": "Summer  Favorites",
                    "count": 2
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Westside",
                    "label": "Westside",
                    "count": 2
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Zipper  Totes",
                    "label": "Zipper  Totes",
                    "count": 2
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Bali  Escape",
                    "label": "Bali  Escape",
                    "count": 1
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Charter",
                    "label": "Charter",
                    "count": 1
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Final  Few",
                    "label": "Final  Few",
                    "count": 1
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Holiday  on  the  High  Seas",
                    "label": "Holiday  on  the  High  Seas",
                    "count": 1
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Laylow",
                    "label": "Laylow",
                    "count": 1
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Mānoa",
                    "label": "Mānoa",
                    "count": 1
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Waikiki  Holiday",
                    "label": "Waikiki  Holiday",
                    "count": 1
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "YWCA  Kauaʻi",
                    "label": "YWCA  Kauaʻi",
                    "count": 1
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "__",
                    "label": "__",
                    "count": 1
                }
            ]
        },
        {
            "field": "product_type",
            "label": "Product Type",
            "type": null,
            "multiple": "multiple-union",
            "collapse": 0,
            "facet_active": 0,
            "values": [
                {
                    "active": false,
                    "type": "value",
                    "value": "Small Pouch",
                    "label": "Small Pouch",
                    "count": 197
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Mid Pouch",
                    "label": "Mid Pouch",
                    "count": 169
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Mini Pouch",
                    "label": "Mini Pouch",
                    "count": 158
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Day Tripper",
                    "label": "Day Tripper",
                    "count": 119
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "MAX Pouch",
                    "label": "MAX Pouch",
                    "count": 89
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Mini Hip Pack",
                    "label": "Mini Hip Pack",
                    "count": 57
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Reversible Tote",
                    "label": "Reversible Tote",
                    "count": 50
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Holo Holo",
                    "label": "Holo Holo",
                    "count": 45
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Mini Mini Duffle",
                    "label": "Mini Mini Duffle",
                    "count": 17
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Throw",
                    "label": "Throw",
                    "count": 14
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Coldies",
                    "label": "Coldies",
                    "count": 13
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Dopp Kit",
                    "label": "Dopp Kit",
                    "count": 12
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Duffle",
                    "label": "Duffle",
                    "count": 12
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Pareo",
                    "label": "Pareo",
                    "count": 10
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Aloha Logo Decal",
                    "label": "Aloha Logo Decal",
                    "count": 6
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Hat",
                    "label": "Hat",
                    "count": 6
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Pillow Cover",
                    "label": "Pillow Cover",
                    "count": 6
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Accessories",
                    "label": "Accessories",
                    "count": 4
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Aloha Travel Mugs",
                    "label": "Aloha Travel Mugs",
                    "count": 4
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Socks",
                    "label": "Socks",
                    "count": 4
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Turkish Towels",
                    "label": "Turkish Towels",
                    "count": 4
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Aloha Hoodie",
                    "label": "Aloha Hoodie",
                    "count": 2
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Apparel",
                    "label": "Apparel",
                    "count": 2
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Pullover",
                    "label": "Pullover",
                    "count": 2
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Zipper Tote",
                    "label": "Zipper Tote",
                    "count": 2
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Barnicle 2.0",
                    "label": "Barnicle 2.0",
                    "count": 1
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Cozy Pullover",
                    "label": "Cozy Pullover",
                    "count": 1
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Cruiser H2.0",
                    "label": "Cruiser H2.0",
                    "count": 1
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Gift Cards",
                    "label": "Gift Cards",
                    "count": 1
                },
                {
                    "active": false,
                    "type": "value",
                    "value": "Tee",
                    "label": "Tee",
                    "count": 1
                }
            ]
        },
        {
            "field": "vendor",
            "label": "Brand",
            "type": null,
            "multiple": "multiple-union",
            "collapse": 0,
            "facet_active": 0,
            "values": [
                {
                    "active": false,
                    "type": "value",
                    "value": "ALOHA Collection",
                    "label": "ALOHA Collection",
                    "count": 1012
                }
            ]
        },
        {
            "field": "ss_price",
            "label": "Price",
            "type": "list",
            "multiple": "multiple-union",
            "collapse": 0,
            "facet_active": 0,
            "values": [
                {
                    "active": false,
                    "type": "range",
                    "low": "*",
                    "high": "10",
                    "label": "Less than $10",
                    "count": 19
                },
                {
                    "active": false,
                    "type": "range",
                    "low": "10",
                    "high": "20",
                    "label": "$10 to $20",
                    "count": 4
                },
                {
                    "active": false,
                    "type": "range",
                    "low": "20",
                    "high": "40",
                    "label": "$20 to $40",
                    "count": 375
                },
                {
                    "active": false,
                    "type": "range",
                    "low": "40",
                    "high": "60",
                    "label": "$40 to $60",
                    "count": 425
                },
                {
                    "active": false,
                    "type": "range",
                    "low": "60",
                    "high": "80",
                    "label": "$60 to $80",
                    "count": 199
                },
                {
                    "active": false,
                    "type": "range",
                    "low": "80",
                    "high": "100",
                    "label": "$80 to $100",
                    "count": 3
                },
                {
                    "active": false,
                    "type": "range",
                    "low": "100",
                    "high": "150",
                    "label": "$100 to $150",
                    "count": 2
                }
            ]
        }
    ],
    "breadcrumbs": [],
    "filterSummary": [],
    "merchandising": {
        "redirect": "",
        "is_elevated": [],
        "elevated": [],
        "removed": [],
        "content": {},
        "facets": [],
        "facetsHide": [],
        "experiments": [],
        "variants": [],
        "personalized": false,
        "triggeredCampaigns": null
    }
}

*/
