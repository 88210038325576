import { WishlistPage, Section } from '@snippets';
import { withInView } from '@utils';
import { Schema } from './Wishlist.schema';

export const Wishlist = withInView(
  ({ cms }) => {
    const { section } = cms;
    return (
      <Section section={section} cms={cms}>
        <WishlistPage cms={cms} />
      </Section>
    );
  },
  { triggerOnce: true }
);

Wishlist.Schema = Schema;
