import { useRef } from 'react';
import { Text, Button, Box } from 'theme-ui';

import { Link } from '@snippets';

import { useMenuDrawer } from '../MenuDrawer';
import { themed } from './Menu.theme';

export const NavItem = themed(({ theme, item }) => {
  const ref = useRef();
  const [{ hovered }, { hoverItem, closeDrawerAndNavigate, unHoverDrawer }] =
    useMenuDrawer();

  const isLink = !!item?.link?.url;
  const isHovered = item.link?.text === hovered?.link?.text;

  return (
    // Level 1 Menu nav item
    <Box
      as={isLink ? Link : Button}
      variant={isLink ? '' : 'buttons.plain'}
      {...(isLink ? { href: item.link.url } : {})}
      onClick={() => {
        if (isLink) {
          closeDrawerAndNavigate();
        }
      }}
      ref={ref}
      data-comp={NavItem.displayName}
      onMouseEnter={() => {
        hoverItem(item);
      }}
      sx={{
        ...theme.item,
        ...(item.highlighted ? theme.item.highlighted : theme.item.regular),
        ...(item?.linkColor && { color: item.linkColor }),
      }}
    >
      {item.link?.text}
    </Box>
  );
});

NavItem.displayName = 'NavItem';
