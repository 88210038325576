import { useMemo } from 'react';

export const useProductSwiperParams = (
  { isProductRecs } = { isProductRecs: false }
) => {
  const productRecsParams = useMemo(
    () => ({
      spaceBetween: 20,
      slidesPerView: 1.6,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: true,
      centerInsufficientSlides: true,
      slidesPerColumnFill: 'row',
      autoHeight: true,
      navigation: false,
      loop: false,
      watchOverflow: true,
      grabCursor: true,
      breakpoints: {
        480: {
          spaceBetween: 20,
          slidesPerView: 1.6,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: true,
          centerInsufficientSlides: true,
        },
        600: {
          spaceBetween: 20,
          slidesPerView: 1.6,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: true,
          centerInsufficientSlides: true,
        },
        768: {
          spaceBetween: 20,
          slidesPerView: 3.2,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: true,
          centerInsufficientSlides: false,
        },
        1024: {
          spaceBetween: 20,
          slidesPerView: 4,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: false,
          centerInsufficientSlides: false,
        },
        1280: {
          spaceBetween: 20,
          slidesPerView: 4,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: false,
          centerInsufficientSlides: false,
        },
      },
    }),
    []
  );

  const productCarouselParams = useMemo(
    () => ({
      spaceBetween: 12,
      slidesPerView: 2.25,
      slidesOffsetBefore: 20,
      slidesOffsetAfter: 20,
      slidesPerGroup: 1,
      centeredSlides: false,
      centeredSlidesBounds: true,
      centerInsufficientSlides: false,
      slidesPerColumnFill: 'row',
      autoHeight: true,
      navigation: false,
      loop: false,
      watchOverflow: true,
      grabCursor: true,
      breakpoints: {
        480: {
          spaceBetween: 12,
          slidesPerView: 2.2,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: true,
          centerInsufficientSlides: false,
        },
        600: {
          spaceBetween: 20,
          slidesPerView: 1.6,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: true,
          centerInsufficientSlides: false,
        },
        768: {
          spaceBetween: 20,
          slidesPerView: 3.2,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: true,
          centerInsufficientSlides: false,
        },
        1024: {
          spaceBetween: 20,
          slidesPerView: 4,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: false,
          centerInsufficientSlides: false,
        },
        1280: {
          spaceBetween: 20,
          slidesPerView: 4,
          slidesOffsetBefore: 0,
          slidesOffsetAfter: 0,
          slidesPerGroup: 1,
          centeredSlides: false,
          centeredSlidesBounds: false,
          centerInsufficientSlides: false,
        },
      },
    }),
    []
  );

  return isProductRecs ? productRecsParams : productCarouselParams;
};
