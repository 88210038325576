import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = {
      mb: 5,
    };

    this.heading = {
      fontSize: ['22px', '28px'],
      lineHeight: ['24px', '38px'],
      mb: 5,
    };

    this.wrapper = (displayTypeMobile) => ({
      display: [
        displayTypeMobile === 'stacked' ? 'none' : 'block',
        null,
        null,
        'block',
      ],
    });

    this.grid = {
      display: ['grid', null, null, 'none'],
      gridTemplateColumns: ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      gap: 5,
      px: 10,
    };
  })()
);
