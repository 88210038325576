import Head from 'next/head';

export function OrganizationHead() {
  const sameAs = [
    `https://www.instagram.com/${
      process.env.NEXT_PUBLIC_SOCIAL_INSTAGRAM || 'alohacollection'
    }`,
    `https://www.tiktok.com/${
      process.env.NEXT_PUBLIC_SOCIAL_TIKTOK || '@alohacollection'
    }`,
    `https://www.pinterest.com/${
      process.env.NEXT_PUBLIC_SOCIAL_PINTEREST || 'alohacollection'
    }`,
    `https://www.facebook.com/${
      process.env.NEXT_PUBLIC_SOCIAL_FACEBOOK || 'alohacollectionca'
    }`,
  ];

  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": ${JSON.stringify(
              process.env.NEXT_PUBLIC_SITE_TITLE || 'ALOHA Collection'
            )},
            "legalName": "ALOHA Collection",
            "description": ${JSON.stringify(
              process.env.SITE_DESCRIPTION ||
                "ALOHA Collection's bags and pouches are a perfect everyday essential! Made out of a special technical material to keep your dry stuff dry and your wet stuff separate!"
            )},
            "url": ${JSON.stringify(process.env.SITE_URL || '')},
            "sameAs": ${JSON.stringify(sameAs)},
            "logo": ${JSON.stringify(
              process.env.NEXT_PUBLIC_SITE_LOGO ||
                'https://cdn.shopify.com/s/files/1/1119/6322/files/aloha-logo.png?v=1668731836'
            )},
            "image": ${JSON.stringify(
              process.env.NEXT_PUBLIC_SITE_LOGO ||
                'https://cdn.shopify.com/s/files/1/1119/6322/files/aloha-logo.png?v=1668731836'
            )},
            "foundingDate": "2014",
            "founder": [
                {
                    "name": "Heather Aiu",
                    "url": "https://www.linkedin.com/in/heather-aiu-1516aa4a",
                    "hasCredential": "International Business Major",
                    "jobTitle": "Founder/Co-Founder"
                },
                {
                    "name": "Rachael Leina'ala Soares",
                    "url": "https://www.linkedin.com/in/rachael-leina-ala-soares-71aa8b39",
                    "jobTitle": "Founder/Co-Founder"
                }
            ],
            "slogan": "To encourage travel, adventure, healthy and active lifestyles, and to share the ALOHA.",
            "knowsAbout": ["splash proof accessories","beach accessories","lake accessories","river accessories","beach bags","world travel","conscious traveling", "travel bags"],
            "hasOfferCatalog": {
            "@type": "OfferCatalog",
            "name": "Travel Bags",
            "itemListElement": [
                {
                    "@type": "Offer",
                    "itemOffered": {
                        "@type": "Service",
                        "name": "Pouches",
                        "url": "https://aloha-collection.com/pages/all-pouches"
                    }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                      "@type": "Service",
                      "name": "Tote Bags",
                      "url": "https://aloha-collection.com/pages/all-totes"
                  }
                },
                {
                "@type": "Offer",
                "itemOffered": {
                    "@type": "Service",
                    "name": "Hip Packs",
                    "url": "https://aloha-collection.com/collections/hip-packs"
                    }
                },
                {
                "@type": "Offer",
                "itemOffered": {
                    "@type": "Service",
                    "name": "Duffle Bags",
                    "url": "https://aloha-collection.com/pages/all-duffles"
                    }
                },
                {
                "@type": "Offer",
                "itemOffered": {
                    "@type": "Service",
                    "name": "Dopp Kits",
                    "url": "https://aloha-collection.com/collections/dopp-kits"
                    }
                }
            ]
            }
          }`,
        }}
      />
    </Head>
  );
}

OrganizationHead.displayName = 'OrganizationHead';
