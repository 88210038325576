import { Box } from 'theme-ui';
import dynamic from 'next/dynamic';

import { themed } from './Search.theme';
import { Empty } from './Results/Empty';

const Input = dynamic(() => import('./Input').then((module) => module.Input), {
  ssr: false,
  suspense: false,
});

const Suggestions = dynamic(
  () => import('./Suggestions').then((module) => module.Suggestions),
  { ssr: false, suspense: false }
);

const SuggestionsHeader = dynamic(
  () =>
    import('./SuggestionsHeader').then((module) => module.SuggestionsHeader),
  { ssr: false, suspense: false }
);
const Results = dynamic(
  () => import('./Results').then((module) => module.Results),
  { ssr: false, suspense: false }
);

export const Search = themed(({ theme }) => {
  return (
    <Box data-comp={Search.displayName}>
      <Box sx={theme.stickySearch}>
        <Box sx={theme.inputWrapper}>
          <Input />
        </Box>
        <SuggestionsHeader />
      </Box>
      {/* No results */}
      <Empty />
      {/* If results — render results, else nothing found empty */}
      <Results />
      {/* If no results — show suggestions */}
      <Suggestions />
    </Box>
  );
});

Search.displayName = 'Search';
