import { useMemo } from 'react';

import { Button, Box } from 'theme-ui';

import { Picture, Link, Video } from '@snippets';

import { TileContent } from './TileContent';
import { themed } from './Tile.theme';

export const Tile = themed(({ theme, imageRatio, tile, ...rawProps }) => {
  const { inputRef, ...props } = rawProps;
  const { media, image, cta } = { ...tile };

  return (
    <Box data-comp={Tile.displayName} {...props} sx={theme.slide(imageRatio)}>
      <Link href={cta?.url} tabIndex="-1" sx={theme.link}>
        <Box sx={{ display: ['block', 'none'] }}>
          {media?.mediaTypeMb === 'image' ? (
            <Picture
              alt={image?.alt || 'Tile picture'}
              images={[
                {
                  src: media?.image?.imageMob?.src,
                  width: 768,
                  ratio: 1,
                },
              ]}
              sx={theme.image}
            />
          ) : (
            <Video
              videoSources={[media?.video?.videoMb]}
              videoTitles={[media?.video?.titleMb]}
            />
          )}
        </Box>

        <Box sx={{ display: ['none', 'block'] }}>
          {media?.mediaTypeDt === 'image' ? (
            <Picture
              alt={image?.alt || 'Tile picture'}
              images={[
                {
                  src: media?.image?.imageDt?.src,
                  width: 700,
                  ratio: 1,
                },
              ]}
              sx={theme.image}
            />
          ) : (
            <Video
              videoSources={[media?.video?.videoDt]}
              videoTitles={[media?.video?.titleDt]}
            />
          )}
        </Box>

        {cta?.text && (
          <Button
            href={cta.url}
            variant="buttons.outlineWhite"
            sx={theme.button}
          >
            {cta.text}
          </Button>
        )}
      </Link>

      <TileContent item={tile} />
    </Box>
  );
});

Tile.displayName = 'Tile';
