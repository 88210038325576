import { section, color } from '@settings/common';

export const Schema = () => ({
  label: 'Testimonials',
  key: 'testimonials',
  fields: [
    {
      label: 'Testimonial settings',
      name: 'slider',
      description:
        'Autoplay, delay, transition effect, bullet color, content position',
      component: 'group',
      fields: [
        {
          label: 'Enable autoplay',
          name: 'autoplay',
          description: 'Refresh page to observe change',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          name: 'delay',
          component: 'number',
          description: 'Refresh page to observe change',
          label: 'Delay between transitions (ms)',
        },
        {
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          name: 'effect',
          label: 'Effect between transitions',
          description: 'Refresh page to observe change',
          options: [
            { label: 'Slide', value: 'slide' },
            { label: 'Fade', value: 'fade' },
          ],
        },
        {
          name: 'opacity',
          component: 'number',
          description: 'Refresh page to observe change',
          label: 'Opacity unselected thumbs (%)',
          defaultValue: 50,
        },
      ],
      defaultValue: {
        autoplay: true,
        delay: 5000,
        effect: 'fade',
        defaultValue: 50,
      },
    },

    {
      label: 'Testimonials',
      name: 'testimonials',
      description: 'Text, image and color',
      component: 'group-list',
      itemProps: {
        label: '{{item.testimonialText}}',
      },
      defaultItem: {
        testimonialText:
          'Aloha collection provides reliable, versatile, & stylish bags fit for any occasion.',
        testimonialColor: '',
        alt: 'Logo',
        image: {
          src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/forbes-logo.png?v=1711910208',
        },
      },
      fields: [
        {
          name: 'testimonialText',
          component: 'text',
          label: 'Testimonial Text',
          description: 'Write the testimonial text. Omit the " " ',
        },
        {
          label: 'Text color',
          name: 'testimonialColor',
          component: 'color',
        },
        {
          name: 'alt',
          component: 'text',
          description: 'Brief description of image',
          label: 'Alt',
        },
        {
          label: 'Image',
          name: 'image',
          component: 'image',
          description: 'image or logo',
        },
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
      ],
      defaultValue: [
        {
          testimonialText:
            'Aloha collection provides reliable, versatile, & stylish bags fit for any occasion.',
          testimonialColor: '',
          alt: 'Logo',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/forbes-logo.png?v=1711910208',
          },
        },
        {
          testimonialText:
            'Aloha collection provides reliable, versatile, & stylish bags fit for any occasion 2.',
          testimonialColor: '',
          alt: 'Logo',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/forbes-logo.png?v=1711910208',
          },
        },
        {
          testimonialText:
            'Aloha collection provides reliable, versatile, & stylish bags fit for any occasion 3.',
          testimonialColor: '',
          alt: 'Logo',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/forbes-logo.png?v=1711910208',
          },
        },
      ],
    },
    {
      ...section,
      defaultValue: {
        bgColor: color.value.black,
        color: color.value.white,
        desktop: {
          container: 'container',
          mt: 'none',
          py: 'm',
        },
        mobile: {
          container: 'container',
          mt: 'none',
          py: 'm',
        },
      },
    },
  ],
});
