import { useEffect } from 'react';
import Script from 'next/script';

export function FueledSnapchatScript({ withKey: snapchatPixelId }) {
  function snapchatInit(event) {
    try {
      if (window.snaptr) {
        const customerEmail =
          event?.detail?.user_properties?.customer_email || '';

        snaptr('init', snapchatPixelId, {
          user_email: customerEmail,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  function snapchatPageViewListener() {
    try {
      if (window.snaptr) {
        window.snaptr('track', 'PAGE_VIEW');
      }
    } catch (e) {
      console.log(e);
    }
  }

  function snapchatViewProductListListener(event) {
    try {
      if (window.snaptr) {
        const collection = event?.detail?.ecommerce?.collection_id;
        const currencyCode = event?.detail?.ecommerce?.currency_code;
        const productIds = event?.detail?.ecommerce?.products.map((product) =>
          product.product_id.toString()
        );

        snaptr('track', 'VIEW_CONTENT', {
          item_category: collection,
          item_ids: [productIds],
          currency: currencyCode || 'USD',
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  function snapchatViewProductListener(event) {
    try {
      if (window.snaptr) {
        const [product] = event.detail.ecommerce.detail.products;

        snaptr('track', 'VIEW_CONTENT', {
          item_category: product.category,
          item_ids: [product.product_id.toString()],
          number_items: product.quantity,
          price: product.price,
          currency: event.detail.ecommerce.currency_code || 'USD',
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  function snapchatAddToCartListener(event) {
    try {
      if (window.snaptr) {
        const product = event?.detail?.ecommerce?.add?.products[0];

        snaptr('track', 'ADD_CART', {
          item_category: product.category,
          item_ids: [product.product_id.toString()],
          number_items: product.quantity,
          price: product.price,
          currency: event.detail.ecommerce.currency_code || 'USD',
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if (window) {
      window.addEventListener('dl_route_update', snapchatInit);
      window.addEventListener('dl_route_update', snapchatPageViewListener);
      window.addEventListener('dl_add_to_cart', snapchatAddToCartListener);
      window.addEventListener('dl_view_item', snapchatViewProductListener);
      window.addEventListener(
        'dl_view_item_list',
        snapchatViewProductListListener
      );
    }

    return () => {
      window.removeEventListener('dl_route_update', snapchatInit);
      window.removeEventListener('dl_route_update', snapchatPageViewListener);
      window.removeEventListener('dl_add_to_cart', snapchatAddToCartListener);
      window.removeEventListener('dl_view_item', snapchatViewProductListener);
      window.removeEventListener(
        'dl_view_item_list',
        snapchatViewProductListListener
      );
    }
  }, [])

  return (
    <Script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
          {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
          a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
          r.src=n;var u=t.getElementsByTagName(s)[0];
          u.parentNode.insertBefore(r,u);})(window,document,
          'https://sc-static.net/scevent.min.js');
        `,
      }}
    />
  );
}
