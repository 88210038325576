import { Box } from 'theme-ui';

export function RewardsEarningTilesWidget() {
  return (
    <Box
      id="rewards-earning-tiles"
      sx={{
        maxWidth: '1000px',
        mx: 'auto',
      }}
    />
  );
}

RewardsEarningTilesWidget.displayName = 'RewardsEarningTilesWidget';
