import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useMatchMedia } from '@hooks';
import { Slide } from './Slide';

import { themed } from './Slider.theme';

export const Slider = themed(
  ({ setSwiper, image, tiles, theme, inputRef: swiperRef }) => {
    const [activeHoveredIndex, setActiveHoveredIndex] = useState(null);

    const { ratio: imageRatio, overlayColor, overlayOpacity } = { ...image };
    const isMobileViewport = useMatchMedia('(max-width: 767px)');

    // wait until useMatchMedia is mounted
    if (isMobileViewport === null) return null;

    return (
      <Swiper
        spaceBetween={6}
        slidesPerView={2.25}
        slidesOffsetBefore={20}
        slidesOffsetAfter={20}
        autoHeight
        loop={!isMobileViewport}
        navigation={false}
        initialSlide={0}
        watchOverflow
        grabCursor
        onSwiper={setSwiper}
        ref={swiperRef}
        breakpoints={{
          768: {
            spaceBetween: 6,
            slidesOffsetBefore: 20,
            slidesPerView: 3.25,
          },
          1024: {
            spaceBetween: 16,
            slidesOffsetBefore: 0,
            slidesPerView: 5,
          },
        }}
        sx={theme.slider}
      >
        {tiles?.map((tile, index) => {
          return (
            <SwiperSlide key={index}>
              <Slide
                imageRatio={imageRatio}
                overlayColor={overlayColor}
                overlayOpacity={overlayOpacity}
                tile={tile}
                activeHoveredIndex={activeHoveredIndex}
                setActiveHoveredIndex={setActiveHoveredIndex}
                index={index}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  }
);

Slider.displayName = 'Slider';
