import { useMemo } from 'react';
import { Flex, Paragraph } from 'theme-ui';

import { Link, SitewideSaleMessage } from '@snippets';

import { AddToCart } from '@templates/product/Main/Details/AddToCart';
import { useVariantPrices } from '@hooks';

import { themed } from './Details.theme';

export const Details = themed(
  ({
    theme,
    fullProduct,
    withAddBtn,
    hasMultipleVariants,
    handleClick,
    isGiftCard,
  }) => {
    const { isOnSale, price, compareAtPrice } = useVariantPrices({
      selectedVariant: fullProduct?.variants?.[0],
    });

    const titles = useMemo(() => {
      if (!fullProduct?.title) return null;
      const [type, name] = fullProduct.title.split('|');
      return { type: type.trim(), name: name?.trim() };
    }, [fullProduct?.title]);

    return (
      <Flex data-comp={Details.displayName} sx={theme.wrapper}>
        {/* Type */}
        <Paragraph sx={theme.type}>
          <Link href={`/products/${fullProduct?.handle}`} onClick={handleClick}>
            {titles?.type}
          </Link>
        </Paragraph>

        {/* Title */}
        <Paragraph sx={theme.title}>
          <Link href={`/products/${fullProduct?.handle}`} onClick={handleClick}>
            {titles?.name}
          </Link>
        </Paragraph>

        {!isGiftCard && (
          <Flex sx={{ alignItems: 'flex-end' }}>
            {isOnSale ? (
              <Flex>
                <Paragraph
                  aria-label={`Original price ${compareAtPrice}`}
                  tabIndex="0"
                  sx={theme.price.lineThrough}
                >
                  {compareAtPrice}
                </Paragraph>
                <Paragraph
                  aria-label={`Current reduced price ${price}`}
                  tabIndex="0"
                  sx={theme.price.regular}
                >
                  {price}
                </Paragraph>
              </Flex>
            ) : (
              <Paragraph
                aria-label={`Price ${price}`}
                tabIndex="0"
                sx={theme.price.regular}
              >
                {price}
              </Paragraph>
            )}
          </Flex>
        )}

        <SitewideSaleMessage
          product={fullProduct}
          componentType="productCard"
          styles={{
            mt: 3,
            width: '100%',
          }}
        />

        {withAddBtn && !isGiftCard && !hasMultipleVariants && fullProduct && (
          <AddToCart
            product={fullProduct}
            selectedVariant={fullProduct.variants[0]}
          />
        )}
      </Flex>
    );
  }
);

Details.displayName = 'Details';
