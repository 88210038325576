import { color, section } from '@settings/common';

export const Schema = () => {
  return {
    label: 'VIP Tiles',
    key: 'vip-tiles',
    fields: [
      {
        name: 'tiles',
        label: 'Tiles',
        description: 'Max of 3 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.header.heading}}',
        },
        defaultItem: {
          header: {
            backgroundColor: '#C5ECED',
            alt: 'Premier',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/badge-0stars.png?v=1683326204',
            },
            heading: 'PREMIER',
            headingColor: '#000000',
            subheading: 'Join',
            subheadingColor: '#ffffff',
          },
          body: {
            backgroundColor: '#F5F5F5',
            textColor: '#000000',
            benefits: [
              {
                text: 'Point Multipler: 1',
              },
            ],
          },
        },
        fields: [
          {
            label: 'Header Settings',
            name: 'header',
            description: 'Heading, subheading, image, color',
            component: 'group',
            fields: [
              {
                label: 'Background Color',
                name: 'backgroundColor',
                component: 'color',
                colors: color.list,
              },
              {
                label: 'Image Alt',
                name: 'alt',
                component: 'text',
                description: 'Brief description of image',
              },
              {
                label: 'Image',
                name: 'image',
                component: 'image',
              },
              {
                label: 'Heading',
                name: 'heading',
                component: 'text',
              },
              {
                label: 'Heading Color',
                name: 'headingColor',
                component: 'color',
                colors: color.list,
              },
              {
                label: 'SubHeading',
                name: 'subheading',
                component: 'text',
              },
              {
                label: 'Color',
                name: 'subheadingColor',
                component: 'color',
                colors: color.list,
              },
            ],
          },
          {
            label: 'Body Settings',
            name: 'body',
            description: 'Heading, subheading, image, color',
            component: 'group',
            fields: [
              {
                label: 'Background Color',
                name: 'backgroundColor',
                component: 'color',
                colors: color.list,
              },
              {
                label: 'Text Color',
                name: 'textColor',
                component: 'color',
                colors: color.list,
              },
              {
                name: 'benefits',
                label: 'Benefits',
                component: 'group-list',
                itemProps: {
                  label: '{{item.text}}',
                },
                fields: [
                  {
                    label: 'Text',
                    name: 'text',
                    component: 'text',
                  },
                ],
              },
            ],
          },
        ],
        defaultValue: [
          {
            header: {
              backgroundColor: '#C5ECED',
              alt: 'Premier',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/badge-0stars.png?v=1683326204',
              },
              heading: 'PREMIER',
              headingColor: '#000000',
              subheading: 'Join',
              subheadingColor: '#ffffff',
            },
            body: {
              backgroundColor: '#F5F5F5',
              textColor: '#000000',
              benefits: [
                {
                  text: 'Point Multipler: 1',
                },
              ],
            },
          },
          {
            header: {
              backgroundColor: '#AFE2E3',
              alt: 'Business Class',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/badge-1star.png?v=1683326204',
              },
              heading: 'BUSINESS CLASS',
              headingColor: '#000000',
              subheading: 'Spend $200',
              subheadingColor: '#ffffff',
            },
            body: {
              backgroundColor: '#F5F5F5',
              textColor: '#000000',
              benefits: [
                {
                  text: 'Point Multipler: 1.5',
                },
                {
                  text: 'Early Access to Sales',
                },
              ],
            },
          },
          {
            header: {
              backgroundColor: '#9AD8D9',
              alt: 'First Class',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/badge-3stars.png?v=1683326204',
              },
              heading: 'FIRST CLASS',
              headingColor: '#000000',
              subheading: 'Spend $400',
              subheadingColor: '#ffffff',
            },
            body: {
              backgroundColor: '#F5F5F5',
              textColor: '#000000',
              benefits: [
                {
                  text: 'Point Multipler: 2',
                },
                {
                  text: 'Early Access to Sales',
                },
                {
                  text: 'Surprise Discounts',
                },
              ],
            },
          },
        ],
      },
      {
        label: 'Header Settings',
        name: 'header',
        description: 'Heading, text align',
        component: 'group',
        fields: [
          {
            name: 'heading',
            component: 'text',
            label: 'Heading',
          },
          {
            label: 'Color',
            name: 'color',
            component: 'color',
            colors: color.list,
          },
        ],
        defaultValue: {
          heading: 'VIP Status',
          color: '#000000',
        },
      },
      {
        ...section,
      },
    ],
  };
};
