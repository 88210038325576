import { Button } from 'theme-ui';

import { Svg } from '@snippets';

import { themed } from './Navigation.theme';

export const NavigationButton = themed(
  ({
    theme,
    src = '/svgs/chevron/left.svg#left',
    alt = 'Previous product',
    isNext = false,
    inactive,
    inputRef,
    ...props
  }) => {
    return (
      <Button
        data-comp={NavigationButton.displayName}
        disabled={inactive}
        {...props}
        variant="buttons.plain"
        sx={{
          ...(isNext ? theme.buttonNext : theme.buttonPrev),
          opacity: inactive ? 0.5 : 1,
          ...props.sx,
        }}
      >
        <Svg
          alt={alt}
          src={src}
          viewBox="0 0 16 16"
          draggable={false}
          sx={theme.icon}
        />
      </Button>
    );
  }
);

NavigationButton.displayName = 'CartUpsellNavigationButton';
