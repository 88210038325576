export const lineHeights = {
  body: '22px',
  11: '11px',
  13: '13px',
  15: '15px',
  16: '16px',
  18: '18px',
  20: '20px',
  21: '21px',
  22: '22px',
  24: '24px',
  28: '28px',
  32: '32px',
  40: '40px',
  48: '48px',
  56: '56px',
  64: '64px',
};
