import { create } from '@theme/create';

import { hexToRgbA } from '@utils/common';

export const themed = create(
  new (function () {
    this.slide = (imageRatio) => ({
      position: 'relative',
      borderRadius: '6px',
      aspectRatio: imageRatio || '1/1',
      overflow: 'hidden',
      ':hover': {
        img: {
          transform: 'scale(1.05)',
        },
      },
    });

    this.link = {
      height: '100%',
    };

    this.image = {
      borderRadius: '6px',
      overflow: 'hidden',
      height: '100%',
      img: {
        height: '100%',
        transition: '0.3s ease-in-out transform',
      },
    };

    this.content = (overlayColor = '#000', overlayOpacity = 50) => ({
      position: 'absolute',
      transform: 'translateZ(1px)',
      zIndex: 1,
      bottom: 0,
      left: 0,
      right: 0,
      p: [6, 8],
      color: 'white',
      borderRadius: '6px',
      pointerEvents: 'none',
      background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, ${hexToRgbA(
        overlayColor,
        overlayOpacity / 100
      )} 100%)`,
    });

    this.overlay = (isHovered) => ({
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      height: '100%',
      width: '100%',
      pointerEvents: 'none',
      transition: 'ease-in-out 0.3s background',
      background: `rgba(0, 0, 0, ${isHovered ? '0.35' : '0'})`,
    });

    this.heading = {
      fontSize: [5, 6],
      fontWeight: 500,
      letterSpacing: '0.54px',
    };
  })()
);
