import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.shell = {
      bg: 'background',
      left: 0,
      position: 'absolute',
      display: ['block', null, 'none'],
      top: 'var(--header-height)',
      transition: 'transform .3s ease-in-out',
      width: '100%',
      maxWidth: '500px',
      willChange: 'transform',
      zIndex: 1,
      overflow: 'hidden',
      touchAction: 'none',
    };
    this.scroll = {
      pt: 0,
      height: `calc(var(--viewport-height) - var(--header-height) - 72px)`, // 72px accounts for Shop/Lifestyle switcher
      overflowY: 'scroll',
      overflowX: 'hidden',
      webkitOverflowScrolling: 'touch',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    };
    this.content = {
      bg: 'lightGray',
      borderBottom: (t) => `0.75px solid ${t.colors.lightGray}`,
    };

    this.socialLinks = {
      px: 10,
      pb: 16,
    };
  })()
);
