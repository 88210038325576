function HeadFonts() {
  return (
    <>
      <link
        key="GothamHTF-Book.woff2"
        rel="preload"
        as="font"
        crossOrigin="anonymous"
        href="/fonts/GothamHTF-Book.woff2"
      />
      <link
        key="GothamHTF-Bold.woff2"
        rel="preload"
        as="font"
        crossOrigin="anonymous"
        href="/fonts/GothamHTF-Bold.woff2"
      />
      <link
        key="Quarto-Bold.woff2"
        rel="preload"
        as="font"
        crossOrigin="anonymous"
        href="/fonts/Quarto-Bold.woff2"
      />
    </>
  );
}

export default HeadFonts;
