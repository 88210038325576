import { Flex, Heading, Paragraph } from 'theme-ui';

import { Picture, Svg } from '@snippets';

export function VIPTile({ tile, theme }) {
  const { header, body } = { ...tile };
  const {
    heading,
    headingColor,
    subheading,
    subheadingColor,
    backgroundColor,
    image,
    alt,
  } = {
    ...header,
  };
  const {
    backgroundColor: bodyBackgroundColor,
    textColor: bodyTextColor,
    benefits,
  } = { ...body };

  return (
    <Flex
      as="li"
      sx={{
        ...theme.tile,
        px: 10,
      }}
    >
      <Flex
        variant="flex.column"
        sx={{
          ...theme.tileContainer,
          backgroundColor: bodyBackgroundColor,
        }}
      >
        <Flex
          variant="flex.column.center"
          sx={{
            ...theme.tileHeader,
            backgroundColor,
          }}
        >
          {image?.src && (
            <Picture
              alt={alt}
              images={[
                {
                  src: image.src,
                  ratio: 1,
                },
              ]}
              imageSx={{
                objectFit: 'contain',
              }}
              sx={{
                width: 130,
                height: 60,
                mb: 4,
              }}
            />
          )}

          {heading && (
            <Heading
              sx={{
                ...theme.tileHeading,
                color: headingColor || 'black',
              }}
            >
              {heading}
            </Heading>
          )}
          {subheading && (
            <Heading
              sx={{
                ...theme.tileSubheading,
                color: subheadingColor || 'white',
              }}
            >
              {subheading}
            </Heading>
          )}
        </Flex>
        <Flex as="ul" variant="flex.column" sx={theme.tileBenefits}>
          {benefits?.map((benefit, index) => {
            const { text } = { ...benefit };
            return (
              <Flex as="li" variant="flex.row.start.center" key={index}>
                <Svg
                  alt="chevron"
                  viewBox="0 0 48 48"
                  src="/svgs/checkmark.svg#checkmark"
                  sx={{
                    width: '20px',
                    mr: 6,
                  }}
                />
                <Paragraph
                  sx={{
                    color: bodyTextColor,
                  }}
                >
                  {text}
                </Paragraph>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </Flex>
  );
}

VIPTile.displayName = 'VIPTile';
