import { forwardRef } from 'react';

import { Svg } from '@snippets';

export const JunipStarsIcon = forwardRef(
  ({ starKey, label, emptyColor, fullColor, width = '13px' }, ref) => {
    return (
      <Svg
        ref={ref}
        alt={label}
        viewBox="0 0 24 24"
        width={width}
        src={`/svgs/yotpo/${starKey}.svg#${starKey}`}
        sx={{
          width,
          color: starKey === 'star-empty' ? emptyColor : fullColor,
        }}
      />
    );
  }
);

JunipStarsIcon.displayName = 'JunipStarsIcon';
