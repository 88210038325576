import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.video = {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      maxHeight: '100%',
      maxWidth: '100%',
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      margin: 'auto',
    };
  })()
);
