import { useState } from 'react';
import { Box } from 'theme-ui';

import { ProductSwiper } from '@snippets';
import { Navigation } from './Navigation';

import { themed } from './Slider.theme';

export const Slider = themed(({ products, theme, inputRef, ...props }) => {
  const [swiper, setSwiper] = useState(null);

  return (
    <Box
      {...props}
      sx={{
        position: 'relative',
      }}
    >
      <ProductSwiper
        products={products}
        displayQuickAdd
        swiper={swiper}
        setSwiper={setSwiper}
        params={{
          ref: inputRef,
          slidesPerView: 2.25,
          slidesOffsetBefore: 20,
          slidesOffsetAfter: 20,
          breakpoints: {
            480: {
              slidesPerView: 2.25,
              slidesOffsetBefore: 20,
              slidesOffsetAfter: 20,
            },
            600: {
              slidesPerView: 2.25,
              slidesOffsetBefore: 20,
              slidesOffsetAfter: 20,
            },
            768: {
              slidesPerView: 3,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
            1024: {
              slidesPerView: 3,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
            1280: {
              slidesPerView: 3,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
          },
        }}
      />

      <Navigation swiper={swiper} sliderRef={inputRef} />
    </Box>
  );
});

Slider.displayName = 'Slider';
