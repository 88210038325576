import { Container, Flex, Grid, Heading } from 'theme-ui';
import { withInView } from '@utils';

import { Section } from '@snippets';

import { Schema } from './VIPTiles.schema';
import { themed } from './VIPTiles.theme';
import { VIPTile } from './VIPTile';

export const VIPTiles = withInView(
  themed(({ theme, cms, inView }) => {
    const { section, header, tiles } = cms;
    const { heading, color } = { ...header };
    return (
      <Section section={section} cms={cms}>
        <Container data-comp={VIPTiles.displayName}>
          {heading && (
            <Heading
              sx={{
                ...theme.heading,
                color,
              }}
            >
              {heading}
            </Heading>
          )}
          <Flex
            as="ul"
            variant="flex.row.center"
            sx={{
              alignItems: 'normal',
              flexWrap: 'wrap',
              mx: 'auto',
              maxWidth: '1200px',
              px: 10,
            }}
          >
            {tiles?.map((tile, index) => {
              return <VIPTile key={index} tile={tile} theme={theme} />;
            })}
          </Flex>
        </Container>
      </Section>
    );
  })
);

VIPTiles.displayName = 'VIPTiles';
VIPTiles.Schema = Schema;
