import { Box, Heading, Spinner, Button } from 'theme-ui';
import { Picture, Link } from '@snippets';
import { themed } from './Slide.theme';

export const Slide = themed(({ imageRatio, theme, tile }) => {
  const { title, image, link } = tile;

  return (
    <Box sx={theme.slide(imageRatio)}>
      <Link href={link?.url} tabIndex="-1" sx={theme.link}>
        <Picture
          alt={title || link?.text}
          sx={theme.image}
          images={[
            {
              src: image,
              width: 350,
              ratio: 1,
            },
          ]}
        >
          {!image && <Spinner sx={theme.spinner} />}

          <Button variant="buttons.outlineWhite" sx={theme.button}>
            Shop Now
          </Button>
        </Picture>
      </Link>

      {/* Title */}
      <Box sx={theme.content()}>
        <Heading as="h3" sx={theme.heading}>
          {title || link?.text}
        </Heading>
      </Box>
    </Box>
  );
});
