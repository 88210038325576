import { color, section, button, align } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Three Tile',
    key: 'three-tile',
    fields: [
      {
        name: 'tiles',
        label: 'Tiles',
        description: 'Max of 3 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        defaultItem: {
          heading: 'New Tile',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod.',
          alt: 'Tile image',
          image: {
            src: 'https://images.unsplash.com/photo-1528629297340-d1d466945dc5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80',
          },
          cta: {
            text: '',
            url: '',
          },
        },
        // validate(list) {
        //   if (list?.length > 3) {
        //     window.__bpCMS.alerts.error(`Max of 3 tiles only.`);
        //   }
        // },
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            name: 'description',
            label: 'Description',
            component: 'markdown',
          },
          {
            label: 'Link',
            name: 'cta',
            component: 'link',
          },
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
        ],
        defaultValue: [
          {
            heading: 'New Releases',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod.',
            cta: {
              text: 'Shop Now',
              url: '/collections/shop-all',
            },
            alt: 'Tile image',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_7_ab44ee2e-190d-4525-b8d4-39f1f79dead9.png',
            },
          },
          {
            heading: 'Top Products',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod.',
            alt: 'Tile image',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_8_a71ca2bf-2262-4eea-b109-009ad153edb3.png',
            },
            cta: {
              text: 'Shop Now',
              url: '/collections/shop-all',
            },
          },
          {
            heading: 'Bundles',
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod.',
            alt: 'Tile image',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_10.png',
            },
            cta: {
              text: 'Shop Now',
              url: '/collections/shop-all',
            },
          },
        ],
      },
      {
        label: 'Heading Settings',
        name: 'header',
        description: 'Heading, text align, heading button',
        component: 'group',
        fields: [
          {
            name: 'heading',
            component: 'text',
            label: 'Heading',
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            description: "Doesn't apply on desktop if button is in header",
            variant: 'radio',
            name: 'textAlign',
            label: 'Heading Alignment',
            options: align.options,
          },
          {
            name: 'ctas',
            label: 'Heading Button',
            component: 'group-list',
            description: 'Max of 1 button',
            itemProps: {
              label: '{{item.cta.text}}',
            },
            // validate(list) {
            //   if (list?.length > 1) {
            //     window.__bpCMS.alerts.error(`Max of 1 button only.`);
            //   }
            // },
            defaultItem: {
              cta: {
                text: "Shop What's Trending",
                url: '/collections/shop-all',
              },
              style: button.value.outline,
            },
            fields: [
              {
                name: 'cta',
                component: 'link',
                label: 'Button',
              },
              {
                name: 'style',
                label: 'Button Style',
                component: 'select',
                options: button.options,
              },
            ],
          },
        ],
        defaultValue: {
          heading: 'Trending Now',
          textAlign: align.value.center,
          ctas: [
            {
              cta: {
                text: "Shop What's Trending",
                url: '/collections/shop-all',
              },
              style: button.value.outline,
            },
          ],
        },
      },
      {
        label: 'Tile Settings',
        name: 'tile',
        description: 'Display, image ratio, text align, link style',
        component: 'group',
        fields: [
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'display',
            label: 'Tile Display (mobile)',
            options: [
              { label: 'Slider', value: 'slider' },
              { label: 'Stacked', value: 'stacked' },
            ],
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'imageRatio',
            label: 'Image Aspect Ratio',
            options: [
              { label: '1:1', value: 'square' },
              { label: '3:4', value: 'portrait' },
            ],
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            label: 'Text Alignment',
            options: align.options,
          },
          {
            name: 'ctaStyle',
            label: 'Link Style',
            component: 'select',
            options: button.options,
          },
        ],
        defaultValue: {
          display: 'slider',
          imageRatio: 'portrait',
          textAlign: align.value.center,
          ctaStyle: button.value.primary,
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.black,
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
