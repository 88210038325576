import { color, section, button } from '@settings/common';

const imageSlide = {
  alt: 'travel img',
  image: {
    src: 'https://cdn.shopify.com/s/files/1/0819/9793/files/04bc791168a7cbd464e7ea661d7f99da.jpg?v=1647718366',
  },
  name: 'Travel',
  cta: {
    url: '/collections/shop-all',
    text: '',
  },
};

const Slides = () => ({
  name: 'slides',
  label: 'Slides',
  component: 'group-list',
  itemProps: {
    label: '{{item.name}}',
  },
  defaultItem: {
    alt: 'image',
    image: {
      src: 'https://images.unsplash.com/photo-1571042196331-3b9f42af39d3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1587&q=80',
    },
    name: 'image text',
    cta: {
      url: '',
      text: '',
    },
  },
  fields: [
    {
      label: 'Image Alt',
      name: 'alt',
      component: 'text',
      description: 'Brief description of image',
    },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
    },
    {
      label: 'Image name',
      name: 'name',
      component: 'text',
    },
    {
      label: 'Image Link',
      name: 'cta',
      component: 'link',
    },
  ],
  defaultValue: [...Array(6).fill(imageSlide)],
});

const content = {
  name: 'content',
  label: 'Content',
  component: 'group',
  fields: [
    {
      name: 'heading',
      label: 'Heading',
      component: 'text',
      defaultValue: 'multi-functional',
    },
    {
      name: 'subheading',
      label: 'Subheading',
      component: 'text',
      defaultValue:
        'Aenean lacinia bibendum nulla sed Amet Pellentesque consectetur.',
    },
    {
      label: 'Bottom Link',
      name: 'cta',
      component: 'link',
      defaultValue: {
        text: 'Shop all',
        url: '/collections/shop-all',
      },
    },
    {
      name: 'ctaStyle',
      label: 'Top link style',
      component: 'select',
      options: button.options,
      defaultValue: button.value.primary,
    },
  ],
};
export const Schema = () => {
  return {
    label: 'Image slider',
    key: 'imageSlider',
    fields: [
      content,
      Slides(),
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
