import { useState } from 'react';
import { useCustomer, useSettings } from '@backpackjs/storefront';
import { Box, Container, Heading } from 'theme-ui';

import { Markdown, Section } from '@snippets';
import { withInView } from '@utils';

import { LoginForm } from '@templates/account/login/LoginForm';
import { RecoverPasswordForm } from '@templates/account/login/RecoverPasswordForm';

import { themed } from './AccountLogin.theme';
import { Schema } from './AccountLogin.schema';

export const AccountLogin = withInView(
  themed(({ theme, cms }) => {
    const { section, heading, body } = cms;
    const [action, setAction] = useState('login');
    const settings = useSettings();
    const customer = useCustomer();

    if (customer) {
      return null;
    }

    return (
      <Section section={section} cms={cms}>
        <Container data-comp={AccountLogin.displayName}>
          <Heading sx={theme.heading}>{heading}</Heading>

          <Box sx={theme.form}>
            {action === 'forgot' ? (
              <RecoverPasswordForm setAction={setAction} settings={settings} />
            ) : (
              <LoginForm
                disableContactLink
                setAction={setAction}
                settings={settings}
              />
            )}
          </Box>

          {body && <Markdown text={body} sx={theme.body} />}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

AccountLogin.displayName = 'AccountLogin';
AccountLogin.Schema = Schema;
