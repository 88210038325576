import { Container, Grid } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './HeroImageWithIcon.schema';
import { themed } from './HeroImageWithIcon.theme';
import dynamic from 'next/dynamic';

const Image = dynamic(() => import('./Image').then((module) => module.Image), {
  ssr: false,
  suspense: false,
});

const Content = dynamic(
  () => import('./Content').then((module) => module.Content),
  { ssr: false, suspense: false }
);

export const HeroImageWithIcon = withInView(
  themed(({ theme, cms, inView }) => {
    const { section, image, altImage, content } = cms;

    return (
      <Section section={section} cms={cms}>
        <Container
          data-comp={HeroImageWithIcon.displayName}
          sx={{ py: '65px', px: '50px' }}
        >
          <Grid>
            {inView && <Image src={image?.src} alt={altImage} />}
            {inView && <Content content={content} />}
          </Grid>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

HeroImageWithIcon.displayName = 'HeroImageWithIcon';
HeroImageWithIcon.Schema = Schema;
