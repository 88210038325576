export const fetchProductFromHandle = async (handle) => {
  try {
    if (!handle) return null;
    const endpoint = `/json/products/${handle}.json`;
    const response = await fetch(endpoint);
    return await response.json();
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const fetchProductsFromHandles = async (handles) => {
  try {
    if (!Array.isArray(handles) || !handles?.length) return [];
    const productPromises = handles.map(fetchProductFromHandle);
    return Promise.all(productPromises);
  } catch (error) {
    console.error(error.message);
    return [];
  }
};

const fetchHandleById = async (id) => {
  if (!id) return null;
  const endpoint = `/json/product-id-to-handle.json`;
  const response = await fetch(endpoint);
  const map = await response.json();
  const handle = map[id];
  return handle;
};

export const fetchProductsFromIds = async (ids) => {
  try {
    if (!Array.isArray(ids) || !ids?.length) return [];
    const productPromises = ids.map(async (id) => {
      const handle = await fetchHandleById(id);
      return fetchProductFromHandle(handle);
    });
    return Promise.all(productPromises);
  } catch (error) {
    console.error(error.message);
    return null;
  }
};
