import { useEffect, useState } from 'react';
import { ProductItem } from '@snippets';

export function WishlistProduct({ variant, handle, index }) {
  const [fullProduct, setFullProduct] = useState(null);
  const [activeVariant, setActiveVariant] = useState(null);

  useEffect(() => {
    (async () => {
      const response = await fetch(`/json/products/${handle}.json`);
      const product = await response.json();

      if (product) {
        setFullProduct(product);
        const foundVariant = product.variants.find(
          (item) => parseInt(item.legacyResourceId, 10) === variant
        );
        if (foundVariant) {
          setActiveVariant(foundVariant);
        }
      }
    })();
  }, []);

  if (!fullProduct) return null;

  return (
    <ProductItem
      product={fullProduct}
      activeVariant={activeVariant}
      displayQuickAdd
      index={index}
    />
  );
}
