export default {
  label: 'Register Settings',
  name: 'register',
  component: 'group',
  fields: [
    // RegisterForm settings
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'Create Account',
    },
    {
      label: 'Subheading',
      name: 'subHeading',
      component: 'textarea',
      defaultValue: '',
    },
    {
      label: 'Button Text',
      name: 'buttonText',
      component: 'text',
      defaultValue: 'Create Account',
    },
    {
      label: 'Return Link Text',
      name: 'return',
      component: 'text',
      defaultValue: 'Return to Login',
    },

    // LoginRegisterLayout settings
    {
      label: 'Image',
      name: 'image',
      component: 'image',
      defaultValue:
        'https://images.unsplash.com/photo-1533641568252-76ce0951d5b4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=924&q=80',
      description: 'Image that will be seen on the right of the register form.',
    },
  ],
};
