import { Fragment, useMemo } from 'react';
import { Box, Flex, Heading, Grid, Spinner } from 'theme-ui';

import { Markdown } from '@snippets';
import { useModal } from '@hooks';
import { convertAlignToFlex, parseContentPosition } from '@utils/common';
import { space } from '@theme/theme.space';

import dynamic from 'next/dynamic';
import { themed } from './Slide.theme';
import { Button } from './Button';

const Video = dynamic(
  () => import('@snippets').then((module) => module.Video),
  {
    ssr: false,
    suspense: false,
  }
);

export const Content = themed(
  ({
    theme,
    heroClickable,
    index,
    isBelowHero,
    slide,
    visible = true,
    ...props
  }) => {
    const { text, button, content } = slide;

    const headingFontFamily = text?.headingFontFamily;

    // converts old schema value to new values
    const newHeadingValuesMap = {
      big: 'hero',
      small: 'h1',
    };

    const headingFontSize = newHeadingValuesMap[text?.headingFontSize]
      ? newHeadingValuesMap[text.headingFontSize]
      : text.headingFontSize;
    const headingFontSizeMobile = text?.headingFontSizeMobile;
    const headingFontWeight = text?.headingFontWeight;

    const superheadingFontSize = text?.superheadingFontSize || 'h6';
    const superheadingFontSizeMobile = text?.superheadingFontSizeMobile || 'h6';

    const subheadingFontSize = text?.subheadingFontSize || 'h4';
    const subheadingFontSizeMobile = text?.subheadingFontSizeMobile || 'h4';

    const textAlign = [content?.alignmentMob, content?.alignment];

    const headingColor = [
      text?.headingColorMob || text?.colorMob || 'currentColor',
      text?.headingColor || text?.colorMob || 'currentColor',
    ];
    const color = [
      text?.colorMob || 'currentColor',
      text?.color || 'currentColor',
    ];

    // Mobile content position
    const posMob = useMemo(
      () => parseContentPosition(content?.positionMob),
      [content?.positionMob]
    );

    // Desktop content position
    const pos = useMemo(
      () => parseContentPosition(content?.position),
      [content?.position]
    );

    const [{ modal }, { openModal, closeModal }] = useModal();

    const toggleVideoModal = () => {
      openModal(
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            pb: '56.25%',
          }}
        >
          <Spinner sx={theme.spinner} />
          <Video
            videoSources={[
              button?.buttons?.[0]?.modal?.videoMb,
              button?.buttons?.[0]?.modal?.videoDt,
            ]}
            isModalVideo
          />
        </Box>,
        {
          container: {
            width: '80vw',
            maxWidth: '80vw',
            minWidth: 'unset !important',
            p: '0 !important',
          },
          close: {
            top: '-16px',
            right: '-22px',
          },
        }
      );
    };

    return (
      <Flex
        data-comp={Content.displayName}
        sx={{
          ...theme.contentWrapper,
          alignItems: [posMob.alignItems, pos.alignItems],
          justifyContent: [posMob.justifyContent, pos.justifyContent],
          ...props.sx,
          visibility: visible ? 'visible' : 'hidden',
        }}
      >
        <Box
          sx={{
            ...theme.content,
            maxWidth: content?.maxWidth
              ? [
                  content.maxWidthMob + space[12] * 2,
                  content.maxWidth + space[15] * 2,
                ]
              : 'none',
            py: isBelowHero ? [11, 12] : [15, 17],
          }}
        >
          {/* Desktop */}
          <Box sx={{ display: ['none', 'block'] }}>
            {text?.superheading && (
              <Heading
                as="h6"
                sx={{
                  ...theme.superheading,
                  variant: `text.${superheadingFontSize}`,
                  fontWeight: 700,
                  textAlign,
                  color: headingColor,
                }}
              >
                {text.superheading}
              </Heading>
            )}

            <Markdown
              text={text?.heading}
              textSx={{
                ...theme.heading,
                // ex: text.{heading-level}.{font-weight}
                variant: `text.${
                  headingFontSize !== 'hero' ? headingFontSize : 'hero'
                }${headingFontFamily === 'quarto' ? '.quarto' : ''}${
                  headingFontWeight === 'light' ? '.light' : ''
                }`,
                textAlign,
                color: headingColor,
              }}
              sx={{
                p: {
                  p: 0,
                },
                mb: text?.subheading
                  ? `${text?.headingMarginBottomDt || 20}px`
                  : 0,
              }}
            />

            {text?.subheading && (
              <Markdown
                text={text?.subheading}
                textSx={{
                  variant: `text.${subheadingFontSize}`,
                  fontWeight: 400,
                  letterSpacing: 0,
                  textTransform: 'normal',
                  textAlign,
                  color,
                }}
                sx={{
                  p: {
                    p: 0,
                  },
                }}
              />
            )}
          </Box>

          {/* Mobile */}
          <Box sx={{ display: ['block', 'none'] }}>
            {text?.superheading && (
              <Heading
                as="h6"
                sx={{
                  ...theme.superheading,
                  variant: `text.${superheadingFontSizeMobile}`,
                  fontWeight: 700,
                  textAlign,
                  color: headingColor,
                }}
              >
                {text.superheading}
              </Heading>
            )}

            <Markdown
              text={text?.heading}
              textSx={{
                ...theme.heading,
                // ex: text.{heading-level}.{font-weight}
                variant: `text.${
                  headingFontSizeMobile !== 'hero'
                    ? headingFontSizeMobile || 'hero'
                    : 'hero'
                }${headingFontFamily === 'quarto' ? '.quarto' : ''}${
                  headingFontWeight === 'light' ? '.light' : ''
                }`,
                textAlign,
                color: headingColor,
              }}
              sx={{
                p: {
                  p: 0,
                },
                mb: text?.subheading
                  ? `${text?.headingMarginBottomMob || 8}px`
                  : 0,
              }}
            />

            {text?.subheading && (
              <Markdown
                text={text?.subheading}
                textSx={{
                  variant: `text.${subheadingFontSizeMobile}`,
                  fontWeight: 400,
                  letterSpacing: 0,
                  textTransform: 'normal',
                  textAlign,
                  color,
                }}
                sx={{
                  p: {
                    p: 0,
                  },
                }}
              />
            )}
          </Box>

          {!button?.hideButtons && button?.buttons?.length > 0 && (
            <Grid
              sx={{
                ...theme.buttons,
                mt: [
                  `${button?.marginTopMob || 24}px`,
                  `${button?.marginTopDt || 32}px`,
                ],
                justifyContent: [
                  convertAlignToFlex(content?.alignmentMob),
                  convertAlignToFlex(content?.alignment),
                ],
                '@media only screen and (max-width: 479px)': {
                  alignItems: convertAlignToFlex(content?.alignmentMob),
                },
              }}
            >
              {button?.buttons?.slice(0, 2).map((item, buttonIndex) => {
                return (
                  <Fragment key={buttonIndex}>
                    <Button
                      heroClickable={heroClickable}
                      item={item}
                      onClick={() => toggleVideoModal()}
                      openModalButton={item?.openModalButton}
                    />
                  </Fragment>
                );
              })}
            </Grid>
          )}
        </Box>
      </Flex>
    );
  }
);

Content.displayName = 'Content';
