import { useState } from 'react';
import { Container, Box } from 'theme-ui';

import { withInView } from '@utils';
import { Section } from '@snippets';
import { Header } from './Header';
import { Slider } from './Slider';

import { Schema } from './TabbedSlider.schema';
import { themed } from './TabbedSlider.theme';

export const TabbedSlider = withInView(
  themed(({ cms, theme, withInView }) => {
    const { content, section, tabs } = cms;

    const [activeIndex, setActiveIndex] = useState(0);

    return (
      <Section section={section} cms={cms}>
        <Container data-comp={TabbedSlider.displayName} sx={theme.wrapper}>
          <Header
            activeIndex={activeIndex}
            content={content}
            setActiveIndex={setActiveIndex}
            tabs={tabs}
          />

          <Box>
            {tabs?.map(({ tiles }, index) => {
              if (index !== activeIndex) return null;
              return (
                <Slider
                  activeIndex={activeIndex}
                  tiles={tiles}
                  tileContentPosition={content?.tileContentPosition}
                />
              );
            })}
          </Box>
        </Container>
      </Section>
    );
  })
);

TabbedSlider.displayName = 'TabbedSlider';
TabbedSlider.Schema = Schema;
