import { section } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Greenhouse',
    key: 'greenhouse',
    fields: [
      {
        label: 'Heading - Open Roles',
        name: 'heading',
        component: 'text',
        defaultValue: 'Open Roles',
      },
      {
        label: 'No Open Positions - Heading',
        name: 'defaultheading',
        component: 'text',
        defaultValue: '',
      },
      {
        label: 'No Open Positions - Copy',
        name: 'defaultcopy',
        component: 'markdown',
        defaultValue: '',
      },
      section,
    ],
  };
};
