import { Container, Box } from 'theme-ui';
import dynamic from 'next/dynamic';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './HalfCollection.schema';
import { themed } from './HalfCollection.theme';

const Content = dynamic(
  () => import('./Collection').then((module) => module.Collection),
  { ssr: false, suspense: false }
);
const Image = dynamic(() => import('./Image').then((module) => module.Image), {
  ssr: false,
  suspense: false,
});

const Video = dynamic(() => import('./Video').then((module) => module.Video), {
  ssr: false,
  suspense: false,
});

export const HalfCollection = withInView(
  themed(({ theme, cms, inView }) => {
    const { media, content, section } = cms;

    const imageLeft = media?.location === 'left'; // desktop
    const imageTop = media?.locationMob === 'top'; // mobile

    return (
      <Section section={section} cms={cms}>
        <Container
          data-comp={HalfCollection.displayName}
          sx={{
            ...theme.grid,
            gridTemplateAreas: [
              imageTop ? '"media" "content"' : '"content" "media"',
              imageLeft ? '"media content"' : '"content media"',
            ],
          }}
        >
          <Box
            sx={{
              ...theme.media,
              ':before': {
                content: '""',
                float: 'left',
                pb: [media?.image?.ratioMob || '100%', 0],
              },
            }}
          >
            {inView && (
              <>
                <Box sx={{ display: ['block', 'none'] }}>
                  {media?.mediaTypeMb === 'image' ? (
                    <Image image={media?.image} />
                  ) : (
                    <Video
                      videoSources={[
                        media?.video?.videoMb,
                        media?.video?.videoDt,
                      ]}
                      videoTitles={[
                        media?.video?.titleMb,
                        media?.video?.titleDt,
                      ]}
                    />
                  )}
                </Box>
                <Box sx={{ display: ['none', 'block'] }}>
                  {media?.mediaTypeDt === 'image' ? (
                    <Image image={media?.image} />
                  ) : (
                    <Video
                      videoSources={[
                        media?.video?.videoMb,
                        media?.video?.videoDt,
                      ]}
                      videoTitles={[
                        media?.video?.titleMb,
                        media?.video?.titleDt,
                      ]}
                    />
                  )}
                </Box>
              </>
            )}
          </Box>

          <Box
            sx={{
              ...theme.content,
              justifyContent: 'flex-start',
            }}
          >
            {inView && <Content content={content} />}
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

HalfCollection.displayName = 'HalfCollection';
HalfCollection.Schema = Schema;
