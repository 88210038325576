import { useEffect, useState, useCallback } from 'react';
import { useCart, useRouter } from '@backpackjs/storefront';

import store, { useRecoilValue } from '@store';

export function useCartIntelliSuggest() {
  const { route } = useRouter();
  const cart = useCart();
  const shopperId = useRecoilValue(store.shopperId);
  const [mounted, setMounted] = useState(false);

  const cartItems = cart?.lines;

  const setMountAfterDelayForCustomerId = useCallback(() => {
    setTimeout(() => {
      setMounted(true);
    }, 3000);
  }, []);

  const sendIntelliSuggestEventOnMountOrCartChange = useCallback(() => {
    try {
      if (!cart?.updatedAt || !mounted) return;
      const body = document.getElementsByTagName('body')[0];

      const skus = JSON.stringify(
        cartItems?.map((item) => item.variant.sku) || []
      );

      const itemsByParentSku = cartItems.reduce((acc, item) => {
        const parentSku = item.variant.sku.split('-')[0];
        const originalSku = item.variant.sku;
        const childSku = item.variant.sku;

        if (!acc[parentSku]) {
          acc[parentSku] = {
            parentSku,
            originalSku,
            childSkus: [childSku],
            qty: item.quantity,
            price: Number(item.estimatedCost.totalAmount.amount),
          };
        } else {
          acc[parentSku].childSkus.push(childSku);
          acc[parentSku].qty += item.quantity;
          acc[parentSku].price += Number(item.estimatedCost.totalAmount.amount);
        }

        return acc;
      }, {});

      const haveItemArr = Object.values(itemsByParentSku).map((item) => {
        const childSkuStr =
          item.childSkus.length > 1
            ? JSON.stringify(item.childSkus.splice(1))
            : ``;
        return `IntelliSuggest.haveItem({sku:"${item.originalSku}",${
          childSkuStr && `childSku:${childSkuStr},`
        } qty:"${item.qty}", price:"${(item.price / item.qty).toFixed(2)}"});`;
      });

      const haveItems = haveItemArr?.join('\n') || '';

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'ss-intellisuggest-cart-script';
      script.innerHTML = `
          try{
            IntelliSuggest.init({siteId:'${process.env.NEXT_PUBLIC_SEARCHSPRING_SITE_ID}', context:'Basket', seed:${skus}});
            IntelliSuggest.setShopperId('${shopperId}');
            ${haveItems}
            IntelliSuggest.inBasket({});
          } catch(err) {}`;
      // scriptRef.current = script;
      if (body) body.appendChild(script);
    } catch (error) {
      console.error(error);
    }
  }, [cart?.updatedAt, mounted, shopperId]);

  const clearProductSkuInWindowOnNonProductPage = useCallback(() => {
    if (!route || route.startsWith('/product')) return;
    if (window.__brumate_product_sku) {
      window.__brumate_product_sku = null;
    }
  }, [route]);

  useEffect(() => {
    setMountAfterDelayForCustomerId();
  }, []);
  useEffect(() => {
    sendIntelliSuggestEventOnMountOrCartChange();
  }, [cart?.updatedAt, mounted]);
  useEffect(() => {
    clearProductSkuInWindowOnNonProductPage();
  }, [route]);
}
