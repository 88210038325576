import { useEffect } from 'react';
import { Box, Paragraph, Flex, Text } from 'theme-ui';

import { useJunipStars } from '@hooks/junip';

import { JunipStarsIcons } from './JunipStarsIcons';

// Fetches stars data from Junip and then renders them
export function JunipStars({
  legacyResourceId,
  isPDP = false,
  withCta = true,
  fullColor = 'text',
  emptyColor = 'text',
  callback,
  ...props
}) {
  const { stars } = useJunipStars({
    legacyResourceId,
  });
  // product stars info returned by Junip
  const { rating_average: averageScore, rating_count: totalReviews } = {
    ...stars,
  };

  useEffect(() => {
    if (typeof callback === 'function') callback(stars);
  }, [stars]);

  if (isPDP && totalReviews === 0) return null;

  return (
    <Box data-comp={JunipStars.displayName} key={averageScore + totalReviews}>
      <Flex
        sx={{
          height: '22px',
          my: 3,
          display: 'flex',
          alignItems: 'center',
        }}
        {...props}
      >
        {stars && (
          <>
            <JunipStarsIcons
              averageScore={averageScore}
              fullColor={fullColor}
              emptyColor={emptyColor}
            />

            {withCta ? (
              <Paragraph
                data-comp={`${JunipStars.displayName}Total`}
                aria-label={`${totalReviews} reviews`}
                // variant="text.link.1"
                sx={{
                  ml: 4,
                  span: {
                    textDecoration: 'underline',
                    textUnderlineOffset: '2px',
                  },
                  fontWeight: 400,
                  fontSize: '10px',
                }}
              >
                {totalReviews ? (
                  <Text
                    sx={{
                      display: 'inline-block',
                    }}
                  >
                    ({totalReviews})
                  </Text>
                ) : (
                  <Text sx={{ display: ['none', 'inline-block'] }}>(0)</Text>
                )}
              </Paragraph>
            ) : null}
          </>
        )}
      </Flex>
    </Box>
  );
}

JunipStars.displayName = 'JunipStars';
