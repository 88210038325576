import { Flex, Button, Box } from 'theme-ui';

import { Svg } from '@snippets';

import { themed } from './Header.theme';

export const Header = themed(
  ({
    theme,
    isOpen,
    setOpenIndex,
    iconColor,
    minHeight,
    index,
    children,
    openIcon,
    collapseIcon,
    iconWidth,
    inputRef,
    ...props
  }) => {
    return (
      <Button
        data-comp={Header.displayName}
        onClick={() => {
          setOpenIndex(isOpen ? null : index);
        }}
        role="tab"
        aria-controls={`tabpanel-id-${index}`}
        aria-expanded={isOpen}
        variant="buttons.plain"
        {...props}
        sx={{
          ...theme.header,
          minHeight,
          lineHeight: minHeight,
          ...props.sx,
        }}
      >
        <Flex sx={theme.flex}>
          <Box sx={theme.text}>{children}</Box>
          <Svg
            src={
              (isOpen ? collapseIcon : openIcon) ||
              '/svgs/chevron/down.svg#down'
            }
            alt="chevron"
            viewBox="0 0 48 48"
            sx={{
              ...theme.icon,
              color: iconColor,
              ...(iconWidth ? { width: iconWidth } : {}),
              transform: isOpen
                ? `rotate(${!collapseIcon ? 180 : 0}deg)`
                : 'rotate(0)',
            }}
          />
        </Flex>
      </Button>
    );
  }
);

Header.displayName = 'Header';
