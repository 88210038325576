import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Slide } from './Slide';
import { Navigation } from './Navigation';

import { themed } from './Slider.theme';

export const Slider = themed(({ tiles, tileContentPosition, theme }) => {
  const [swiper, setSwiper] = useState(null);

  return (
    <Swiper
      spaceBetween={5}
      slidesPerView={1.25}
      slidesOffsetBefore={0}
      slidesOffsetAfter={0}
      autoHeight
      navigation={false}
      loop
      watchOverflow
      grabCursor
      onSwiper={setSwiper}
      breakpoints={{
        600: {
          slidesPerView: 2.25,
        },
        1024: {
          slidesPerView: 3.25,
        },
        1280: {
          slidesPerView: 4,
        },
      }}
    >
      {tiles?.map((tile, index) => {
        return (
          <SwiperSlide key={index}>
            <Slide tile={tile} tileContentPosition={tileContentPosition} />
          </SwiperSlide>
        );
      })}

      {tiles?.length > 1 && <Navigation swiper={swiper} />}
    </Swiper>
  );
});

Slider.displayName = 'Slider';
