import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.iconTiles = {
      flexWrap: 'wrap',
      px: 0,
    };

    this.iconTile = {
      mx: 'auto',
      width: ['100%', '33.33%'],
      p: [10, 5],
    };

    this.description = {
      fontSize: '20px',
      textAlign: 'center',
      p: {
        mt: 0,
      },
    };
  })()
);
