const defaultInput = {
  variant: 'text.base',
  color: 'text',
  pt: 8,
  pb: 7,
  mb: ['40px'],
  pl: '40px',
  pr: 8,
  border: (t) => `1px solid ${t.colors.gray}`,
  borderRadius: 0,
  ':focus': {
    outline: 'none !important',
  },
};

export const input = {
  ...defaultInput,

  search: {
    ...defaultInput,
    pl: '50px',
    mb: 10,
    border: 'none',
    borderBottom: '2px solid',
    borderColor: 'darkGray',
    ':focus': {
      outline: 'none !important',
      boxShadow: 'none',
    },
    ':hover': {
      boxShadow: 'none',
    },
  },
};
