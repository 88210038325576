import { useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';

/**
  A utility to sort the siteSettings.header.menu data structure
  to match what's required UX wise by MenuDrawer and MenuSideDrawer
* */
export const useMenu = () => {
  const settings = useSettings();
  const { header } = { ...settings };
  const menu = Array.isArray(header?.menu) ? header.menu : [];

  const refresh = `${menu.length}${menu
    .map((link) => JSON.stringify(link))
    .join('-')}`;

  return useMemo(() => {
    const output = {
      desktop: {
        menu: [],
        subNav: header?.subNav,
      },
      mobile: {
        links: header?.links || [],
        bestSellers: header?.bestSellers || [],
        menu: {
          nested: [], // with sub links
          direct: [], // no sub links
        },
      },
    };

    if (!menu.length) return output;

    output.desktop.menu = menu;

    /**
      mobile menu: sort links with sub links and those that don't have
    * */
    output.mobile.menu = {
      nested: menu.filter(
        (link) =>
          (link?.links?.length ||
            link?.medias?.length ||
            link?.products?.length) &&
          !link?.hideOnMobile
      ), // will be render as "accordions"
      direct: menu.filter(
        (link) =>
          !link?.links?.length &&
          !link?.medias?.length &&
          !link?.products?.length
      ), // rendered as direct links
    };

    return output;
  }, [refresh]);
};
