import { section, color } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Visual Navigation',
    key: 'visual-navigation',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Shop All',
      },
      {
        label: 'Body',
        name: 'body',
        component: 'markdown',
        defaultValue:
          'Find versatile bags & pouches designed for everyday use.',
      },
      {
        label: 'Tiles',
        name: 'tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.link.text}}',
        },
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
        ],
        defaultValue: [
          {
            link: {
              url: '/pages/all-totes',
              text: 'Totes',
              type: 'isPage',
            },
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/visual-nav-totes.png?v=1711660281',
            },
          },
          {
            link: {
              url: '/pages/all-pouches',
              text: 'Pouches',
              type: 'isPage',
            },
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/visual-nav-pouches.png?v=1711660278',
            },
          },
          {
            link: {
              url: '/collections/shop-all',
              text: 'Crossbody',
              type: 'isPage',
            },
            image: {
              id: 'gid://shopify/MediaImage/23724553994263',
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/crossbody_nav_8cbdb437-e389-4a20-bac0-c36abe3e3d20.jpg?v=1712692215',
              size: 236349,
              type: 'file',
              width: 500,
              format: 'image/jpeg',
              height: 400,
              altText: '',
              filename:
                'crossbody_nav_8cbdb437-e389-4a20-bac0-c36abe3e3d20.jpg?v=1712692215',
              directory: '',
              previewSrc:
                'https://cdn.shopify.com/s/files/1/1119/6322/files/crossbody_nav_8cbdb437-e389-4a20-bac0-c36abe3e3d20.jpg?v=1712692215',
              aspectRatio: 1.25,
            },
          },
          {
            link: {
              url: '/pages/all-duffles',
              text: 'Duffles',
              type: 'isPage',
            },
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/visual-nav-duffles.png?v=1711660273',
            },
          },
          {
            link: {
              url: '/collections/hip-packs',
              text: 'Hip Packs',
              type: 'isPage',
            },
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/visual-nav-hip-packs.png?v=1711660275',
            },
          },
          {
            link: {
              url: 'aloha-collection.com/collections/dopp-kits',
              text: 'Dopp Kits',
              type: 'isExternal',
            },
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/visual-nav-dopp-kits.jpg?v=1711660284',
            },
          },
          {
            link: {
              url: '/collections/apparel-accessories',
              text: 'Apparel',
              type: 'isPage',
            },
            image: {
              id: 'gid://shopify/MediaImage/23724555010071',
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/apparel_nav.jpg?v=1712692245',
              size: 191238,
              type: 'file',
              width: 500,
              format: 'image/jpeg',
              height: 400,
              altText: '',
              filename: 'apparel_nav.jpg?v=1712692245',
              directory: '',
              previewSrc:
                'https://cdn.shopify.com/s/files/1/1119/6322/files/apparel_nav.jpg?v=1712692245',
              aspectRatio: 1.25,
            },
          },
          {
            link: {
              url: '/collections/apparel-accessories',
              text: 'Accessories',
              type: 'isPage',
            },
            image: {
              id: 'gid://shopify/MediaImage/23724555304983',
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/accessories_nav.jpg?v=1712692263',
              size: 217147,
              type: 'file',
              width: 500,
              format: 'image/jpeg',
              height: 400,
              altText: '',
              filename: 'accessories_nav.jpg?v=1712692263',
              directory: '',
              previewSrc:
                'https://cdn.shopify.com/s/files/1/1119/6322/files/accessories_nav.jpg?v=1712692263',
              aspectRatio: 1.25,
            },
          },
        ],
      },
      {
        label: 'Image Settings',
        name: 'image',
        component: 'group',
        description: 'aspect ratio, overlay',
        fields: [
          {
            label: 'Aspect Ratio',
            component: 'select',
            name: 'ratio',
            options: [
              {
                value: '6/4',
                label: '6:4',
              },
              {
                value: '5/4',
                label: '5:4',
              },
              {
                value: '1/1',
                label: '1:1',
              },
              {
                value: '4/5',
                label: '4:5',
              },
            ],
            defaultValue: '5/4',
          },
          {
            label: 'Select the overlay color',
            name: 'overlayColor',
            component: 'select',
            options: [{ label: 'Default', value: '' }, ...color.options],
          },
          {
            label: 'Overlay opacity (%)',
            name: 'overlayOpacity',
            component: 'number',
            description: 'set the opacity of the overlay in percentage',
            validate(opacity) {
              if (opacity > 100) {
                window.__bpCMS.alerts.error(`Max 100% opacity`);
              }
              if (opacity < 0) {
                window.__bpCMS.alerts.error(`Min 0 opacity`);
              }
            },
            defaultValue: 50,
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
