import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = {
      gridTemplateColumns: ['1fr', '1fr', '2fr 3fr'],
      height: '100%',
      position: 'relative',
      gap: 6,
    };
  })()
);
