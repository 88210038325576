import { Box } from 'theme-ui';

import { themed } from './Promo.theme';

export const PromoBarMessageCopy = themed(({ copy, align }) => {
  return (
    <Box
      data-comp={PromoBarMessageCopy.displayName}
      sx={{
        textAlign: align,
      }}
      dangerouslySetInnerHTML={{ __html: copy }}
    />
  );
});

PromoBarMessageCopy.displayName = 'PromoBarMessageCopy';
