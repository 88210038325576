import { Grid, Container, Heading, Box } from 'theme-ui';

import { Section } from '@snippets';

import { Slider } from './Slider';
import { Slide } from './Slider/Slide';

import { Schema } from './FeaturedCollections.schema';
import { themed } from './FeaturedCollections.theme';

export const FeaturedCollections = themed(({ theme, cms }) => {
  const { heading, displayTypeMobile, tiles, image, section } = cms;

  return (
    <Section section={section} cms={cms}>
      <Container data-comp={FeaturedCollections.displayName}>
        <Box
          sx={{
            ...theme.header,
            px: [section?.mobile?.container === 'container' ? 10 : 0, 0, 0],
          }}
        >
          {heading && (
            <Heading
              as="h2"
              variant="text.h3"
              sx={{ ...theme.heading, color: section?.color || 'black' }}
            >
              {heading}
            </Heading>
          )}
        </Box>

        <Box sx={theme.wrapper(displayTypeMobile)}>
          <Slider image={image} tiles={tiles} />
        </Box>

        {displayTypeMobile === 'stacked' && (
          <Grid sx={theme.grid}>
            {tiles?.map((tile, index) => {
              return (
                <Slide imageRatio={image?.ratio} tile={tile} index={index} />
              );
            })}
          </Grid>
        )}
      </Container>
    </Section>
  );
});

FeaturedCollections.Schema = Schema;
FeaturedCollections.displayName = 'FeaturedCollections';
