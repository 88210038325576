import { useCallback, useState } from 'react';
import { Box, Button, Input, Paragraph } from 'theme-ui';

import { useDataLayerActions } from '@hooks';

import { themed } from './EmailSignup.theme';

export const EmailSignup = themed(({ theme, ...rawProps }) => {
  const { sendSubscribeEvent } = useDataLayerActions();

  const { inputRef, ...props } = rawProps;
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [placeholderText, setPlaceholderText] = useState('Email address');

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!email || submitted || isLoading) return;
      setIsLoading(true);

      try {
        const response = await fetch('/api/klaviyo', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify({
            action: 'clientSubscribe',
            email,
            source: window.location.origin + window.location.pathname,
            listId: 'J8teHa',
          }),
        });
        const data = await response.json();

        if (!data.ok && data.errors) {
          setPlaceholderText(data?.data || data?.errors);
          setTimeout(() => {
            setPlaceholderText('Email Address');
          }, 4000);
          return;
        }

        setSubmitted(true);
        setEmail('');
        setPlaceholderText('Email submitted!');
        sendSubscribeEvent({ email });

        setTimeout(() => {
          setSubmitted(false);
          setIsLoading(false);
          setPlaceholderText('Email Address');
        }, 4000);
      } catch (error) {
        console.error({ error });
        setPlaceholderText('Please try again later');
        setIsLoading(false);
      }
    },
    [email, submitted, isLoading]
  );

  return (
    <Box
      data-comp="EmailSignup"
      as="form"
      email={email}
      onSubmit={handleSubmit}
      {...props}
      sx={{
        ...theme.wrapper,
        ...props.sx,
      }}
    >
      <Paragraph sx={theme.label}>{placeholderText}</Paragraph>
      <Input
        placeholder="Enter your email address"
        type="email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        sx={theme.input}
      />
      <Button sx={theme.button} disabled={isLoading || submitted}>
        Join the ‘ohana
      </Button>
    </Box>
  );
});
