import {
  position,
  align,
  button,
  sectionWithHeightNoColor,
  color,
} from '@settings/common';

// Random image for demo purposes only
const images = [
  'https://cdn.shopify.com/s/files/1/1119/6322/files/image_c797f821-9bce-4ca4-9887-9ca958447730.jpg?v=1654642385',
  'https://cdn.shopify.com/s/files/1/1119/6322/files/image_ea1eb9b2-4b04-4045-a57c-0ffe8e914ff2.jpg?v=1654644031',
];

export const Schema = () => {
  const randomImage = images[Math.floor(Math.random() * images.length)];

  const sectionWithHeightUpdated = JSON.parse(
    JSON.stringify(sectionWithHeightNoColor)
  );

  sectionWithHeightUpdated?.fields.forEach((field) => {
    if (field?.name === 'mobile') {
      delete field?.defaultValue?.maxHeight;
    }
  });

  return {
    label: 'Half Media',
    key: 'half-media',
    fields: [
      {
        label: 'Media settings',
        name: 'media',
        description: 'Select Media Type (image - video)',
        component: 'group',
        fields: [
          {
            label: 'Image Settings',
            name: 'image',
            description: 'Image, position, placement, aspect ratio',
            component: 'group',
            fields: [
              {
                label: 'Alt',
                name: 'alt',
                component: 'text',
                description: 'Brief description of image',
              },
              {
                name: 'image',
                label: 'Image (desktop)',
                component: 'image',
              },
              {
                name: 'position',
                component: 'select',
                label: 'Image Position (desktop)',
                options: position.object,
              },
              {
                label: 'Aspect Ratio (desktop)',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                name: 'ratio',
                options: [
                  {
                    value: '50%',
                    label: '2:1',
                  },
                  {
                    value: '80%',
                    label: '5:4',
                  },
                  {
                    value: '100%',
                    label: '1:1',
                  },
                  {
                    value: '120%',
                    label: '5:6',
                  },
                ],
              },
              {
                label: 'Image (mobile)',
                name: 'imageMob',
                component: 'image',
              },
              {
                name: 'positionMob',
                component: 'select',
                label: 'Image Position (mobile)',
                options: position.object,
              },
              {
                label: 'Aspect Ratio (mobile)',
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                name: 'ratioMob',
                options: [
                  {
                    value: '50%',
                    label: '2:1',
                  },
                  {
                    value: '80%',
                    label: '5:4',
                  },
                  {
                    value: '100%',
                    label: '1:1',
                  },
                  {
                    value: '120%',
                    label: '5:6',
                  },
                ],
              },
              {
                name: 'minImageHeightMob',
                label: 'Min image height (mobile)',
                component: 'number',
              },
              {
                name: 'maxImageHeightMob',
                label: 'Max image height (mobile)',
                component: 'number',
              },
            ],
            defaultValue: {
              ratio: '100%',
              ratioMob: '100%',
              alt: `Half Media image`,
              image: {
                src: randomImage,
              },
              position: 'center center',
              imageMob: {
                src: randomImage,
              },
              positionMob: 'center center',
            },
          },
          {
            label: 'Video settings',
            name: 'video',
            description: 'Video, video position',
            component: 'group',
            fields: [
              {
                label: 'Disable Video Modal',
                name: 'disableVideoModal',
                component: 'toggle',
                toggleLabels: {
                  true: 'Yes',
                  false: 'No',
                },
                defaultValue: false,
              },
              {
                label: 'Autoplay Video',
                name: 'autoplay',
                component: 'toggle',
                toggleLabels: {
                  true: 'Yes',
                  false: 'No',
                },
                defaultValue: true,
              },
              {
                label: 'Title (mobile)',
                name: 'titleMb',
                component: 'text',
              },
              {
                label: 'Aspect ratio (mobile)',
                name: 'aspectRatioMb',
                component: 'radio-group',
                description: 'Close then reopen modal to see change',
                options: [
                  { label: '16:9', value: '56.25%' },
                  { label: '1:1', value: '100%' },
                  { label: '4:5', value: '125%' },
                  { label: '9:16', value: '177.78%' },
                ],
              },
              {
                name: 'videoMb',
                description:
                  'Video url must end with .mp4 unless Vimeo\nClose then reopen modal to see change',
                label: 'Video url (mobile)',
                component: 'text',
              },
              {
                name: 'videoPosterMb',
                label: 'Video poster (mobile)',
                component: 'image',
              },
              {
                label: 'Title (desktop)',
                name: 'titleDt',
                component: 'text',
              },
              {
                label: 'Aspect ratio (desktop)',
                name: 'aspectRatioDt',
                description: 'Close then reopen modal to see change',
                component: 'radio-group',
                options: [
                  { label: '16:9', value: '56.25%' },
                  { label: '1:1', value: '100%' },
                ],
              },
              {
                name: 'videoDt',
                description:
                  'Video url must end with .mp4 unless Vimeo\nFor better results on desktop use min resolution of 1280px\nClose then reopen modal to see change',
                label: 'Video url (desktop)',
                component: 'text',
              },
              {
                name: 'videoPosterDt',
                label: 'Video poster (desktop)',
                component: 'image',
              },
            ],
            defaultValue: {
              titleMb: 'Half video',
              aspectRatioMb: '56.25%',
              videoMb: 'https://placehold.co/1920x1080.mp4?text=Hello+World',
              videoPosterMb: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_39_3e34fc33-23bb-4244-af14-ac1da410e6c8.png?v=1655220031',
              },
              titleDt: 'Half video',
              aspectRatioDt: '56.25%',
              videoDt: 'https://placehold.co/1920x1080.mp4?text=Hello+World',
              videoPosterDt: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_39.png?v=1655219656',
              },
            },
          },
          {
            label: 'Select Media Type (mobile)',
            description: 'Allows select between image or video for mobile',
            name: 'mediaTypeMb',
            component: 'radio-group',
            direction: 'horizontal',
            options: [
              { label: 'Image', value: 'image' },
              { label: 'Video', value: 'video' },
            ],
            defaultValue: 'image',
          },
          {
            label: 'Select Media Type (desktop)',
            description: 'Allows select between image or video for desktop',
            name: 'mediaTypeDt',
            component: 'radio-group',
            direction: 'horizontal',
            options: [
              { label: 'Image', value: 'image' },
              { label: 'Video', value: 'video' },
            ],
            defaultValue: 'video',
          },
          {
            label: 'Placement (desktop)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'location',
            options: [
              {
                value: 'left',
                label: 'Left',
              },
              {
                value: 'right',
                label: 'Right',
              },
            ],
          },
          {
            label: 'Placement (mobile)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'locationMob',
            options: [
              {
                value: 'top',
                label: 'Top',
              },
              {
                value: 'bottom',
                label: 'Bottom',
              },
            ],
          },
          {
            name: 'bgColor',
            label: 'Background Color',
            component: 'color',
            colors: color.list,
            widget: 'block',
          },
        ],
        defaultValue: {
          image: {
            ratio: '100%',
            ratioMob: '100%',
            alt: `Half Media image`,
            image: {
              src: randomImage,
            },
            position: 'center center',
            imageMob: {
              src: randomImage,
            },
            positionMob: 'center center',
          },
          video: {
            titleMb: 'Half video',
            videoMb: 'https://placehold.co/1920x1080.mp4?text=Hello+World',
            // videoPosterMb:
            //   'https://cdn.shopify.com/s/files/1/1119/6322/files/image_39_3e34fc33-23bb-4244-af14-ac1da410e6c8.png?v=1655220031',
            titleDt: 'Half video',
            videoDt: 'https://placehold.co/1920x1080.mp4?text=Hello+World',
            // videoPosterDt:
            //   'https://cdn.shopify.com/s/files/1/1119/6322/files/image_39.png?v=1655219656',
          },
          location: 'right',
          locationMob: 'top',
          mediaTypeDt: 'image',
          mediaTypeMb: 'image',
        },
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, superheading, body, link, content alignment',
        component: 'group',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'markdown',
          },
          {
            label: 'Heading Font Family',
            name: 'headingFontFamily',
            component: 'radio-group',
            direction: 'horizontal',
            options: [
              { label: 'Gotham', value: 'default' },
              { label: 'Quarto', value: 'quarto' },
            ],
            defaultValue: 'default',
          },
          {
            label: 'Heading Font Weight',
            name: 'headingFontWeight',
            component: 'radio-group',
            direction: 'horizontal',
            options: [
              { label: 'Bold', value: 'default' },
              { label: 'Light', value: 'light' },
            ],
            defaultValue: 'Bold',
          },
          {
            label: 'Heading Font Size',
            name: 'headingSize',
            component: 'select',
            direction: 'horizontal',
            options: [
              { label: 'Hero', value: 'hero' },
              { label: 'H1', value: 'h1' },
              { label: 'H2', value: 'h2' },
              { label: 'H3', value: 'h3' },
              { label: 'H4', value: 'h4' },
              { label: 'H5', value: 'h5' },
              { label: 'H6', value: 'h6' },
            ],
            defaultValue: 'h2',
          },
          {
            label: 'Heading Font Size (mobile)',
            name: 'headingSizeMobile',
            component: 'select',
            options: [
              { label: 'Hero', value: 'hero' },
              { label: 'H1', value: 'h1' },
              { label: 'H2', value: 'h2' },
              { label: 'H3', value: 'h3' },
              { label: 'H4', value: 'h4' },
              { label: 'H5', value: 'h5' },
              { label: 'H6', value: 'h6' },
            ],
            defaultValue: 'h3',
          },
          {
            name: 'headingColor',
            label: 'Heading Color (desktop)',
            component: 'color',
            colors: color.list,
            widget: 'block',
          },
          {
            name: 'headingColorMob',
            label: 'Heading Color (mobile)',
            component: 'color',
            colors: color.list,
            widget: 'block',
          },
          {
            label: 'Superheading',
            name: 'superheading',
            component: 'text',
          },
          {
            label: 'Superheading Font Size (Desktop)',
            name: 'superheadingFontSize',
            component: 'select',
            direction: 'horizontal',
            options: [
              { label: 'Hero', value: 'hero' },
              { label: 'H1', value: 'h1' },
              { label: 'H2', value: 'h2' },
              { label: 'H3', value: 'h3' },
              { label: 'H4', value: 'h4' },
              { label: 'H5', value: 'h5' },
              { label: 'H6', value: 'h6' },
            ],
            defaultValue: 'h5',
          },
          {
            label: 'Superheading Font Size (Mobile)',
            name: 'superheadingFontSizeMobile',
            component: 'select',
            direction: 'horizontal',
            options: [
              { label: 'Hero', value: 'hero' },
              { label: 'H1', value: 'h1' },
              { label: 'H2', value: 'h2' },
              { label: 'H3', value: 'h3' },
              { label: 'H4', value: 'h4' },
              { label: 'H5', value: 'h5' },
              { label: 'H6', value: 'h6' },
            ],
            defaultValue: 'h5',
          },
          {
            label: 'Body (Markdown)',
            name: 'body',
            component: 'markdown',
          },
          {
            label: 'Body (HTML)',
            description:
              'Adding text here will override text from Body (Markdown) component',
            name: 'bodyHTML',
            component: 'html',
          },
          {
            label: 'Body Font Size (Desktop)',
            name: 'bodyFontSize',
            component: 'select',
            direction: 'horizontal',
            options: [
              { label: 'Hero', value: 'hero' },
              { label: 'H1', value: 'h1' },
              { label: 'H2', value: 'h2' },
              { label: 'H3', value: 'h3' },
              { label: 'H4', value: 'h4' },
              { label: 'H5', value: 'h5' },
              { label: 'H6', value: 'h6' },
            ],
            defaultValue: 'h4',
          },
          {
            label: 'Body Font Size (Mobile)',
            name: 'bodyFontSizeMobile',
            component: 'select',
            direction: 'horizontal',
            options: [
              { label: 'Hero', value: 'hero' },
              { label: 'H1', value: 'h1' },
              { label: 'H2', value: 'h2' },
              { label: 'H3', value: 'h3' },
              { label: 'H4', value: 'h4' },
              { label: 'H5', value: 'h5' },
              { label: 'H6', value: 'h6' },
            ],
            defaultValue: 'h4',
          },
          {
            name: 'color',
            label: 'Body Color (desktop)',
            component: 'color',
            colors: color.list,
            widget: 'block',
          },
          {
            name: 'colorMob',
            label: 'Body Color (mobile)',
            component: 'color',
            colors: color.list,
            widget: 'block',
          },
          {
            label: 'Links settings',
            name: 'links',
            description: 'Manage links',
            component: 'group-list',
            fields: [
              {
                label: 'Link',
                name: 'cta',
                component: 'link',
              },
              {
                name: 'ctaStyle',
                label: 'Link Style',
                component: 'select',
                options: button.options,
              },
              {
                component: 'radio-group',
                direction: 'horizontal',
                variant: 'radio',
                name: 'textSize',
                label: 'Text Size',
                options: [
                  { label: 'Small', value: 'small' },
                  { label: 'Big', value: 'big' },
                ],
                defaultValue: 'big',
              },
            ],
          },
          {
            label: 'Icons settings',
            name: 'icons',
            description: 'Manage icons',
            component: 'group-list',
            itemProps: {
              label: '{{item.label}}',
            },
            defaultItem: {
              label: 'lightweight',
              alt: 'icon',
              icon: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/lightweight.svg?v=1654698840',
              },
            },
            fields: [
              {
                label: 'Label',
                name: 'label',
                description: 'The inscription displayed next to the icon',
                component: 'text',
              },
              {
                label: 'Alt',
                name: 'alt',
                component: 'text',
                description: 'Brief description of icon',
              },
              {
                name: 'icon',
                label: 'icon',
                component: 'image',
              },
            ],
            defaultValue: [
              {
                label: 'lightweight',
                alt: 'icon',
                icon: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/lightweight.svg?v=1654698840',
                },
              },
              {
                label: 'Packable',
                alt: 'icon',
                icon: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/packable_1.svg?v=1652939647',
                },
              },
              {
                label: 'Water Resistant',
                alt: 'icon',
                icon: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/splashproof_1.svg?v=1652939791',
                },
              },
              {
                label: 'Easy to clean',
                alt: 'icon',
                icon: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/easy_to_clean_1.svg?v=1652939917',
                },
              },
            ],
          },
          {
            label: 'Content Alignment (desktop)',
            name: 'alignment',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
          {
            label: 'Content Alignment (mobile)',
            name: 'alignmentMob',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
        ],
        defaultValue: {
          heading: 'Introducing a New Bike',
          headingSize: 'h2',
          superheading: 'New Product',
          body: 'A lightweight alloy frame is the foundation for comfortable cycling. Paired with a **lightweight** threadless steering system, this bike is built to _fly_.',
          links: [
            {
              ctaStyle: button.value.underlineLink,
              cta: {
                text: 'Learn More',
                url: '#',
              },
              textSize: 'big',
            },
          ],
          icons: [
            {
              label: 'lightweight',
              alt: 'icon',
              icon: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/lightweight.svg?v=1654698840',
              },
            },
            {
              label: 'Packable',
              alt: 'icon',
              icon: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/packable_1.svg?v=1652939647',
              },
            },
            {
              label: 'Water Resistant',
              alt: 'icon',
              icon: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/splashproof_1.svg?v=1652939791',
              },
            },
            {
              label: 'Easy to clean',
              alt: 'icon',
              icon: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/easy_to_clean_1.svg?v=1652939917',
              },
            },
          ],
          alignment: align.value.left,
          alignmentMob: align.value.left,
        },
      },
      {
        ...sectionWithHeightUpdated,
        defaultValue: {
          bgColor: color.value.lightestGray,
          color: color.value.text,
        },
      },
    ],
  };
};
