import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.media = (mediaType) => ({
      position: 'relative',
      overflow: 'hidden',
      borderRadius: '6px',
      border: '1px solid lightGray',
      pb: mediaType === 'image' ? 0 : ['100%', 0],
    });

    this.link = {
      height: '100%',
    };

    this.image = {
      height: '100%',
    };
  })()
);
