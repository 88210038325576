import Script from 'next/script';
import { Section } from '@snippets';
import { Schema } from './StoreLocatorEmbed.schema';

export function StoreLocatorEmbed({ cms }) {
  const { storeLocatorId, section } = cms;

  return (
    <>
      <Section section={section} cms={cms}>
        <div id="storepoint-container" data-map-id={storeLocatorId} />
      </Section>

      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `(function(){var a=document.createElement("script");a.type="text/javascript";a.async=!0;a.src="https://cdn.storepoint.co/api/v1/js/${storeLocatorId}.js";var b=document.getElementsByTagName("script")[0];b.parentNode.insertBefore(a,b);}());`,
        }}
      />
    </>
  );
}

StoreLocatorEmbed.displayName = 'StoreLocatorEmbed';
StoreLocatorEmbed.Schema = Schema;
