import React from 'react';

import { ShopifyImage } from './ShopifyImage';
import { ShopifyVideoExternal } from './ShopifyVideoExternal';
import { ShopifyVideo } from './ShopifyVideo';

export const ShopifyMedia = ({ media, ...props }) => {
  if (!media)
    throw new Error(
      'ShopifyMedia missing required media prop. A shopify media or [media] must be provided.'
    );

  // maps a shopify media item to a react component
  const mapMediaItemToComponent = (item) => {
    const { mediaContentType } = item;

    switch (mediaContentType) {
      case 'IMAGE': {
        const imageProps = {
          ...props,
          ...item.image,
          alt: item.image.altText,
          src: item.image.originalSrc,
          key: item.image.id,
        };
        delete imageProps.altText;
        delete imageProps.originalSrc;
        return React.createElement(ShopifyImage, imageProps);
      }

      case 'VIDEO': {
        const videoProps = {
          ...props,
          alt: item.alt,
          sources: item.sources,
          key: item.sources[0]?.url || item.alt,
        };

        return React.createElement(ShopifyVideo, videoProps);
      }

      case 'EXTERNAL_VIDEO': {
        const externalVideoProps = {
          ...props,
          ...item,
          alt: item.alt,
          key: item.id || item.alt,
        };
        return React.createElement(ShopifyVideoExternal, externalVideoProps);
      }

      default:
        console.warn(
          'Media element is not valid. Supported media content types: IMAGE, VIDEO, EXTERNAL_VIDEO and MODEL_3D ',
          media
        );
        return null;
    }
  };

  // returns a the map component(s)
  return React.useMemo(
    () =>
      Array.isArray(media)
        ? media.map(mapMediaItemToComponent)
        : mapMediaItemToComponent(media),
    [media]
  );
};

/*
[
  {
      "mediaContentType": "IMAGE",
      "alt": "",
      "image": {
          "id": "gid://shopify/ImageSource/21462796533944",
          "originalSrc": "https://cdn.shopify.com/s/files/1/0566/5882/1304/products/15mm-icetoolz-combo-wrench_1.jpg?v=1620067885",
          "altText": "",
          "width": 480,
          "height": 320
      }
  },
  {
      "mediaContentType": "VIDEO",
      "alt": "",
      "sources": [
          {
              "url": "https://cdn.shopify.com/videos/c/vp/e4e1db06d7dd44e494a4e24d57ac9066/e4e1db06d7dd44e494a4e24d57ac9066.HD-720p-1.6Mbps.mp4",
              "mimeType": "video/mp4",
              "format": "mp4",
              "height": 720,
              "width": 860
          },
          {
              "url": "https://cdn.shopify.com/videos/c/vp/e4e1db06d7dd44e494a4e24d57ac9066/e4e1db06d7dd44e494a4e24d57ac9066.m3u8",
              "mimeType": "application/x-mpegURL",
              "format": "m3u8",
              "height": 720,
              "width": 860
          },
          {
              "url": "https://cdn.shopify.com/videos/c/vp/e4e1db06d7dd44e494a4e24d57ac9066/e4e1db06d7dd44e494a4e24d57ac9066.SD-480p-0.9Mbps.mp4",
              "mimeType": "video/mp4",
              "format": "mp4",
              "height": 480,
              "width": 574
          }
      ]
  },
  {
      "mediaContentType": "MODEL_3D",
      "alt": "",
      "sources": [
          {
              "url": "https://model3d.shopifycdn.com/models/o/4f0f71eba56af1de/pack-emblem.glb",
              "mimeType": "model/gltf-binary",
              "format": "glb",
              "filesize": 6459380
          },
          {
              "url": "https://model3d.shopifycdn.com/models/o/3784a4be4d0e8d4b/pack-emblem.usdz",
              "mimeType": "model/vnd.usdz+zip",
              "format": "usdz",
              "filesize": 3743066
          }
      ]
  },
  {
      "mediaContentType": "IMAGE",
      "alt": "",
      "image": {
          "id": "gid://shopify/ImageSource/22925286998200",
          "originalSrc": "https://cdn.shopify.com/s/files/1/0566/5882/1304/products/6594_pepe_wine.gif?v=1625850683",
          "altText": "",
          "width": 128,
          "height": 128
      }
  },
  {
      "mediaContentType": "IMAGE",
      "alt": "",
      "image": {
          "id": "gid://shopify/ImageSource/22925292273848",
          "originalSrc": "https://cdn.shopify.com/s/files/1/0566/5882/1304/products/2019-pepe-copter.gif?v=1625850705",
          "altText": "",
          "width": 112,
          "height": 112
      }
  },
  {
      "mediaContentType": "EXTERNAL_VIDEO",
      "alt": "",
      "id": "gid://shopify/ExternalVideo/22916913103032",
      "host": "YOUTUBE",
      "embeddedUrl": "https://youtu.be/bQEJWb2GFSw",
      "preview": {
          "image": {
              "id": "gid://shopify/ImageSource/22925454540984",
              "originalSrc": "https://cdn.shopify.com/s/files/1/0566/5882/1304/products/hqdefault.jpg?v=1625851304",
              "altText": "",
              "width": 480,
              "height": 360
          }
      }
  },
  {
      "mediaContentType": "EXTERNAL_VIDEO",
      "alt": "",
      "id": "gid://shopify/ExternalVideo/22916920934584",
      "host": "VIMEO",
      "embeddedUrl": "https://player.vimeo.com/video/525829236",
      "preview": {
          "image": {
              "id": "gid://shopify/ImageSource/22925462372536",
              "originalSrc": "https://cdn.shopify.com/s/files/1/0566/5882/1304/products/1088544901_640.jpg?v=1625851331",
              "altText": "",
              "width": 640,
              "height": 360
          }
      }
  },
  {
      "mediaContentType": "IMAGE",
      "alt": "",
      "image": {
          "id": "gid://shopify/ImageSource/22925477871800",
          "originalSrc": "https://cdn.shopify.com/s/files/1/0566/5882/1304/products/5nskjq9qpn541.jpg?v=1625851392",
          "altText": "",
          "width": 960,
          "height": 742
      }
  }
]
*/
