import {
  position,
  sectionWithHeightAndAspectRatioNoColor,
  color,
  align,
  button,
} from '@settings/common';

const image = ({ position }) => ({
  label: 'Image Settings',
  name: 'image',
  description: 'Image, image position',
  component: 'group',
  fields: [
    {
      name: 'alt',
      component: 'text',
      description: 'Brief description of image',
      label: 'Alt',
    },
    {
      name: 'image',
      label: 'Image (desktop)',
      component: 'image',
      parse: (image) => image,
    },
    {
      name: 'position',
      component: 'select',
      label: 'Image Position (desktop)',
      options: position.object,
    },
    {
      name: 'imageMob',
      label: 'Image (mobile)',
      component: 'image',
      parse: (image) => image,
    },
    {
      name: 'positionMob',
      component: 'select',
      label: 'Image Position (mobile)',
      options: position.object,
    },
    {
      label: 'Activate overlay',
      description: 'Activate image overlay',
      name: 'imageOverlay',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      component: 'select',
      name: 'overlayColor',
      label: 'Select the overlay color',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'overlayOpacity',
      component: 'number',
      label: 'Overlay opacity (%)',
      description: 'set the opacity of the overlay in percentage',
      validate(opacity) {
        if (opacity > 100) {
          window.__bpCMS.alerts.error(`Max 100% opacity`);
        }
        if (opacity < 0) {
          window.__bpCMS.alerts.error(`Min 0 opacity`);
        }
      },
      defaultValue: 50,
    },
  ],
  defaultValue: {
    alt: 'Hero image',
    image: {
      src: 'https://images.unsplash.com/photo-1508028339507-ff30e5b2d025?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2552&q=75',
    },
    position: 'center center',
    imageMob: {
      src: 'https://images.unsplash.com/photo-1508028339507-ff30e5b2d025?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=90',
    },
    positionMob: 'center center',
  },
});

const video = ({ color }) => ({
  label: 'Video settings',
  name: 'video',
  description: 'video, video position',
  component: 'group',
  fields: [
    {
      label: 'Title (mobile)',
      name: 'titleMb',
      component: 'text',
      description: 'Title of the video (mobile)',
    },
    {
      name: 'videoMb',
      description: 'Video url must end with .mp4',
      label: 'video url (mobile)',
      component: 'text',
    },
    {
      label: 'Title (desktop)',
      name: 'titleDt',
      component: 'text',
      description: 'Title of the video (desktop)',
    },
    {
      name: 'videoDt',
      description:
        'Video url must end with .mp4 \n For better results on desktop use minimum resolution of 1280px',
      label: 'video url (desktop)',
      component: 'text',
    },
    {
      label: 'Activate overlay',
      description: 'Activate video overlay',
      name: 'videoOverlay',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      component: 'select',
      name: 'overlayColor',
      label: 'Select the overlay color',
      options: [{ label: 'Default', value: '' }, ...color.options],
    },
    {
      name: 'overlayOpacity',
      component: 'number',
      label: 'Overlay opacity (%)',
      description: 'set the opacity of the overlay in percentage',
      validate(opacity) {
        if (opacity > 100) {
          window.__bpCMS.alerts.error(`Max 100% opacity`);
        }
        if (opacity < 0) {
          window.__bpCMS.alerts.error(`Min 0 opacity`);
        }
      },
      defaultValue: 50,
    },
  ],
  defaultValue: {
    titleMb: 'Hero video',
    videoMb:
      'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
    titleDt: 'Hero video',
    videoDt:
      'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
    overlayOpacity: 50,
    overlayColor: '',
    videoOverlay: false,
  },
});

const media = ({ position, color }) => ({
  label: 'Media settings',
  name: 'media',
  description:
    'Select Media Type (image - video), position, placement, aspect ratio',
  component: 'group',
  fields: [
    image({ position }),
    video({ color }),
    {
      label: 'Select Media Type (mobile)',
      description: 'Allows select between image or video for mobile',
      name: 'mediaTypeMb',
      component: 'radio-group',
      direction: 'horizontal',
      options: [
        { label: 'Image', value: 'image' },
        { label: 'Video', value: 'video' },
      ],
      defaultValue: 'image',
    },
    {
      label: 'Select Media Type (desktop)',
      description: 'Allows select between image or video for desktop',
      name: 'mediaTypeDt',
      component: 'radio-group',
      direction: 'horizontal',
      options: [
        { label: 'Image', value: 'image' },
        { label: 'Video', value: 'video' },
      ],
      defaultValue: 'video',
    },
  ],
});

const text = ({ color }) => ({
  label: 'Text Settings',
  name: 'text',
  description: 'Heading, superheading, subheading, color',
  component: 'group',
  fields: [
    {
      name: 'heading',
      component: 'markdown',
      label: 'Heading',
      description:
        'Ensure heading is "h1" by starting with "# " e.g. "# Shop All". Text below will become large and bold to indicate "h1".',
    },
    {
      label: 'Heading Font Family',
      name: 'headingFontFamily',
      component: 'radio-group',
      direction: 'horizontal',
      options: [
        { label: 'Gotham', value: 'default' },
        { label: 'Quarto', value: 'quarto' },
      ],
      defaultValue: 'default',
    },
    {
      label: 'Heading Font Weight',
      name: 'headingFontWeight',
      component: 'radio-group',
      direction: 'horizontal',
      options: [
        { label: 'Bold', value: 'default' },
        { label: 'Light', value: 'light' },
      ],
      defaultValue: 'Bold',
    },
    {
      label: 'Heading Font Size (Desktop)',
      name: 'headingFontSize',
      component: 'select',
      direction: 'horizontal',
      options: [
        { label: 'Hero', value: 'hero' },
        { label: 'H1', value: 'h1' },
        { label: 'H2', value: 'h2' },
        { label: 'H3', value: 'h3' },
        { label: 'H4', value: 'h4' },
        { label: 'H5', value: 'h5' },
        { label: 'H6', value: 'h6' },
      ],
      defaultValue: 'hero',
    },
    {
      label: 'Heading Font Size (Mobile)',
      name: 'headingFontSizeMobile',
      component: 'select',
      options: [
        { label: 'Hero', value: 'hero' },
        { label: 'H1', value: 'h1' },
        { label: 'H2', value: 'h2' },
        { label: 'H3', value: 'h3' },
        { label: 'H4', value: 'h4' },
        { label: 'H5', value: 'h5' },
        { label: 'H6', value: 'h6' },
      ],
      defaultValue: 'image',
    },
    {
      name: 'headingColor',
      label: 'Heading Color (Desktop)',
      component: 'color',
      colors: color.list,
      widget: 'block',
    },
    {
      name: 'headingColorMob',
      label: 'Heading Color (Mobile)',
      component: 'color',
      colors: color.list,
      widget: 'block',
    },
    {
      label: 'Heading Margin Bottom (Desktop)',
      name: 'headingMarginBottomDt',
      component: 'number',
    },
    {
      label: 'Heading Margin Bottom (Mobile)',
      name: 'headingMarginBottomMob',
      component: 'number',
    },
    {
      label: 'Superheading',
      name: 'superheading',
      component: 'text',
    },
    {
      label: 'Superheading Font Size (Desktop)',
      name: 'superheadingFontSize',
      component: 'select',
      direction: 'horizontal',
      options: [
        { label: 'Hero', value: 'hero' },
        { label: 'H1', value: 'h1' },
        { label: 'H2', value: 'h2' },
        { label: 'H3', value: 'h3' },
        { label: 'H4', value: 'h4' },
        { label: 'H5', value: 'h5' },
        { label: 'H6', value: 'h6' },
      ],
      defaultValue: 'h6',
    },
    {
      label: 'Superheading Font Size (Mobile)',
      name: 'superheadingFontSizeMobile',
      component: 'select',
      direction: 'horizontal',
      options: [
        { label: 'Hero', value: 'hero' },
        { label: 'H1', value: 'h1' },
        { label: 'H2', value: 'h2' },
        { label: 'H3', value: 'h3' },
        { label: 'H4', value: 'h4' },
        { label: 'H5', value: 'h5' },
        { label: 'H6', value: 'h6' },
      ],
      defaultValue: 'h6',
    },
    {
      name: 'subheading',
      component: 'markdown',
      label: 'Subheading',
    },
    {
      label: 'Subheading Font Size (Desktop)',
      name: 'subheadingFontSize',
      component: 'select',
      direction: 'horizontal',
      options: [
        { label: 'Hero', value: 'hero' },
        { label: 'H1', value: 'h1' },
        { label: 'H2', value: 'h2' },
        { label: 'H3', value: 'h3' },
        { label: 'H4', value: 'h4' },
        { label: 'H5', value: 'h5' },
        { label: 'H6', value: 'h6' },
      ],
      defaultValue: 'h4',
    },
    {
      label: 'Subheading Font Size (Mobile)',
      name: 'subheadingFontSizeMobile',
      component: 'select',
      direction: 'horizontal',
      options: [
        { label: 'Hero', value: 'hero' },
        { label: 'H1', value: 'h1' },
        { label: 'H2', value: 'h2' },
        { label: 'H3', value: 'h3' },
        { label: 'H4', value: 'h4' },
        { label: 'H5', value: 'h5' },
        { label: 'H6', value: 'h6' },
      ],
      defaultValue: 'h4',
    },
    {
      name: 'color',
      label: 'Subheading Color (Desktop)',
      component: 'color',
      colors: color.list,
      widget: 'block',
    },
    {
      name: 'colorMob',
      label: 'Subheading Color (Mobile)',
      component: 'color',
      colors: color.list,
      widget: 'block',
    },
  ],
  defaultValue: {
    heading: '# Welcome to Storefront',
    headingFontSize: 'big',
    headingFontSizeMobile: 'h1',
    headingMarginBottomDt: 20,
    headingMarginBottomMob: 8,
    superheading: 'Backpack Storefronts',
    subheading: 'Manage content directly within the customizer.',
    subheadingFontSize: 'big',
    color: '',
    colorMob: '',
  },
});

const buttons = ({ button }) => ({
  label: 'Button Settings',
  name: 'button',
  description: 'Buttons, clickable hero',
  component: 'group',
  fields: [
    {
      label: 'Clickable Hero',
      description:
        'Allows entire hero to be clickable, using first button link as url',
      name: 'clickableHero',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      label: 'Hide Buttons',
      name: 'hideButtons',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      label: 'Margin Top (Desktop)',
      name: 'marginTopDt',
      component: 'number',
      defaultValue: 32,
    },
    {
      label: 'Margin Top (Mobile)',
      name: 'marginTopMob',
      component: 'number',
      defaultValue: 24,
    },
    {
      name: 'buttons',
      label: 'Buttons',
      component: 'group-list',
      description: 'Max of 2 buttons',
      itemProps: {
        label: '{{item.cta.text}}',
      },
      validate(list) {
        if (list?.length > 2) {
          window.__bpCMS.alerts.error(`Max of 2 buttons only.`);
        }
      },
      defaultItem: () => ({
        cta: {
          text: 'Shop Now',
          url: '/collections/shop-all',
        },
        style: 'primary',
        styleMob: 'primary',
      }),
      fields: [
        {
          name: 'cta',
          component: 'link',
          label: 'Button',
        },
        {
          label: 'Open Modal Button',
          name: 'openModalButton',
          description:
            'If set to "On", button will open modal with video. Use "Modal Settings" to configure.',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
        {
          label: 'Modal Settings',
          name: 'modal',
          description: 'Used only if modal button is set to "On"',
          component: 'group',
          fields: [
            {
              name: 'videoMb',
              description:
                'Video url must end with .mp4 unless Vimeo.\nClose then reopen modal to see change',
              label: 'Video url (mobile)',
              component: 'text',
            },
            {
              name: 'videoDt',
              description:
                'Video url must end with .mp4 unless Vimeo.\nFor better results on desktop use min resolution of 1280px.\nClose then reopen modal to see change.',
              label: 'Video url (desktop)',
              component: 'text',
            },
          ],
        },
        {
          name: 'style',
          label: 'Button Style (Desktop)',
          component: 'select',
          options: button.options,
        },
        {
          name: 'styleMob',
          label: 'Button Style (Mobile)',
          component: 'select',
          options: button.options,
        },
        {
          label: 'Button Style Overrides',
          name: 'buttonStylesOverride',
          component: 'group',
          fields: [
            {
              label: 'Color',
              name: 'color',
              component: 'color',
            },
            {
              label: 'Background Color',
              name: 'backgroundColor',
              component: 'color',
            },
            {
              label: 'Border Color',
              name: 'borderColor',
              component: 'color',
            },
            {
              label: 'Color - Hover',
              name: 'colorHover',
              component: 'color',
            },
            {
              label: 'Background Color - Hover',
              name: 'backgroundColorHover',
              component: 'color',
            },
            {
              label: 'Border Color - Hover',
              name: 'borderColorHover',
              component: 'color',
            },
          ],
        },
      ],
      defaultValue: [
        {
          cta: {
            text: 'Shop Now',
            url: '/collections/shop-all',
          },
          style: 'primary',
          styleMob: 'primary',
        },
      ],
    },
  ],
});

const content = ({ position }) => ({
  label: 'Content Settings',
  name: 'content',
  description: 'Content alignment, content position',
  component: 'group',
  fields: [
    {
      name: 'alignment',
      label: 'Content Alignment (desktop)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      name: 'position',
      component: 'select',
      label: 'Content Position (desktop)',
      options: position.flex,
    },
    {
      name: 'alignmentMob',
      label: 'Content Alignment (mobile)',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: align.options,
    },
    {
      name: 'positionMob',
      component: 'select',
      label: 'Content Position (mobile)',
      options: position.flex,
    },
    {
      name: 'maxWidth',
      component: 'number',
      label: 'Max Content Pixel Width (desktop)',
    },
    {
      name: 'maxWidthMob',
      component: 'number',
      label: 'Max Content Pixel Width (mobile)',
    },
  ],
  defaultValue: {
    alignment: 'center',
    position: 'center center',
    alignmentMob: 'center',
    positionMob: 'center center',
    maxWidth: 800,
    maxWidthMob: 200,
  },
});

const slider = ({ color }) => ({
  label: 'Slider Settings',
  name: 'slider',
  description:
    'Autoplay, delay, transition effect, bullet color, content position',
  component: 'group',
  fields: [
    {
      label: 'Enable Autoplay',
      name: 'autoplay',
      description: 'Refresh page to observe change',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      name: 'delay',
      component: 'number',
      description: 'Refresh page to observe change',
      label: 'Delay Between Transitions (ms)',
    },
    {
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      name: 'effect',
      label: 'Effect Between Transitions',
      description: 'Refresh page to observe change',
      options: [
        { label: 'Slide', value: 'slide' },
        { label: 'Fade', value: 'fade' },
      ],
    },
    {
      name: 'activeBulletColor',
      label: 'Active Bullet Color',
      component: 'color',
      colors: color.list,
      defaultValue: color.value.text,
      widget: 'block',
    },
  ],
  defaultValue: {
    autoplay: false,
    delay: 8000,
    effect: 'fade',
    activeBulletColor: color.value.text,
    contentPosition: 'absolute',
    contentPositionMob: 'absolute',
  },
});

const defaultSlide1 = () => ({
  media: {
    mediaTypeDt: 'image',
    mediaTypeMb: 'image',
    image: {
      alt: 'Hero image',
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_dfc844c6-e59e-4458-bc11-0d853e867110.jpg',
      },
      position: 'center center',
      imageMob: {
        src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_47.jpg?v=1654630937',
      },
      positionMob: 'center center',
    },
    video: {
      titleMb: 'Hero video',
      videoMb:
        'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
      titleDt: 'Hero video',
      videoDt:
        'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
    },
  },
  text: {
    heading: '# Welcome to Storefront',
    headingFontSize: 'big',
    headingMarginBottomDt: 20,
    headingMarginBottomMob: 8,
    superheading: 'Backpack Storefronts',
    subheading: '',
    subheadingFontSize: 'big',
    color: '',
    colorMob: '',
  },
  button: {
    marginTopDt: 32,
    marginTopMob: 24,
    buttons: [
      {
        cta: {
          text: 'Shop Now',
          url: '/collections/shop-all',
        },
        style: 'primary',
        styleMob: 'primary',
      },
    ],
  },
  content: {
    alignment: 'center',
    position: 'center center',
    alignmentMob: 'center',
    positionMob: 'center center',
    maxWidth: 1000,
  },
});

const defaultSlide2 = ({ color }) => ({
  media: {
    mediaTypeDt: 'image',
    mediaTypeMb: 'image',
    image: {
      alt: 'Hero image',
      image: {
        src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_dfc844c6-e59e-4458-bc11-0d853e867110.jpg',
      },
      position: 'center center',
      imageMob: {
        src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_47.jpg?v=1654630937',
      },
      positionMob: 'center center',
    },
    video: {
      titleMb: 'Hero video',
      videoMb:
        'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
      titleDt: 'Hero video',
      videoDt:
        'https://cdn.shopify.com/videos/c/o/v/db51ee059d3140f2a97af202883a6ff9.mp4',
    },
  },
  text: {
    heading: '# Explore New Products',
    headingFontSize: 'big',
    headingMarginBottomDt: 20,
    headingMarginBottomMob: 8,
    superheading: 'New Product',
    subheading: '',
    subheadingFontSize: 'big',
    color: color.value.white,
    colorMob: color.value.white,
  },
  button: {
    marginTopDt: 32,
    marginTopMob: 24,
    buttons: [
      {
        cta: {
          text: 'Shop Now',
          url: '/collections/shop-all',
        },
        style: 'primary',
        styleMob: 'primary',
      },
    ],
  },
  content: {
    alignment: 'center',
    position: 'center center',
    alignmentMob: 'center',
    positionMob: 'center center',
    maxWidth: 1000,
  },
});

export const Schema = () => {
  return {
    label: 'Hero Slider',
    key: 'hero',
    fields: [
      {
        name: 'slides',
        label: 'Slides',
        component: 'group-list',
        itemProps: {
          label: '{{item.text.heading}}',
        },
        defaultItem: () => defaultSlide1,
        fields: [
          media({ position, color }),
          text({ color }),
          buttons({ button }),
          content({ position }),
        ],
        defaultValue: [defaultSlide1(), defaultSlide2({ color })],
      },
      slider({ color }),
      sectionWithHeightAndAspectRatioNoColor,
    ],
  };
};
