import { Box } from 'theme-ui';
import { Svg, Picture, Link } from '@snippets';

import { themed } from './Menu.theme';

export const Logo = themed(({ theme }) => {
  return (
    <Link href="/" aria-label="Go to homepage" sx={{ height: '100%' }}>
      <Box sx={theme.logo_wrap}>
        <Svg
          role="presentation"
          viewBox="0 0 792 406"
          src="/svgs/logo.svg#logo"
          alt="Aloha logo"
          sx={theme.logo}
        />
      </Box>
    </Link>
  );
});
