import { Container, Grid } from 'theme-ui';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';

import { themed } from './Footer.theme';

const Menu = dynamic(() => import('./Menu').then((mod) => mod.Menu), {
  ssr: false,
  suspense: false,
});
const Legal = dynamic(() => import('./Legal').then((mod) => mod.Legal), {
  ssr: false,
  suspense: false,
});
const EmailSignupAndSocial = dynamic(
  () =>
    import('./EmailSignupAndSocial').then((mod) => mod.EmailSignupAndSocial),
  { ssr: false, suspense: false }
);

export const Footer = themed(({ theme }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: '400px',
  });

  return (
    <Container
      data-comp={Footer.displayName}
      as="footer"
      ref={ref}
      sx={theme.wrapper}
    >
      {inView && (
        <Container sx={theme.content}>
          <Grid sx={theme.grid}>
            <Menu />

            <EmailSignupAndSocial />
          </Grid>

          <Legal />
        </Container>
      )}
    </Container>
  );
});

Footer.displayName = 'Footer';
