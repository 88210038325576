import { useState, useRef, useEffect } from 'react';

import { Container, Box, Flex, Grid } from 'theme-ui';
import { Section } from '@snippets';

import { Header } from './Header';
import { Slider } from './Slider';
import { Media } from './Media';
import { Button } from './Button';

import { themed } from './SuperSlider.theme';
import { Schema } from './SuperSlider.schema';

export const SuperSlider = themed(({ cms, theme }) => {
  const { heading, media: mediaSettings, tabs, section } = cms;
  const { location: locationDt, locationMob } = { ...mediaSettings };

  const mediaRef = useRef();
  const sliderRef = useRef();

  const [activeIndex, setActiveIndex] = useState(0);
  const [height, setHeight] = useState(null);

  const mediaLeft = locationDt === 'left'; // desktop
  const mediaTop = locationMob === 'top'; // mobile

  useEffect(() => {
    const getHeight = () => {
      const elHeight = sliderRef.current.getBoundingClientRect()?.height;
      setHeight(window.innerWidth > 768 ? elHeight : 'auto');
    };

    getHeight();
    ['resize', 'scroll'].forEach((evt) =>
      window.addEventListener(evt, getHeight)
    );

    return () => {
      ['resize', 'scroll'].forEach((evt) =>
        window.removeEventListener(evt, getHeight)
      );
    };
  }, []);

  return (
    <Section section={section} cms={cms}>
      <Container data-comp={SuperSlider.displayName}>
        {mediaTop && (
          <Media
            ref={mediaRef}
            link={tabs?.[activeIndex]?.link}
            media={tabs?.[activeIndex]?.media}
            height={height}
            sx={{
              gridArea: 'media',
            }}
          />
        )}

        <Header
          heading={heading}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          tabs={tabs}
          section={section}
        />

        <Box>
          {tabs?.map(
            (
              {
                link,
                media,
                products,
                ctaStyle,
                buttonStylesOverride,
                hideButton,
              },
              index
            ) => {
              if (index !== activeIndex) return null;

              return (
                <>
                  <Grid
                    sx={{
                      ...theme.grid,
                      gridTemplateAreas: [
                        mediaTop ? '"media" "slider"' : '"slider" "media"',
                        null,
                        mediaLeft ? '"media slider"' : '"slider media"',
                      ],
                    }}
                  >
                    <Media
                      ref={mediaRef}
                      link={link}
                      media={media}
                      height={height}
                      section={section}
                      sx={{
                        gridArea: 'media',
                        display: mediaTop ? 'none' : 'block',
                      }}
                    />

                    <Slider
                      activeIndex={activeIndex}
                      products={products}
                      ref={sliderRef}
                      sx={{
                        gridArea: 'slider',
                      }}
                    />
                  </Grid>

                  {!hideButton && (
                    <Flex
                      variant="flex.row.center.center"
                      sx={{
                        mt: 14,
                      }}
                    >
                      <Button
                        link={link}
                        ctaStyle={ctaStyle}
                        buttonStylesOverride={buttonStylesOverride}
                      />
                    </Flex>
                  )}
                </>
              );
            }
          )}
        </Box>
      </Container>
    </Section>
  );
});

SuperSlider.displayName = 'SuperSlider';
SuperSlider.Schema = Schema;
//
