import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.heading = {
      variant: 'text.h2',
      mb: [12, 14],
    };

    this.jobboard = {
      flexDirection: 'column',
      mb: [12, 14],
    };

    this.role = {
      mb: [6, 7],
      padding: 3,
      bg: 'background',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      transition: 'all .25s ease-in-out',
      borderRadius: 5,
      ':hover': {
        bg: colors.lighterGray,
        transition: 'all .25s ease-in-out',
      },
      dept: {
        flexDirection: 'column',
        alignItems: 'center',
        my: 10,
        name: {
          bg: colors.sand,
          p: 3,
          borderRadius: 5,
          mt: '-1.9rem',
          variant: 'text.h5',
        },
        divider: {
          m: 7,
          height: '1px',
          width: '100%',
          bg: 'lightGray',
        },
      },
      position: {
        variant: 'text.h5',
        fontSize: [2, 2, 4],
        textTransform: 'uppercase',
      },
      location: {
        variant: 'text.small',
        fontSize: [2, 2, 4],
      },
      apply: {
        display: 'block',
        variant: 'text.h5',
        mx: 7,
        textTransform: 'uppercase',
        '::after': {
          content: '">"',
          position: 'absolute',
          marginLeft: '5px',
          height: '10px',
          width: '10px',
          transition: 'margin .25s ease-in-out',
        },
        ':hover::after': {
          marginLeft: '10px',
        },
      },
    };
  })()
);
