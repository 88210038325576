import { useMemo } from 'react';
import { Flex, Box, Paragraph } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { hexToRgbA } from '@utils/common';

export function SitewideSaleMessage({ product, componentType, ...props }) {
  const settings = useSettings();

  const { enabled, collection: saleCollection } = {
    ...settings?.sitewideSale,
  };

  const { bgColor, bgColorOpacity, borderColor, message, textColor } =
    useMemo(() => {
      if (
        !enabled ||
        !componentType ||
        !product?.collections ||
        !saleCollection?.handle
      )
        return {};

      const collections = product?.collections?.nodes
        ? product.collections.nodes
        : product.collections;

      const hasSaleCollection = collections?.find(
        ({ handle }) => saleCollection.handle === handle
      );

      if (hasSaleCollection) {
        return {
          bgColor: settings?.sitewideSale?.[componentType]?.text?.bgColor,
          bgColorOpacity:
            settings?.sitewideSale?.[componentType]?.text?.bgColorOpacity,
          borderColor:
            settings?.sitewideSale?.[componentType]?.text?.borderColor,
          message: settings?.sitewideSale?.[componentType]?.text?.message,
          textColor: settings?.sitewideSale?.[componentType]?.text?.textColor,
        };
      }

      return {};
    }, [
      product?.handle,
      componentType,
      JSON.stringify(settings?.sitewideSale),
    ]);

  if (!message) {
    return null;
  }

  return (
    <Box
      data-comp={SitewideSaleMessage.displayName}
      sx={{
        textAlign: 'center',
        width: 'auto',
        border: '1px solid',
        borderRadius: '6px',
        py: '5px',
        px: 6,
        background: hexToRgbA(bgColor || '#CBEBEA', bgColorOpacity / 100),
        borderColor: borderColor || 'seafoam',
        ...props.styles,
      }}
    >
      <Paragraph
        sx={{
          fontSize: ['9px', 1],
          fontWeight: 500,
          lineHeight: '1',
          letterSpacing: ['-0.18px', '-0.24px'],
          color: textColor || 'black',
          ...props.textStyles,
        }}
      >
        {message}
      </Paragraph>
    </Box>
  );
}

SitewideSaleMessage.displayName = 'SitewideSaleMessage';
