import { useState, useEffect, useMemo } from 'react';
import { Paragraph, Flex, Image } from 'theme-ui';

import { Svg } from '@snippets';
import { colors } from '@theme/theme.colors';

export function SelectField({
  label = '',
  placeholder = '',
  list = [],
  defaultValue = null,
  action = () => null,
  isMenu = false,
  id = '',
  smallPadding = false,
  listAsArrayOfStrings = false,
  isCountryProvinceSelector = true,
  zIndex = 1,
  sx,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const selectId = `styled-select-${id}`;

  const activeLabel = useMemo(() => {
    if (typeof activeIndex !== 'number') return placeholder;
    const _activeLabel = listAsArrayOfStrings
      ? list[activeIndex]
      : list[activeIndex]?.label;
    return _activeLabel;
  }, [activeIndex]);

  useEffect(() => {
    if (!isOpen) return undefined;
    const element = document.getElementById(selectId);
    const isVisible = (el) => {
      return (
        !!el &&
        !!(el.offsetWidth || el.offsetHeight || el.getClientRects().length)
      );
    };
    const outsideClickHandler = (e) => {
      if (!element.contains(e.target) && isVisible(element)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', outsideClickHandler);
    return () => {
      document.removeEventListener('click', outsideClickHandler);
    };
  }, [isOpen]);

  return (
    <Flex
      data-comp="StyledSelect"
      id={selectId}
      variant="flex.column"
      sx={{
        ...containerSx(zIndex),
        ...sx,
      }}
      {...props}
    >
      <Flex
        variant="flex.row.between.center"
        sx={{
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          position: 'relative',
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Paragraph
          variant="text.label"
          sx={{
            position: 'absolute',
            top: '4px',
            left: '16px',
          }}
        >
          {label}
        </Paragraph>
        <Paragraph
          variant="base"
          sx={{
            mr: 4,
            px: '16px',
            height: '100%',
            pt: '20px',
            pb: '8px',
          }}
        >
          {isCountryProvinceSelector ? defaultValue : activeLabel}
        </Paragraph>

        <Svg
          alt="Select country"
          src={
            isOpen ? `/svgs/chevron/up.svg#up` : `/svgs/chevron/down.svg#down`
          }
          viewBox="0 0 48 48"
          draggable={false}
          sx={{
            width: [18, null, 22],
            mr: '11px',
            userSelect: 'none',
          }}
        />
      </Flex>

      {isOpen ? (
        <Flex
          variant="flex.column"
          sx={{
            position: 'absolute',
            left: '-1px',
            top: 'calc(100% + 4px)',
            width: 'calc(100% + 2px)',
            bg: `${colors.lightestGray}`,
            border: '1px solid',
            borderColor: 'lightgrey',
            maxHeight: '400px',
            overflowY: 'scroll',
            '::-webkit-scrollbar': {
              display: 'none',
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}
        >
          {list?.map((item, index) => {
            if (index === activeIndex) return null;
            const value = listAsArrayOfStrings ? item : item.value;
            const label = listAsArrayOfStrings ? item : item.label;
            return (
              <Paragraph
                sx={itemSx(smallPadding)}
                key={index}
                onClick={() => {
                  setActiveIndex(index);
                  setIsOpen(false);
                  action({
                    value,
                    index,
                  });
                }}
              >
                {label}
              </Paragraph>
            );
          })}
        </Flex>
      ) : null}
    </Flex>
  );
}

const itemSx = (smallPadding) => ({
  cursor: 'pointer',
  px: 6,
  py: smallPadding ? 3 : 6,
  bg: 'transparent',
  transition: 'all 0.1s ease-in-out',
  color: 'gray.6',
  ':hover': {
    bg: 'white',
    color: 'black',
  },
});

const containerSx = (zIndex) => ({
  width: 'auto',
  minWidth: '200px',
  // height: '44px',
  position: 'relative',
  bg: 'transparent',
  variant: 'text.base',
  border: '1px solid',
  borderColor: 'lightgrey',
  zIndex,
  ':focus': {
    outline: 'none !important',
    borderColor: 'gold',
  },
  ':valid': {
    borderColor: 'gold',
  },
  '&::placeholder': {
    fontSize: 4,
    color: 'gray.5',
  },
});
