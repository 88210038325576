import { store } from '@backpackjs/storefront';

const cartPromos = store.recoil.atom({
  key: 'cart/cartPromos',
  default: null,
});

const cartPromoMessages = store.recoil.atom({
  key: 'cart/cartPromoMessages',
  default: null,
});

const gwpProduct = store.recoil.atom({
  key: 'cart/gwpProduct',
  default: null,
});

const gwpProductInCart = store.recoil.atom({
  key: 'cart/gwpProductInCart',
  default: false,
});

const hiddenGwpProducts = store.recoil.atom({
  key: 'cart/hiddenGwpProducts',
  default: null,
});

const lastItemAdded = store.recoil.atom({
  key: 'cart/lastItemAdded',
  default: null,
});

const upsellProductsNotInCart = store.recoil.atom({
  key: 'cart/upsellProductsNotInCart',
  default: [],
});

const seenConfetti = store.recoil.atom({
  key: 'cart/seenConfetti',
  default: false,
});

const playItemAddedNotification = store.recoil.atom({
  key: 'cart/playItemAddedNotification',
  default: false,
});

export default {
  cartPromos,
  cartPromoMessages,
  gwpProduct,
  gwpProductInCart,
  hiddenGwpProducts,
  lastItemAdded,
  upsellProductsNotInCart,
  seenConfetti,
  playItemAddedNotification,
};
