import { Fragment, useMemo } from 'react';
import { Button, Alert } from 'theme-ui';

export function StatefulButton({
  text = {
    started: 'LOADING',
    success: 'DONE',
    error: 'FAILED',
    default: 'SUBMIT',
  },
  started,
  success,
  finished,
  errors,
  reset,
  resetTimer,
  showErrors = true,
  autoReset = true,
  resetAfter = 2000,
  children,
  loading,
  isAtc,
  ...rawProps
}) {
  const { setErrors, setFinished, setStarted, setSuccess, ...props } = rawProps;
  const resetTimerRef = resetTimer || React.useRef(null);

  const buttonText = useMemo(() => {
    if (finished && autoReset) {
      resetTimerRef.current = setTimeout(() => {
        reset && reset();
        clearTimeout(resetTimerRef.current);
      }, resetAfter);
    }

    return finished
      ? success
        ? text.success
        : text.error
      : // not finished yet
      started
      ? text.started
      : text.default;
  }, [started, success, finished, Object.values(text).join(',')]);

  return (
    <>
      <Button {...props} disabled={started && !isAtc}>
        {buttonText}
        {children}
      </Button>

      {showErrors && errors?.length
        ? errors.map((err, index) => (
            <Alert key={err} variant="alerts.error" mt={6}>
              {err}
            </Alert>
          ))
        : null}
    </>
  );
}
