import { useRef } from 'react';
import { Container } from 'theme-ui';

import { useSetHeaderHeightCssVar } from '@hooks';

import { PromoBar } from './PromoBar';
import { RotatingBar } from './RotatingBar';
import { Menu } from './Menu';
import { MenuSidebarShell } from './MenuSidebar';
import { MenuDrawerShell } from './MenuDrawer';
import { themed } from './Header.theme';

export const Header = themed(({ theme }) => {
  const headerRef = useRef(null);

  // Sets --header-height CSS var
  useSetHeaderHeightCssVar({ headerRef });

  return (
    <>
      <PromoBar />
      <RotatingBar />

      <Container
        data-comp={Header.displayName}
        as="header"
        ref={headerRef}
        sx={theme.header}
      >
        {/* logo, top level menu items, search, account cart */}
        <Menu />

        {/* mobile: menu sliding sidebar on menu click */}
        <MenuSidebarShell />

        {/* desktop: mega-nav drawer on hover */}
        <MenuDrawerShell />
      </Container>
    </>
  );
});

Header.displayName = 'Header';
