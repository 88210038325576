import React from 'react';
import { Flex } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

import { Link, Svg } from '@snippets';

import { themed } from './SocialLinks.theme';

const getSocialIcon = (icon, network, inHeader) => {
  if (icon) return icon;
  return inHeader
    ? `/svgs/noprecache/social/header/${network}.svg#${network}`
    : `/svgs/noprecache/social/${network}.svg#${network}`;
};
export const SocialLinks = themed(
  ({ theme, size = 28, inHeader = false, ...rawProps }) => {
    const settings = useSettings();
    const { inputRef, ...props } = rawProps;
    const socialLinks = settings?.social?.links;

    return socialLinks?.length ? (
      <Flex data-comp={SocialLinks.displayName} {...props}>
        {socialLinks.map(({ network, handle, icon }, index) => {
          return (
            <Link
              key={index}
              newTab
              href={
                networks[network] && handle
                  ? `${networks[network].url}${handle}`
                  : ''
              }
              aria-label={network}
              sx={{
                ...theme.link,
                width: size,
                height: size,
              }}
            >
              <Svg
                src={getSocialIcon(icon, network, inHeader)}
                alt={network}
                viewBox="0 0 24 24"
                sx={{
                  ...theme.icon,
                  width: size,
                  height: size,
                }}
              />
            </Link>
          );
        })}
      </Flex>
    ) : null;
  }
);

SocialLinks.displayName = 'SocialLinks';

const networks = {
  facebook: {
    url: 'https://www.facebook.com/',
  },
  instagram: {
    url: 'https://www.instagram.com/',
  },
  twitter: {
    url: 'https://www.twitter.com/',
  },
  pinterest: {
    url: 'https://www.pinterest.com/',
  },
  youtube: {
    url: 'https://www.youtube.com/',
  },
  vimeo: {
    url: 'https://www.vimeo.com/',
  },
  tiktok: {
    url: 'https://www.tiktok.com/@',
  },
  spotify: {
    url: 'https://www.spotify.com/',
  },
};
