import { useRef, useEffect } from 'react';

export const useSetPromoBarHeightCssVar = (inView, entry) => {
  const heightVarSet = useRef(false);

  useEffect(() => {
    if (heightVarSet.current) return;
    if (!entry) {
      document.documentElement.style.setProperty('--promoBar-height', '0px');
      return;
    }
    if (!inView) return;

    const { target } = entry;
    const promoBarHeightPx = inView
      ? `${
          typeof target.getBoundingClientRect === 'function'
            ? target.getBoundingClientRect()?.height
            : 0
        }px`
      : `0px`;

    document.documentElement.style.setProperty(
      '--promoBar-height',
      promoBarHeightPx
    );
    heightVarSet.current = true;
  }, [inView]);
};
