import { detailContentBlock } from './detailContentBlock';
import { sizeGuides } from './sizeGuides';
import { swatches } from './swatches';

export default {
  label: 'Product Settings',
  description: 'Manage product swatches, size guides, global product settings',
  name: 'product',
  component: 'group',
  fields: [
    {
      label: 'Badges',
      name: 'badges',
      component: 'group',
      description: 'Badge colors',
      fields: [
        {
          label: 'Badge Colors',
          name: 'badgeColors',
          component: 'group-list',
          description:
            'Note: product badges are set up via Shopify tags using the format "badge::Some Value". "Pre Order Item" is hardcoded in front end. Do not remove',
          itemProps: {
            label: '{{item.tag}}',
          },
          fields: [
            {
              label: 'Tag Value',
              name: 'tag',
              component: 'text',
              description:
                'Letter casing must be same as tag value in Shopify, e.g. "REVERSIBLE", "LIMITED-EDITION"',
            },
            {
              label: 'Text Color',
              name: 'textColor',
              component: 'color',
            },
            {
              label: 'Border Color',
              name: 'borderColor',
              component: 'color',
            },
            {
              label: 'Background Color',
              name: 'bgColor',
              component: 'color',
            },
            {
              label: 'Background Color Opacity (%)',
              name: 'bgColorOpacity',
              component: 'number',
              description: 'set the opacity of the background in percentage',
              validate(opacity) {
                if (opacity > 100) {
                  window.__bpCMS.alerts.error(`Max 100% opacity`);
                }
                if (opacity < 0) {
                  window.__bpCMS.alerts.error(`Min 0 opacity`);
                }
              },
              defaultValue: 100,
            },
          ],
        },
      ],
    },
    detailContentBlock,
    sizeGuides,
    {
      label: 'PDP Customizations',
      name: 'pdpCustomizations',
      component: 'group',
      description: 'hide wishlist, quantity selector, shipping, icons',
      fields: [
        {
          label: 'PDP Customizations',
          name: 'products',
          component: 'group-list',
          itemProps: {
            label: '{{item.product.handle}}',
          },
          fields: [
            {
              label: 'Product',
              name: 'product',
              component: 'productSearch',
              description: 'Product to customize',
            },
            {
              label: 'Hide Wishlist Link',
              name: 'hideWishlistLink',
              component: 'toggle',
              toggleLabels: {
                true: 'Hide',
                false: 'Show',
              },
              defaultValue: false,
            },
            {
              label: 'Hide Quantity Selector',
              name: 'hideQuantitySelector',
              component: 'toggle',
              toggleLabels: {
                true: 'Hide',
                false: 'Show',
              },
              defaultValue: false,
            },
          ],
        },
        {
          label: 'Product Types',
          name: 'productTypes',
          component: 'group-list',
          description:
            'Product Type Customizations for Icons and Disabling Image Swap',
          itemProps: {
            label: '{{item.productType}}',
          },
          fields: [
            {
              label: 'Product Type',
              name: 'productType',
              description: 'Value needs to match product type set in Shopify',
              component: 'text',
            },
            {
              label: 'Icon Row',
              name: 'icons',
              component: 'group-list',
              itemProps: {
                label: '{{item.heading}}',
              },
              fields: [
                {
                  label: 'Icon',
                  name: 'icon',
                  component: 'image',
                },
                {
                  label: 'Icon Width (px)',
                  name: 'iconWidth',
                  component: 'number',
                },
                {
                  label: 'Heading',
                  name: 'heading',
                  component: 'text',
                },
              ],
              defaultValue: [
                {
                  heading: 'Lightweight',
                },
                {
                  heading: 'Splash-Proof®',
                },
                {
                  heading: 'Easy to Clean',
                },
                {
                  heading: 'Carry-on Approved',
                },
              ],
              defaultItem: {
                heading: 'Lightweight',
              },
            },
            {
              label: 'Disable Image Swap',
              name: 'disableImageSwap',
              description:
                'Disables Image Swap where PDP shows 2nd image in Shopify as the first image',
              component: 'toggle',
              toggleLabels: {
                true: true,
                false: false,
              },
              defaultValue: false,
            },
          ],
        },
        {
          label: 'Shipping & Returns',
          name: 'shippingCopy',
          component: 'group',
          fields: [
            {
              label: 'Heading',
              name: 'heading',
              component: 'text',
            },
            {
              label: 'Message',
              name: 'message',
              component: 'text',
            },
          ],
          defaultValue: {
            heading: 'Shipping & Returns',
            body: `Free shipping on all orders over $100<br />Returns accepted within 30 days`,
            message: 'Free shipping on all orders over $100',
          },
        },
      ],
    },
    {
      label: 'Print Slider Settings',
      name: 'printSlider',
      component: 'group',
      description: 'heading, print type map',
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
          defaultValue: 'Also Available In',
        },
        {
          label: 'Print Type Map',
          name: 'printTypeMapping',
          component: 'group-list',
          description:
            'A list of product types that maps to collections to render Print Slider on PDP and collection description as product description. Production desc in Shopify will be used as print story',
          itemProps: {
            label: '{{item.productType}}',
          },
          fields: [
            {
              label: 'Product Type',
              name: 'productType',
              description: 'Value needs to match product type set in Shopify',
              component: 'text',
            },
            {
              label: 'Collection',
              name: 'collection',
              component: 'collections',
            },
            {
              label: 'Enable Slider',
              name: 'enableSlider',
              component: 'toggle',
              toggleLabels: {
                true: true,
                false: false,
              },
              defaultValue: false,
            },
          ],
        },
      ],
    },
    {
      name: 'thresholdOnlyFewLeft',
      component: 'number',
      label: 'Only few left',
      description: 'Threshold that triggers "only a few left" text',
    },
    {
      component: 'list',
      name: 'sizeComparisonProductTypes',
      label: 'Size Comparison Product Types',
      description:
        'List of product types to include size comparison link on pdp.',
      field: {
        component: 'text',
      },
      defaultValue: [
        'mini pouch',
        'small pouch',
        'mid pouch',
        'max pouch',
        'reversible tote',
        'zipper tote',
        'day tripper',
        'holo holo',
      ],
    },
    swatches,
  ],
};
