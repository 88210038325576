import { Swiper, SwiperSlide } from 'swiper/react';

import { Slide } from './Slide';

import { themed } from './Slider.theme';

export const Slider = themed(({ setSwiper, image, tiles, theme, inputRef }) => {
  const { ratio: imageRatio } = { ...image };

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1.25}
      slidesOffsetBefore={20}
      slidesOffsetAfter={20}
      autoHeight
      navigation={false}
      watchOverflow
      grabCursor
      onSwiper={setSwiper}
      ref={inputRef}
      breakpoints={{
        600: {
          spaceBetween: 10,
          slidesOffsetBefore: 20,
          slidesPerView: 2.25,
        },
        768: {
          spaceBetween: 10,
          slidesOffsetBefore: 0,
          slidesPerView: 2.25,
        },
        1024: {
          spaceBetween: 20,
          slidesOffsetBefore: 0,
          slidesPerView: 4,
        },
      }}
      sx={theme.slider}
    >
      {tiles?.map((tile, index) => {
        return (
          <SwiperSlide key={index}>
            <Slide imageRatio={imageRatio} tile={tile} index={index} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
});

Slider.displayName = 'Slider';
