import { sectionWithHeight, button } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Hero Image with Icon',
    key: 'heroImageWithIcon',
    fields: [
      {
        label: 'Image (Background)',
        name: 'image',
        component: 'image',
        defaultValue: {
          src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_50.png?v=1653045256',
        },
      },
      {
        label: 'Alt image',
        name: 'altImage',
        component: 'text',
        defaultValue: 'image',
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, body, link',
        component: 'group',
        fields: [
          {
            label: 'Logo',
            name: 'logo',
            component: 'image',
          },
          {
            label: 'Alt logo',
            name: 'altLogo',
            component: 'text',
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
          {
            name: 'cta',
            component: 'link',
            label: 'Button',
          },
          {
            name: 'style',
            label: 'Button Style',
            component: 'select',
            options: button.options,
          },
        ],
        defaultValue: {
          logo: {
            src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_49_56x56@2x.png?v=1653044067',
          },
          altLogo: 'logo',
          heading: 'Join the ohana',
          body: 'For every purchase, youll earn ALOHA Miles to redeem on future orders',
          cta: {
            text: 'Sign up',
            url: '/collections/shop-all',
          },
          style: 'primary',
        },
      },
      sectionWithHeight,
    ],
  };
};
