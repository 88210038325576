import { section } from '@settings/common';

export function Schema() {
  return {
    label: 'Dash Hudson',
    key: 'dash-hudson',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Aloha Abroad',
      },
      {
        label: 'Body',
        name: 'body',
        component: 'markdown',
        defaultValue: 'Follow Along With Us @alohacollection',
      },
      {
        label: 'Widget Settings',
        name: 'widget',
        component: 'group',
        fields: [
          {
            label: 'Gallery ID',
            name: 'galleryId',
            component: 'text',
            defaultValue: '3322522',
          },
          {
            label: 'Row Size',
            name: 'rowSize',
            component: 'number',
            defaultValue: 4,
          },
          {
            label: 'Row Size (Mobile)',
            name: 'rowSizeMobile',
            component: 'number',
            defaultValue: 2,
          },
          {
            label: 'Row Limit',
            name: 'rowLimit',
            component: 'number',
            defaultValue: 3,
          },
          {
            label: 'Gap Size',
            name: 'gapSize',
            component: 'number',
            defaultValue: 1,
          },
          {
            label: 'Gap Size (Mobile)',
            name: 'gapSizeMobile',
            component: 'number',
            defaultValue: 1,
          },
        ],
      },
      section,
    ],
  };
}
