import { useCallback, useState, useMemo, useEffect } from 'react';
import { Box, Button, Flex, Paragraph } from 'theme-ui';
import { LoadingAnimation, StatefulButton, Svg } from '@snippets';
import { useAddToCart, useModal } from '@hooks';
import { useProductByHandle } from '@backpackjs/storefront';
import { themed } from './AddToCart.theme';
import { BackInStockModal } from './BackInStockModal';

export const AddToCart = themed(
  ({
    theme,
    product,
    selectedVariant,
    withQuantity = false,
    title,
    addText = 'ADD TO CART',
    callback,
    inputRef,
    isStickyAtc,
    isKitItem,
    styles,
    buttonStyles,
    ...props
  }) => {
    const productByHandle = useProductByHandle({
      handle: selectedVariant?.product?.handle,
    });

    const [{ status, isPreOrder, isSoldOut, selectedPlan }, { addToCart }] =
      useAddToCart({ product: productByHandle.product, selectedVariant });

    const [quantity, setQuantity] = useState(1);

    const increaseQuantity = () => {
      setQuantity((qty) => qty + 1);
    };

    const decreaseQuantity = () => {
      setQuantity((qty) => qty - 1);
    };

    const [, { openModal }] = useModal();
    const handleOutOfStock = useCallback(() => {
      openModal(
        <BackInStockModal
          legacyResourceId={selectedVariant?.legacyResourceId}
          selectedOptionsMap={selectedVariant?.selectedOptionsMap}
          title={title}
        />
      );
    }, [selectedVariant?.id]);

    const isNonReplenish =
      productByHandle.product?.tags?.includes('non-replen');

    const forcedPreOrder =
      productByHandle.product?.tags?.includes('allow-preorder');

    const text = () => {
      if (status?.inventoryStatus?.finished) {
        if (status?.inventoryStatus?.success) {
          if (isSoldOut) {
            if (isNonReplenish) {
              return 'Sold Out';
            }
            return 'Notify Me';
          }
          if (isPreOrder || forcedPreOrder) {
            return 'PRE-ORDER';
          }
          if (selectedPlan) {
            return 'SUBSCRIBE';
          }
          return addText;
        }
        return 'Sold Out';
      }
      return 'LOADING...';
    };

    const [atcStatus, setAtcStatus] = useState(null);

    useEffect(() => {
      setAtcStatus(text);
    }, [text]);

    const handleAtcStatus = () => {
      setAtcStatus('Item added to cart');

      setTimeout(() => {
        setAtcStatus(text);
      }, 2000);
    };

    return (
      <Box
        data-comp={AddToCart.displayName}
        sx={{
          ...theme.wrapper,
          maxHeight: isStickyAtc ? 'inherit' : '48px',
          height: isStickyAtc ? 'inherit' : '48px',
          ...styles,
          ...props.sx,
        }}
        ref={inputRef}
      >
        {withQuantity && (
          <Flex sx={theme.quantityWrapper}>
            <Button
              sx={theme.quantityButton}
              onClick={decreaseQuantity}
              disabled={quantity === 1}
            >
              <Svg src="/svgs/minus.svg" alt="minus" sx={theme.svg} />
            </Button>
            <Paragraph sx={theme.quantity}>{quantity}</Paragraph>
            <Button sx={theme.quantityButton} onClick={increaseQuantity}>
              <Svg src="/svgs/plus.svg" alt="plus" sx={theme.svg} />
            </Button>
          </Flex>
        )}
        <StatefulButton
          disabled={isSoldOut}
          aria-disabled={isSoldOut}
          aria-label={atcStatus}
          isAtc
          sx={{
            ...theme.button,
            ...buttonStyles,
            ...(isSoldOut && isNonReplenish
              ? {
                  ...theme.button.unavailable,
                  ...(selectedPlan
                    ? theme.button.unavailable.subscribe
                    : theme.button.unavailable.oneTime),
                }
              : {
                  ...theme.button.available,
                  ...(selectedPlan
                    ? theme.button.available.subscribe
                    : theme.button.available.oneTime),
                  ...(isKitItem && theme.button.available.kit),
                }),
            cursor: status.addItem.started ? 'default' : 'pointer',

            // ...(status.addItem.started && !isStickyAtc
            //   ? {
            //       bg: 'white',
            //       ':hover': {
            //         bg: 'white',
            //       },
            //     }
            //   : null),
          }}
          {...status.addItem}
          text={{
            default: text(),
            started: (
              <LoadingAnimation
                sx={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                type={isStickyAtc ? 'light' : 'dark'}
              />
            ),
            success: (
              <Flex
                variant="flex.row.center.center"
                sx={{
                  gap: 2,
                }}
              >
                <Svg
                  src="/svgs/noprecache/svg/circle-checkmark.svg#circle-checkmark"
                  viewBox="0 0 100 100"
                  alt="checkmark"
                  sx={theme.svg}
                />
                <Box as="span">Added!</Box>
              </Flex>
            ),
            error: 'FAILED TO ADD',
          }}
          onClick={() => {
            if (status.addItem.started) return;
            if (isSoldOut) {
              if (!isNonReplenish) handleOutOfStock();
            } else {
              addToCart({
                quantity,
                itemAttributes: isKitItem
                  ? [
                      {
                        key: 'kit_item',
                        value: window.location.pathname?.replace(
                          '/products/',
                          ''
                        ),
                      },
                    ]
                  : [],
                openCart: !isKitItem,
                callback,
              });
              // handleAtcStatus();
            }
          }}
        />
      </Box>
    );
  }
);

AddToCart.displayName = 'AddToCart';
