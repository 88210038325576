import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      variant: 'flex.row.center',
      py: 17,
      px: 10,
      borderBottom: (t) => `1px solid ${t.colors.lightGray}`,
    };

    this.text = {
      variant: 'text.h4',
    };
  })()
);
