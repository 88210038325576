import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.label = {
      mx: 2,
      variant: 'text.sm',
      textTransform: 'capitalize',
      width: 'fit-content',
      color: 'mediumGray',
      letterSpacing: 0,
      fontSize: 0,
      mb: 0,
    };

    this.switcher = {
      border: '1px solid black',
      height: 21,
      minWidth: '43px',
      width: '43px',
      backgroundColor: 'white',
      m: 0,
      mx: 1,
      '& > div': {
        right: '0px',
        height: 15,
        width: 15,
        border: '5px solid black',
        margin: 0,
      },
      'input:checked ~ &': {
        backgroundColor: 'white',
        '> div': {
          right: '-7px',
        },
      },
    };
  })()
);
