import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      display: 'flex',
      cursor: 'pointer',
      ':hover div[data-type="checkmark"]': {
        borderColor: 'darkGray',
      },
    };

    this.input = {
      display: 'none',
    };
    this.checkmark = {
      variant: 'flex.row.center.center',
      width: '20px',
      height: '20px',
      backgroundColor: 'white',
      border: '1px solid',
      borderColor: 'gray',
      borderRadius: '50%',
      position: 'relative',
    };

    this.checkmarkChecked = {
      ...this.checkmark,
      backgroundColor: 'darkGray',
      border: 'none',
      ':after': {
        content: '""',
        display: 'block',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: 'white',
      },
    };
    this.label = {
      ml: 4,
    };
  })()
);
