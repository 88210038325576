import PropTypes from 'prop-types';

const backpackHeadUserConfig = {
  url: process.env.SITE_URL,
  title: process.env.SITE_SEO_TITLE,
  description: process.env.SITE_DESCRIPTION,
  author: process.env.SITE_AUTHOR,
  locale: process.env.SITE_LOCALE,
  social: {
    twitter: process.env.SOCIAL_TWITTER,
    facebook: process.env.SOCIAL_FACEBOOK,
    instagram: process.env.SOCIAL_INSTAGRAM,
  },
};

function PageHead(props) {
  const { article, blog, page, template } = props;

  let seo = page?.seo;
  let pageUrl = '';

  if (page?.resourceType === 'home_page') {
    pageUrl = backpackHeadUserConfig.url;
  } else if (page?.resourceType === 'page') {
    pageUrl = `${backpackHeadUserConfig.url}/pages/${page.handle}`;
  } else if (page?.resourceType === 'product_page') {
    pageUrl = `${backpackHeadUserConfig.url}/products/${page.handle}`;
  } else if (page?.resourceType === 'collection_page') {
    pageUrl = `${backpackHeadUserConfig.url}/collections/${page.handle}`;
  } else if (article) {
    pageUrl = `${backpackHeadUserConfig.url}/articles/${article.handle}`;
    seo = article.seo;
  } else if (blog) {
    pageUrl = `${backpackHeadUserConfig.url}/blogs/${blog.handle}`;
    seo = blog.seo;
  } else {
    pageUrl = backpackHeadUserConfig.url;
  }

  return (
    <>
      <title key="page-title">
        {seo?.title || backpackHeadUserConfig.title}
      </title>
      <meta
        key="page-description"
        name="description"
        content={seo?.description || backpackHeadUserConfig.description}
      />
      <link key="page-canonical-url" rel="canonical" href={pageUrl} />

      {/* IMPORTANT
            DO NOT NEST THESE INTO SUB-COMPONENTS OR REACT FRAGMENTS
            as next/head doesn't parse nested metas
        */}

      {/* OG and TWIITER CARD IMAGE */}

      {seo?.image ? (
        <>
          <meta key="page:fb:image" property="og:image" content={seo.image} />
          <meta
            key="page:fb:image:alt"
            property="og:image:alt"
            content={seo?.title || backpackHeadUserConfig.title}
          />
          <meta
            key="page:twitter:image"
            name="twitter:image"
            content={seo.image}
          />
        </>
      ) : null}

      {/*
          OpenGraph
            @see: more info https://dev.twitter.com/cards/getting-started
            @see: testing https://cards-dev.twitter.com/validator
        */}

      {process.env.FACEBOOK_APP_ID ? (
        <meta
          key="page:fb:app_id"
          property="fb:app_id"
          content={process.env.FACEBOOK_APP_ID}
        />
      ) : null}

      <meta key="page:fb:url" property="og:url" content={pageUrl} />

      <meta key="page:fb:type" property="og:type" content="website" />

      <meta
        key="page:fb:title"
        property="og:title"
        content={seo?.title || backpackHeadUserConfig.title}
      />

      <meta
        key="page:fb:description"
        property="og:description"
        content={seo?.description || backpackHeadUserConfig.description}
      />

      <meta
        property="og:site_name"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />

      <meta
        key="page:fb:locale"
        property="og:locale"
        content={backpackHeadUserConfig.locale}
      />

      <meta
        key="page:fb:author"
        property="og:author"
        content={backpackHeadUserConfig.author}
      />

      {/*
          TwitterCard
            @see: more info https://dev.twitter.com/cards/getting-started
            @see: testing https://cards-dev.twitter.com/validator
        */}

      <meta key="page:twitter:card" name="twitter:card" content="summary" />

      {backpackHeadUserConfig.social.twitter ? (
        <meta
          key="page:twitter:site"
          name="twitter:site"
          content={backpackHeadUserConfig.social.twitter}
        />
      ) : null}

      {backpackHeadUserConfig.social.twitter ? (
        <meta
          key="page:twitter:creator"
          name="twitter:creator"
          content={backpackHeadUserConfig.social.twitter}
        />
      ) : null}

      <meta key="page:twitter:url" name="twitter:url" content={pageUrl} />

      <meta
        key="page:twitter:title"
        name="twitter:title"
        content={seo?.title || backpackHeadUserConfig.title}
      />

      <meta
        key="page:twitter:description"
        name="twitter:description"
        content={seo?.description || backpackHeadUserConfig.description}
      />

      <meta
        key="page:twitter:image:alt"
        name="twitter:image:alt"
        content={seo?.title || backpackHeadUserConfig.title}
      />

      {template ? (
        <>
          <meta
            key="default-all-search-engines-crawling"
            name="robots"
            content="index,follow"
          />

          <meta
            key="default-google-engine-crawling"
            name="googlebot"
            content="index,follow"
          />
        </>
      ) : (
        <>
          <meta
            key="no-index-all-search-engines-crawling"
            name="robots"
            content="noindex,nofollow"
          />

          <meta
            key="no-index-google-engine-crawling"
            name="googlebot"
            content="noindex,nofollow"
          />
        </>
      )}
      {/* pwa (progressive web app) */}
      <meta
        name="theme-color"
        content={process.env.NEXT_PUBLIC_THEME_COLOR || '#FFFFFF'}
      />
      <link rel="manifest" href="/manifest.json" />
      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/icons/icon-384x384.png"
      />
      <meta
        name="application-name"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta
        name="apple-mobile-web-app-title"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta
        name="msapplication-TileColor"
        content={process.env.NEXT_PUBLIC_THEME_COLOR || '#FFFFFF'}
      />
      <meta name="msapplication-tap-highlight" content="no" />
    </>
  );
}

PageHead.displayName = 'PageHead';
PageHead.propTypes = {
  page: PropTypes.object,
};

export default PageHead;
