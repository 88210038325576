import { Container, Paragraph } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './BrandTagline.schema';
import { themed } from './BrandTagline.theme';

export const BrandTagline = withInView(
  themed(({ theme, cms }) => {
    const { section, text } = cms;

    return (
      <Section section={section} cms={cms}>
        <Container
          data-comp={BrandTagline.displayName}
          sx={theme.brandTaglineWrapper}
        >
          <Paragraph sx={theme.text}>{text}</Paragraph>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

BrandTagline.displayName = 'BrandTagline';
BrandTagline.Schema = Schema;
