export default {
  label: 'Footer Settings',
  name: 'footer',
  component: 'group',
  fields: [
    {
      label: 'Email Marketing',
      name: 'emailSignup',
      component: 'group',
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'textarea',
        },
      ],
      defaultValue: {
        heading: 'Sign up for our newsletter',
        subtext: '',
      },
    },
    {
      label: 'Footer Menu',
      name: 'menu',
      component: 'group-list',
      itemProps: {
        label: '{{item.name}}',
      },
      defaultItem: () => ({
        name: 'Shop',
        subMenuItems: [
          {
            cta: {
              text: 'Accessories',
              url: '/collections/shop-all',
            },
          },
          {
            cta: {
              text: 'Bundles',
              url: '/collections/bundles',
            },
          },
          {
            cta: {
              text: 'Shop All',
              url: '/collections/shop-all',
            },
          },
        ],
      }),
      fields: [
        {
          label: 'Menu Item Name',
          name: 'name',
          component: 'text',
        },
        {
          label: 'Sub Menu',
          name: 'subMenuItems',
          component: 'group-list',
          itemProps: {
            label: '{{item.cta.text}}',
          },
          defaultItem: () => ({
            cta: {
              text: 'Shop All',
              url: '/collections/shop-all',
            },
          }),
          fields: [
            {
              label: 'Link',
              name: 'cta',
              component: 'link',
            },
          ],
        },
      ],
      defaultValue: [
        {
          name: 'Help',
          subMenuItems: [
            {
              cta: {
                text: 'Contact Us',
                url: '/pages/contact',
              },
            },
            {
              cta: {
                text: 'Returns & Exchanges',
                url: '/pages/returns-exchanges',
              },
            },
            {
              cta: {
                text: 'Product Care',
                url: '/pages/product-care',
              },
            },
            {
              cta: {
                text: 'FAQ',
                url: '/pages/faq',
              },
            },
            {
              cta: {
                text: 'Reviews',
                url: '/pages/reviews',
              },
            },
            {
              cta: {
                text: 'Accessibility',
                url: '/pages/accessibility',
              },
            },
            {
              cta: {
                text: 'Privacy Policy',
                url: '/pages/privacy-policy',
              },
            },
            {
              cta: {
                text: 'Terms of Service',
                url: '/pages/terms-of-service',
              },
            },
          ],
        },
        {
          name: 'Shop',
          subMenuItems: [
            {
              cta: {
                text: 'Shop All',
                url: '/collections/shop-all',
              },
            },
            {
              cta: {
                text: 'Wishlist',
                url: '/pages/wishlist',
              },
            },
            {
              cta: {
                text: 'Fan Favorites',
                url: '/collections/aloha-fan-top-reviewed-products',
              },
            },
            {
              cta: {
                text: 'New Arrivals',
                url: '/collections/new-arrivals',
              },
            },
            {
              cta: {
                text: 'Best Sellers',
                url: '/collections/best-sellers',
              },
            },
            {
              cta: {
                text: 'Collabs',
                url: '/collections/all-collabs',
              },
            },
            {
              cta: {
                text: 'Earn Rewards',
                url: '/pages/rewards',
              },
            },
            {
              cta: {
                text: 'Give $20 Get $20',
                url: '/collections',
              },
            },
          ],
        },
        {
          name: 'Brand',
          subMenuItems: [
            {
              cta: {
                text: 'About Us',
                url: '/pages/about',
              },
            },
            {
              cta: {
                text: 'Our Blog',
                url: '/blogs/our-blog',
              },
            },
            {
              cta: {
                text: 'Lookbooks',
                url: '/blogs/all-lookbooks',
              },
            },
            {
              cta: {
                text: 'What is SPLASH PROOF?',
                url: '/blogs/what-is-splash-proof',
              },
            },
            {
              cta: {
                text: 'Press',
                url: '/blogs/press',
              },
            },
            {
              cta: {
                text: 'Join Our Team',
                url: '/page/join-our-team',
              },
            },
            {
              cta: {
                text: 'Flagship Store',
                url: '/pages/flagship-store',
              },
            },
          ],
        },
      ],
    },
    {
      label: 'Legal Links',
      name: 'legalLinks',
      component: 'group-list',
      itemProps: {
        label: '{{item.cta.text}}',
      },
      defaultItem: () => ({
        cta: {
          text: 'Privacy Policy',
          url: '/pages/privacy-policy',
        },
      }),
      fields: [
        {
          label: 'Link',
          name: 'cta',
          component: 'link',
        },
      ],
    },
    {
      label: 'Site copyright notice',
      name: 'copyrightNotice',
      component: 'text',
      defaultValue: 'ALOHA Collection • Powered by magic 🌴🌈✨',
    },
  ],
};
