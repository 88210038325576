import { store } from '@backpackjs/storefront';
import equal from 'fast-deep-equal';
import effects from './effects';

// tracks the combined selected options e.g { size: 'sm', color: 'red' }
const selectedOptions = store.recoil.atomFamily({
  key: 'product/selectedOptions',
  default: null,
  effects_UNSTABLE: [
    // effects.logStateChange('selectedOptions'),
  ],
});

const selectedVariant = store.recoil.atomFamily({
  key: 'product/selectedVariant',
  default: null,
  effects_UNSTABLE: [
    // effects.logStateChange('selectedVariant'),
    effects.onSetUpdateVariantUrlParam(),
    effects.onChange({
      key: 'selectedVariant',
      topic: 'VARIANT_UPDATE',
      actions: ['publishGtmEvent', 'publishBusEvent'],
    }),
  ],
});

// merges a newly selected option with the existing selectedOptions
// newOption: should be an object e.g { color: 'blue' }
const setSelectedOptions$ = store.recoil.selectorFamily({
  key: 'product/setSelectedOptions$',
  set:
    (handle) =>
    ({ get, set }, newOptions) => {
      const _product = get(store.state.product);

      // navigating to a page other than PDP
      if (!_product || !newOptions || !handle) {
        return;
      }

      // have option update both
      const _selectedOptions = get(selectedOptions(handle));
      const updatedSelectedOptions = { ..._selectedOptions, ...newOptions };

      // update selected options for this product
      set(selectedOptions(handle), updatedSelectedOptions);

      const _selectedVariant = getVariantFromOptions(
        updatedSelectedOptions,
        _product.variants
      );

      if (_selectedVariant) {
        set(selectedVariant(handle), _selectedVariant);
      }
    },
});

// parse all key::value tags from selectedVariant and its parent product
const selectedTags$ = store.recoil.selector({
  key: 'product/selectedTags',
  get: ({ get }) => {
    const tags = null;

    const _product = get(store.state.product);
    if (!_product) return null;

    const _selectedVariant = get(selectedVariant(_product.handle));

    if (!_selectedVariant) return tags;
    return _selectedVariant?.product?.tags?.reduce(parseTags, {}) || {};
  },
});

// determines wether a set of options e.g selectedOptions + a passedOption
// returns a variant that's available for sale
const optionIsAvailable$ = store.recoil.selectorFamily({
  key: 'product/optionIsAvailable$',
  get:
    (queriedOption) =>
    ({ get }) => {
      const _product = get(store.state.product);
      if (!_product) return false;

      const _selectedOptions = get(selectedOptions(_product.handle));

      const queriedOptions = { ..._selectedOptions, ...queriedOption };
      const _selectedVariant = getVariantFromOptions(
        queriedOptions,
        _product.variants
      );
      return _selectedVariant || null;
    },
});

const isSoldOut$ = store.recoil.selector({
  key: 'product/isSoldOut$',
  get: ({ get }) => {
    const _product = get(store.state.product);
    if (!_product) return false;

    const _selectedVariant = get(selectedVariant(_product.handle));

    return _selectedVariant
      ? _selectedVariant.inventoryQuantity <= 0 &&
          _selectedVariant.inventoryPolicy !== 'CONTINUE'
      : false;
  },
});

const isPreOrder$ = store.recoil.selector({
  key: 'product/isPreOrder$',
  get: ({ get }) => {
    const _product = get(store.state.product);
    if (!_product) return false;

    const _selectedVariant = get(selectedVariant(_product.handle));

    return _selectedVariant
      ? _selectedVariant.inventoryQuantity <= 0 &&
          _selectedVariant.inventoryPolicy === 'CONTINUE'
      : false;
  },
});

// for PrintSlider.jsx
const activePrintSlideIndex = store.recoil.atom({
  key: 'product/activePrintSlideIndex',
  default: 0,
});

/* Utilities */
const getVariantFromOptions = (selectedOptions, variants) => {
  return variants?.find(({ selectedOptionsMap }) => {
    return equal(selectedOptions, selectedOptionsMap);
  });
};

const parseTags = (tags, tag) => {
  try {
    const [key, value] = tag.split('::');
    tags[key] = value || true;
    return tags;
  } catch (e) {
    return tags;
  }
};

export default {
  activePrintSlideIndex,
  selectedOptions,
  selectedVariant,
  setSelectedOptions$,
  optionIsAvailable$,
  selectedTags$,
  // selectors
  isPreOrder$,
  isSoldOut$,
};
