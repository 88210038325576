import { Paragraph, Flex, Spinner } from 'theme-ui';

import store, { useRecoilValue } from '@store';
import { useSearch } from '../../hooks';
import { themed } from './Empty.theme';

export const Empty = themed(({ theme }) => {
  const term = useRecoilValue(store.term);
  const searchLoading = useRecoilValue(store.searchLoading);
  const [{ totalResults }] = useSearch();

  const noResultsFound = !searchLoading && term && !totalResults;
  const loading = searchLoading && !totalResults;

  return noResultsFound || loading ? (
    <Flex data-comp={Empty.displayName} sx={theme.wrapper}>
      {noResultsFound && (
        <Paragraph sx={theme.text}>
          No results found, please try a different search
        </Paragraph>
      )}

      {loading && <Spinner sx={theme.loadingSpinner} />}
    </Flex>
  ) : null;
});

Empty.displayName = 'Empty';
