import { useMemo } from 'react';

export const useVariantPrices = ({ selectedVariant }) => {
  const amount = selectedVariant?.priceV2?.amount;
  const compareAtAmount = selectedVariant?.compareAtPriceV2?.amount;

  const prices = useMemo(() => {
    if (!selectedVariant)
      return { isOnSale: false, price: null, compareAtPrice: null };
    const float = parseFloat(amount);
    const compareAtFloat = compareAtAmount ? parseFloat(compareAtAmount) : null;
    const isOnSale = compareAtFloat ? compareAtFloat > float : false;
    const price = float ? `$${float.toFixed(2).replace(/\.00$/, '')}` : null;
    const compareAtPrice = compareAtFloat
      ? `$${compareAtFloat.toFixed(2).replace(/\.00$/, '')}`
      : null;
    return {
      isOnSale,
      price,
      compareAtPrice,
    };
  }, [amount, compareAtAmount]);

  const { isOnSale, price, compareAtPrice } = prices;

  return {
    isOnSale,
    price,
    compareAtPrice,
  };
};
