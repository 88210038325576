export default {
  label: 'Login Settings',
  name: 'login',
  component: 'group',
  fields: [
    // LoginForm settings
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'Sign In',
    },
    {
      label: 'Subheading',
      name: 'subHeading',
      component: 'textarea',
      defaultValue: '',
    },
    {
      label: 'Button Text',
      name: 'buttonText',
      component: 'text',
      defaultValue: 'Sign In',
    },
    {
      label: 'Forgot Password Text',
      name: 'resetText',
      component: 'text',
      defaultValue: 'Forgot password',
    },
    {
      label: 'Create Account Text',
      name: 'createText',
      component: 'text',
      defaultValue: "Don't have an account?",
    },
    {
      label: 'Create Account Link Text',
      name: 'createLink',
      component: 'text',
      defaultValue: 'create account',
    },
    {
      label: 'Need Help Text',
      name: 'helpText',
      component: 'text',
      defaultValue: 'Need help?',
    },
    {
      label: 'Need Help Link Text',
      name: 'helpLink',
      component: 'link',
      defaultValue: {
        text: 'Contact Us.',
        url: '/pages/contact-us',
      },
    },

    // LoginRegisterLayout settings
    {
      label: 'Image',
      name: 'image',
      component: 'image',
      defaultValue:
        'https://images.unsplash.com/photo-1620492435196-5116e4a319ce?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=924&q=80',
      description: 'Image that will be seen on the right of the login form.',
    },
  ],
};
