import React, { useState, Children, cloneElement } from 'react';
import { Container } from 'theme-ui';
import PropTypes from 'prop-types';

import { Accordion } from './Accordion';
import { themed } from './Accordions.theme';

export const Accordions = themed(
  ({
    theme,
    borderColor = 'text',
    children,
    iconColor = 'text',
    maxHeight = '350px', // upper range of how tall an open accordion panel could get
    minHeight = '60px', // height of accordion panel when closed
    textColor = 'text',
    openIcon,
    collapseIcon,
    iconWidth,
    ...rawProps
  }) => {
    const { inputRef, ...props } = rawProps;
    const [openIndex, setOpenIndex] = useState(null);

    return (
      <Container
        data-comp={Accordions.displayName}
        {...props}
        sx={{
          ...theme.accordions,
          borderColor,
          color: textColor,
          ...props.sx,
        }}
      >
        {Children.map(children, (child, index) => {
          const displayName =
            child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName ||
            child?.type?.displayName;

          if (displayName !== 'Accordion') return null;

          return cloneElement(child, {
            borderColor,
            iconColor,
            index,
            isOpen: index === openIndex,
            key: index,
            maxHeight,
            minHeight,
            setOpenIndex,
            openIcon,
            collapseIcon,
            iconWidth,
            textColor,
            ...child.props,
          });
        })}
      </Container>
    );
  }
);

Accordions.displayName = 'Accordions';
Accordions.Item = Accordion;

// Accordions.propTypes = {
//   items: PropTypes.arrayOf(PropTypes.object),
//   textColor: PropTypes.string,
//   borderColor: PropTypes.string,
//   iconColor: PropTypes.string,
//   minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   children: PropTypes.node,
// };
