import { section } from '@settings/common';

export function Schema({ page }) {
  if (page?.handle !== 'wishlist') return null;

  return {
    label: 'Wishlist',
    key: 'wishlist',
    fields: [section],
  };
}
