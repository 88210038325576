import { useMemo } from 'react';
import { FueledBingScript } from './FueledBingScript';
import { FueledClientScript } from './FueledClientScript';
import { FueledAttentiveScript } from './FueledAttentiveScript';
// import { FueledGtmScript } from './FueledGtmScript';
import { FueledImpactScript } from './FueledImpactScript';
import { FueledKlaviyoScript } from './FueledKlavyioScript';
import { FueledPinterestScript } from './FueledPinterestScript';
import { FueledTikTokScript } from './FueledTikTokScript';
import { FueledGrinScript } from './FueledGrinScript';
import { FueledSnapchatScript } from './FueledSnapchatScript';
import { FueledCustomGa4EventsScript } from './FueledCustomGa4EventsScript';

// const gtmContainerId = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID || '';
const attentiveShopId = process.env.NEXT_PUBLIC_ATTENTIVE_PUBLIC_API_KEY || 'alohacollection';
const bingId = process.env.NEXT_PUBLIC_BING_KEY || '134600667';
const impactPixelId = process.env.NEXT_PUBLIC_IMPACT_KEY || 'A4134936-a1b4-4f9f-a7ee-3f6b51ad2dd81';
const klaviyoId = process.env.NEXT_PUBLIC_KLAVIYO_PUBLIC_API_KEY || 'Q2rcY8';
const pinterestPixelId = process.env.NEXT_PUBLIC_PINTEREST_PUBLIC_API_KEY || '2613941867614';
const snapchatPixelId = process.env.NEXT_PUBLIC_SNAPCHAT_PUBLIC_API_KEY || 'feb1528d-21ce-4639-8c7e-190d98fcc30d';
const tiktokPixelId = process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID || 'CCL05UJC77U01DQBOCVG';
const ga4Id = process.env.NEXT_PUBLIC_GA4_PUBLIC_API_KEY || 'G-E3GBVD7Z6H';

export function Fueled() {
  const scripts = useMemo(
    () => [
      // { withKey: gtmContainerId, Component: FueledGtmScript },
      { withKey: attentiveShopId, Component: FueledAttentiveScript },
      { withKey: bingId, Component: FueledBingScript },
      { withKey: impactPixelId, Component: FueledImpactScript },
      { withKey: klaviyoId, Component: FueledKlaviyoScript },
      { withKey: pinterestPixelId, Component: FueledPinterestScript },
      { withKey: snapchatPixelId, Component: FueledSnapchatScript },
      { withKey: tiktokPixelId, Component: FueledTikTokScript },
      { withKey: ga4Id, Component: FueledCustomGa4EventsScript},
      { Component: FueledGrinScript },
    ],
    []
  );

  return (
    <>
      <FueledClientScript />
      {/* Uncomment the following line to enable the FueledDlDebug component */}
      {/* <FueledDlDebug /> */}
      {scripts.map(({ withKey, Component }, index) => (
        <Component withKey={withKey || ''} key={index} />
      ))}
    </>
  );
}

export default Fueled;
