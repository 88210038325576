import dynamic from 'next/dynamic';

import { useSidebar } from '@hooks';
import { SidebarShell } from '@snippets';

const CartSearchSidebar = dynamic(
  () => import('./CartSearchSidebar').then((mod) => mod.CartSearchSidebar),
  { ssr: false, suspense: false }
);

// Desktop menu drawer — hovered item sub items
export function CartSearchSidebarShell(props) {
  const [{ sidebar }] = useSidebar();

  return (
    <SidebarShell open={sidebar}>
      {sidebar ? <CartSearchSidebar /> : null}
    </SidebarShell>
  );
}

CartSearchSidebarShell.displayName = 'CartSearchSidebarShell';
