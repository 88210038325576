import { store } from '@backpackjs/storefront';
import Cart from './Cart';
import Cms from './Cms';
import Collection from './Collection';
import Global from './Global';
import Junip from './Junip';
import Menu from './Menu';
import Search from './Search';
import SelectedSellingPlan from './SelectedSellingPlan';
import SelectedVariant from './SelectedVariant';
import Wishlist from './Wishlist';

export { useGotoRecoilSnapshot } from 'recoil';

// share storefront recoil within the project
// export const useGotoRecoilSnapshot = store.recoil.useGotoRecoilSnapshot;
export const {
  useRecoilState,
  useRecoilStateLoadable,
  useSetRecoilState,
  useResetRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useRecoilSnapshot,
  useRecoilCallback,
  atom,
  atomFamily,
  selector,
  selectorFamily,
} = store.recoil;

// eslint-disable-next-line import/no-default-export
export default {
  ...Cart,
  ...Cms,
  ...Collection,
  ...Global,
  ...Junip,
  ...Menu,
  ...Search,
  ...SelectedSellingPlan,
  ...SelectedVariant,
  ...Wishlist,
};
