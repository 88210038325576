import { useState, useCallback, useEffect, useRef } from 'react';

import { useSearchspringBeacon, useSelectedVariant } from '@hooks';

export const useProductRecsBeacon = ({
  profileData,
  profileTag,
  visibleProducts,
  newVisibleProduct,
  inView,
}) => {
  const cachedMounted = useRef(false); // prevent double events on mount
  const { sendEventsToBeacon } = useSearchspringBeacon();
  const [selectedVariant] = useSelectedVariant();
  const [mounted, setMounted] = useState(false);
  const [viewed, setViewed] = useState(false);

  const sku = selectedVariant?.sku;

  const sendBeaconEventsOnMount = useCallback(async () => {
    try {
      if (
        cachedMounted.current ||
        mounted ||
        !profileData?.results ||
        !profileTag
      )
        return;

      cachedMounted.current = true;

      await sendEventsToBeacon({
        profileTypes: ['profile.render'],
        productTypes: ['profile.product.render'],
        tag: profileTag,
        placement: 'product-page',
        products: profileData.results,
        seed: [{ sku }],
      });
      setMounted(true);
    } catch (error) {
      console.error(error.message);
    }
  }, [profileData?.results?.length, profileTag, mounted, sku]);

  const sendBeaconEventsOnInView = useCallback(async () => {
    try {
      if (viewed || !inView || !visibleProducts?.length || !profileTag) return;

      await sendEventsToBeacon({
        profileTypes: ['profile.impression'],
        productTypes: ['profile.product.impression'],
        tag: profileTag,
        placement: 'product-page',
        visibleProducts,
        seed: [{ sku }],
      });
      setViewed(true);
    } catch (error) {
      console.error(error.message);
    }
  }, [visibleProducts, profileTag, sku, inView, viewed]);

  const sendBeaconEventsOnClick = useCallback(
    async (event, product) => {
      try {
        if (!profileTag) return;
        const dataComp = [...(event.target.attributes || [])].find(
          (attr) => attr.name === 'data-comp'
        );
        const productClicked =
          product &&
          (event.target.nodeName === 'svg' ||
            event.target.nodeName === 'use' ||
            dataComp?.value === 'AddToCart' ||
            dataComp?.value === 'ProductItemTitle' ||
            dataComp?.value === 'SeeDetails' ||
            dataComp?.value === 'OkendoStarsTotal' ||
            dataComp?.value === 'OkendoStarsTotalText' ||
            dataComp?.value === 'PictureImage');

        await sendEventsToBeacon({
          profileTypes: ['profile.click'],
          productTypes: productClicked ? ['profile.product.click'] : [],
          tag: profileTag,
          placement: 'product-page',
          clickedProduct: productClicked ? product : null,
          seed: [{ sku }],
        });
      } catch (error) {
        console.error(error.message);
      }
    },
    [profileTag, sku]
  );

  const sendBeaconEventsOnProductsInView = useCallback(async () => {
    try {
      if (!mounted || !viewed || !visibleProducts?.length || !profileTag)
        return;
      await sendEventsToBeacon({
        productTypes: ['profile.product.impression'],
        tag: profileTag,
        placement: 'product-page',
        visibleProducts: newVisibleProduct || visibleProducts,
        seed: [{ sku }],
      });
    } catch (error) {
      console.error(error.message);
    }
  }, [visibleProducts, profileTag, mounted, viewed, sku]);

  useEffect(() => {
    sendBeaconEventsOnMount();
  }, [profileData?.results, visibleProducts, profileTag]);

  useEffect(() => {
    sendBeaconEventsOnInView();
  }, [inView, visibleProducts, profileTag]);

  useEffect(() => {
    sendBeaconEventsOnProductsInView();
  }, [visibleProducts?.map((p) => p?.id).join(','), newVisibleProduct]);

  return { sendBeaconEventsOnClick };
};
