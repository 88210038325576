import { section, align, color } from '@settings/common';

const interactiveMediaContent = [
  {
    label: 'Link',
    name: 'link',
    component: 'link',
  },
  {
    label: 'Select Media Type',
    description: 'Allows select between image or video for mobile',
    name: 'mediaType',
    component: 'radio-group',
    direction: 'horizontal',
    options: [
      { label: 'Image', value: 'image' },
      { label: 'Video', value: 'video' },
    ],
    defaultValue: 'image',
  },
  {
    label: 'Image Settings',
    name: 'image',
    description: 'Image, position, placemen',
    component: 'group',
    fields: [
      {
        label: 'Alt',
        name: 'alt',
        component: 'text',
        description: 'Brief description of image',
      },
      {
        name: 'image',
        label: 'Image',
        component: 'image',
      },
      {
        label: 'Image (mobile)',
        name: 'imageMob',
        component: 'image',
      },
    ],
  },
  {
    label: 'Video settings',
    name: 'video',
    description: 'Video, video poster',
    component: 'group',
    fields: [
      {
        name: 'video',
        description:
          'Video url must end with .mp4 unless Vimeo\nClose then reopen modal to see change',
        label: 'Video url',
        component: 'text',
      },
      {
        name: 'videoPoster',
        label: 'Video poster',
        component: 'image',
      },
    ],
    defaultValue: {
      video:
        'https://cdn.shopify.com/videos/c/o/v/8bf3cb57950f47c0979068f4ae8aef90.mp4',
      videoPoster: {
        src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_39.png?v=1655219656',
      },
    },
  },
];

export function Schema() {
  return {
    label: 'Interactive Half Media',
    key: 'interactive-half-media',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'We Have A Bag For That',
      },
      {
        label: 'Default Content',
        name: 'defaultContent',
        component: 'group',
        fields: [...interactiveMediaContent],
        defaultValue: {
          mediaType: 'video',
          video: {
            video:
              'https://cdn.shopify.com/videos/c/o/v/8bf3cb57950f47c0979068f4ae8aef90.mp4',
            videoPoster: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_39.png?v=1655219656',
            },
          },
        },
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'content alignment',
        component: 'group',
        fields: [
          {
            label: 'Content Alignment (desktop)',
            name: 'alignment',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
          {
            label: 'Content Alignment (mobile)',
            name: 'alignmentMob',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
        ],
      },
      {
        label: 'Media Settings',
        name: 'media',
        component: 'group',
        description: 'media placement, aspect ratio',
        fields: [
          {
            label: 'Media Placement (desktop)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'location',
            options: [
              {
                value: 'left',
                label: 'Left',
              },
              {
                value: 'right',
                label: 'Right',
              },
            ],
            defaultValue: 'right',
          },
          {
            label: 'Media Placement (mobile)',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'locationMob',
            options: [
              {
                value: 'top',
                label: 'Top',
              },
              {
                value: 'bottom',
                label: 'Bottom',
              },
            ],
            defaultValue: 'bottom',
          },
          {
            label: 'Aspect Ratio (desktop)',
            component: 'select',
            name: 'ratio',
            options: [
              {
                value: '6/4',
                label: '6:4',
              },
              {
                value: '5/4',
                label: '5:4',
              },
              {
                value: '1/1',
                label: '1:1',
              },
              {
                value: '4/5',
                label: '4:5',
              },
              {
                value: '3/4',
                label: '3:4',
              },
              {
                value: '2/3',
                label: '2:3',
              },
              {
                value: '3/5',
                label: '3:5',
              },
              {
                value: '1/2',
                label: '1:2',
              },
            ],
            defaultValue: '1/1',
          },
          {
            label: 'Aspect Ratio (mobile)',
            component: 'select',
            name: 'ratioMob',
            options: [
              {
                value: '6/4',
                label: '6:4',
              },
              {
                value: '5/4',
                label: '5:4',
              },
              {
                value: '1/1',
                label: '1:1',
              },
              {
                value: '4/5',
                label: '4:5',
              },
              {
                value: '3/4',
                label: '3:4',
              },
              {
                value: '2/3',
                label: '2:3',
              },
              {
                value: '3/5',
                label: '3:5',
              },
              {
                value: '1/2',
                label: '1:2',
              },
            ],
            defaultValue: '1/1',
          },
        ],
      },
      {
        label: 'Tabs',
        name: 'tabs',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          ...interactiveMediaContent,
        ],
        defaultValue: [
          {
            heading: 'Travel',
            mediaType: 'image',
            image: {
              alt: 'Travel',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/interactive-media-1.png?v=1712607591',
              },
              imageMob: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/interactive-media-1.png?v=1712607591',
              },
            },
          },
          {
            heading: 'Beach',
            mediaType: 'image',
            image: {
              alt: 'Beach',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/interactive-media-2.png?v=1712607602',
              },
              imageMob: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/interactive-media-2.png?v=1712607602',
              },
            },
          },
          {
            heading: 'Active',
            mediaType: 'image',
            image: {
              alt: 'Active',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/interactive-media-3.png?v=1712607602',
              },
              imageMob: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/interactive-media-3.png?v=1712607602',
              },
            },
          },
          {
            heading: 'Moms',
            mediaType: 'image',
            image: {
              alt: 'Moms',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/interactive-media-4.png?v=1712607602',
              },
              imageMob: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/interactive-media-4.png?v=1712607602',
              },
            },
          },
          {
            heading: 'Tech',
            mediaType: 'image',
            image: {
              alt: 'Tech',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/interactive-media-5.png?v=1712607602',
              },
              imageMob: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/interactive-media-5.png?v=1712607602',
              },
            },
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'none',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'none',
          },
        },
      },
    ],
  };
}
