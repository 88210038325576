import { Container, Paragraph } from 'theme-ui';

import dynamic from 'next/dynamic';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './FAQ.schema';
import { themed } from './FAQ.theme';

const FAQGrid = dynamic(
  () => import('./FAQGrid').then((module) => module.FAQGrid),
  { ssr: false, suspense: false }
);

export const FAQ = withInView(
  themed(({ theme, cms, inView }) => {
    const { section, content } = cms;
    const titleAlignment = content?.alignment || 'center';
    return (
      <Section section={section} cms={cms}>
        <Container data-comp={FAQ.displayName} sx={theme.wrapper}>
          {content?.title && (
            <Paragraph sx={{ ...theme.title, textAlign: titleAlignment }}>
              {content?.title}
            </Paragraph>
          )}
          {!!content?.faqItems?.length && inView && (
            <FAQGrid items={content?.faqItems} />
          )}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

FAQ.displayName = 'FAQ';
FAQ.Schema = Schema;
