import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.brandTaglineWrapper = {
      px: ['34px'],
      py: [14, 15],
      bg: 'lightestGray',
    };

    this.text = {
      textAlign: 'center',
      variant: 'text.h3',
    };
  })()
);
