import { useCallback } from 'react';
import { useCustomer } from '@backpackjs/storefront';
import _xorBy from 'lodash.xorby';
import { Button, Paragraph } from 'theme-ui';
import { useWishlist } from '@hooks';
import { Svg } from '@snippets';
import store, { useRecoilState, useRecoilValue } from '@store';
import { themed } from './Favorite.theme';

export const Favorite = themed(
  ({ theme, selectedVariant, withText = false }) => {
    const customer = useCustomer();
    const { updateWishlist, createWishlistList } = useWishlist();
    const [wishlist, setWishlist] = useRecoilState(store.wishlist);
    const [wishlistId, setWishlistId] = useRecoilState(store.wishlistId);
    const [anonymousWishlist, setAnonymousWishlist] = useRecoilState(
      store.anonymousWishlist
    );
    const lid = useRecoilValue(store.wishlistId);
    const wishlistEmail = useRecoilValue(store.wishlistEmail);
    const isWishlisted = useRecoilValue(
      store.variantIsWishlisted(selectedVariant)
    );

    const toggleWishlist = useCallback(
      async (event) => {
        event.preventDefault();

        const item = {
          epi: parseInt(selectedVariant.legacyResourceId, 10),
          empi: parseInt(selectedVariant.product.legacyResourceId, 10),
          du: `https://${process.env.NEXT_PUBLIC_SEO_SHOPIFY_DOMAIN}/products/${selectedVariant.product.handle}`,
        };

        const updatedWishlist = _xorBy(wishlist, [item], 'epi');
        const updatedAnonymousWishlist = _xorBy(
          customer?.email ? wishlist : anonymousWishlist,
          [item],
          'epi'
        );
        setWishlist(updatedWishlist);

        if (!customer?.email) {
          setAnonymousWishlist(updatedAnonymousWishlist);
        }

        // updates wishlist for guest and logged-in customer
        if(!lid){
          let listData = await createWishlistList({
            params:{
              lname: 'wishlist',
              email: wishlistEmail
            }
          }); 
          if(listData && listData.lid){
            lid = listData.lid;
            setWishlistId(listData.lid);
          }
        }
        await updateWishlist({
          params: {
            lid,
            email: wishlistEmail,
            add: isWishlisted ? [] : [item],
            remove: isWishlisted ? [item] : [],
          },
        });
      },
      [selectedVariant, wishlist, isWishlisted, customer]
    );

    return (
      <Button
        data-comp={Favorite.displayName}
        sx={theme.button}
        onClick={toggleWishlist}
        aria-label={isWishlisted ? 'Remove from wishlist' : 'Add to wishlist'}
        type="button"
      >
        <Svg
          viewBox="0 0 24 24"
          alt="Favorite"
          src={
            isWishlisted
              ? '/svgs/noprecache/svg/like-filled.svg#like'
              : '/svgs/noprecache/svg/like.svg#like'
          }
          sx={theme.icon}
        />
        {withText ? (
          <Paragraph
            variant="text.link.3"
            sx={{
              ml: 2,
              pb: 0,
              textTransform: 'uppercase',
            }}
          >
            {isWishlisted ? 'Added!' : 'Add to Wishlist'}
          </Paragraph>
        ) : null}
      </Button>
    );
  }
);

Favorite.displayName = 'Favorite';
