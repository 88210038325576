import { useEffect } from 'react';
import { Box, Heading, Container } from 'theme-ui';

import { Section, Markdown } from '@snippets';

import { Schema } from './DashHudson.schema';
import { themed } from './DashHudson.theme';

export const DashHudson = themed(({ cms, theme }) => {
  const { heading, body, widget, section } = cms;
  const {
    galleryId = '3322522',
    rowSize = 4,
    rowSizeMobile = 2,
    rowLimit = 3,
    gapSize = 1,
    gapSizeMobile = 1,
  } = { ...widget };

  useEffect(() => {
    if (document.getElementById('dashhudson-board-embed')?.length) return;

    const script = document.createElement('script');

    script.src = 'https://cdn.dashhudson.com/web/js/board-embed.js';
    script.id = 'dashhudson-board-embed';
    script.async = true;
    script.type = 'text/javascript';
    script.setAttribute('data-name', 'dhboard');
    script.setAttribute('data-gallery-id', galleryId);
    script.setAttribute('data-row-size', rowSize);
    script.setAttribute('data-mobile-row-size', rowSizeMobile);
    script.setAttribute('data-row-limit', rowLimit);
    script.setAttribute('data-gap-size', gapSize);
    script.setAttribute('data-mobile-gap-size', gapSizeMobile);
    script.setAttribute('data-call-to-action', 'shop_now');

    document
      .querySelector(
        `[data-dashhudson-cms-id="${cms?.clientId || cms?.cmsId}"]`
      )
      ?.append(script);
  }, [cms, widget]);

  return (
    <Section section={section} cms={cms}>
      <Box sx={theme.header}>
        {heading && (
          <Heading
            as="h1"
            variant="text.h3"
            sx={{ ...theme.heading, color: section?.color || 'black' }}
          >
            {heading}
          </Heading>
        )}

        {body && (
          <Markdown
            text={body}
            sx={{ ...theme.body, color: section?.color || 'black' }}
          />
        )}
      </Box>

      <Container
        data-comp={DashHudson.displayName}
        data-dashhudson-cms-id={cms?.clientId || cms?.cmsId}
      />
    </Section>
  );
});

DashHudson.displayName = 'DashHudson';
DashHudson.Schema = Schema;
