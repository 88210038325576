import { AccountLogin } from './AccountLogin';
import { Brands } from './Brands';
import { BrandTagline } from './BrandTagline';
import { DashHudson } from './DashHudson';
import { FAQ } from './FAQ';
import { FeaturedCollections } from './FeaturedCollections';
import { FormBuilder } from './FormBuilder';
// import { FunctionalSchemaDemo } from './FunctionalSchemaDemo';
import { Greenhouse } from './Greenhouse';
import { HalfCollection } from './HalfCollection';
import { HalfMedia } from './HalfMedia';
import { Hero } from './Hero';
import { HeroImageWithIcon } from './HeroImageWithIcon';
import { Html } from './Html';
import { IconRow } from './IconRow';
import { Image } from './Image';
import { ImageSlider } from './ImageSlider';
import { InstaSlider } from './InstaSlider';
import { InstaProductSlider } from './InstaProductSlider';
import { InteractiveHalfMedia } from './InteractiveHalfMedia';
import { Markdown } from './Markdown';
import { Navigation } from './Navigation';
// import { MediaGrid } from './MediaGrid';
import { Parallax } from './Parallax';
import { ProductBadge } from './ProductBadge';
// import { ProductMeta } from './ProductMeta';
import { ProductRecs } from './ProductRecs';
import { ProductsSlider } from './ProductsSlider';
import { ReferralsBP } from './ReferralsBP';
import { RewardsEarningTiles } from './RewardsEarningTiles';
import { ShortstackEmbed } from './ShortstackEmbed';
import { StoreLocatorEmbed } from './StoreLocatorEmbed';
import { SuperCollection } from './SuperCollection';
import { SuperSlider } from './SuperSlider';
import { TabbedSlider } from './TabbedSlider';
import { Testimonials } from './Testimonials';
import { Text } from './Text';
import { ThreeTile } from './ThreeTile';
import { TileSlider } from './TileSlider';
import { TwoTile } from './TwoTile';
import { VideoBlock } from './VideoBlock';
import { VIPTiles } from './VIPTiles';
import { VisualNavigation } from './VisualNavigation';
import { Wishlist } from './Wishlist';
import {
  AccountWidget,
  AdvocateLP,
  FriendLP,
  FriendBuyCustomWidget,
} from './FriendBuy';
import { Reviews } from './Reviews';

import { KlaviyoSubscribe } from './KlaviyoSubscribe';

export default [
  AccountLogin,
  Brands,
  BrandTagline,
  DashHudson,
  FAQ,
  FeaturedCollections,
  FormBuilder,
  // FunctionalSchemaDemo,
  // ProductMeta,
  Greenhouse,
  HalfCollection,
  HalfMedia,
  Hero,
  HeroImageWithIcon,
  Html,
  IconRow,
  Image,
  ImageSlider,
  InstaSlider,
  InstaProductSlider,
  InteractiveHalfMedia,
  KlaviyoSubscribe,
  Markdown,
  Navigation,
  Parallax,
  ProductBadge,
  ProductRecs,
  ProductsSlider,
  ReferralsBP,
  RewardsEarningTiles,
  ShortstackEmbed,
  StoreLocatorEmbed,
  SuperCollection,
  SuperSlider,
  TabbedSlider,
  Testimonials,
  Text,
  ThreeTile,
  TileSlider,
  TwoTile,
  VideoBlock,
  VIPTiles,
  VisualNavigation,
  Wishlist,
  Reviews,
  // MediaGrid,
  AccountWidget,
  AdvocateLP,
  FriendLP,
  FriendBuyCustomWidget,
];
