const accordionItemDefaultImage =
  'https://cdn.shopify.com/s/files/1/1119/6322/files/sm_coco_palms_black_379abd7d-22d1-4d55-a7dd-3e5ea352f16c.jpg?v=1656458349';
const expandedItemDefaultImage =
  'https://cdn.shopify.com/s/files/1/1119/6322/files/image_7_ab44ee2e-190d-4525-b8d4-39f1f79dead9.png?v=1653036799';

export const defaultListItems = (image = accordionItemDefaultImage) => [
  {
    link: {
      url: '/collections/all-pouches',
      text: 'Pouches',
    },
    image: {
      src: image,
    },
  },
  {
    link: {
      url: '/collections/totes',
      text: 'Totes',
    },
    image: {
      src: image,
    },
  },
  {
    link: {
      url: '/collections/hip-packs',
      text: 'Hip Packs',
    },
    image: {
      src: image,
    },
  },
  {
    link: {
      url: '/collections/all-pouches',
      text: 'Pouches',
    },
    image: {
      src: image,
    },
  },
  {
    link: {
      url: '/collections/dopp-kits',
      text: 'Dopp Kits',
    },
    image: {
      src: image,
    },
  },
  {
    link: {
      url: '/collections/shop-all',
      text: 'Shop All',
    },
    image: {
      src: image,
    },
  },
];

export const defaultAccordionItems = defaultListItems(
  accordionItemDefaultImage
);
export const defaultExpandedItems = defaultListItems(expandedItemDefaultImage);

export const subMenuTypes = {
  options: [
    {
      value: 'link',
      label: 'Link',
    },
    {
      value: 'accordion',
      label: 'Accordion',
    },
    {
      value: 'expanded',
      label: 'Expanded',
    },
    {
      value: 'featured-image',
      label: 'Featured Image',
    },
  ],
  value: {
    link: 'link',
    accordion: 'accordion',
    expanded: 'expanded',
  },
};

export const generateSubMenuList = (type = 'subMenu') => ({
  label: `${type === 'subMenu' ? 'Sub menu' : type} items`,
  name: `${type}Items`,
  component: 'group-list',
  itemProps: {
    label: '{{item.link.text}}',
  },
  defaultItem: (item) => ({
    link: {
      url: '/collections/all-pouches',
      text: 'Pouches',
    },
    image: {
      src: '',
    },
  }),
  fields: [
    {
      label: 'Accordion item link',
      name: 'link',
      component: 'link',
    },
    {
      label: 'Accordion item link - Color',
      name: 'linkColor',
      component: 'color',
    },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
      parse: (image) => image,
    },
    {
      label: 'Image (Mobile)',
      name: 'imageMobile',
      component: 'image',
      parse: (image) => image,
    },
  ],
  defaultValue: [],
});
