import { color } from './color';
import { layout } from './layout';
import { margin, padding } from './space';

const fields = [
  {
    name: 'loggedInUsersOnly',
    label: 'Exclusive to Logged In User Only',
    component: 'toggle',
    description:
      'Enabling this will disable this section unless user is logged in',
    toggleLabels: {
      true: 'On',
      false: 'Off',
    },
    defaultValue: false,
  },
  {
    component: 'radio-group',
    label: 'Collection Content Placement',
    name: 'collectionContentPlacement',
    description: 'For Collection Template usage only',
    direction: 'horizontal',
    variant: 'radio',
    options: [
      {
        label: 'Above Main Content',
        value: 'above',
      },
      {
        label: 'Below Main Content',
        value: 'below',
      },
    ],
    defaultValue: 'above',
  },
  {
    name: 'bgColor',
    label: 'Background Color',
    component: 'color',
    colors: color.list,
    defaultValue: color.value.background,
    widget: 'block',
  },
  {
    name: 'color',
    label: 'Text Color',
    component: 'color',
    colors: color.list,
    defaultValue: color.value.text,
    widget: 'block',
  },
];

const fieldsWithoutColor = [
  {
    name: 'loggedInUsersOnly',
    label: 'Exclusive to Logged In User Only',
    component: 'toggle',
    description:
      'Enabling this will disable this section unless user is logged in',
    toggleLabels: {
      true: 'On',
      false: 'Off',
    },
    defaultValue: false,
  },
  {
    component: 'radio-group',
    label: 'Collection Content Placement',
    name: 'collectionContentPlacement',
    description: 'For Collection Template usage only',
    direction: 'horizontal',
    variant: 'radio',
    options: [
      {
        label: 'Above Main Content',
        value: 'above',
      },
      {
        label: 'Below Main Content',
        value: 'below',
      },
    ],
    defaultValue: 'above',
  },
  {
    name: 'bgColor',
    label: 'Background Color',
    component: 'color',
    colors: color.list,
    defaultValue: color.value.background,
    widget: 'block',
  },
];

const mobileFields = [
  {
    name: 'hide',
    label: 'Hide (mobile)',
    component: 'toggle',
    toggleLabels: {
      true: 'On',
      false: 'Off',
    },
    defaultValue: false,
  },
  {
    label: 'Container Width (mobile)',
    component: 'select',
    name: 'container',
    options: layout.options,
  },
  {
    name: 'mt',
    label: 'Margin Top (mobile)',
    component: 'select',
    options: margin.options,
  },
  {
    name: 'py',
    label: 'Vertical Padding (mobile)',
    component: 'select',
    options: padding.options,
  },
];

const mobileDefaultValue = {
  container: layout.value.container,
  mt: 'none',
  py: 'none',
};

const desktopFields = [
  {
    name: 'hide',
    label: 'Hide (desktop)',
    component: 'toggle',
    toggleLabels: {
      true: 'On',
      false: 'Off',
    },
    defaultValue: false,
  },
  {
    label: 'Container Width (desktop)',
    component: 'select',
    name: 'container',
    options: layout.options,
  },
  {
    name: 'mt',
    label: 'Margin Top (desktop)',
    component: 'select',
    options: margin.options,
  },
  {
    name: 'py',
    label: 'Vertical Padding (desktop)',
    component: 'select',
    options: padding.options,
  },
];

const desktopDefaultValue = {
  container: layout.value.container,
  mt: 'none',
  py: 'none',
};

const mobile = {
  label: 'Mobile Settings',
  name: 'mobile',
  component: 'group',
  fields: mobileFields,
  defaultValue: mobileDefaultValue,
};

const desktop = {
  label: 'Desktop Settings',
  name: 'desktop',
  component: 'group',
  fields: desktopFields,
  defaultValue: desktopDefaultValue,
};

const mobileWithHeight = {
  label: 'Mobile Settings',
  name: 'mobile',
  component: 'group',
  fields: [
    ...mobileFields,
    {
      name: 'minHeight',
      component: 'number',
      label: 'Min Section Pixel Height (mobile)',
    },
    {
      name: 'maxHeight',
      component: 'number',
      label: 'Max Section Pixel Height (mobile)',
    },
  ],
  defaultValue: {
    ...mobileDefaultValue,
    maxHeight: 400,
    minHeight: 300,
  },
};

const desktopWithHeight = {
  label: 'Desktop Settings',
  name: 'desktop',
  component: 'group',
  fields: [
    ...desktopFields,
    {
      name: 'minHeight',
      component: 'number',
      label: 'Min Section Pixel Height (desktop)',
    },
    {
      name: 'maxHeight',
      component: 'number',
      label: 'Max Section Pixel Height (desktop)',
    },
  ],
  defaultValue: {
    ...desktopDefaultValue,
    maxHeight: 680,
    minHeight: 300,
  },
};

const mobileWithHeightAndAspectRatio = {
  label: 'Mobile Settings',
  name: 'mobile',
  component: 'group',
  fields: [
    ...mobileFields,
    {
      label: 'Aspect Ratio (mobile)',
      name: 'aspectRatio',
      component: 'select',
      description:
        'Sets a fixed width by height aspect ratio. Required if min and max heights are not set',
      options: [
        { label: 'None', value: 'none' },
        { label: '14:1', value: '7%' },
        { label: '12:1', value: '8.3%' },
        { label: '10:1', value: '10%' },
        { label: '8:1', value: '12.5%' },
        { label: '7:1', value: '14%' },
        { label: '6:1', value: '16.67%' },
        { label: '5:1', value: '20%' },
        { label: '4:1', value: '25%' },
        { label: '3:1', value: '33.33%' },
        { label: '5:2', value: '40%' },
        { label: '2:1', value: '50%' },
        { label: '16:9', value: '56.25%' },
        { label: '3:2', value: '66.67%' },
        { label: '4:3', value: '75%' },
        { label: '5:4', value: '80%' },
        { label: '1:1', value: '100%' },
        { label: '7:8', value: '114%' },
        { label: '4:5', value: '125%' },
        { label: '3:4', value: '133.33%' },
        { label: '2:3', value: '150%' },
        { label: '9:16', value: '177.78%' },
      ],
    },
    {
      name: 'minHeight',
      component: 'number',
      label: 'Min Section Pixel Height (mobile)',
      description:
        'Sets a minimum height section must be. Optional if aspect ratio is set',
    },
    {
      name: 'maxHeight',
      component: 'number',
      label: 'Max Section Pixel Height (mobile)',
      description:
        'Sets maximum height section can be. Optional if aspect ratio is set',
    },
  ],
  defaultValue: {
    ...mobileDefaultValue,
    aspectRatio: '150%',
    maxHeight: 0,
    minHeight: 0,
  },
};

const desktopWithHeightAndAspectRatio = {
  label: 'Desktop Settings',
  name: 'desktop',
  component: 'group',
  fields: [
    ...desktopFields,
    {
      label: 'Aspect Ratio (desktop)',
      name: 'aspectRatio',
      component: 'select',
      description:
        'Sets a fixed width by height aspect ratio. Required if min and max heights are not set',
      options: [
        { label: 'None', value: 'none' },
        { label: '14:1', value: '7%' },
        { label: '12:1', value: '8.3%' },
        { label: '10:1', value: '10%' },
        { label: '8:1', value: '12.5%' },
        { label: '7:1', value: '14%' },
        { label: '6:1', value: '16.67%' },
        { label: '5:1', value: '20%' },
        { label: '4:1', value: '25%' },
        { label: '3:1', value: '33.33%' },
        { label: '5:2', value: '40%' },
        { label: '2:1', value: '50%' },
        { label: '16:9', value: '56.25%' },
        { label: '3:2', value: '66.67%' },
        { label: '4:3', value: '75%' },
        { label: '5:4', value: '80%' },
        { label: '1:1', value: '100%' },
        { label: '7:8', value: '114%' },
        { label: '4:5', value: '125%' },
        { label: '3:4', value: '133.33%' },
        { label: '2:3', value: '150%' },
        { label: '9:16', value: '177.78%' },
      ],
    },
    {
      name: 'minHeight',
      component: 'number',
      label: 'Min Section Pixel Height (desktop)',
      description:
        'Sets a minimum height section must be. Optional if aspect ratio is set',
    },
    {
      name: 'maxHeight',
      component: 'number',
      label: 'Max Section Pixel Height (desktop)',
      description:
        'Sets maximum height section can be. Optional if aspect ratio is set',
    },
  ],
  defaultValue: {
    ...desktopDefaultValue,
    aspectRatio: '50%',
    maxHeight: 0,
    minHeight: 0,
  },
};

export const section = {
  label: 'Section Settings',
  name: 'section',
  description:
    'Background color, text color, container width, margin top, vertical padding',
  component: 'group',
  fields: [...fields, desktop, mobile],
};

export const sectionWithHeight = {
  label: 'Section Settings',
  name: 'section',
  description:
    'Background color, text color, container width, margin top, vertical padding, section heights',
  component: 'group',
  fields: [...fields, desktopWithHeight, mobileWithHeight],
};

export const sectionWithHeightNoColor = {
  label: 'Section Settings',
  name: 'section',
  description:
    'Background color, text color, container width, margin top, vertical padding, section heights',
  component: 'group',
  fields: [...fieldsWithoutColor, desktopWithHeight, mobileWithHeight],
};

export const sectionWithHeightAndAspectRatio = {
  label: 'Section Settings',
  name: 'section',
  description:
    'Background color, text color, container width, margin top, vertical padding, section aspect ratio, min and max heights',
  component: 'group',
  fields: [
    ...fields,
    desktopWithHeightAndAspectRatio,
    mobileWithHeightAndAspectRatio,
  ],
};

export const sectionWithHeightAndAspectRatioNoColor = {
  label: 'Section Settings',
  name: 'section',
  description:
    'Background color, text color, container width, margin top, vertical padding, section aspect ratio, min and max heights',
  component: 'group',
  fields: [
    ...fieldsWithoutColor,
    desktopWithHeightAndAspectRatio,
    mobileWithHeightAndAspectRatio,
  ],
};
