import { withInView } from '@utils';
import { Section, Link, Picture } from '@snippets';
import { useMounted } from '@hooks';
import { Box, Container, Flex, Heading } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Schema } from './ImageSlider.schema';
import { themed } from './ImageSlider.theme';

export const ImageSlider = withInView(
  themed(({ theme, cms }) => {
    const { content, slides, section } = cms;
    const { heading, subheading, cta, ctaStyle } = { ...content };
    const { mounted } = useMounted();

    return mounted ? (
      <Section section={section} cms={cms}>
        <Container sx={theme.wrap}>
          <Heading sx={theme.heading}>{heading}</Heading>
          <Box sx={theme.subheading}>{subheading}</Box>
          <Box
            sx={{
              ...theme.swiper,
              '.swiper-button-prev': {
                ...theme.swiperPrev,
              },
              '.swiper-button-next': {
                ...theme.swiperNext,
              },
            }}
          >
            <Swiper
              fadeEffect={{ crossFade: true }}
              loop
              navigation
              slidesPerView={2.6}
              spaceBetween={20}
              slidesOffsetBefore={40}
              breakpoints={{
                480: {
                  slidesOffsetBefore: 60,
                },
                600: {
                  slidesOffsetBefore: 80,
                },
                768: {
                  spaceBetween: 20,
                  slidesOffsetBefore: 40,
                  slidesPerView: 3.3,
                },
                900: {
                  spaceBetween: 20,
                  slidesOffsetBefore: 40,
                  slidesPerView: 4.3,
                },
                1280: {
                  spaceBetween: 20,
                  slidesOffsetBefore: 60,
                  slidesPerView: 4.3,
                },
              }}
            >
              {slides?.map(({ cta, image, name }, index) => (
                <SwiperSlide key={index}>
                  <Link href={cta?.url}>
                    <Picture
                      images={[
                        {
                          src: image?.src,
                          ratio: 1,
                        },
                      ]}
                      sx={theme.image}
                    />

                    <Box sx={theme.name}>{name}</Box>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          <Flex sx={theme.bottom}>
            <Link href={cta?.url} variant={`buttons.${ctaStyle || 'primary'}`}>
              {cta?.text}
            </Link>
          </Flex>
        </Container>
      </Section>
    ) : null;
  })
);

ImageSlider.displayName = 'ImageSlider';
ImageSlider.Schema = Schema;
