export const swatches = {
  label: 'Swatches',
  name: 'swatches',
  component: 'group-list',
  itemProps: {
    label: '{{item.name}}',
  },
  defaultItem: {
    name: 'black',
    color: '#000000',
  },
  fields: [
    {
      label: 'name',
      name: 'name',
      component: 'text',
    },
    {
      label: 'Color',
      name: 'color',
      component: 'color',
    },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
      parse: (img) => img,
    },
  ],
};
