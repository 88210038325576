import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'grid',
      gridTemplateColumns: ['1fr', '1fr 1fr'],
    };

    this.block = ({ gridArea, ratioDt, ratioMob }) => ({
      gridArea,
      width: '100%',
      aspectRatio: [ratioMob || '1/1', ratioDt || '1/1'],
      height: '100%',
    });

    this.content = {
      gap: [6, 11],
      width: '100%',
      px: [10, '10%', '15%'],
      py: ['10%'],
    };

    this.heading = {
      fontSize: [3, 6],
      textTransform: 'uppercase',
      letterSpacing: ['0.42px', '0.54px'],
      lineHeight: 1,
    };

    this.headingList = {
      p: 0,
      gap: [6, 11],
    };

    this.tabHeading = {
      display: 'inline',
      position: 'relative',
      ':after': {
        content: '""',
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        maxWidth: 0,
        height: '1px',
        bg: 'currentColor',
        transition: 'max-width 0.2s ease-in-out',
      },
      ':hover:after': {
        maxWidth: '100%',
      },
    };
  })()
);
