import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.heading = {
      fontSize: [5, '28px'],
      fontWeight: '400',
      lineHeight: [16, 40],
      textAlign: 'center',
      mb: 15,
    };

    this.form = {
      border: '1px solid black',
      maxWidth: '500px',
      m: '0 auto',
    };

    this.body = {
      fontSize: [5, '28px'],
      fontWeight: '400',
      lineHeight: [16, 40],
      textAlign: 'center',
      mt: 15,
      '*': {
        fontWeight: '400',
      },
    };
  })()
);
