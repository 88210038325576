import { section, color, align, button } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Super Collection',
    key: 'super-collection',
    fields: [
      {
        name: 'collection',
        label: 'Collection Settings',
        component: 'group',
        fields: [
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'displayType',
            label: 'Display Type',
            options: [
              {
                value: 'slider',
                label: 'Slider',
              },
              {
                value: 'grid',
                label: 'Grid',
              },
            ],
            defaultValue: 'slider',
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'gridDisplayDt',
            label: 'Grid Display - Desktop',
            options: [
              {
                value: '4x4',
                label: '4x4',
              },
              {
                value: '2x2',
                label: '2x2',
              },
            ],
            defaultValue: '4x4',
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'gridDisplay',
            label: 'Grid Display - Mobile',
            options: [
              {
                value: '2x2',
                label: '2x2',
              },
              {
                value: '1x1',
                label: '1x1',
              },
            ],
            defaultValue: '2x2',
          },
          {
            name: 'name',
            label: 'Super Collection Name',
            component: 'text',
          },
          {
            name: 'products',
            label: 'Products List',
            component: 'group-list',
            itemProps: {
              label: '{{item.product.handle}}',
            },
            defaultItem: {
              product: { handle: 'mini-pua-pink' },
            },
            fields: [
              {
                name: 'productBadge',
                component: 'text',
                label: 'product badge',
              },
              {
                name: 'product',
                component: 'productSearch',
                label: 'Product',
              },
            ],
            defaultValue: [
              {
                product: { handle: 'mini-pua-pink' },
              },
              {
                product: { handle: 'small-waipio-pouch-black' },
              },
              {
                product: { handle: 'small-rio-pouch' },
              },
              {
                product: { handle: 'small-protea' },
              },
            ],
          },
          {
            name: 'cta',
            component: 'link',
            label: 'Group Button',
          },
          {
            label: 'Hide Lines',
            name: 'hideLines',
            component: 'toggle',
            toggleLabels: {
              true: 'Hide',
              false: 'Show',
            },
            defaultValue: false,
          },
        ],
        defaultValue: {
          name: 'Super collection',
          products: [
            {
              product: { handle: 'mini-pua-pink' },
            },
            {
              product: { handle: 'small-waipio-pouch-black' },
            },
            {
              product: { handle: 'small-rio-pouch' },
            },
            {
              product: { handle: 'small-protea' },
            },
          ],
          cta: {
            text: 'See all prints',
            url: '/collections/shop-all',
          },
        },
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, text align, button style, button alignment',
        component: 'group',
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            name: 'subheading',
            label: 'SubHeading',
            component: 'text',
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            description: "Doesn't apply on desktop when group list is inline",
            label: 'Heading Alignment',
            options: align.options,
          },
          {
            name: 'ctaStyle',
            label: 'Group Button Style',
            component: 'select',
            options: button.options,
          },
          {
            name: 'ctaAlign',
            label: 'Group Button Alignment (Desktop)',
            component: 'select',
            options: [
              { label: 'Top', value: 'top' },
              { label: 'Bottom', value: 'bottom' },
            ],
            defaultValue: 'bottom',
          },
          {
            name: 'ctaAlignMobile',
            label: 'Group Button Alignment (Mobile)',
            component: 'select',
            options: align.options,
          },
        ],
        defaultValue: {
          heading: 'Mini Pouches',
          subheading: 'Beach to Bar, Pool to Plane, Boat to Dock',
          textAlign: align.value.left,
          ctaStyle: 'underlineLinkWithArrow',
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
