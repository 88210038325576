import { useState, useCallback } from 'react';
import { Box, Spinner } from 'theme-ui';
import store, { useSetRecoilState } from '@store';

import { Favorite, Link, Picture } from '@snippets';
import { useBreakpoint, useProductItemImages } from '@hooks';

import { themed } from './Image.theme';
import { Badge } from './Badge';
import { QuickAdd } from '../QuickAdd';
import { QuickAddOptions } from '../QuickAddOptions';
import { QuickAddMobile } from '../QuickAddMobile';

export const Image = themed(
  ({
    theme,
    addToCart,
    fullProduct,
    selectedVariant,
    displayQuickAdd = false,
    activeVariant,
    handleClick,
    isGiftCard,
    hasMultipleVariants,
    isQuickAddActive,
    isHovered,
    isSoldOut,
    invertImages,
    isClicked,
    setIsClicked,
  }) => {
    const { primary, hover } = useProductItemImages({ product: fullProduct });
    const [imageIsHovered, setImageIsHovered] = useState(false);
    const setSelectedProductItem = useSetRecoilState(store.selectedProductItem);

    const { s, m } = useBreakpoint();
    const isMobile = s || m;

    const hideQuickAddMobile = isSoldOut && !hasMultipleVariants;

    const handleClickQA = useCallback(
      (handle) => {
        setSelectedProductItem(handle);
        setIsClicked(!isClicked);
      },
      [isQuickAddActive]
    );

    return (
      <Box
        data-comp={Image.displayName}
        sx={{
          ...theme.image,
        }}
        onMouseEnter={() => {
          setImageIsHovered(true);
        }}
        onMouseLeave={() => {
          setImageIsHovered(false);
        }}
      >
        <Box sx={theme.favorite}>
          <Favorite selectedVariant={activeVariant || selectedVariant} />
        </Box>

        <Link
          href={`/products/${fullProduct?.handle}`}
          tabIndex="-1"
          onClick={handleClick}
        >
          <Picture
            alt={fullProduct?.title}
            foreignHover={imageIsHovered}
            hasForeignHover
            images={[
              {
                src: invertImages
                  ? hover?.originalSrc || primary?.originalSrc
                  : primary?.originalSrc,
                width: 350,
                ratio: 1,
              },
            ]}
            hoverImages={[
              {
                src: !isMobile
                  ? invertImages
                    ? primary?.originalSrc
                    : hover?.originalSrc
                  : null,
                width: 350,
                ratio: 1,
              },
            ]}
            sx={{
              borderRadius: '6px',
            }}
          >
            {!primary?.originalSrc && <Spinner sx={theme.spinner} />}
          </Picture>

          <Badge tags={fullProduct?.tags} />
        </Link>

        {displayQuickAdd && !isGiftCard && !hasMultipleVariants && (
          <QuickAdd
            fullProduct={fullProduct}
            selectedVariant={selectedVariant}
            isQuickAddActive={isQuickAddActive}
            isHovered={isHovered}
            isSoldOut={isSoldOut}
          />
        )}

        {displayQuickAdd && !isGiftCard && hasMultipleVariants && (
          <QuickAddOptions
            fullProduct={fullProduct}
            selectedVariant={selectedVariant}
            isQuickAddActive={isQuickAddActive}
            isHovered={isHovered}
          />
        )}

        {displayQuickAdd && !isGiftCard && !hideQuickAddMobile && (
          <QuickAddMobile
            addToCart={addToCart}
            handleClick={handleClickQA}
            hasMultipleVariants={hasMultipleVariants}
            isQuickAddActive={isQuickAddActive}
            selectedVariant={selectedVariant}
          />
        )}
      </Box>
    );
  }
);

Image.displayName = 'Image';
