import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.image = {
      mb: [2, 3],
      bg: '#FAFAFA',
      border: (t) => `1px solid ${t.colors.lightGray}`,
      borderRadius: '6px',
      overflow: 'hidden',
      position: 'relative',
    };

    this.spinner = {
      width: '30px',
      color: 'gray',
      m: 'auto',
    };

    this.favorite = {
      position: 'absolute',
      top: ['3px', '10px'],
      right: ['5px', '13px'],
      zIndex: 3,
      button: {
        display: 'block',
        width: 24,
        height: 24,
      },
    };
  })()
);
