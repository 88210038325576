import { Container, Heading, Box, Flex } from 'theme-ui';
import Script from 'next/script';

import { Section, JunipProductReviewsWidget } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './Reviews.schema';

export const Reviews = withInView(
  ({ cms }) => {
    const { heading, section, showReviews } = cms;
    return (
      <Section section={section} cms={cms}>
        <Script
          type="text/javascript"
          id="junip-script"
          strategy="afterInteractive"
          src="https://scripts.juniphq.com/v1/junip_shopify.js"
        />

        <span
          className="junip-store-key"
          data-store-key={process.env.NEXT_PUBLIC_JUNIP_STORE_KEY}
        />

        {showReviews && (
          <Container data-comp={Reviews.displayName}>
            <Flex
              data-comp="JunipReviews"
              variant="flex.section"
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Heading
                variant="text.h4"
                sx={{
                  textAlign: ['center'],
                  mb: [6, 9],
                }}
              >
                {heading}
              </Heading>
              <Box sx={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                <JunipProductReviewsWidget cms={cms} />
              </Box>
            </Flex>
          </Container>
        )}
      </Section>
    );
  },
  { triggerOnce: true }
);

Reviews.displayName = 'JunipReviews';
Reviews.Schema = Schema;
