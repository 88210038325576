import { useCallback } from 'react';

export const useWishlist = () => {
  const mergeSessions = useCallback(async ({ params }) => {
    try {
      const response = await fetch('/api/swym', {
        method: 'post',
        body: JSON.stringify({
          action: 'guestValidateSync',
          ...params,
        }),
      });

      const json = await response.json();
      const { data } = json;

      const regId = data?.regid;

      // overwrite cookie with new regId from logging in
      if (regId) {
        document.cookie = `swym_regid=${regId};path=/`;
      }

      return data;
    } catch (error) {
      console.log('updateWishlist error:', error);
      return null;
    }
  }, []);

  const updateWishlist = useCallback(async ({ params }) => {
    try {
      const response = await fetch('/api/swym', {
        method: 'post',
        body: JSON.stringify({
          action: 'updateListProducts',
          ...params,
        }),
      });

      const json = await response.json();
      const { data } = json;

      const regId = data?.value?.regid;
      const sessionId = data?.value?.sessionid;

      let cookieRegId = false;

      document.cookie?.split(/\s*;\s*/).find((cookie) => {
        const [key, value] = cookie.split(/\s*=\s*/);
        if (key !== 'swym_regid') return false;
        cookieRegId = value;
        return true;
      });

      // add swym regid cookie if it does not exist
      if (!cookieRegId && regId) {
        document.cookie = `swym_regid=${regId};path=/`;
        document.cookie = `swym_sessionid=${sessionId};path=/`;
      }

      return data;
    } catch (error) {
      console.log('updateWishlist error:', error);
      return null;
    }
  }, []);

  const createWishlistList = useCallback(async({ params }) => {
    try {
      const response = await fetch('/api/swym', {
        method: 'post',
        body: JSON.stringify({
          action: 'createList',
          ...params,
        }),
      });

      const json = await response.json();
      const { data } = json;
      
      return data;
    } catch (error) {
      console.log('createList error:', error);
      return null;
    }
  }, []);

  return { mergeSessions, updateWishlist, createWishlistList };
};
