import { useCallback, useState, useMemo } from 'react';
import { Button, Flex, Spinner } from 'theme-ui';
import { useAddToCart } from '@hooks';
import { themed } from './QuickAddOptions.theme';

export const QuickAddOption = themed(
  ({
    theme,
    inventory,
    inventoryFetched,
    optionName,
    fullProduct,
    selectedVariant,
    value,
  }) => {
    const [isAdding, setIsAdding] = useState(false);

    const variantToAdd = useMemo(() => {
      return fullProduct.variants?.find((variant) => {
        return variant.selectedOptionsMap[optionName] === value;
      });
    }, [optionName, fullProduct?.id, selectedVariant?.id, value]);

    const variantInventory = inventory?.variants?.[variantToAdd?.id];

    const variantIsSoldOut =
      inventoryFetched && !variantInventory?.availableForSale;

    const disabled = !inventoryFetched || !variantToAdd;

    const [, { addToCart }] = useAddToCart({
      product: fullProduct,
      selectedVariant: variantToAdd,
    });

    const handleAddToCart = useCallback(async () => {
      if (!variantToAdd?.id || !inventoryFetched || isAdding) return;
      setIsAdding(true);

      await addToCart();

      setIsAdding(false);
    }, [variantToAdd?.id, inventoryFetched, isAdding, addToCart]);

    return (
      <Button
        aria-label={value}
        disabled={disabled || variantIsSoldOut}
        onClick={handleAddToCart}
        type="button"
        sx={theme.button}
      >
        {isAdding ? (
          <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Spinner width="20" height="20" />
          </Flex>
        ) : (
          value
        )}
      </Button>
    );
  }
);

QuickAddOption.displayName = 'QuickAddOption';
