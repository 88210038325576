import { useState } from 'react';
import { Box } from 'theme-ui';
import { useRouter } from '@backpackjs/storefront';
import { Video } from '@snippets';

import { Content } from './Content';
import { Image } from './Image';
import { themed } from './Slide.theme';

export const Slide = themed(
  ({ theme, slide, isSlider, showContentSlider, visible }) => {
    const [videoErrorMobile, setVideoErrorMobile] = useState(false);
    const [videoErrorDesktop, setVideoErrorDesktop] = useState(false);
    const router = useRouter();
    const { button, media } = slide;
    const cta = button?.buttons?.[0]?.cta;
    const clickable = button?.clickableHero && cta?.url;

    return (
      <Box
        data-comp={Slide.displayName}
        onClick={() => (clickable ? router.push(cta?.url) : null)}
        tabIndex={clickable && button?.hideButtons ? '0' : '-1'}
        role={clickable ? 'button' : 'banner'}
        aria-label={clickable && button?.hideButtons ? cta?.text : ''}
        sx={{
          ...theme.slide,
          cursor: clickable ? 'pointer' : isSlider ? 'grab' : 'auto',
        }}
      >
        <Box sx={{ display: ['block', 'none'] }}>
          {media?.mediaTypeMb === 'image' ||
          (videoErrorMobile && media?.image?.imageMob?.src) ? (
            <Image slide={slide} />
          ) : (
            <Video
              videoSources={[media?.video?.videoMb]}
              videoTitles={[media?.video?.titleMb]}
              setVideoError={setVideoErrorMobile}
              videoOverlay={media?.video?.videoOverlay}
              overlayOpacity={media?.video?.overlayOpacity}
              overlayColor={media?.video?.overlayColor}
            />
          )}
        </Box>

        <Box sx={{ display: ['none', 'block'] }}>
          {media?.mediaTypeDt === 'image' ||
          (videoErrorDesktop && media?.image?.image?.src) ? (
            <Image slide={slide} />
          ) : (
            <Video
              videoSources={[media?.video?.videoDt]}
              videoTitles={[media?.video?.titleDt]}
              setVideoError={setVideoErrorDesktop}
              videoOverlay={media?.video?.videoOverlay}
              overlayOpacity={media?.video?.overlayOpacity}
              overlayColor={media?.video?.overlayColor}
            />
          )}
        </Box>

        {!showContentSlider && (
          <Content heroClickable={clickable} slide={slide} visible={visible} />
        )}
      </Box>
    );
  }
);

Slide.displayName = 'Slide';
