import { store } from '@backpackjs/storefront';

const overlay = store.recoil.atom({
  key: 'global/overlay',
  default: null,
});

const modal = store.recoil.atom({
  key: 'global/modal',
  default: null,
});

const extraSx = store.recoil.atom({
  key: 'global/extraSx',
  default: null,
});

const sidebar = store.recoil.atom({
  key: 'global/sidebar',
  default: null, // null || 'cart' || 'search' || 'filter'
});

const forceInView = store.recoil.atom({
  key: 'global/forceInView',
  default: null,
});

const shopperId = store.recoil.atom({
  key: 'global/shopperId',
  default: null,
});

const selectedProductItem = store.recoil.atom({
  key: 'global/selectedProductItem',
  default: null,
});

export default {
  overlay,
  modal,
  extraSx,
  sidebar,
  forceInView,
  shopperId,
  selectedProductItem,
};
