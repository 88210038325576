import { create } from '@theme/create';

const PROMO_HEIGHT = ['34px', null, '40px'];

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'layout.padded',
      position: 'relative',
      zIndex: 31,
      alignItems: 'center',
      minHeight: PROMO_HEIGHT,
      maxHeight: PROMO_HEIGHT,
      px: 0,
      '&:hover': {
        '[data-comp="PromoBarClose"]': {
          opacity: 1,
        },
      },
    };

    this.close = {
      position: 'absolute',
      cursor: 'pointer',
      right: ['4px', null, '24px'],
      width: ['10px', '20px'],
      opacity: 0,
      top: '50%',
      zIndex: 6,
      transform: 'translateY(-50%) scale(.9)',
      transformOrigin: 'center',
      transition: 'transform .4s ease-in',
      '&:hover': {
        transform: 'translateY(-50%) scale(1)',
      },
    };

    this.swiper = {
      width: '100%',
      '[data-comp="PromoBarMessage"]': {
        height: PROMO_HEIGHT,
        fontSize: 1, // for markdown
      },
      '@media screen and (min-width: 1025px)': {
        '[data-comp="PromoBarMessage"]': {
          height: PROMO_HEIGHT,
          fontSize: 2, // for markdown
        },
      },
    };

    this.message = (color) => ({
      // wrapper
      variant: 'flex.row.center',
      minHeight: 'inherit',
      maxHeight: 'inherit',
      width: '100%',
      px: [6, 10],
      p: {
        display: 'block',
        alignItems: 'center',
        variant: 'text.caption',
        color,
        marginBlockStart: 0,
        marginBlockEnd: 0,
        fontSize: ['10px', '13px'],
        lineHeight: ['16px', '18px'],
      },
      // copy
      copy: {
        width: '100%',
      },
    });
  })()
);
