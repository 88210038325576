import { section, color, align, button } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Products Slider',
    key: 'products-slider',
    fields: [
      {
        name: 'groups',
        label: 'Product Groups',
        component: 'group-list',
        itemProps: {
          label: '{{item.name}}',
        },
        defaultItem: {
          name: 'New Group',
          cta: {
            text: 'Shop All Products',
            url: '/collections/shop-all',
          },
          products: [
            {
              product: { handle: 'mini-pouch-hana-hou-watermelon' },
            },
            {
              product: { handle: 'mini-pouch-hana-hou-watermelon' },
            },
            {
              product: { handle: 'mini-pouch-hana-hou-watermelon' },
            },
            {
              product: { handle: 'mini-pouch-hana-hou-watermelon' },
            },
            {
              product: { handle: 'mini-pouch-hana-hou-watermelon' },
            },
          ],
        },
        fields: [
          {
            name: 'name',
            label: 'Group Name',
            component: 'text',
          },
          {
            name: 'products',
            label: 'Products',
            component: 'group-list',
            itemProps: {
              label: '{{item.product.handle}}',
            },
            defaultItem: {
              product: { handle: 'mini-pouch-hana-hou-watermelon' },
            },
            fields: [
              {
                name: 'product',
                component: 'productSearch',
                label: 'Product',
              },
            ],
            defaultValue: [
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
            ],
          },
          {
            name: 'cta',
            component: 'link',
            label: 'Group Button',
          },
        ],
        defaultValue: [
          {
            name: 'Shop All',
            products: [
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-watermelon' },
              },
            ],
            cta: {
              text: 'Shop All Products',
              url: '/collections/shop-all',
            },
          },
          {
            name: 'New Arrivals',
            products: [
              {
                product: { handle: 'mini-pouch-hana-hou-tangelo' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-tangelo' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-tangelo' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-tangelo' },
              },
              {
                product: { handle: 'mini-pouch-hana-hou-tangelo' },
              },
            ],
            cta: {
              text: 'Shop New Arrivals',
              url: '/collections/new-arrivals',
            },
          },
          {
            name: 'Fan Favorites',
            products: [
              {
                product: { handle: 'small-pouch-hana-hou-tangelo' },
              },
              {
                product: { handle: 'small-pouch-hana-hou-tangelo' },
              },
              {
                product: { handle: 'small-pouch-hana-hou-tangelo' },
              },
              {
                product: { handle: 'small-pouch-hana-hou-tangelo' },
              },
              {
                product: { handle: 'small-pouch-hana-hou-tangelo' },
              },
            ],
            cta: {
              text: 'Shop Fan Favorites',
              url: '/collections/aloha-fan-favorites-top-reviewed',
            },
          },
        ],
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, text align, button style',
        component: 'group',
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            name: 'subheading',
            label: 'SubHeading',
            component: 'text',
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            description: "Doesn't apply on desktop when group list is inline",
            label: 'Heading Alignment',
            options: align.options,
          },
          {
            name: 'ctaStyle',
            label: 'Group Button Style',
            component: 'select',
            options: button.options,
          },
        ],
        defaultValue: {
          heading: 'Shop Best Sellers',
          subheading: 'Optional subhead optional subhead optional subhead.',
          textAlign: align.value.center,
          ctaStyle: 'primary',
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
