import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Input, Paragraph, Flex, Button, Spinner } from 'theme-ui';
import {
  useCartUpdateItem,
  useCartRemoveItem,
  useCartUpdatedAt,
} from '@backpackjs/storefront';

import { Svg } from '@snippets';

import { themed } from './Quantity.theme';

export const Quantity = themed(
  ({
    theme: themes,
    inSidebar,
    id,
    isGWP,
    quantity,
    setIsDeleting,
    cartRemoveItem,
    ...rawProps
  }) => {
    const { inputRef, ...props } = rawProps;
    const { cartUpdateItem } = useCartUpdateItem({
      autoReset: 100,
    });
    const cartUpdatedAt = useCartUpdatedAt();
    const [isUpdating, setIsUpdating] = useState(false);
    const disabled = !id || isUpdating;

    // apply the sidebar or cart page context
    const theme = inSidebar ? themes.sidebar : themes.page;

    const handleDecrement = useCallback(async () => {
      try {
        if (disabled) return;
        const prevQuantity = quantity - 1;
        if (prevQuantity) {
          setIsUpdating(true);
          await cartUpdateItem({ lineId: id, quantity: prevQuantity });
          setIsUpdating(false);
        } else {
          setIsDeleting(true);
          await cartRemoveItem({ lineId: id });
        }
      } catch (error) {
        setIsUpdating(false);
        setIsDeleting(false);
        console.error(error.message);
      }
    }, [id, quantity, disabled]);

    const handleIncrement = useCallback(async () => {
      try {
        if (disabled) return;
        setIsUpdating(true);
        await cartUpdateItem({ lineId: id, quantity: quantity + 1 });
        setIsUpdating(false);
      } catch (error) {
        setIsUpdating(false);
        console.error(error.message);
      }
    }, [id, quantity, disabled]);

    const handleInputChange = useCallback(
      async (e) => {
        try {
          if (disabled) return;
          const typedQuantity = parseInt(e.target.value, 10);
          if (quantity === typedQuantity) return;

          if (typedQuantity) {
            setIsUpdating(true);
            await cartUpdateItem({ lineId: id, quantity: typedQuantity });
            setIsUpdating(false);
          } else {
            setIsDeleting(true);
            await cartRemoveItem({ lineId: id });
          }
        } catch (error) {
          setIsUpdating(false);
          setIsDeleting(false);
          console.error(error.message);
        }
      },
      [id, quantity, disabled]
    );

    return !id ? (
      'Loading...'
    ) : (
      <Flex data-comp={Quantity.displayName} {...props} sx={theme.quantity}>
        {!isGWP && (
          <>
            <Button
              variant="buttons.plain"
              sx={{
                ...theme.quantity.item,
                opacity: isUpdating ? 0.7 : 1,
                cursor: disabled ? 'default' : 'pointer',
              }}
              disabled={disabled}
              aria-label="Decrement quantity by 1"
              onClick={handleDecrement}
            >
              <Svg
                data-comp="QuantityReduce"
                viewBox="0 0 48 48"
                alt="Reduce line item quantity"
                src="/svgs/minus.svg#minus"
                sx={theme.quantity.item.icon}
              />
            </Button>

            {/* changing key on update ensures re-renders on changes */}
            <Flex sx={theme.quantity.item.inputWrapper}>
              <Paragraph
                key={cartUpdatedAt}
                as={Input}
                type="number"
                min="-1"
                max="10"
                name="quantity"
                defaultValue={quantity}
                onBlur={handleInputChange}
                disabled={disabled}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleInputChange(e);
                  }
                }}
                sx={{
                  ...theme.quantity.item.input,
                  opacity: isUpdating ? 0 : 1,
                }}
              />

              {isUpdating && <Spinner sx={theme.quantity.item.spinner} />}
            </Flex>

            {/* Add item */}
            <Button
              variant="buttons.plain"
              sx={{
                ...theme.quantity.item,
                opacity: isUpdating ? 0.7 : 1,
                cursor: disabled ? 'default' : 'pointer',
              }}
              aria-label="Increment quantity by 1"
              onClick={handleIncrement}
              disabled={disabled}
            >
              <Svg
                data-comp="QuantityIncrease"
                viewBox="0 0 48 48"
                alt="Increase line item quantity"
                src="/svgs/plus.svg#plus"
                sx={theme.quantity.item.icon}
              />
            </Button>
          </>
        )}
      </Flex>
    );
  }
);

Quantity.displayName = 'Quantity';
Quantity.propTypes = {
  id: PropTypes.string.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};
