import { useState, useRef, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSettings } from '@backpackjs/storefront';

import { useSetPromoBarHeightCssVar } from './useSetPromoBarHeightCssVar';

export const usePromobar = () => {
  const swiperRef = useRef(false);
  const { ref: promoBarRef, inView, entry } = useInView();

  const settings = useSettings();
  const { promoBar } = { ...settings?.header };

  const closable = promoBar?.closable;

  const haveMessages = promoBar?.messages?.length;
  const isSingleMessage = haveMessages === 1;

  const [swiper, setSwiper] = useState();
  const [closed, setClosed] = useState(false); // closed when clicked X
  const [activeIndex, setActiveIndex] = useState(0);

  const updateActiveIndex = useCallback((swiper) => {
    setActiveIndex(swiper.realIndex);
  }, []);

  const animateClose = useCallback(() => {
    setClosed(true);
  }, []);

  useSetPromoBarHeightCssVar(inView, entry);

  return [
    {
      // refs
      swiperRef,
      promoBarRef,

      // state
      swiper,

      isDisabled: !haveMessages || !promoBar?.enabled,
      closed,
      isSingleMessage,

      // site settings
      promoBar,

      currentColor: promoBar?.messages?.[activeIndex]?.color || 'secondary',
      bgColor: promoBar?.messages?.[activeIndex]?.background || 'primary',
      closable,
    },
    {
      animateClose,
      updateActiveIndex,
      setSwiper,
    },
  ];
};
