import { Container } from 'theme-ui';

import { Link, Picture, Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './Image.schema';

export const Image = withInView(
  ({ cms, inView }) => {
    const { alt, mobile, desktop, cta, section } = cms;
    return (
      <Section section={section} cms={cms}>
        <Container data-comp={Image.displayName}>
          <Link
            href={cta?.url}
            {...(cta?.url ? { 'aria-label': cta.text } : null)}
          >
            <Picture
              data-comp={Image.displayName}
              alt={alt}
              images={[
                {
                  src: mobile?.src?.src || mobile?.src,
                  width: 768,
                  ratio: mobile?.ratio || 1,
                },
                {
                  src: desktop?.src?.src || desktop?.src,
                  width: 1024,
                  ratio: desktop?.ratio || 1.75,
                },
                {
                  src: desktop?.src?.src || desktop?.src,
                  width: 1024,
                  ratio: desktop?.ratio || 1.75,
                },
                {
                  src: desktop?.src?.src || desktop?.src,
                  width: 1024,
                  ratio: desktop?.ratio || 1.75,
                },
              ]}
              imageSx={{
                objectPosition: [mobile?.position, desktop?.position],
                objectFit: [
                  mobile?.objectFit || 'cover',
                  desktop?.objectFit || 'cover',
                ],
              }}
              sx={{
                minHeight: [mobile?.minHeight || 'unset', 'unset'],
                maxHeight: [
                  mobile?.maxHeight || 'unset',
                  desktop?.maxHeight || 'unset',
                ],
                minWidth: [mobile?.minHeight || 'unset', 'unset'],
                maxWidth: ['unset', desktop?.maxWidth || 'unset'],
                margin: '0px auto',
              }}
            />
          </Link>
        </Container>
      </Section>
    );
  },
  { triggerOnce: true }
);

Image.displayName = 'Image';
Image.Schema = Schema;
