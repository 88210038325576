import { useCallback } from 'react';
import { useRouter } from '@backpackjs/storefront';
import store, { useRecoilState } from '@store';

export const useJunipScrollToReviews = () => {
  const router = useRouter();
  const [forceInView, setForceInView] = useRecoilState(store.forceInView);

  // used by JunipStars to scroll to the main reviews section on PDPs
  const scrollToReviews = useCallback(() => {
    try {
      let delay = 0;
      if (!forceInView) {
        setForceInView(true);
        delay = 1000;
      }
      setTimeout(() => {
        const isProductPage = /\/products\//.test(router.asPath);
        if (!isProductPage) return;

        const reviewsContainer = document.querySelector(
          `[data-comp="JunipProductReviews"]`
        );
        reviewsContainer?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
      }, delay);
    } catch (error) {
      // throw new Error(error)
      console.warn('Error while scrolling to reviews form', error.message);
    }
  });

  return {
    scrollToReviews,
  };
};
