import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.container = {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    };

    this.heading = {
      variant: 'text.h2',
      mb: [12, 14],
    };

    this.subheading = {
      variant: 'text.h3',
      mb: [12, 14],
    };

    this.roleboard = {
      flexDirection: 'column',
      mb: [12, 14],
    };

    this.role = {
      display: ['none', 'none', 'flex'],
      flexDirection: 'row',
      bg: 'background',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      mb: [6, 7],
      ':not:last-of-type': {
        borderBottom: '1px solid lightGray',
      },
      dept: {
        variant: 'text.h3',
      },
      position: {
        variant: 'text.h3',
        textTransform: 'uppercase',
      },
      location: {
        display: ['none', 'none', 'block'],
        pr: [6, 7],
        variant: 'text.small',
        icon: {
          width: '48px',
          stroke: '#666',
        },
      },
      apply: {
        display: ['none', 'none', 'block'],
        variant: 'text.h5',
        textTransform: 'uppercase',
        '::after': {
          content: '">"',
          position: 'absolute',
          marginLeft: '5px',
          height: '10px',
          width: '10px',
          transition: 'margin .25s ease-in-out',
        },
        ':hover::after': {
          marginLeft: '10px',
        },
      },
    };
  })()
);
