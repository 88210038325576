import { Box } from 'theme-ui';
import { useRouter } from '@backpackjs/storefront';
import Script from 'next/script';

import { Section } from '@snippets';

import { Schema } from './AccountWidget.schema';

export function AccountWidget({ cms }) {
  const { section } = cms;
  const router = useRouter();
  const handle = router.query?.handle;

  return (
    <>
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          window["friendbuyAPI"] = friendbuyAPI = window["friendbuyAPI"] || [];
          friendbuyAPI.push([
            "track",
            "page",
            {name: "${handle}"},
          ]);
          `,
        }}
      />

      <Section section={section} cms={cms}>
        <Box data-comp={AccountWidget.displayName} id="friendbuyaccountpage" />
      </Section>
    </>
  );
}

AccountWidget.displayName = 'AccountWidget';
AccountWidget.Schema = Schema;
