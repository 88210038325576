const body = {
  fontFamily: 'body',
  fontWeight: 400,
};

const heading = {
  fontFamily: 'heading',
  fontWeight: 700,
  letterSpacing: '0.03em',
  textTransform: 'uppercase',
};

const quartoHeading = {
  fontFamily: 'quarto',
  fontWeight: 600,
  fontStyle: 'italic',
  letterSpacing: '0.03em',
  textTransform: 'none',
};

// gotham
const hero = {
  ...heading,
  fontSize: [9, 11, 12], // [40px, 48px, 56px]
  lineHeight: [48, 56, 64],
};
const h1 = {
  ...heading,
  fontSize: [9, 10, 11], // [32px, 40px, 48px]
  lineHeight: ['1', 48, 56],
};
const h2 = {
  ...heading,
  fontSize: [8, 9], // [24px, 32px]
  lineHeight: [32, 40],
};
const h3 = {
  ...heading,
  fontSize: ['22px', '28px'], // [22px, 28px]
  lineHeight: ['24px', '38px'],
};
const h4 = {
  ...heading,
  fontSize: 6, // 18px
  lineHeight: 24,
};
const h5 = {
  ...heading,
  fontSize: 3, // 14px
  lineHeight: 18,
};
const h6 = {
  ...heading,
  fontSize: 1, // 12px
  lineHeight: 18,
};

// quarto
const heroQuarto = {
  ...quartoHeading,
  fontSize: [70, 100], // [70px, 100px]
  lineHeight: ['78px', '108px'],
};
const h1Quarto = {
  ...quartoHeading,
  fontSize: [62, 80], // [62px, 80px]
  lineHeight: ['70px', '88px'],
};
const h2Quarto = {
  ...quartoHeading,
  fontSize: [48, 64], // [48px, 64px]
  lineHeight: ['54px', '72px'],
};
const h3Quarto = {
  ...quartoHeading,
  fontSize: ['40px', '52px'], // [40px, 52px]
  lineHeight: ['48px', '60px'],
};
const h4Quarto = {
  ...quartoHeading,
  fontSize: ['32px', '44px'], // [32px, 44px]
  lineHeight: ['40px', '52px'],
};

const link = {
  fontSize: 1, // 12px
  lineHeight: 13,
  fontWeight: 700,
  fontFamily: 'body',
  letterSpacing: '0.08em',
  textTransform: 'uppercase',
  position: 'relative',
  overflow: 'hidden',
  pb: '3px',
  ':after': {
    content: '""',
    position: 'absolute',
    top: 'calc(100% - 1px)',
    left: 0,
    width: '100%',
    height: '1px',
    bg: 'currentColor',
    transition: 'transform 0.4s ease-in-out',
  },
  ':before': {
    content: '""',
    position: 'absolute',
    top: 'calc(100% - 1px)',
    left: '-200%',
    width: '100%',
    height: '1px',
    bg: 'currentColor',
    transition: 'transform 0.4s ease-in-out',
  },
  ':hover:after': {
    opacity: 0.6,
  },
  ':hover:before': {
    opacity: 0.6,
  },
};

export const text = {
  // HEADINGS

  hero: {
    ...hero,
    // hero.quarto
    quarto: {
      ...heroQuarto,
      // hero.quarto.light
      light: {
        ...heroQuarto,
        fontWeight: 500,
      },
    },
  },
  h1: {
    ...h1,
    // h1.light
    light: {
      ...h1,
      fontWeight: 400,
    },
    // h1.quarto
    quarto: {
      ...h1Quarto,
      // h1.quarto.light
      light: {
        ...h1Quarto,
        fontWeight: 500,
      },
    },
  },
  h2: {
    ...h2,
    // h1.light
    light: {
      ...h2,
      fontWeight: 400,
    },
    // h2.quarto
    quarto: {
      ...h2Quarto,
      // h2.quarto.light
      light: {
        ...h2Quarto,
        fontWeight: 500,
      },
    },
  },
  h3: {
    ...h3,
    // h3.light
    light: {
      ...h3,
      fontWeight: 400,
    },
    // h3.quarto
    quarto: {
      ...h3Quarto,
      // h3.quarto.light
      light: {
        ...h3Quarto,
        fontWeight: 500,
      },
    },
  },
  h4: {
    ...h4,
    // h4.light
    light: {
      ...h4,
      fontWeight: 400,
    },
    // h4.quarto
    quarto: {
      ...h4Quarto,
      // h4.quarto.light
      light: {
        ...h4Quarto,
        fontWeight: 500,
      },
    },
  },
  h5: {
    ...h5,
    // h5.light
    // h5.quarto
    light: {
      ...h5,
      fontWeight: 400,
    },
    quarto: {
      ...h4Quarto,
      // h5.quarto.light
      light: {
        ...h4Quarto,
        fontWeight: 500,
      },
    },
  },
  h6: {
    ...h6,
    // h6.light
    light: {
      ...h6,
      fontWeight: 400,
      textTransform: 'none',
    },
    // h6.quarto
    quarto: {
      ...h4Quarto,
      // h6.quarto.light
      light: {
        ...h4Quarto,
        fontWeight: 500,
      },
    },
  },

  // BODY

  sm: {
    ...body,
    fontSize: 1, // 12px
    lineHeight: 18,
  },
  base: {
    ...body,
    fontSize: 5, // 16px
    lineHeight: 22,
  },
  lg: {
    ...body,
    fontSize: 6, // 18px
    lineHeight: 24,
  },

  // LABELS, LINKS, OTHERS

  caption: {
    fontSize: 2, // 13px
    lineHeight: 18,
    fontWeight: 700,
    fontFamily: 'body',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
    color: 'gray',
  },

  label: {
    fontSize: 0, // 11px
    lineHeight: 16,
    fontWeight: 700,
    fontFamily: 'body',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
  },

  textLink: {
    fontSize: 3, // 14px
    lineHeight: 20,
    fontWeight: 400,
    fontFamily: 'body',
    textDecoration: 'underline',
  },

  link: {
    ...link,
    // link.1
    1: link,
    // link.2
    2: {
      ...link,
      fontSize: 4, // 16px
      lineHeight: 18,
    },
    3: {
      ...link,
      textTransform: 'none',
      fontWeight: 400,
      width: 'max-content',
      fontSize: '10px',
      lineHeight: '16px',
      fontFamily: 'Helvetica',
      pb: 0,
      cursor: 'pointer',
    },
  },

  buttonText: {
    fontSize: 4, // 15px
    lineHeight: 20,
    fontWeight: 700,
    fontFamily: 'body',
    letterSpacing: '0.01em',
    textTransform: 'uppercase',
  },

  // NAVIGATION

  navItem: {
    fontSize: 4, // 15px
    lineHeight: 20,
    fontWeight: 400,
    fontFamily: 'body',
    textTransform: 'uppercase',
  },

  navSubItem: {
    fontSize: 4, // 15px
    lineHeight: 21,
    fontWeight: 400,
    fontFamily: 'body',
    color: 'mediumGray',
  },

  searchHeading: {
    variant: 'text.label',
    position: 'sticky',
    top: ['-32px', '56px'],
    borderBottom: (t) => `1px solid ${t.colors.lightGray}`,
    zIndex: 2,
    pt: 8,
    pb: 4,
    mb: 5,
    bg: 'background',
  },
};
