import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.sidebar = {
      wrapper: {
        variant: 'flex.column',
        boxShadow: 'top',
        py: 10,
        px: 9,
        pb: '22px',
        mb: 0,
        color: 'text',
      },

      summary: {
        display: 'none',
        color: 'red',
      },

      shipping: {
        variant: 'text.sm',
        color: 'text',
      },

      threshold: {
        variant: 'text.sm',
        color: '#C0412C',
      },

      checkout: {
        variant: 'buttons.primary',
        mt: 10,
        height: '48px',
        lineHeight: 0,
        position: 'relative',
      },

      spinner: {
        color: 'currentColor',
        height: '60%',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      },
    };
    // cart empty — rendered in /cart
    this.page = {
      wrapper: {
        ...this.sidebar.wrapper,
        backgroundColor: 'lightestGray',
        border: '1px solid',
        borderColor: 'lightGray',
        boxShadow: 'none',
        pt: [10, null, 11],
        pb: [10, null, 10],
        px: [10, null, 9],
        mb: [11, null, 9],
      },

      summary: {
        display: 'none',
        variant: 'text.lg',
        mb: 8,
      },

      shipping: {
        variant: 'text.sm',
        color: 'gray',
        mb: 6,
      },

      shipping: {
        ...this.sidebar.shipping,
      },

      threshold: {
        variant: 'text.sm',
        color: '#C0412C',
        mt: 8,
      },

      checkout: {
        ...this.sidebar.checkout,
      },

      spinner: {
        ...this.sidebar.spinner,
      },
    };
  })()
);
