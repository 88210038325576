import { useEffect, useState } from 'react';
import { Box } from 'theme-ui';
import { useBreakpoint } from '@hooks';

import { themed } from './Sidebar.theme';

export const SidebarShell = themed(
  ({ theme, open = false, rtl = true, children, ...rawProps }) => {
    const { inputRef, ...props } = rawProps;
    const [zIndex, setZIndex] = useState(999999);
    const { s, m } = useBreakpoint();
    const isMobile = s || m;

    useEffect(() => {
      if (open) {
        const chatIcon = document.getElementById('chat-button');

        const chatIconStyle = chatIcon
          ? window.getComputedStyle(chatIcon)
          : undefined;
        if (chatIconStyle) {
          setZIndex(Number(chatIconStyle.zIndex) * 1.5);
        }
      }
    }, [open, isMobile]);

    return (
      <Box
        as="aside"
        data-comp={SidebarShell.displayName}
        {...props}
        sx={{
          ...props.sx,
          ...theme.shell,
          ...(open ? theme.shell.open(zIndex) : theme.shell.closed),
        }}
      >
        {open ? children : null}
      </Box>
    );
  }
);

SidebarShell.displayName = 'SidebarShell';
