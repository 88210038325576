import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = {
      mb: 5,
      px: [10, null, '60px'],
    };

    this.heading = {
      fontSize: ['22px', '28px'],
      lineHeight: ['24px', '38px'],
      mb: 5,
    };

    this.body = {
      maxWidth: '520px',
      '*': {
        fontSize: [1, 5],
        lineHeight: [18, 22],
      },
      p: {
        mt: 0,
      },
    };

    this.wrapper = {
      px: [0, null, '60px'],
    };
  })()
);
