import { useState, useEffect, useCallback } from 'react';

import { NavigationButton } from './NavigationButton';
import { themed } from './Navigation.theme';

export const Navigation = themed(({ theme, swiper, onNavigationClick }) => {
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const setStartAndEndOnSwiperChange = useCallback(() => {
    if (!swiper) return;
    setIsStart(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
    swiper.on('slideChange', () => {
      setIsStart(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    });
  }, [swiper]);

  const handleNextSlideClick = (event) => {
    if (onNavigationClick) onNavigationClick(event);
    if (swiper) {
      swiper.slideNext();
    }
  };

  const handlePrevSlideClick = (event) => {
    if (onNavigationClick) onNavigationClick(event);
    if (swiper) {
      swiper.slidePrev();
    }
  };

  useEffect(() => {
    setStartAndEndOnSwiperChange();
  }, [swiper]);

  return (
    <>
      <NavigationButton
        data-comp={Navigation.displayName}
        onClick={(e) => handlePrevSlideClick(e)}
        inactive={isStart}
      />

      <NavigationButton
        data-comp={Navigation.displayName}
        onClick={(e) => handleNextSlideClick(e)}
        src="/svgs/chevron/right.svg#right"
        alt="Next product"
        inactive={isEnd}
        isNext
      />
    </>
  );
});

Navigation.displayName = 'Navigation';
