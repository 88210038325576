import { Button } from 'theme-ui';

import { Svg } from '@snippets';

import { useMenuSidebar } from '../../MenuSidebar';
import { themed } from '../Menu.theme';

export const CtaMenu = themed(({ theme, ...rawProps }) => {
  const { inputRef, ...props } = rawProps;
  const [{ menuSidebar }, { toggleSidebar }] = useMenuSidebar();

  return (
    <Button
      data-comp={CtaMenu.displayName}
      {...props}
      onClick={toggleSidebar}
      variant="buttons.plain"
      aria-label={menuSidebar ? 'Close menu sidebar' : 'Open menu sidebar'}
      sx={{
        ...props.sx,
        ...theme.cta,
      }}
    >
      <Svg
        alt={menuSidebar ? 'Close menu' : 'Open menu'}
        src={
          menuSidebar
            ? '/svgs/close.svg#close'
            : '/svgs/cta/rounded-menu.svg#rounded-menu'
        }
        sx={theme.cta.icon}
        viewBox={menuSidebar ? '0 0 48 48' : '0 0 30 30'}
      />
    </Button>
  );
});

CtaMenu.displayName = 'CtaMenu';
