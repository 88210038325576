import { useMemo, useEffect, useState, useCallback } from 'react';
import {
  useCart,
  useCartItems,
  useProductByHandle,
  useCartAddItem,
  useCartRemoveItem,
  useSettings,
} from '@backpackjs/storefront';
import store, { useRecoilState, useSetRecoilState } from '@store';

export const useCartPromo = () => {
  const setLastItemAdded = useSetRecoilState(store.lastItemAdded);
  const [isAddStarted, setIsAddStarted] = useState(false);
  const { cartAddItem } = useCartAddItem();
  const { cartRemoveItem } = useCartRemoveItem();
  const cart = useCart();
  const cartItems = useCartItems();
  const settings = useSettings();
  const { freeShipping, promo } = { ...settings?.cart };
  const {
    icon: freeShippingIcon,
    spend: freeShippingSpend,
    message: freeShippingMessageRemainder,
    messageQualified: freeShippingMessageQualified,
  } = { ...freeShipping };
  const { enabled, type, percentageOff, gwp } = { ...promo };

  // assuming GWP is only a single variant product
  const availableGWP = gwp?.products?.find(
    ({ product }) => product?.firstAvailableVariantIndex === 0
  );

  const { product: gwpProduct } = useProductByHandle({
    handle: availableGWP?.product?.handle,
  });

  const [cartPromos, setCartPromos] = useRecoilState(store.cartPromos);
  const setGwpProduct = useSetRecoilState(store.gwpProduct);
  const setCartPromoMessages = useSetRecoilState(store.cartPromoMessages);
  const setHiddenGwpProducts = useSetRecoilState(store.hiddenGwpProducts);
  const [gwpProductInCart, setGwpProductInCart] = useRecoilState(
    store.gwpProductInCart
  );

  const promos = useMemo(() => {
    const total = cart?.lines?.reduce((carry, line) => {
      const subtotalAmount = line?.estimatedCost?.subtotalAmount?.amount;
      if (line?.variant?.product?.tags.includes('no-tiered-discounts'))
        return carry;
      return carry + parseFloat(subtotalAmount);
    }, 0);

    const getRemainderMessage = (lockMessage, spend = 0) => {
      const remainder = (parseFloat(spend) - total).toFixed(2);
      const remainderPrice = remainder.endsWith('00')
        ? remainder.split('.')[0]
        : remainder;
      return lockMessage?.replace('$', `$${remainderPrice}`);
    };

    const _promos = [
      {
        active: total >= freeShippingSpend,
        icon: freeShippingIcon,
        lockMessage: getRemainderMessage(
          freeShippingMessageRemainder,
          freeShippingSpend
        ),
        threshold: freeShippingSpend,
        unlockMessage: freeShippingMessageQualified,
        type: 'free-shipping',
      },
    ];

    if (enabled && type === 'percentage-off') {
      percentageOff?.tiers?.forEach((tier) => {
        if (!tier.enabled) return;
        _promos.push({
          active: total >= tier.threshold,
          icon: tier.icon,
          lockMessage: getRemainderMessage(tier.lockTier, tier.threshold),
          threshold: tier.threshold,
          unlockMessage: tier.unlockTier,
          type,
        });
      });
    }

    if (enabled && type === 'gift-with-purchase') {
      if (gwpProduct) {
        // this needs to be stored in global state as well
        _promos.push({
          active: total >= gwp.threshold,
          icon: gwp.icon,
          lockMessage: getRemainderMessage(gwp.lockTier, gwp.threshold),
          threshold: gwp.threshold,
          unlockMessage: gwp.unlockTier,
          product: gwpProduct,
          type,
        });
      } else {
        // wait until gwp product has been fetched
        return null;
      }
    }

    return _promos;
  }, [cart?.updatedAt, enabled, type, percentageOff, gwp, gwpProduct]);

  const promoMessages = useMemo(() => {
    if (!promos) return null;
    const activePromo = [...promos]?.reverse().find((_promo) => _promo?.active);
    const nextPromo = promos.find((_promo) => !_promo?.active);

    return {
      lockMessage: nextPromo?.lockMessage,
      unlockMessage: activePromo?.unlockMessage,
    };
  }, [promos]);

  useEffect(() => {
    if (enabled && type === 'gift-with-purchase' && gwp?.products?.length) {
      setHiddenGwpProducts(gwp?.products?.map(({ product }) => product));
    }
  }, [enabled, gwp, type]);

  useEffect(() => {
    if (promos && promoMessages) {
      setCartPromos(promos);
      setCartPromoMessages(promoMessages);
    }
    if (gwpProduct) {
      setGwpProduct(gwpProduct);
    }
  }, [promos, promoMessages, gwpProduct]);

  const checkGWP = useCallback(
    async (gwpPromoIsActive) => {
      if (isAddStarted) return null;

      let userRemovedGwp = false;

      document.cookie?.split(/\s*;\s*/).find((cookie) => {
        const [key, value] = cookie.split(/\s*=\s*/);
        if (key !== 'user_removed_gwp') return false;
        userRemovedGwp = value;
        return true;
      });

      if (cartPromos) {
        if (
          gwpPromoIsActive &&
          !gwpProductInCart &&
          gwpProduct &&
          !userRemovedGwp &&
          !isAddStarted
        ) {
          const item = {
            merchandiseId: gwpProduct?.variants?.[0]?.storefrontId,
            quantity: 1,
            attributes: [{ key: '_gwp', value: 'true' }],
          };
          setIsAddStarted(true);
          setLastItemAdded(item);
          await cartAddItem(item);
          setIsAddStarted(false);
        }

        if (!gwpPromoIsActive && gwpProductInCart) {
          cartRemoveItem({ lineId: gwpProductInCart.id });
        }
      }
    },
    [cartPromos, gwpProductInCart, gwpProduct, isAddStarted]
  );

  useEffect(() => {
    setGwpProductInCart(
      cartItems?.find(
        (item) => item?.variant?.product?.handle === gwpProduct?.handle
      )
    );

    const gwpPromoIsActive = cartPromos?.some(
      (_promo) => _promo?.active && _promo?.product
    );

    checkGWP(gwpPromoIsActive);
  }, [cartItems, cartPromos, gwpProduct]);

  // console.log('promos:', promos);
  // console.log('promoMessages:', promoMessages);
};
