import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.button = {
      position: 'absolute',
      bottom: 0,
      variant: 'buttons.secondary',
      minWidth: 'unset',
      width: '100%',
      borderRadius: '0px',
      lineHeight: 1.1,
      justifyContent: 'center',
      alignItems: 'center',
      height: '50px',
      bg: 'white',
      ':hover': {
        bg: 'white',
        color: 'black',
      },
    };

    this.buttonText = {
      display: 'inline-block',
      variant: 'text.h6',
      lineHeight: 1.1,
      borderBottom: '1px solid',
      borderColor: 'darkGray',
      color: 'black',
    };
  })()
);
