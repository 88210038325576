import { isBrowser } from '@utils';

export const usePostScript = ({ product, selectedVariant }) => {
  // console.log({ product, selectedVariant });
  if (!isBrowser)
    return { postScriptPdp: () => {}, postScriptAddToCart: () => {} };
  const previousPath = window?.sessionStorage.getItem('PREVIOUS_PATH');
  const list = previousPath?.startsWith('/collections') ? previousPath : '';
  const event = {
    shop_id: process.env.NEXT_PUBLIC_POSTSCRIPT_SHOP_ID || 343383,
    url: window.location.href,
    search_params: {
      variant:
        new URLSearchParams(new URL(window.location.href).search).get(
          'variant'
        ) || '',
    },
    page_type: 'product',
    referrer: list,
    resource: {
      category: selectedVariant?.product?.productType,
      name: selectedVariant?.product?.title,
      price_in_cents: Math.round(
        Number(selectedVariant?.priceV2?.amount) * 100
      ),
      resource_id: product?.legacyResourceId,
      resource_type: 'product',
      sku: selectedVariant?.sku,
      variant_id: selectedVariant?.legacyResourceId,
      vendor: product?.vendor,
    },
  };

  const postScriptPdp = () => {
    if (window.postscript) {
      console.log('executing postscript event postScriptPdp', { event });
      window.postscript.event('page_view', event);
    }
  };
  const postScriptAddToCart = () => {
    if (window.postscript) {
      console.log('executing postscript event postScriptAddToCart', { event });
      window.postscript.event('add_to_cart', event);
    }
  };

  return { postScriptPdp, postScriptAddToCart };
};
