import { Box, Spinner } from 'theme-ui';
import { Picture, Link, Video } from '@snippets';

import { themed } from './Media.theme';

export const Media = themed(
  ({ theme, link, media, height, inputRef, section, ...props }) => {
    const { image, mediaType, video } = { ...media };
    const { alt, image: imageDt, imageMob } = { ...image };

    const noMediaAvailable =
      (!imageDt?.src && !imageMob?.src && mediaType === 'image') ||
      (!video?.video && mediaType === 'video');

    if (noMediaAvailable) return null;

    return (
      <Box
        {...props}
        ref={inputRef}
        sx={{
          ...theme.media(mediaType),
          height,
          mx: [section?.mobile?.container === 'container' ? 10 : 0, 0, 0],
        }}
      >
        <Link href={link?.url} tabIndex="-1" sx={theme.link}>
          {mediaType === 'image' ? (
            <Picture
              alt={alt || link?.text}
              sx={theme.image}
              images={[
                {
                  src: imageMob?.src || imageDt?.src,
                  width: 768,
                  ratio: 1,
                },
                {
                  src: imageDt?.src,
                  width: 1024,
                  ratio: 1,
                },
                {
                  src: imageDt?.src,
                  width: 1280,
                  ratio: 1,
                },
                {
                  src: imageDt?.src,
                  width: 1440,
                  ratio: 1,
                },
              ]}
            >
              {!image?.imageMob && imageMob?.Dt && (
                <Spinner sx={theme.spinner} />
              )}
            </Picture>
          ) : (
            <Video videoSources={[video?.video]} />
          )}
        </Link>
      </Box>
    );
  }
);

Media.displayName = 'Media';
