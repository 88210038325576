import { useMemo } from 'react';

export const useProductItemImages = ({ product }) => {
  return useMemo(() => {
    if (!product) return { primary: null, hover: null };

    // If not grouped, return 1st and 2nd image automatically
    if (!product.grouping) {
      return {
        primary: product.images?.[0] || product.variants?.[0]?.image || null,
        hover: product.images?.[1] || null,
      };
    }

    // If no media, return first variant image if available
    if (!product.media?.length) {
      return {
        primary: product.variants?.[0]?.image || null,
        hover: null,
      };
    }

    // Find first media item with type "image"
    let imageIndex = null;
    const primary = product.media.find((item, index) => {
      if (item.mediaContentType === 'IMAGE') {
        imageIndex = index;
        return true;
      }
      return false;
    });

    // Find second media item with type "image"
    // If encounter media with different handle, early exit
    let noHoverImage = false;
    const hover = product.media.find((item, index) => {
      if (item.product?.handle !== product.handle) {
        noHoverImage = true;
        return true;
      }
      return index > imageIndex && item.mediaContentType === 'IMAGE';
    });

    return {
      primary: primary?.image || null,
      hover: noHoverImage ? null : hover?.image || null,
    };
  }, [product?.id, product?.media?.length, product?.images?.length]);
};
