import { useEffect, useState, useMemo } from 'react';
import { Box, Container, Heading, Spinner } from 'theme-ui';

import { Section, Markdown } from '@snippets';
import { withInView } from '@utils';

import { themed } from './InstaProductSlider.theme';
import { Schema } from './InstaProductSlider.schema';

export const InstaProductSlider = withInView(
  themed(({ theme, cms, inView }) => {
    const { section, heading, description } = cms;

    const [isMounted, setIsMounted] = useState(false);
    const [numberOfPosts, setNumberOfPosts] = useState(0);

    useEffect(() => {
      if (isMounted || !inView) return;

      const scriptInit = async () => {
        try {
          const element = document.getElementById('insta-product-slider');
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = 'https://foursixty.com/media/scripts/fs.slider.v2.5.js';
          script.id = 'four-sixty-script';
          script.setAttribute('key', 'foursixtylib');
          script.setAttribute('data-for-url', 'true');
          script.setAttribute('data-cell-size', '24%');
          script.setAttribute('data-feed-id', 'alohacollection');
          script.setAttribute('data-theme', 'slider_v2_5');
          script.setAttribute('data-shopify-add-to-cart', 'false');
          script.setAttribute('data-open-links-in-same-page', 'true');

          element.appendChild(script);

          setTimeout(() => {
            setNumberOfPosts(window?.FoursixtyEmbed?.postNum);
          }, 500);

          setTimeout(() => {
            setNumberOfPosts(window?.FoursixtyEmbed?.postNum);
          }, 1500);

          setIsMounted(true);
        } catch (error) {
          setIsMounted(false);
          throw new Error(error);
        }
      };

      scriptInit();
    }, [inView]);

    const productSliderOffset = useMemo(() => {
      if (!numberOfPosts) return null;

      if (numberOfPosts < 3) {
        const postWidth = 24 * numberOfPosts;
        return `${(100 - postWidth) / 2}%`;
      }

      return null;
    }, [numberOfPosts]);

    const display = numberOfPosts ? 'block' : 'none !important';

    return (
      <Section section={section} cms={cms} sx={{ display }}>
        <Container
          data-comp={InstaProductSlider.displayName}
          sx={theme.outerWrap}
        >
          <Box sx={theme.wrapper}>
            <Box
              sx={{
                ...theme.top,
                display: numberOfPosts > 0 ? 'block' : 'none',
              }}
            >
              <Heading sx={theme.heading}>{heading}</Heading>
              {description && (
                <Markdown text={description} textSx={theme.description} />
              )}
            </Box>
            <Box
              sx={{
                position: 'relative',
                minHeight: ['160px', '200px', '250px', '320px', '360px'],
              }}
            >
              <Box
                id="insta-product-slider"
                sx={{
                  ...theme.productSlider,
                  transform: [null, `translateX(${productSliderOffset})`],
                  'style ~ *': { display: 'none' },
                }}
              />

              {!isMounted && (
                <Spinner
                  sx={{
                    width: '32px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              )}
            </Box>
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true, rootMargin: '400px' }
);

InstaProductSlider.displayName = 'InstaProductSlider';
InstaProductSlider.Schema = Schema;
