import { create } from '@theme/create';

const MENU_HEIGHT = ['60px', null, '64px'];

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'layout.padded',
      alignItems: 'center',
      bg: 'background',
      columnGap: [4, 8, 12, 14],
      // px: [10, null, 17],
      gridTemplateColumns: ['1fr auto 1fr ', null, 'auto auto 1fr'],
      justifyContent: 'space-between',
      position: 'relative',
      height: MENU_HEIGHT,
      width: '100%',
      zIndex: 2,
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'lightGray',
    };

    // burger + search — mobile
    this.toggle = {
      display: ['flex', null, 'none'],
    };
    this.logo_wrap = {
      position: 'relative',
      width: ['120px', null, '106px'],
      height: ['62px', null, '53px'],
      transform: ['translate(0, 13px)', null, 'translate(0, 18px)'],
      '&:before': {
        content: '""',
        display: 'block',
        width: ['106px', null, '96px'],
        height: ['85px', null, '82px'],
        borderRadius: '50%',
        backgroundColor: 'white',
        position: 'absolute',
        bottom: '-10px',
        border: '1px solid',
        borderColor: 'lightGray',
        zIndex: 2,
      },
      '&:after': {
        content: '""',
        display: 'block',
        width: ['107px', null, '96px'],
        height: ['60px', null, '64px'],
        backgroundColor: 'white',
        position: 'absolute',
        bottom: ['16px', null, '8px'],
        zIndex: 3,
      },
    };
    this.logo = {
      cursor: 'pointer',
      mx: 'auto',
      // width: MENU_HEIGHT,
      width: ['120px', null, '106px'],
      height: ['62px', null, '53px'],
      position: 'relative',
      zIndex: 5,
    };
    this.nav = {
      variant: 'flex.row.start.center',
      display: ['none', null, 'flex'],
      height: 'inherit',
      flex: 1,
    };
    this.navList = {
      display: 'flex',
      alignItems: 'center',
      pl: 0,
      height: 'inherit',
    };
    this.listItem = {
      height: 'inherit',
    };
    this.item = {
      variant: 'text.h6',
      fontSize: 3,
      height: 'inherit',
      display: 'flex',
      alignItems: 'center',
      px: [5, 6, 8, 10],
      opacity: 1,
      position: 'relative',
      ':hover': {
        bg: 'transparent',
      },

      // hovered menu item
      hover: {
        bg: 'text',
        borderRadius: 2,
        bottom: 0,
        content: '" "',
        display: 'block',
        height: '2px',
        left: 0,
        position: 'absolute',
        right: 0,
        transformOrigin: 'bottom left',
        width: '100%',

        hidden: {
          transform: 'scaleX(0)',
        },
        visible: {
          transform: 'scaleX(1)',
        },
      },
    };
    this.ctas = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: [6, 8, 10],
      gridTemplateColumns: ['repeat(2, 1fr)', null, 'repeat(3, 1fr)'],
      ml: 'auto',
    };
    this.cta = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      icon: {
        width: '24px',
        cursor: 'pointer',
      },
      text: {
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        // variant: 'text.h6',
        // fontSize: '11px',
        // lineHeight: '1',
        // bg: 'seafoam',
        // borderRadius: '100%',
        // border: '1px solid black',
        // position: 'absolute',
        // top: '-8px',
        // right: '-8px',
        // height: '18px',
        // width: '18px',
      },
    };
  })()
);
