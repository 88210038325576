import { Box, Button, Text } from 'theme-ui';

import store, { useRecoilValue } from '@store';

import { useCartCount } from '@backpackjs/storefront';

import { Svg } from '@snippets';
import { useSidebar } from '@hooks';

import { themed } from '../Menu.theme';

export const CtaCart = themed(({ theme, ...rawProps }) => {
  const { inputRef, ...props } = rawProps;
  const { openCartSidebar } = useSidebar()[1];
  const cartCount = useCartCount();

  const playItemAddedNotification = useRecoilValue(
    store.playItemAddedNotification
  );

  return (
    <Button
      data-comp={CtaCart.displayName}
      {...props}
      variant="buttons.plain"
      onClick={openCartSidebar}
      aria-label="Open cart sidebar"
      sx={{
        ...props.sx,
        ...theme.cta,
      }}
    >
      <Svg
        alt="Open cart sidebar"
        viewBox="0 0 24 24"
        src="/svgs/cta/cart-new.svg#cart-new"
        sx={{
          ...theme.cta.icon,
          width: '24px',
        }}
      />
      {cartCount > 0 && (
        <Box
          sx={{
            position: 'absolute',
            top: '-8px',
            right: '-8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              borderRadius: '100%',
              border: '1px solid black',
              height: '18px',
              width: '18px',
              bg: 'seafoam',
              '@keyframes cartAnimation': {
                '0%': {
                  transform: 'scale(1)',
                },
                '50%': {
                  transform: 'scale(1.5)',
                },
                '100%': {
                  transform: 'scale(1)',
                },
              },
              animation: playItemAddedNotification
                ? 'cartAnimation 0.75s 2 linear'
                : null,
            }}
          />
          <Text
            sx={{
              variant: 'text.h6',
              fontSize: '11px',
              position: 'absolute',
              lineHeight: '1',
            }}
          >
            {cartCount}
          </Text>
        </Box>
      )}
    </Button>
  );
});

CtaCart.displayName = 'CtaCart';
