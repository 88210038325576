import { color } from '../common';

export const rotatingBar = {
  label: 'Rotating bar',
  name: 'rotatingBar',
  component: 'group',
  fields: [
    {
      label: 'Enabled',
      name: 'enabled',
      component: 'toggle',
      description: 'Promo bar must be disabled to show Rotating bar',
      toggleLabels: {
        true: 'Yes',
        false: 'No',
      },
      defaultValue: false,
    },
    {
      label: 'Background color',
      name: 'background',
      component: 'color',
      defaultValue: color.value.seafoam,
    },
    {
      label: 'Messages',
      name: 'messages',
      component: 'group-list',
      itemProps: {
        label: '{{item.copy}}',
      },
      fields: [
        {
          label: 'Copy',
          name: 'copy',
          component: 'text',
          defaultValue: 'Free U.S. Returns & Shipping $150+',
        },
        {
          label: 'Text color',
          name: 'color',
          component: 'color',
          defaultValue: color.value.text,
        },
        {
          label: 'Link (optional)',
          name: 'link',
          component: 'link',
        },
        {
          label: 'Modal Settings',
          name: 'modal',
          component: 'group',
          fields: [
            {
              label: 'Enabled',
              name: 'enabled',
              component: 'toggle',
              description: 'Overrides link and opens modal on click event',
              toggleLabels: {
                true: 'Yes',
                false: 'No',
              },
              defaultValue: false,
            },
            {
              label: 'Heading',
              name: 'heading',
              component: 'text',
            },
            {
              label: 'Body',
              name: 'body',
              component: 'markdown',
            },
          ],
        },
      ],
      defaultValue: [
        {
          copy: 'FREE SHIPPING ON ORDERS OVER $100',
        },
        {
          copy: 'SPEND $200, GET 20% OFF YOUR PURCHASE!',
        },
        {
          copy: 'Try our rewards program! Fly Aloha.',
          link: {
            url: '/pages/rewards',
            text: '',
          },
        },
      ],
    },
  ],
};
