export const fontSizes = [
  /*
    DON'T ADD NEW fontSizes AS IT WILL MESS UP THE SCALE ORDER
    If your value is not here, either choose the closes or just add it inline i.e "40px"
  */
  '11px', // 0
  '12px', // 1
  '13px', // 2
  '14px', // 3
  '15px', // 4
  '16px', // 5 --- base/body ----
  '18px', // 6
  '20px', // 7
  '24px', // 8
  '32px', // 9
  '40px', // 10
  '48px', // 11
  '56px', // 12
  /* DON'T ADD NEW fontSizes AS IT WILL MESS UP THE SCALE ORDER */
];
