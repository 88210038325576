import { Flex, Heading, Paragraph, Button, Box } from 'theme-ui';

import { Link, Markdown } from '@snippets';

import { useModal } from '@hooks';

import { PromoBarMessageCopy } from './PromoBarMessageCopy';
import { themed } from './Promo.theme';

export const PromoBarMessage = themed(
  ({
    theme,
    link,
    copy,
    color,
    background,
    modal,
    align,
    isActive,
    index,
    ...props
  }) => {
    const [, { openModal }] = useModal();

    const onOpenModal = ({ heading, body } = {}) => {
      openModal(
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            textAlign: 'center',
            py: '25px',
            minHeight: '400px',
          }}
        >
          <Heading as="h2" variant="text.h2">
            {heading}
          </Heading>
          <Markdown text={body} />
        </Box>,
        {
          container: {
            bg: 'white !important',
          },
          close: {
            top: ['10px'],
            right: ['10px'],
          },
        }
      );
    };

    return (
      <Flex
        data-comp={PromoBarMessage.displayName}
        sx={{ ...theme.message(color), backgroundColor: background }}
      >
        <Box data-comp="PromoBarMessageCopyWrapper" sx={theme.message.copy}>
          {modal?.enabled ? (
            <Button onClick={() => onOpenModal(modal)} variant="buttons.plain">
              <PromoBarMessageCopy copy={copy} align={align} />
            </Button>
          ) : (
            <Link href={link?.url} prefetch={false}>
              <PromoBarMessageCopy copy={copy} align={align} />
            </Link>
          )}
        </Box>
      </Flex>
    );
  }
);

PromoBarMessage.displayName = 'PromoBarMessage';
