import { useMemo, Children, cloneElement } from 'react';
import { Box } from 'theme-ui';
import { useLocalizedOrderLine } from '@backpackjs/storefront';

import { formatAmountItem } from './formatAmountItem';

export function LocaleOrderLine({ line, currencyCode, children, ...props }) {
  if (!line) return null;

  const { localized } = useLocalizedOrderLine({
    line: { ...line },
    currencyCode,
  });

  const Components = useMemo(() => {
    let PriceComp = null;
    let LoaderComp = null;
    let ErrorComp = null;

    Children.forEach(children, (child) => {
      const displayName =
        child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName ||
        child?.type?.displayName;

      switch (displayName) {
        case 'Locale.Price': {
          // TODO: validate if this hold when we have subscriptions to test
          const isSubscription = !!localized?.sellingPlanAllocation;
          const discountAmount = formatAmountItem(
            localized?.discountAllocations?.[0]?.allocatedAmount
          );
          const isDiscounted = discountAmount?.amount > 0;

          PriceComp = localized
            ? cloneElement(child, {
                key: 'Locale.Price',
                originalTotalPrice: formatAmountItem(
                  localized?.originalTotalPrice
                ),
                discountAmount,
                discountedTotalPrice: formatAmountItem(
                  localized?.discountedTotalPrice
                ),
                locale: localized.locale,
                isSubscription,
                isDiscounted,
                ...child.props,
              })
            : null;
          break;
        }

        case 'Locale.Error':
          ErrorComp = null;
          break;

        case 'Locale.Loader':
          LoaderComp = null;
          break;

        default:
          break;
      }
    });

    // active components
    return [PriceComp, LoaderComp, ErrorComp].filter(Boolean);
  }, [
    localized?.originalTotalPrice?.amount,
    localized?.discountAmount?.amount,
    localized?.discountedTotalPrice?.amount,
  ]);

  return (
    <Box
      data-comp={LocaleOrderLine.displayName}
      {...props}
      sx={{ ...props.sx }}
    >
      {Components.map((Component) => Component)}
    </Box>
  );
}

LocaleOrderLine.displayName = 'Locale.OrderLine';
