import { Button, Grid } from 'theme-ui';

import { Svg } from '@snippets';

import { themed } from './VideoPlayButton.theme';

export const VideoPlayButton = themed(
  /**
   * @param {import ('./types/videoPlayButton.types').Props } props
   */
  ({
    src = '/svgs/noprecache/svg/play.svg#play',
    alt = 'Play',
    onClick,
    showContent,
    iconStyles,
    theme,
    styles,
    viewBox = '0 0 10 24',
    text,
    ...rawProps
  }) => {
    const { inputRef, ...props } = rawProps;

    return (
      <Button
        data-comp={VideoPlayButton.displayName}
        onClick={onClick}
        sx={{
          ...theme.button,
          // ...styles,
          ...(showContent ? theme.hiddenButton : {}),
        }}
        {...props}
      >
        {!showContent && (
          <Svg
            alt={alt}
            src="/svgs/play-button-fill.svg#play-fill"
            viewBox="0 0 500 500"
            sx={{
              color: 'white',
              width: ['64px', '80px'],
              height: ['64px', '80px'],
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
        {/* {!showContent && (
          <>
            <Svg
              alt={alt}
              src={src}
              draggable={false}
              viewBox={viewBox}
              sx={{ ...theme.icon, ...iconStyles }}
            />
            {text && <Grid>{text}</Grid>}
          </>
        )} */}
      </Button>
    );
  }
);

VideoPlayButton.displayName = 'VideoPlayButton';
