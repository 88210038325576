import { useCallback } from 'react';

import { useSearchspring } from '@hooks';
import store, { useRecoilState } from '@store';

export const useSearchSuggestions = () => {
  const { requestSearchspring } = useSearchspring();
  const [suggestions, setSuggestions] = useRecoilState(store.searchSuggestions);
  const [pageSuggestions, setPageSuggestions] = useRecoilState(
    store.searchPageSuggestions
  );
  const [suggested, setSuggested] = useRecoilState(store.searchSuggested);
  const [pageSuggested, setPageSuggested] = useRecoilState(
    store.searchPageSuggested
  );
  const [spellCorrection, setSpellCorrection] = useRecoilState(
    store.searchSpellCorrection
  );
  const [pageSpellCorrection, setPageSpellCorrection] = useRecoilState(
    store.searchSpellCorrection
  );
  const [isAutocomplete, setIsAutocomplete] = useRecoilState(
    store.isAutocomplete
  );

  // Get search suggestions based off query from ss
  const getSearchSuggestions = useCallback(async ({ query, isPage }) => {
    try {
      if (!query) {
        setSuggestions([]);
        setSuggested(null);
        setIsAutocomplete(false);
        setSpellCorrection(null);
        return false;
      }

      if (isPage && !query) {
        setPageSuggestions([]);
        setPageSuggested(null);
        setPageSpellCorrection(null);
        return false;
      }

      const data = await requestSearchspring({
        action: 'getSearchSuggestions',
        params: { query },
      });

      const _alternatives = data?.alternatives?.length
        ? data.alternatives.map(({ text }) => text)
        : [];

      // combine top suggested suggestion and alternative suggestions into one array
      const _suggestions = [
        ...(data?.suggested?.type === 'completed' ? [data.suggested.text] : []),
        ..._alternatives,
      ];

      if (isPage) {
        setPageSuggestions(_suggestions);
        setPageSuggested(data?.suggested || null);
        setPageSpellCorrection(data?.['corrected-query'] || null);
        return data;
      }

      setSuggestions(_suggestions);
      setSuggested(data?.suggested || null);
      setIsAutocomplete(data?.suggested?.type === 'completed' || false);
      setSpellCorrection(data?.['corrected-query'] || null);
      return data;
    } catch (error) {
      console.error(`Error with getSearchSuggestions: ${error.message}`);
      throw error;
    }
  }, []);

  return {
    suggestions,
    pageSuggestions,
    suggested,
    getSearchSuggestions,
    isAutocomplete,
    pageSuggested,
    spellCorrection,
    pageSpellCorrection,
  };
};
