import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.slide = {
      height: '100%',
      position: 'relative',
      width: '100%',
    };

    this.image = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    };

    this.contentWrapper = {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      pointerEvents: 'none',
      position: 'absolute',
      width: '100%',
    };

    this.content = {
      px: [12, 15],
      py: [15, 17],
      pointerEvents: 'auto',
    };

    this.superheading = {
      mb: 5,
    };

    this.heading = {
      m: 0,
    };

    this.buttons = {
      gridAutoFlow: 'row',
      gridGap: 6,
      '@media only screen and (min-width: 480px)': {
        gridAutoFlow: 'column',
        gridGap: 9,
      },
    };

    this.button = {
      variant: ['buttons.primary', 'buttons.secondary'],
    };

    this.spinner = {
      color: 'white',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    };
  })()
);
