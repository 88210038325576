import { Box } from 'theme-ui';
import { Section } from '@snippets';
import Script from 'next/script';

import { Schema } from './FriendLP.schema';

export function FriendLP({ cms }) {
  const { section } = cms;

  return (
    <>
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          window["friendbuyAPI"] = friendbuyAPI = window["friendbuyAPI"] || [];
          friendbuyAPI.push([
            "track",
            "page",
            {name: "friends"},
          ]);
          `,
        }}
      />

      <Section section={section} cms={cms}>
        <Box
          data-comp={FriendLP.displayName}
          className="friendbuyfriendspage"
        />
      </Section>
    </>
  );
}

FriendLP.displayName = 'FriendLP';
FriendLP.Schema = Schema;
