import { Box, Heading, Flex } from 'theme-ui';

import { Markdown } from '@snippets';

import { themed } from './Tile.theme';

export const TileContent = themed(({ theme, item }) => {
  return (
    <Flex
      data-comp={TileContent.displayName}
      sx={{
        ...theme.content(),
        flexDirection: 'column',
      }}
    >
      <Box className="tile-content">
        <Heading as="h3" sx={theme.heading}>
          {item?.heading}
        </Heading>

        {item?.description && (
          <Markdown text={item.description} sx={theme.body} />
        )}
      </Box>
    </Flex>
  );
});

TileContent.displayName = 'TileContent';
