import { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link as LinkUI, Box, Button } from 'theme-ui';
import LinkNext from 'next/link';

export const Link = forwardRef(
  (
    {
      href = '',
      newTab = false,
      hiddenForSeo = false,
      prefetch = false,
      children,
      query, // next/link query object
      sx,
      ...rawProps
    },
    ref
  ) => {
    const { inputRef, ...props } = rawProps;

    const isAnchorLink = href?.startsWith('#') && href?.length > 1;

    const scrollTo = useCallback(() => {
      if (!isAnchorLink) return;
      const element = document.querySelector(
        `[data-comp-id="${href?.replace('#', '')}"]`
      );
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.offsetTop,
      });
    }, [isAnchorLink, href]);

    if (hiddenForSeo) {
      return (
        <LinkUI
          data-comp="Link-Hidden"
          href={href}
          ref={ref}
          sx={{ display: 'none' }}
        />
      );
    }

    if (href && newTab) {
      return (
        <LinkUI
          data-comp="Link-External"
          href={href}
          ref={ref}
          target="_blank"
          sx={{
            color: 'inherit',
            textDecoration: 'none',
            ...sx,
          }}
          {...props}
        >
          {children}
        </LinkUI>
      );
    }

    if (isAnchorLink) {
      return (
        <Button
          variant="plain"
          aria-label="Anchor Link to Section"
          onClick={(e) => {
            e.preventDefault();
            scrollTo();
          }}
          sx={{
            color: 'inherit',
            textDecoration: 'none',
            ...sx,
          }}
          {...props}
        >
          {children}
        </Button>
      );
    }

    if (href) {
      return (
        <LinkNext
          href={typeof query === 'object' ? { pathname: href, query } : href}
          prefetch={prefetch}
          passHref
        >
          <LinkUI
            data-comp="Link-Internal"
            ref={ref}
            sx={{
              display: 'block',
              width: 'auto',
              height: 'auto',
              ...sx,
            }}
            {...props}
          >
            {children}
          </LinkUI>
        </LinkNext>
      );
    }

    return (
      <Box
        data-comp="Link-NoHref"
        ref={ref}
        sx={{ cursor: 'default', ...sx }}
        {...props}
      >
        {children}
      </Box>
    );
  }
);

Link.propTypes = {
  href: PropTypes.string,
  newTab: PropTypes.bool,
  hiddenForSeo: PropTypes.bool,
  prefetch: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  query: PropTypes.object,
  sx: PropTypes.object,
};
