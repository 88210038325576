import { useCallback, useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';

import { mapProductItemProduct, mapProductItemVariant } from './utils';

export function useDataLayerCollection({
  baseEcommerce,
  DEBUG,
  userDataEvent,
  userDataEventTriggered,
  userProperties,
}) {
  const collectionHandleRef = useRef(null);

  const [collectionProducts, setCollectionProducts] = useState(null);
  const [collection, setCollection] = useState(null);
  const [clickedCollectionItem, setClickedCollectionItem] = useState(null);
  const [clickedCollectionSubnavItem, setClickedCollectionSubnavItem] =
    useState(null);
  const [clickedCollectionFilter, setClickedCollectionFilter] = useState(null);
  const [unclickedCollectionFilter, setUnclickedCollectionFilter] =
    useState(null);

  const viewCollectionEvent = useCallback(
    ({ ecommerce, products, userProperties: _userProperties }) => {
      if (!products?.length) return;
      const event = {
        event: 'dl_view_item_list',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: _userProperties,
        ecommerce: {
          ...ecommerce,
          products: products.slice(0, 12).map(mapProductItemProduct),
        },
      };

      window.dispatchEvent(
        new CustomEvent(event.event, {
          detail: event,
        })
      );
      if (DEBUG) console.log(`DataLayer:dispatch:${event.event}`, event);
    },
    []
  );

  const clickCollectionItemEvent = useCallback(
    ({ ecommerce, variant }) => {
      if (!variant) return;
      const event = {
        event: 'dl_select_item',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: userProperties,
        ecommerce: {
          ...ecommerce,
          click: {
            actionField: {
              list: window.location.pathname.startsWith('/collection')
                ? window.location.pathname
                : '',
              action: 'click',
            },
            products: [variant].map(mapProductItemVariant),
          },
        },
      };

      window.dispatchEvent(
        new CustomEvent(event.event, {
          detail: event,
        })
      );
      if (DEBUG) console.log(`DataLayer:dispatch:${event.event}`, event);
    },
    [userProperties]
  );

  const clickCollectionSubnavItemEvent = useCallback(
    ({ ecommerce, link }) => {
      if (!link) return;
      const event = {
        event: 'dl_select_subnav_item',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: userProperties,
        ecommerce: {
          ...ecommerce,
          click: {
            actionField: {
              list: window.location.pathname.startsWith('/collection')
                ? window.location.pathname
                : '',
              action: 'click',
              text: link.text,
              url: link.url,
            },
          },
        },
      };

      window.dispatchEvent(
        new CustomEvent(event.event, {
          detail: event,
        })
      );
      if (DEBUG) console.log(`DataLayer:dispatch:${event.event}`, event);
    },
    [userProperties]
  );

  const clickCollectionFilterEvent = useCallback(
    ({ ecommerce, filter }) => {
      if (!filter) return;
      const event = {
        event: 'dl_select_item_list_filter',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: userProperties,
        ecommerce: {
          ...ecommerce,
          click: {
            actionField: {
              list: window.location.pathname.startsWith('/collection')
                ? window.location.pathname
                : '',
              action: 'click',
              filter: filter.name,
              value: filter.value,
            },
          },
        },
      };

      window.dispatchEvent(
        new CustomEvent(event.event, {
          detail: event,
        })
      );
      if (DEBUG) console.log(`DataLayer:dispatch:${event.event}`, event);
    },
    [userProperties]
  );

  const unclickCollectionFilterEvent = useCallback(
    ({ ecommerce, filter }) => {
      if (!filter) return;
      const event = {
        event: 'dl_deselect_item_list_filter',
        event_id: nanoid(),
        event_time: new Date().toISOString(),
        user_properties: userProperties,
        ecommerce: {
          ...ecommerce,
          click: {
            actionField: {
              list: window.location.pathname.startsWith('/collection')
                ? window.location.pathname
                : '',
              action: 'unclick',
              filter: filter.name,
              value: filter.value,
            },
          },
        },
      };

      window.dispatchEvent(
        new CustomEvent(event.event, {
          detail: event,
        })
      );
      if (DEBUG) console.log(`DataLayer:dispatch:${event.event}`, event);
    },
    [userProperties]
  );

  // Subscribe to PubSub topic for 'dl_view_item_list' and 'dl_select_item' events
  useEffect(() => {
    const viewCollection = PubSub.subscribe(
      'VIEW_COLLECTION_PAGE',
      async (event, { products, id, title }) => {
        setCollectionProducts(products);
        setCollection({ id, title });
      }
    );
    const clickCollectionItem = PubSub.subscribe(
      'CLICK_COLLECTION_ITEM',
      async (event, variant) => {
        setClickedCollectionItem(variant);
      }
    );
    const clickCollectionSubnavItem = PubSub.subscribe(
      'CLICK_COLLECTION_SUBNAV_ITEM',
      async (event, link) => {
        setClickedCollectionSubnavItem(link);
      }
    );
    const clickCollectionFilter = PubSub.subscribe(
      'CLICK_COLLECTION_FILTER',
      async (event, filter) => {
        setClickedCollectionFilter(filter);
      }
    );
    const unclickCollectionFilter = PubSub.subscribe(
      'UNCLICK_COLLECTION_FILTER',
      async (event, filter) => {
        setUnclickedCollectionFilter(filter);
      }
    );
    return () => {
      if (viewCollection) {
        PubSub.unsubscribe(viewCollection);
      }
      if (clickCollectionItem) {
        PubSub.unsubscribe(clickCollectionItem);
      }
      if (clickCollectionSubnavItem) {
        PubSub.unsubscribe(clickCollectionSubnavItem);
      }
      if (clickCollectionFilter) {
        PubSub.unsubscribe(clickCollectionFilter);
      }
      if (unclickCollectionFilter) {
        PubSub.unsubscribe(unclickCollectionFilter);
      }
    };
  }, []);

  // Trigger 'dl_user_data' and view 'dl_view_item_list'
  // events on collection page and after base data is ready
  useEffect(() => {
    const pageHandle = window.location.pathname.startsWith('/pages')
      ? `/pages/${window.location.pathname.split('/').pop()}`
      : window.location.pathname.split('/').pop();

    if (
      !collection ||
      !collectionProducts?.length ||
      !baseEcommerce ||
      !userProperties ||
      collectionHandleRef.current === pageHandle
    )
      return;
    userDataEvent({ ecommerce: baseEcommerce, userProperties });
    viewCollectionEvent({
      ecommerce: {
        ...baseEcommerce,
        collection_id: collection.id,
        collection_title: collection.title,
      },
      products: collectionProducts,
      userProperties,
    });
    collectionHandleRef.current = pageHandle;
  }, [collection, collectionProducts, !!baseEcommerce, !!userProperties]);

  // Trigger 'dl_select_item' event on clicked collection
  // item and after user event
  useEffect(() => {
    if (!clickedCollectionItem || !baseEcommerce || !userDataEventTriggered)
      return;
    clickCollectionItemEvent({
      ecommerce: baseEcommerce,
      variant: clickedCollectionItem,
    });
  }, [clickedCollectionItem, !!baseEcommerce, userDataEventTriggered]);

  // Trigger 'dl_select_item' event on clicked collection
  // item and after user event
  useEffect(() => {
    if (
      !clickedCollectionSubnavItem ||
      !baseEcommerce ||
      !userDataEventTriggered
    )
      return;
    clickCollectionSubnavItemEvent({
      ecommerce: baseEcommerce,
      link: clickedCollectionSubnavItem,
    });
  }, [clickedCollectionSubnavItem, !!baseEcommerce, userDataEventTriggered]);

  // Trigger 'dl_select_item_list_filter' event on clicked collection
  // item and after user event
  useEffect(() => {
    if (!clickedCollectionFilter || !baseEcommerce || !userDataEventTriggered)
      return;
    clickCollectionFilterEvent({
      ecommerce: baseEcommerce,
      filter: clickedCollectionFilter,
    });
  }, [clickedCollectionFilter, !!baseEcommerce, userDataEventTriggered]);

  // Trigger 'dl_deselect_item_list_filter' event on clicked collection
  // item and after user event
  useEffect(() => {
    if (!unclickedCollectionFilter || !baseEcommerce || !userDataEventTriggered)
      return;
    unclickCollectionFilterEvent({
      ecommerce: baseEcommerce,
      filter: unclickedCollectionFilter,
    });
  }, [unclickedCollectionFilter, !!baseEcommerce, userDataEventTriggered]);
}
