import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = (hideLines) => ({
      display: 'flex',
      flexDirection: 'column',
      pb: 11,
      borderBottom: hideLines ? 'none' : '1px solid',
      borderColor: 'lightGray',
    });

    this.header = {
      minHeight: ['35px', '45px', '60px'],
    };

    this.slider = {
      minHeight: ['200px'],
    };
  })()
);
