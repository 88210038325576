import { store } from '@backpackjs/storefront';

/**
 *  MenuDrawer states (desktop)
 */

// the currently hovered to menu link object
const hoveredItem = store.recoil.atom({
  key: 'menu/hoveredItem',
  default: false,
});

const hideDrawerContentsTransition = store.recoil.atom({
  key: 'menu/hideDrawerContentsTransition',
  default: false,
});

const mainNavClicked = store.recoil.atom({
  key: 'menu/mainNavClicked',
  default: false,
});

// true when the menuDrawer is fully open
const menuDrawer = store.recoil.atom({
  key: 'menu/menuDrawer',
  default: false,
});

// the currently hovered to menu sub nav link object
const hoveredSubNavDrawer = store.recoil.atom({
  key: 'menu/hoveredSubNavDrawer',
  default: false,
});

/**
 *  MenuSideBar states (mobile)
 */
const menuSidebar = store.recoil.atom({
  key: 'menu/menuSidebar',
  default: false,
});

const selectedItem = store.recoil.atom({
  key: 'menu/selectedItem',
  default: false,
});

const selectedIndex = store.recoil.atom({
  key: 'menu/selectedIndex',
  default: 0,
});

// eslint-disable-next-line import/no-default-export
export default {
  // MenuDrawer (desktop)
  menuDrawer,
  hideDrawerContentsTransition,
  hoveredItem,
  mainNavClicked,

  hoveredSubNavDrawer,
  // MenuSideBar (Mobile)
  menuSidebar,
  selectedItem,
  selectedIndex,
};

// import { store } from '@backpackjs/storefront';

// const drawerOpen  = store.recoil.atom({
//   key: 'menu/drawerOpen',
//   default: false
// })

// const setDrawerOpen$  = store.recoil.selector({
//   key: 'menu/setDrawerOpen$',
//   set: ({ get, set }, newState) => {
//     const _menuItem = get(menuItem);
//     const _drawerOpen = get(drawerOpen);

//     // stay open
//     if (_menuItem && newState !== false) {
//       set(drawerOpen, true)
//     }
//     // maybe closing
//     else {
//       const isClosing = !drawerOpen || newState === false

//       if (isClosing) {
//         // ensure we close the drawer
//         set(menuItem, null)
//       }

//       if (typeof newState === 'undefined') {
//         set(drawerOpen, !_drawerOpen)
//       } else {
//         set(drawerOpen, newState)
//       }
//     }
//   }
// })

// const drawerHoverThreshold = store.recoil.atom({
//   key: 'menu/drawerHoverThreshold',
//   default: 250,
// })

// const menuItem = store.recoil.atom({
//   key: 'menu/menuItem',
//   default: null,
//   effects_UNSTABLE: [
//     // logStateChange('menuItem'),
//   ]
// })

// const menuItemChild = store.recoil.atom({
//   key: 'menu/menuItemChild',
//   default: null,
//   effects_UNSTABLE: [
//     // logStateChange('menuItem')
//   ]
// })

// const setMenuItemAndChild$  = store.recoil.selector({
//   key: 'menu/setMenuItemAndChild$',
//   set: ({ get, set }, newState) => {
//     set(menuItem, newState)
//     const hoverParentHasSubItem = newState?.subItems?.length
//     if (hoverParentHasSubItem) {
//       // set first subItem as hovered
//       set(menuItemChild, newState?.subItems[0])
//     } else {
//       set(menuItemChild, null)
//     }
//   }
// })

// const menuItemHasChildren$ = store.recoil.selector({
//   key: 'menu/menuItemHasChildren$',
//   get: ({ get}) => {
//     const _menuItem = get(menuItem);
//     return _menuItem?.subItems?.length
//   }
// })

// // eslint-disable-next-line import/no-default-export
// export default  {
//   // atoms
//   drawerOpen,
//   drawerHoverThreshold,
//   menuItem,
//   menuItemChild,
//   // selectors
//   setDrawerOpen$,
//   menuItemHasChildren$,
//   setMenuItemAndChild$
// }
