import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      display: 'flex',
      flexDirection: 'column',
      pb: 11,
      borderBottom: '1px solid',
      borderColor: 'lightGray',
      position: 'relative',
    };

    this.slider = {
      minHeight: ['200px'],
    };

    this.grid = (gridDisplayMobile) => ({
      display: 'grid',
      columnGap: [10],
      rowGap: [11, 12],
      gridTemplateColumns: [
        gridDisplayMobile === '2x2' ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)',
        'repeat(2, 1fr)',
        'repeat(4, 1fr)',
      ],
    });

    this.button = {
      mt: [12, 14],
    };
  })()
);
