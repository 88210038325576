import { useState, useCallback, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  EffectFade,
  Thumbs,
  FreeMode,
  Autoplay,
  Pagination,
} from 'swiper';
import { Box, Image } from 'theme-ui';

import { Link } from '@snippets';
import { themed } from './Slider.theme';

import { TestimonialSlide } from '../Testimonial/TestimonialSlide';

export const Slider = themed(({ theme, testimonials, slider }) => {
  const [swiper, setSwiper] = useState();

  const [activeIndex, setActiveIndex] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const setActiveIndexAndSlideToOnSlideChange = useCallback(() => {
    if (!swiper || testimonials?.length < 2) return;
    swiper?.on('slideChange', () => {
      setActiveIndex(swiper?.realIndex);
    });
  }, [swiper, testimonials?.length]);

  useEffect(() => {
    setActiveIndexAndSlideToOnSlideChange();
  }, [swiper, testimonials?.length]);
  return (
    <Box
      data-comp={Slider.displayName}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Swiper
        onSwiper={setSwiper}
        autoplay={
          slider?.autoplay
            ? { delay: slider?.delay || 5000, disableOnInteraction: false }
            : false
        }
        effect={slider?.effect === 'fade' ? 'fade' : null}
        fadeEffect={{ crossFade: true }}
        centeredSlides={false}
        spaceBetween={1}
        navigation={false}
        thumbs={{ swiper: thumbsSwiper }}
        threshold={25}
        modules={[FreeMode, Pagination, Thumbs, Autoplay, EffectFade]}
        sx={{
          ...theme.slider,
        }}
      >
        {testimonials?.map((slide, index) => (
          <SwiperSlide key={index}>
            <TestimonialSlide
              text={slide.testimonialText}
              visible={activeIndex === index}
              color={slide.testimonialColor}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <Box
        sx={{
          maxWidth: '1025px',
          mx: 'auto',
          width: '100%',
        }}
      >
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={3.25}
          modules={[Navigation, Thumbs]}
          threshold={25}
          sx={{
            ...theme.thumbsSwiper(testimonials?.length * 7 || 5),
          }}
          breakpoints={{
            768: {
              slidesPerView: testimonials?.length,
            },
          }}
        >
          {testimonials?.map((slide, index) => (
            <SwiperSlide key={index}>
              <Link
                href={slide?.link?.url}
                newTab={slide?.link?.newTab}
                sx={theme.logoLink}
              >
                <Image
                  alt={slide.alt}
                  src={slide.image.src}
                  sx={{
                    maxHeight: ['45px', '90px'],
                    opacity:
                      activeIndex === index ? 1 : slider?.opacity / 100 || 0.5,
                  }}
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  );
});

Slider.displayName = 'Slider';
