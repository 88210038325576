import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.widget = {
      position: 'relative',
      width: '100%',
      '.junip-product-review-container': {
        maxWidth: '100%',
      },
      '.junip-review-list-item-container, .junip-product-review-title, .junip-product-review-summary-container, .junip-filters-label, .junip-filters-container':
        {
          px: '0 !important',
        },
      '.junip-write-review-cta-container': {
        right: '0 !important',
      },
      '.junip-separator': {
        mx: '0 !important',
      },
      '.junip-powered-by-footer': {
        display: 'none !important',
      },
      '.junip-avatar': {
        backgroundColor: 'black !important',
      },
    };
  })()
);
