import { useState, useEffect } from 'react';
import { Button, Box } from 'theme-ui';
import { Svg } from '@snippets';
import { themed } from './QuickAddMobile.theme';

export const QuickAddMobile = themed(
  ({
    theme,
    addToCart,
    handleClick,
    hasMultipleVariants,
    isQuickAddActive,
    selectedVariant,
  }) => {
    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
      if (isClicked) {
        setTimeout(() => {
          setIsClicked(false);
        }, 100);
      }
    }, [isClicked]);

    if (isQuickAddActive && hasMultipleVariants) {
      return null;
    }

    return (
      <Box sx={theme.mobileWrapper}>
        <Button
          data-comp={QuickAddMobile.displayName}
          sx={{
            ...theme.mobileButton,
            display: ['block', 'none'],
          }}
          onClick={() => {
            setIsClicked(true);
            if (hasMultipleVariants) {
              handleClick(selectedVariant?.product?.handle);
            } else {
              addToCart();
            }
          }}
          aria-label="open mobile quick add"
          type="button"
        >
          <Svg
            viewBox="0 0 22 22"
            alt="mobile quick add"
            src={
              isQuickAddActive || isClicked
                ? '/svgs/mobile-quickadd-black.svg#mobile-quickadd-black'
                : '/svgs/mobile-quickadd-white.svg#mobile-quickadd-white'
            }
            sx={theme.icon}
          />
        </Button>
      </Box>
    );
  }
);

QuickAddMobile.displayName = 'QuickAddMobile';
