import { useEffect, useRef, useState, useCallback } from 'react';
import { Box } from 'theme-ui';
import Player from '@vimeo/player';
import PropTypes from 'prop-types';

import { VideoPlayButton, Picture } from '@snippets';

const modalVideoOptions = {
  loop: false,
  autoplay: false,
  background: false,
  responsive: true,
  muted: false,
  controls: true,
};

export function VimeoPlayer({
  source,
  videoPoster,
  title,
  isModalVideo,
  autoPlay,
  showPlayButton,
  playButtonIcon,
  playButtonStyle,
  playIconStyle,
  viewBox,
  playButtonText,
}) {
  const videoBoxRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [player, setPlayer] = useState(null);

  const handlePlay = useCallback(() => {
    if (!player) return;
    setIsPlaying(true);
    player.play();
  }, [player]);

  const playerDeps = [source, autoPlay, isModalVideo, modalVideoOptions];

  const createVimeoPlayerOnMount = useCallback(() => {
    try {
      if (!source || !videoBoxRef.current) {
        return;
      }
      const _player = new Player(videoBoxRef.current, {
        id: source,
        ...(isModalVideo
          ? modalVideoOptions
          : {
              loop: autoPlay,
              autoplay: autoPlay,
              background: autoPlay,
              responsive: true,
              muted: autoPlay,
              controls: !autoPlay,
            }),
      });
      setPlayer(_player);
    } catch (error) {
      console.error('VimeoPlayer:error:', error.message);
    }
  }, [...playerDeps]);

  const autoPlayModalVideoOnLoad = useCallback(() => {
    if (!isModalVideo || !player) return;
    player.play();
  }, [player, isModalVideo]);

  useEffect(createVimeoPlayerOnMount, [...playerDeps]);
  useEffect(autoPlayModalVideoOnLoad, [player, isModalVideo]);

  return (
    <Box data-comp={VimeoPlayer.displayName} sx={{ position: 'relative' }}>
      <Box ref={videoBoxRef} />

      {showPlayButton && !isPlaying && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}
        >
          <Picture
            alt={title}
            images={[
              {
                src: videoPoster,
                ratio: 16 / 9,
              },
            ]}
            sx={{ objectPosition: 'center' }}
          />

          <VideoPlayButton
            onClick={handlePlay}
            showContent={isPlaying}
            src={playButtonIcon}
            styles={playButtonStyle}
            iconStyles={playIconStyle}
            viewBox={viewBox}
            text={playButtonText}
          />
        </Box>
      )}
    </Box>
  );
}

VimeoPlayer.displayName = 'VimeoPlayer';
VimeoPlayer.defaultProps = {
  source: null,
  autoPlay: false,
  isModalVideo: false,
};
VimeoPlayer.propTypes = {
  source: PropTypes.string,
  autoPlay: PropTypes.bool,
  isModalVideo: PropTypes.bool,
};
