import { Paragraph, Flex } from 'theme-ui';

import { useVariantPrices } from '@hooks';

import { themed } from './Price.theme';

export const Price = themed(({ theme: themes, isGWP, item, inSidebar }) => {
  const { isOnSale, price, compareAtPrice } = useVariantPrices({
    selectedVariant: item.variant,
  });

  // apply the sidebar or cart page context
  const theme = inSidebar ? themes.sidebar : themes.page;

  if (isGWP) {
    return (
      <Flex data-comp={Price.displayName} sx={theme.price}>
        <Paragraph sx={theme.price.strike}>{price}</Paragraph>
        <Paragraph
          sx={{
            ...theme.price.regular,
            mt: isOnSale ? 0 : 4,
          }}
        >
          $0.00
        </Paragraph>
      </Flex>
    );
  }

  return (
    <Flex data-comp={Price.displayName} sx={theme.price}>
      {isOnSale ? (
        <Paragraph sx={theme.price.strike}>{compareAtPrice}</Paragraph>
      ) : null}

      <Paragraph
        sx={{
          ...theme.price.regular,
          mt: isOnSale ? 0 : 4,
        }}
      >
        {price}
      </Paragraph>
    </Flex>
  );
});

Price.displayName = 'Price';
