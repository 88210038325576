import PropTypes from 'prop-types';
import { useState, useCallback, useMemo } from 'react';
import { Grid, Paragraph, Flex, Spinner, Button } from 'theme-ui';
import { useCartRemoveItem } from '@backpackjs/storefront';

import store, { useRecoilValue } from '@store';

import { Svg, Picture, Link, SitewideSaleMessage } from '@snippets';
import { useSidebar } from '@hooks';

import { getValidId } from '@utils';

import { letterSpacings } from '@theme/theme.letterSpacings';
import { Quantity } from './Quantity';
import { Price } from './Price';
import { themed } from './LineItem.theme';

const getExpirationDate = () => {
  const now = new Date();
  const time = now.getTime();
  const expireTime = time + 1000 * 86400; // 1 day
  now.setTime(expireTime);
  return now;
};

export const LineItem = themed(
  ({ theme: themes, inSidebar, isLast, item, ...rawProps }) => {
    const { inputRef, ...props } = rawProps;
    const { cartRemoveItem } = useCartRemoveItem();
    const [, { closeSidebar }] = useSidebar();

    const cartPromos = useRecoilValue(store.cartPromos);

    const productExcludedFromDiscount = useMemo(() => {
      if (!cartPromos.some((promo) => promo.type === 'percentage-off'))
        return false;
      if (!item?.variant.product?.tags?.includes('no-tiered-discounts'))
        return false;

      return (
        cartPromos?.find(
          (promo, index) =>
            !promo?.active &&
            promo.type === 'percentage-off' &&
            cartPromos?.[index - 1]?.active
        ) || cartPromos.every((promo) => promo.active)
      );
    }, [cartPromos]);

    const [isDeleting, setIsDeleting] = useState(false);

    const { title } = item.variant.product;

    const isGWP = item?.attributes?.some((attr) => attr.key === '_gwp');

    const isPreOrder = item?.attributes?.some(
      (attr) => attr.key === 'pre_order'
    );

    const handleRemove = useCallback(async () => {
      setIsDeleting(true);
      if (isGWP) {
        // add cookie to prevent GWP from being added to cart
        document.cookie = `user_removed_gwp=true;expires=${getExpirationDate()?.toUTCString()}`;
      }
      await cartRemoveItem({ lineId: item.id });
    }, [item.id, isGWP]);

    const titles = useMemo(() => {
      const [type, name] = title.split('|');
      return { type: type.trim(), name: name?.trim() };
    }, [title]);

    const productUrl = isGWP
      ? ''
      : `/products/${item.variant.product.handle}?variant=${
          getValidId(item.variant?.id) || ''
        }`;
    // apply the sidebar or cart page context
    const theme = inSidebar ? themes.sidebar : themes.page;

    console.log('item:', item);

    return (
      <Grid
        data-comp={LineItem.displayName}
        {...props}
        sx={{
          ...props.sx,
          ...theme.item,
          ...(isLast ? theme.item.last : theme.item.between),
        }}
      >
        {/* left column — picture */}
        <Link
          sx={{ ...theme.column.left, pointerEvents: isGWP ? 'none' : 'auto' }}
          href={productUrl}
          onClick={() => {
            if (!inSidebar) return;
            closeSidebar();
          }}
        >
          <Picture
            alt={item.variant?.altText}
            images={[
              {
                src:
                  item.variant?.image?.src ||
                  '/svgs/noprecache/svg/default-image.svg',
                ratio: item.variant.image.width / item.variant.image.height,
                width: 70,
              },
            ]}
            sx={theme.picture}
          />
        </Link>

        {/* center column  — title, quantity etc.. */}
        <Flex sx={theme.column.center}>
          {/* variant option title */}
          <Paragraph sx={theme.title.variant}>{titles.type}</Paragraph>

          {/* product title */}
          <Link
            href={productUrl}
            tabIndex="-1"
            sx={theme.title.product}
            onClick={() => {
              if (!inSidebar) return;
              closeSidebar();
            }}
          >
            {titles.name}
          </Link>

          {productExcludedFromDiscount && (
            <Paragraph sx={theme.title.disclaimer}>
              (Product Excluded From Discount)
            </Paragraph>
          )}

          {/* /!* Show onetime/subscription option *!/ */}
          {/* <Paragraph sx={theme.title.plan}> */}
          {/*  {item?.sellingPlanAllocation?.sellingPlan?.name || 'One time purchase'} */}
          {/* </Paragraph> */}

          <SitewideSaleMessage
            product={item?.variant?.product}
            componentType="cartLineItem"
            styles={{
              my: 3,
              py: '3px',
            }}
            textStyles={{
              fontSize: '10px',
              letterSpacing: '-0.2px',
            }}
          />

          <Quantity
            id={item.id}
            inSidebar={inSidebar}
            isGWP={isGWP}
            quantity={item.quantity}
            setIsDeleting={setIsDeleting}
            cartRemoveItem={cartRemoveItem}
          />

          {isPreOrder && (
            <Paragraph sx={theme.title.preorder}>* Pre Ordered!</Paragraph>
          )}
        </Flex>

        {/* right column — price, remove */}
        <Flex sx={theme.column.right}>
          <Button
            variant="buttons.plain"
            aria-label={`Remove ${item.variant.product.title} from cart`}
          >
            <Svg
              alt="Remove item from cart"
              src="/svgs/close.svg#close"
              viewBox="0 0 48 48"
              sx={theme.remove}
              onClick={handleRemove}
            />
          </Button>

          <Price isGWP={isGWP} item={item} inSidebar={inSidebar} />
        </Flex>

        {isDeleting && (
          <Flex sx={theme.deleting}>
            <Spinner sx={theme.deleting.spinner} />
          </Flex>
        )}
      </Grid>
    );
  }
);

LineItem.displayName = 'LineItem';
LineItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  isLast: PropTypes.bool,
  inSidebar: PropTypes.bool,
};
