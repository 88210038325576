import PropTypes from 'prop-types';
import { useRef, useEffect, useState, useCallback } from 'react';
import { Box } from 'theme-ui';

import { VideoPlayButton } from '@snippets';

import { themed } from './VideoPlayer.theme';

/**
 * Video player component
 */
export const VideoPlayer = themed(
  /**
   * @param {import ('./types/videoPlayer.types').Props } props
   */
  ({
    autoPlay,
    controls,
    isModalVideo,
    loop,
    muted,
    playButtonIcon,
    playButtonStyle,
    playButtonText,
    playIconStyle,
    playsInline,
    poster,
    setVideoError,
    showPlayButton,
    source,
    styles,
    theme,
    title,
    type,
    videoStyles,
    viewBox,
  }) => {
    const videoRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = useCallback(() => {
      setIsPlaying((isPlaying) => !isPlaying);
    }, []);

    useEffect(() => {
      if (!videoRef.current) return;
      if (isPlaying) {
        videoRef.current.load();
      } else {
        const startPlayPromise = videoRef.current.play();
        if (startPlayPromise !== undefined) {
          startPlayPromise
            .then(() => {})
            .catch(() => {
              if (setVideoError) setVideoError(true);
            });
        }
      }
    }, [isPlaying]);

    useEffect(() => {
      if (!isModalVideo || !videoRef.current) return;
      videoRef.current.pause();
    }, [isModalVideo]);

    return (
      <Box
        data-comp={VideoPlayer.displayName}
        sx={{
          ...styles,
        }}
      >
        {showPlayButton && (
          <VideoPlayButton
            onClick={handlePlay}
            showContent={!isPlaying}
            src={playButtonIcon}
            styles={playButtonStyle}
            iconStyles={playIconStyle}
            viewBox={viewBox}
            text={playButtonText}
          />
        )}
        <video
          ref={videoRef}
          title={title}
          sx={{
            ...theme.video,
            ...videoStyles,
            height: '100%',
          }}
          playsInline={playsInline}
          muted={isModalVideo ? false : muted}
          loop={isModalVideo ? false : loop}
          autoPlay={isModalVideo ? null : autoPlay}
          controls={isModalVideo ? true : controls}
          poster={isModalVideo ? null : poster}
        >
          <source src={source} type={`video/${type}`} />
        </video>
      </Box>
    );
  }
);

VideoPlayer.propTypes = {
  styles: PropTypes.object,
  muted: PropTypes.bool,
  playsInline: PropTypes.bool,
  loop: PropTypes.bool,
  autoPlay: PropTypes.bool,
  controls: PropTypes.bool,
  poster: PropTypes.string,
  source: PropTypes.string.isRequired,
  showPlayButton: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.object,
};

VideoPlayer.defaultProps = {
  muted: true,
  playsInline: true,
  loop: true,
  autoPlay: false,
  controls: false,
  poster: '',
  type: 'mp4',
  title: 'video media',
  showPlayButton: false,
};
VideoPlayer.displayName = 'VideoPlayer';
