import { sectionWithHeight } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Brand Tagline',
    key: 'brandTagline',
    fields: [
      {
        label: 'Brand Tagline text',
        name: 'text',
        component: 'text',
        description: 'Brief statement about the brand',
        defaultValue: 'Travel is our inspiration, the beach is our home',
      },
      sectionWithHeight,
    ],
  };
};
