import { create } from '@theme/create';
import { hexToRgbA } from '@utils/common';

export const themed = create(
  new (function () {
    this.wrapper = {
      position: 'absolute',
      width: '100%',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    };

    this.vimeoContainer = {
      ...this.wrapper,
      'div[data-vimeo-initialized="true"] > div': {
        padding: '0 !important',
        position: 'static !important',
      },
    };

    this.video = ({ overlayActive, overlayColor, overlayOpacity }) => ({
      ...this.wrapper,
      ':after': {
        content: "''",
        position: 'absolute',
        background: overlayActive
          ? hexToRgbA(overlayColor, overlayOpacity / 100)
          : 'transparent',
        borderRadius: '5px',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    });
  })()
);
