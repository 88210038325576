import { Container, Grid } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Video } from './Video';

import { Schema } from './VideoBlock.schema';
import { themed } from './VideoBlock.theme';

export const VideoBlock = withInView(
  themed(({ theme, cms, inView }) => {
    const { section, video, ctaText, ctaStyle } = cms;
    const { videoDtHeight, videoMobHeight } = { ...video };

    const videoHeight = [
      videoMobHeight ? `${videoMobHeight}px` : '280px',
      videoDtHeight ? `${videoDtHeight}px` : '650px',
    ];

    return (
      <Section section={section} cms={cms}>
        {/* Regular slider for hero media and contained content */}
        <Container
          data-comp={VideoBlock.displayName}
          sx={{
            ...theme.videoWrapper,
            px: [
              section?.mobile?.container === 'padded' ? '32px' : 0,
              section?.desktop?.container === 'padded' ? '32px' : 0,
            ],
          }}
        >
          {video ? (
            <Grid
              data-comp="video-wrapper"
              sx={{
                position: 'relative',
                maxHeight: videoHeight,
                width: '100%',
                height: 0,
                /**
                 * For human readability, the ratio is expressed as
                 * width / height, so we need to invert it.
                 */
                paddingBottom: `${(1 / (16 / 9)) * 100}%`,
              }}
            >
              {inView && (
                <Video
                  videoSources={[video?.src]}
                  videoTitles={[video?.title]}
                  autoPlay={false}
                  posterSources={[
                    video?.coverMobPhoto?.src,
                    video?.coverDtPhoto?.src,
                  ]}
                  showPlayButton
                  playButtonText={ctaText}
                  playButtonStyle={{
                    variant: `buttons.${ctaStyle}`,
                    ...theme.playButtonStyle,
                  }}
                  playIconStyle={{ height: 24, width: 24 }}
                  videoStyles={{
                    objectFit: 'cover',
                  }}
                />
              )}
            </Grid>
          ) : null}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

VideoBlock.displayName = 'VideoBlock';
VideoBlock.Schema = Schema;
