import { useMemo, forwardRef } from 'react';
import { Grid } from 'theme-ui';

import { JunipStarsIcon } from './JunipStarsIcon';

const fullStar = {
  key: 'star-full',
  label: 'Full Star',
};
const emptyStar = {
  key: 'star-empty',
  label: 'Empty Star',
};
const halfStar = {
  key: 'star-half-empty',
  label: 'Half Star',
};

export const JunipStarsIcons = forwardRef(
  (
    {
      averageScore,
      emptyColor = 'text',
      fullColor = 'text',
      width = '13px',
      ...props
    },
    ref
  ) => {
    const stars = useMemo(() => {
      if (typeof averageScore === 'undefined') return null;

      return [...Array(5).keys()].map((index) => {
        const diff = averageScore - index;
        if (diff >= 0.75) {
          return fullStar;
        }
        if (diff >= 0.25) {
          return halfStar;
        }
        return emptyStar;
      });
    }, [averageScore]);

    return (
      <Grid
        data-comp={JunipStarsIcons.displayName}
        ref={ref}
        variant="flex.row.start.center"
        {...props}
        sx={{
          gridTemplateColumns: 'repeat(5, 1fr)',
          columnGap: '2px',
          ...props.sx,
        }}
      >
        {stars?.map(({ key, label }, index) => {
          return (
            <JunipStarsIcon
              key={index}
              starKey={key}
              label={label}
              emptyColor={emptyColor}
              fullColor={fullColor}
              width={width}
            />
          );
        })}
      </Grid>
    );
  }
);

JunipStarsIcons.displayName = 'JunipStarsIcons';
