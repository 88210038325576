import { useEffect, useState, useRef } from 'react';

export const useNearScreen = ({
  distance = '100px',
  externalRef,
  once = true,
} = {}) => {
  const [isNearScreen, setIsNearScreen] = useState(false);
  const fromRef = useRef();

  useEffect(() => {
    const elementRef = externalRef ? externalRef.current : fromRef.current;
    const onChange = (entries, observer) => {
      const el = entries[0];
      if (el.isIntersecting) {
        setIsNearScreen(true);
        if (once) observer.disconnect();
      } else if (!once) setIsNearScreen(false);
    };

    const observer = new IntersectionObserver(onChange, {
      rootMargin: distance,
    });

    if (elementRef) observer.observe(elementRef);

    return () => {
      if (observer) observer.disconnect();
    };
  });

  return { isNearScreen, fromRef };
};
