import store, { useRecoilValueLoadable } from '@store';

/*
  results: returns the API search results when term changes.
  because it's an async call any component that uses this useSearch hook
  must be wrapped on <Suspense />
*/
export const useSearchResults = () => {
  const results = useRecoilValueLoadable(store.results$);
  const foundProducts = useRecoilValueLoadable(store.foundProducts$);
  const foundCollections = useRecoilValueLoadable(store.foundCollections$);

  if (
    results.state === 'hasValue' &&
    foundProducts.state === 'hasValue' &&
    foundCollections.state === 'hasValue'
  ) {
    return [
      {
        results: results.contents,
        foundProducts: foundProducts.contents,
        foundCollections: foundCollections.contents,
      },
      {},
    ];
  }

  return [{ results: null, foundProducts: null, foundCollections: null }, {}];
};
