import { useCallback, useMemo, useState, useEffect } from 'react';
import { Box } from 'theme-ui';
import { useInView } from 'react-intersection-observer';
import {
  useLocalizedVariant,
  useProductByHandle,
} from '@backpackjs/storefront';

import store, { useRecoilValue } from '@store';

import {
  useAddToCart,
  useDataLayerActions,
  useTrackClickIntelliSuggest,
} from '@hooks';
import { Image } from './Image';
import { Details } from './Details';
import { themed } from './ProductItem.theme';

export const ProductItem = themed(
  ({
    theme,
    product: passedProduct,
    withAddBtn = false,
    customDetails,
    displayQuickAdd,
    activeVariant,
    viewPerRow,
    onItemClick = () => null,
    index,
    showBadges,
    badgeVariant,
    isSearchPage,
    searchTerm,
    invertImages,
    ...rawProps
  }) => {
    const hiddenGwpProducts = useRecoilValue(store.hiddenGwpProducts);
    const selectedProductItem = useRecoilValue(store.selectedProductItem);
    const [isQuickAddActive, setQuickAddIsActive] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const { inputRef, ...props } = rawProps;
    const isGiftCard = passedProduct.handle === 'aloha-gift-card';
    const { intellisuggestTrackClick } =
      useTrackClickIntelliSuggest(passedProduct);
    const { sendClickProductItemEvent } = useDataLayerActions();

    const { ref, inView } = useInView({
      rootMargin: '400px',
      triggerOnce: true,
    });

    const { product: fetchedFullProduct } = useProductByHandle({
      handle: passedProduct?.handle,
      fetchOnMount: inView,
    });
    const initialProduct =
      fetchedFullProduct || (passedProduct?.loaded ? passedProduct : null);
    const { localized } = useLocalizedVariant({
      variant: initialProduct?.variants?.[0],
    });

    const [{ isSoldOut }, { addToCart }] = useAddToCart({
      product: initialProduct,
      selectedVariant: initialProduct?.variants?.[0],
    });

    const handleClick = useCallback(() => {
      intellisuggestTrackClick();
      sendClickProductItemEvent({
        isSearchResult: isSearchPage,
        listIndex: index,
        localized,
        product: initialProduct,
        searchTerm,
        selectedVariant: initialProduct?.variants?.[0],
      });
    }, [initialProduct?.id, index, localized]);

    const hasMultipleVariants = useMemo(() => {
      return initialProduct?.options?.some((option) => {
        return option.values.length > 1;
      });
    }, [initialProduct?.id]);

    const selectedVariant = initialProduct?.variants?.[0];

    useEffect(() => {
      if (
        !isQuickAddActive &&
        selectedProductItem === selectedVariant?.product?.handle
      ) {
        setQuickAddIsActive(true);
      } else {
        setQuickAddIsActive(false);
      }
    }, [selectedProductItem, isClicked]);

    useEffect(() => {
      let isActiveTimeout;
      if (isQuickAddActive) {
        isActiveTimeout = setTimeout(() => setQuickAddIsActive(false), 4000);
      }
      return () => {
        clearTimeout(isActiveTimeout);
      };
    }, [isQuickAddActive, selectedProductItem]);

    if (
      hiddenGwpProducts?.some(
        (_product) => _product?.handle === passedProduct?.handle
      )
    )
      return null;

    return (
      <Box
        data-comp={ProductItem.displayName}
        onClick={(event) => onItemClick(event, index)}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        ref={ref}
        {...props}
        sx={{
          ...theme.wrapper,
          ...props.sx,
        }}
      >
        <Image
          addToCart={addToCart}
          fullProduct={initialProduct}
          selectedVariant={selectedVariant}
          customDetails={customDetails}
          displayQuickAdd={displayQuickAdd}
          activeVariant={activeVariant}
          handleClick={handleClick}
          isGiftCard={isGiftCard}
          hasMultipleVariants={hasMultipleVariants}
          isSoldOut={isSoldOut}
          isQuickAddActive={isQuickAddActive}
          isHovered={isHovered}
          invertImages={invertImages}
          isClicked={isClicked}
          setIsClicked={setIsClicked}
        />

        <Details
          fullProduct={initialProduct}
          withAddBtn={withAddBtn}
          handleClick={handleClick}
          isGiftCard={isGiftCard}
          hasMultipleVariants={hasMultipleVariants}
        />
      </Box>
    );
  }
);

ProductItem.displayName = 'ProductItem';
