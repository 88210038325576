import { useEffect, useState } from 'react';
import { Box, Container, ThemeProvider } from 'theme-ui';
import {
  useRouter,
  useStorefrontInit,
  useCartAddDiscountUrl,
  useSettings,
} from '@backpackjs/storefront';

import {
  Header,
  Footer,
  Overlay,
  CartSearchSidebarShell,
  Fueled,
  Modal,
  SeoPageSchema,
  Link,
} from '@snippets';
import {
  useTrackSettings,
  useSetViewportHeightCssVar,
  useInitWishlist,
  useDataLayerEventListeners,
  useHistoryState,
  useCartIntelliSuggest,
  useCartPromo,
  useFriendBuy,
  useShopifyAnalytics,
  useScrollToSection,
  usePackCookie,
} from '@hooks';
import StorefrontHead from '../head';
import StorefrontTheme from '../theme';

function StorefrontLayout({ ContentForLayout, ...props }) {
  const { asPath } = useRouter();

  const settings = useSettings();

  // initialize customer, cart, shop and markets
  useStorefrontInit();

  // adds --viewport-height css var to the :root
  useSetViewportHeightCssVar();

  // auto applies cart discount found on the landing url
  useCartAddDiscountUrl();

  // track siteSetting changes in recoil
  useTrackSettings();

  // initialize wishlist
  useInitWishlist();

  // topical fix for back button not loading the previous page
  useHistoryState();

  // send intellisuggest event on cart change
  useCartIntelliSuggest();

  // sets global vars for cart
  useCartPromo();

  // tracks customer - updates friendbuy key in localStorage to authenticate customer
  useFriendBuy();

  // send page view and add to cart events to Shopify analytics
  useShopifyAnalytics({ collection: props.collection, product: props.product });

  // use Pack Digital 1st-party cookie.
  usePackCookie();

  useDataLayerEventListeners({
    pageTitle: props.pageTitle,
    template: props.template,
  });

  useScrollToSection();
  const isBrowser = typeof window !== 'undefined';
  const url = isBrowser ? new URL(window.location.href) : undefined;

  useEffect(() => {
    const { pathname } = window.location;
    const handle = pathname.split('/').pop();

    const shouldHide = settings?.noIndex?.handles.some(
      (element) => element.handle === handle
    );

    const metaTags = document.querySelectorAll(
      'meta[name="robots"], meta[name="googlebot"]'
    );

    metaTags.forEach((tag) => {
      if (shouldHide) {
        if (tag.getAttribute('content') !== 'noindex,nofollow') {
          tag.setAttribute('content', 'noindex,nofollow');
        }
      } else if (tag.getAttribute('content') !== 'index,follow') {
        tag.setAttribute('content', 'index,follow');
      }
    });
  }, [settings, url]);

  return (
    <ThemeProvider theme={StorefrontTheme}>
      {/* Hidden link - for accessibility */}
      <Link
        href="/pages/accessibility"
        aria-label="Click to view our Accessibility Statement"
        sx={{
          variant: 'text.buttonText',
          display: 'block !important',
          border: '0px',
          clip: 'rect(0 0 0 0)',
          height: '1px',
          width: '1px',
          margin: '-1px',
          padding: '0px',
          overflow: 'hidden',
          position: 'absolute',
          '&:focus': {
            padding: '1rem',
            position: 'fixed',
            top: '10px',
            left: '10px',
            bg: 'white',
            color: 'black',
            zIndex: 101,
            height: 'auto',
            width: 'auto',
            clip: 'auto',
          },
        }}
      >
        Accessibility
      </Link>

      {/* <head> links, scripts... */}
      <StorefrontHead {...props} />

      <Fueled />

      <SeoPageSchema {...props} />

      <Header />

      {/* main content */}
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight:
            'calc(var(--viewport-height) - var(--header-height) - var(--promoBar-height))',
          alignItems: 'stretch',
        }}
      >
        <Container
          as="main"
          role="main"
          sx={{
            flexGrow: 1,
            flexShrink: 0,
          }}
        >
          <ContentForLayout {...props} key={asPath} />
        </Container>

        <Box sx={{ flexShrink: 0 }}>
          <Footer />
        </Box>
      </Box>

      <Overlay />
      {/*
        This is empty sidebar shell, the content's js
        is dynamically loaded only when opened dynamic
      */}
      <CartSearchSidebarShell />

      <Modal />
    </ThemeProvider>
  );
}

// eslint-disable-next-line import/no-default-export
export default StorefrontLayout;
