import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      pb: 10,
      pt: [14, 18],
      color: 'text',
      bg: 'lightestGray',
      minHeight: '250px',
    };

    this.content = {
      variant: 'layout.content',
      // px: [10, 12, 14],
      maxWidth: '1360px',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: [15, 18],
    };

    this.grid = {
      width: '100%',
      gridTemplateColumns: ['1fr', null, '3fr 1fr'],
      gridTemplateAreas: ['"menu" "email"', null, '"menu email"'],
      rowGap: 11,
    };
  })()
);
