import { useState, useEffect, useCallback } from 'react';

export function useVisibleProductRecItems({
  profileData,
  swiper,
  loop = true,
}) {
  const [mounted, setMounted] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [newVisibleProduct, setNewVisibleProduct] = useState(0);

  const setActiveIndexOnSlideChange = useCallback(() => {
    const onSlideChange = (_swiper) => {
      setActiveIndex(_swiper.realIndex);
    };
    if (swiper && swiper.on) {
      swiper.on('slideChange', onSlideChange);
    }
    return () => {
      if (swiper && swiper.off) {
        swiper.off('slideChange', onSlideChange);
      }
    };
  }, [swiper]);

  const setVisibleProductsOnProfileInView = useCallback(() => {
    if (!swiper || mounted || !profileData?.results?.length) return;
    const { realIndex } = swiper;
    const { centeredSlides } = swiper.params;
    const slidesPerView = Math.ceil(swiper.params.slidesPerView);
    const totalSlides = profileData.results.length;

    let numSlidesBefore = 0;
    let numSlidesAfter = slidesPerView - 1;
    let totalIndexes = slidesPerView;

    if (centeredSlides) {
      if (loop) {
        const halfSlidesCount = Math.ceil((slidesPerView - 1) / 2);
        numSlidesBefore = halfSlidesCount;
        numSlidesAfter = halfSlidesCount;
      } else {
        numSlidesAfter = Math.ceil((slidesPerView - 1) / 2);
      }
    }

    if (loop) {
      totalIndexes = numSlidesBefore + numSlidesAfter + 1;
    }

    const firstIndex = realIndex - numSlidesBefore;
    const indexes = new Array(totalIndexes)
      .fill()
      .map((_, i) => firstIndex + i);
    const realIndexes = indexes.reduce((arr, index) => {
      if (index < 0) return [...arr, totalSlides + index];
      if (index >= totalSlides) {
        if (loop) return [...arr, index - totalSlides];
        return arr;
      }
      return [...arr, index];
    }, []);

    const _visibleProducts = realIndexes.map((index) => {
      return profileData.results[index];
    });

    const _visibleProductsUniqueId = _visibleProducts.filter(
      (item, index, array) => {
        return array.findIndex((element) => element.id === item.id) === index;
      }
    );

    setVisibleProducts(_visibleProductsUniqueId);

    setMounted(true);
  }, [profileData?.results, swiper]);

  const setVisibleProductsOnSlideChange = useCallback(() => {
    if (!mounted || !swiper || !swiper?.params || !profileData?.results?.length)
      return;
    const { realIndex } = swiper;
    const { centeredSlides } = swiper.params;
    const slidesPerView = Math.ceil(swiper.params.slidesPerView);
    const totalSlides = profileData.results.length;

    let numSlidesBefore = 0;
    let numSlidesAfter = slidesPerView - 1;
    let totalIndexes = slidesPerView;

    if (centeredSlides) {
      if (loop) {
        const halfSlidesCount = Math.ceil((slidesPerView - 1) / 2);
        numSlidesBefore = halfSlidesCount;
        numSlidesAfter = halfSlidesCount;
      }
    }

    if (loop) {
      totalIndexes = numSlidesBefore + numSlidesAfter + 1;
    }

    const firstIndex = realIndex - numSlidesBefore;
    const indexes = new Array(totalIndexes)
      .fill()
      .map((_, i) => firstIndex + i);
    const realIndexes = indexes.reduce((arr, index) => {
      if (index < 0) return [...arr, totalSlides + index];
      if (index >= totalSlides) {
        if (loop) return [...arr, index - totalSlides];
        return arr;
      }
      return [...arr, index];
    }, []);

    const _visibleProducts = realIndexes.map((index) => {
      return profileData.results[index];
    });
    const _visibleProductsUniqueId = _visibleProducts.filter(
      (item, index, array) => {
        return array.findIndex((element) => element.id === item.id) === index;
      }
    );

    setVisibleProducts(_visibleProductsUniqueId);
    if (realIndexes.slice(-1) > 3) {
      setNewVisibleProduct([profileData.results[realIndexes.slice(-1)]]);
    } else {
      setNewVisibleProduct(null);
    }
  }, [activeIndex]);

  useEffect(() => {
    setActiveIndexOnSlideChange();
  }, [swiper]);

  useEffect(() => {
    setVisibleProductsOnSlideChange();
  }, [activeIndex]);

  useEffect(() => {
    setVisibleProductsOnProfileInView();
  }, [profileData?.results, swiper]);

  return { visibleProducts, newVisibleProduct };
}
