import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.grid = (gridDisplay, gridDisplayDt) => ({
      display: 'grid',
      columnGap: [10],
      rowGap: [11, 12],
      gridTemplateColumns: [
        gridDisplay === '1x1' ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)',
        'repeat(2, 1fr)',
        gridDisplayDt === '2x2' ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)',
      ],
    });

    this.bottom = (ctaAlignMobile) => ({
      width: '100%',
      justifyContent: [
        ctaAlignMobile === 'left'
          ? 'flex-start'
          : ctaAlignMobile === 'right'
          ? 'flex-end'
          : 'center',
        null,
        'flex-end',
      ],
    });

    this.button = {
      mt: [12, 14],
      mx: 0,
    };
  })()
);
