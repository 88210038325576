import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = ({ background }) => ({
      height: '40px',
      overflow: 'hidden',
      position: 'relative',
      backgroundColor: background || '#000000',
    });

    this.messageTextWrapper = {
      display: 'flex',
      flexShrink: 0,
      position: 'relative',
      animation: 'marquee 30s infinite linear',
      '@keyframes marquee': {
        '0%': {
          transform: 'translate3d(0px, 0px, 0px)',
        },
        '100%': {
          transform: 'translate3d(-100%, 0px, 0px)',
        },
      },
    };

    this.text = (color) => ({
      fontSize: 1,
      fontWeight: 700,
      textTransform: 'uppercase',
      lineHeight: '1',
      color: color || 'ffffff',
      mx: '30px',
    });
  })()
);
