import { useEffect } from 'react';
import Script from 'next/script';

export function FueledAttentiveScript({ withKey: attentiveShopId }) {
  useEffect(() => {
    const identify = (event) => {
      // Identify a user
      if (
        event.detail.user_properties?.customer_phone ||
        event.detail.user_properties?.customer_email
      ) {
        window.attentive?.analytics?.identify({
          phone: event.detail.user_properties?.customer_phone,
          email: event.detail.user_properties?.customer_email,
        });
      }
    };

    const viewItemListener = (event) => {
      identify(event);

      const [product] = event.detail.ecommerce.detail.products;

      window.attentive?.analytics?.productView({
        items: [
          {
            productId: product.product_id,
            productVariantId: product.variant_id,
            name: product.name,
            productImage: product.image,
            category: product.category,
            price: {
              value: product.price,
              currency: event.detail.ecommerce.currency_code,
            },
            quantity: 1,
          },
        ],
      });
    };

    const addToCartListener = (event) => {
      identify(event);

      const [product] = event.detail.ecommerce.add.products;

      window.attentive?.analytics?.addToCart({
        items: [
          {
            productId: product.product_id,
            productVariantId: product.variant_id,
            name: product.name,
            productImage: product.image,
            category: product.category,
            price: {
              value: product.price,
              currency: event.detail.ecommerce.currency_code,
            },
            quantity: 1,
          },
        ],
      });
    };

    window.addEventListener('dl_view_item', viewItemListener);
    window.addEventListener('dl_add_to_cart', addToCartListener);

    return () => {
      window.removeEventListener('dl_view_item', viewItemListener);
      window.removeEventListener('dl_add_to_cart', addToCartListener);
    };
  }, []);

  const inlineScript = `
    !function(w){
      w.attn_d0x0b_evt=[];
      var n=w.attentive={analytics:{}},a=["enable","disable","track","pageView","productView","addToCart","purchase"];
      function e(n){return function(){w.attn_d0x0b_evt.push({func:n,args:arguments})}}
      for(var i=0,c=a.length;i<c;i++)n.analytics[a[i]]=e(a[i])
    }(window);
  `;

  return (
    <>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{ __html: inlineScript }}
      />
      <Script
        strategy="afterInteractive"
        src={`https://cdn.attn.tv/${attentiveShopId}/dtag.js`}
      />
    </>
  );
}
