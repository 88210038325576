import {
  convertAlignToFlex,
  parseContentPosition,
  pxWithFullBleed,
  calcResponsiveHeight,
} from './common';
import { formatLocalePrice } from './formatLocalePrice';
import { generateId } from './generateId';
import { isBrowser } from './isBrowser';
import { loading } from './loading';
import { shopifySrcFilter } from './shopifySrcFilter';
import { withInView } from './withInView';
import { getValidId } from './getValidateId';
import {
  fetchProductFromHandle,
  fetchProductsFromHandles,
  fetchProductsFromIds,
} from './product';

export {
  calcResponsiveHeight,
  convertAlignToFlex,
  formatLocalePrice,
  generateId,
  isBrowser,
  loading,
  parseContentPosition,
  pxWithFullBleed,
  shopifySrcFilter,
  withInView,
  getValidId,
  fetchProductFromHandle,
  fetchProductsFromHandles,
  fetchProductsFromIds,
};
