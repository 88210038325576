import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
} from 'swiper';
import { Box } from 'theme-ui';

import { Slide } from '../Slide';
import { themed } from './Slider.theme';

// import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

export const Slider = themed(
  ({
    theme,
    cmsId,
    activeIndex,
    setSwiper,
    showContentSlider,
    slider,
    slides,
    swiper,
  }) => {
    const defaultParams = {
      centeredSlides: false,
      fadeEffect: { crossFade: true },
      freeMode: false,
      loop: false,
      navigation: false,
      pagination: {
        el: `.swiper-pagination-${cmsId}`,
        type: 'bullets',
        clickable: true,
      },
      slidesPerGroup: 1,
      slidesPerView: 1,
      spaceBetween: 0,
      watchOverflow: true,
    };

    return (
      <Box
        data-comp={Slider.displayName}
        sx={{
          ...theme.slider,
          '.swiper-pagination-bullet-active': {
            bg: slider?.activeBulletColor || 'text',
          },
        }}
      >
        <Swiper
          {...defaultParams}
          autoplay={slider?.autoplay ? { delay: slider.delay || 5000 } : false}
          effect={slider?.effect === 'fade' ? 'fade' : null}
          onSwiper={setSwiper}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          {slides?.map((slide, index) => (
            <SwiperSlide key={index}>
              <Slide
                slide={slide}
                isSlider
                showContentSlider={showContentSlider}
                visible={slider?.effect !== 'fade' || index === activeIndex}
              />
            </SwiperSlide>
          ))}

          {/* Bullets nav */}
          <div
            className={`swiper-pagination-${cmsId} swiper-pagination`}
            aria-label={Slider.paginationAriaLabel}
            sx={theme.bullets}
          />
        </Swiper>
      </Box>
    );
  }
);

Slider.displayName = 'Slider';
Slider.paginationAriaLabel = 'Hero slide pagination';
