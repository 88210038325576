import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'theme-ui';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y } from 'swiper';

import { ProductItem } from '@snippets';

import { Navigation } from './Navigation';
import { useProductSwiperParams } from './useProductSwiperParams';
import { themed } from './ProductSwiper.theme';

export const ProductSwiper = themed(
  ({
    theme,
    products,
    params = {},
    isProductRecs,
    displayQuickAdd,
    swiper: passedSwiper,
    setSwiper: setPassedSwiper,
    onItemClick,
    onNavigationClick,
    isSuperCollection = false,
    children,
    inputRef,
    ...props
  }) => {
    const defaultParams = useProductSwiperParams({ isProductRecs });
    const [localSwiper, setLocalSwiper] = useState(null);

    const swiper = passedSwiper || localSwiper;
    const setSwiper = setPassedSwiper || setLocalSwiper;

    return (
      <Box
        data-comp={ProductSwiper.displayName}
        role="list"
        {...props}
        sx={{
          ...theme.swiper,
          ...props.sx,
        }}
      >
        <Swiper
          onSwiper={setSwiper}
          {...defaultParams}
          {...params}
          modules={[A11y]}
          a11y={{ enabled: false }} // TODO: this module enables autofocus on swiper causing a glitch on quick add to cart
        >
          {products?.map((item, index) => {
            const product = isProductRecs ? item : item.product;
            if (!product?.handle) return null;
            return (
              <SwiperSlide key={product.handle + index}>
                <ProductItem
                  index={index}
                  product={product}
                  showBadges={item?.showBadge}
                  badgeVariant={item?.badgeStyle}
                  onItemClick={onItemClick}
                  displayQuickAdd={displayQuickAdd}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        {products?.length > 4 && isSuperCollection && (
          <Navigation swiper={swiper} onNavigationClick={onNavigationClick} />
        )}
      </Box>
    );
  }
);

ProductSwiper.displayName = 'ProductSwiper';
ProductSwiper.propTypes = {
  products: PropTypes.array,
  params: PropTypes.object,
  isProductRecs: PropTypes.bool,
};
