import { Link, Svg } from '@snippets';

import { themed } from '../Menu.theme';

export const CtaWishlist = themed(({ theme, ...rawProps }) => {
  const { inputRef, ...props } = rawProps;

  return (
    <Link
      data-comp={CtaWishlist.displayName}
      {...props}
      href="/pages/wishlist"
      sx={{
        ...props.sx,
        ...theme.cta,
        display: ['none', 'none', 'block'],
      }}
    >
      <Svg
        alt="Open wishlist"
        src="/svgs/heart.svg#heart"
        sx={{ ...theme.cta.icon }}
        viewBox="0 0 24 24"
      />
    </Link>
  );
});

CtaWishlist.displayName = 'CtaWishlist';
