import { align, section } from '@settings/common';

export function Schema() {
  return {
    label: 'HTML',
    key: 'html',
    fields: [
      {
        label: 'HTML',
        name: 'html',
        component: 'html',
      },
      {
        label: 'Content Settings',
        name: 'content',
        component: 'group',
        description: 'Content alignment, text alignment',
        fields: [
          {
            label: 'Content Alignment',
            name: 'contentAlign',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
          {
            label: 'Text Alignment',
            name: 'textAlign',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
        ],
        defaultValue: {
          contentAlign: align.value.center,
          textAlign: align.value.center,
        },
      },
      section,
    ],
  };
}
