import { useState, useRef } from 'react';
import { Container, Heading, Box } from 'theme-ui';

import { Section, Markdown } from '@snippets';

import { Slider } from './Slider';
import { Navigation } from './Slider/Navigation';

import { themed } from './VisualNavigation.theme';
import { Schema } from './VisualNavigation.schema';

export const VisualNavigation = themed(({ cms, theme }) => {
  const { heading, body, tiles, image, section } = cms;
  const [swiper, setSwiper] = useState(null);

  const sliderRef = useRef();

  return (
    <Section section={section} cms={cms}>
      <Container data-comp={VisualNavigation.displayName}>
        <Box sx={theme.header}>
          {heading && (
            <Heading
              as="h1"
              variant="text.h3"
              sx={{ ...theme.heading, color: section?.color || 'black' }}
            >
              {heading}
            </Heading>
          )}

          {body && <Markdown text={body} sx={theme.body} />}
        </Box>

        <Box sx={theme.wrapper}>
          <Slider
            image={image}
            tiles={tiles}
            swiper={swiper}
            setSwiper={setSwiper}
            ref={sliderRef}
          />
        </Box>

        {tiles?.length > 1 && (
          <Navigation swiper={swiper} sliderRef={sliderRef} />
        )}
      </Container>
    </Section>
  );
});

VisualNavigation.displayName = 'VisualNavigation';
VisualNavigation.Schema = Schema;
