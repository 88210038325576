import { useEffect } from 'react';

// topical fix for back button not loading the previous page
export const useHistoryState = () => {
  useEffect(() => {
    const onHistoryBack = () => {
      // window history state
      const stateUrl = window.history.state.url || '';
      const stateUrlParams = new URLSearchParams(
        stateUrl.split('?').pop() || ''
      );
      const stateHandle = stateUrlParams.get('handle');
      const statePathname = stateUrl.split('/')[1] || '';

      // window location
      const pathname = window.location.pathname.split('/')[1] || '';
      const handle = window.location.pathname.split('/')[2] || '';

      const mismatchedPathname = pathname !== statePathname;
      const mismatchedHandle =
        stateHandle !== null ? stateHandle !== handle : false;

      // reload page if location and history state don't match
      if (mismatchedPathname || mismatchedHandle) {
        window.location.reload();
      }
    };
    window.addEventListener('popstate', onHistoryBack);
    return () => {
      window.removeEventListener('popstate', onHistoryBack);
    };
  }, []);
};
