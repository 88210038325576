import { section } from '@settings/common';

export function Schema({ product }) {
  if (!product) return null;

  return {
    label: 'Reviews List',
    key: 'ReviewsList',
    fields: [
      {
        component: 'toggle',
        label: 'Hide / show reviews',
        name: 'showReviews',
        toggleLabels: {
          true: 'Yes',
          false: 'No',
        },
        defaultValue: true,
      },
      {
        name: 'heading',
        label: 'Heading',
        component: 'text',
        defaultValue: 'Reviews',
      },
      section,
    ],
  };
}
