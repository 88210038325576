import { Container, Flex, Heading } from 'theme-ui';

import { Section, Markdown, Picture } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './IconRow.schema';
import { themed } from './IconRow.theme';

export const IconRow = withInView(
  themed(({ theme, cms, inView }) => {
    const { section, text, icons } = cms;
    const { textSize } = { ...text };

    return (
      <Section section={section} cms={cms}>
        <Container data-comp={IconRow.displayName} sx={theme.wrapper}>
          {inView && (
            <Flex as="ul" variant="flex.row.between" sx={theme.iconTiles}>
              {icons?.map((item, index) => {
                const {
                  heading,
                  headingColor,
                  description,
                  descriptionColor,
                  icon,
                  iconWidth,
                } = { ...item };

                return (
                  <Flex
                    as="li"
                    key={index}
                    variant="flex.column.center"
                    sx={theme.iconTile}
                  >
                    {icon?.src && (
                      <Picture
                        alt={heading}
                        images={[
                          {
                            src: icon.src,
                            width: (iconWidth || 60) * 2,
                            ratio: 1,
                          },
                        ]}
                        sx={{
                          width: iconWidth || 60,
                          mb: 8,
                        }}
                      />
                    )}

                    {heading && (
                      <Heading
                        sx={{
                          fontSize: textSize === 'large' ? 11 : 8,
                          fontWeight: 700,
                          lineHeight: 1,
                          color: headingColor || 'black',
                          mb: textSize === 'large' ? 0 : 4,
                        }}
                      >
                        {heading}
                      </Heading>
                    )}

                    {description && (
                      <Markdown
                        sx={{
                          ...theme.description,
                          fontSize: textSize === 'large' ? 8 : 7,
                          color: descriptionColor || '#959595',
                        }}
                        text={description}
                      />
                    )}
                  </Flex>
                );
              })}
            </Flex>
          )}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

IconRow.displayName = 'IconRow';
IconRow.Schema = Schema;
