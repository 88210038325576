import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      variant: 'flex.column.start',
      width: '100%',
      display: 'inline-flex',
      color: 'darkGray',
    };

    this.type = {
      variant: 'text.label',
      fontSize: [0, 1],
      minHeight: '10px',
    };

    this.title = {
      fontFamily: 'body',
      fontSize: [1, 3],
      lineHeight: ['16px', '22px'],
      pr: 6,
      flex: 1,
      py: 2,
    };

    this.button = {
      variant: 'buttons.underlineLink',
      mt: 6,
      display: ['block', 'none'],
    };

    this.price = {
      regular: {
        variant: 'text.sm',
        fontSize: ['10px', 1],
        fontWeight: 400,
        color: 'text',
      },
      lineThrough: {
        variant: 'text.sm',
        textDecoration: 'line-through',
        mr: 4,
        color: 'mediumGray',
      },
      spinner: {
        height: '16px',
        width: '16px',
        color: 'gray',
      },
    };
  })()
);
