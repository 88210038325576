import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.outerWrapper = {
      fontFamily: 'body',
    };
    this.top = {
      mb: '40px',
      textAlign: 'center',
    };
    this.heading = {
      variant: 'text.h2',
      mb: 4,
    };
    this.description = {
      margin: '0 auto',
    };
    this.button = {
      top: ' 50%',
      height: '56px',
      width: '56px',
      minWidth: '30px',
      opacity: 1,
      borderRadius: '50%',
      transform: 'translate(-50%)',
    };
    this.wrapper = {
      overflow: 'hidden',
      '.fs-has-links::after': {
        color: '#ffffff',
        content: '"SHOP IT"',
        padding: '3px 6px',
        textDecoration: 'underline',
      },
      '.fs-wrapper.fs-slider_v2_5-feed.fs-timeline': {
        scrollPaddingLeft: ['5vw', '2vw'],
      },
      '.fs-slider_v2_5  .fs-entry-container': {
        width: ['45% !important', '24% !important'],
        paddingTop: ['initial', '25%!important'],
        height: '0 !important',
        minHeight: '160px',
      },
      '.fs-slider-prev-button': {
        ...this.button,
        left: '2vw',
      },
      '.fs-slider-next-button': {
        ...this.button,
        right: '-1vw',
      },
      '.fs-wrapper': {
        height: 'auto',
      },
      '.fs-wrapper div.fs-text-container .fs-entry-title, div.fs-detail-title':
        {
          fontFamily: 'Times New Roman, serif',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
        },
      'div.fs-text-container .fs-entry-date, div.fs-detail-container .fs-post-info, div.fs-wrapper div.fs-has-links::after, .fs-text-product, .fs-overlink-text':
        {
          fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
        },
      '.fs-slider-next-button, .fs-slider-prev-button': {
        opacity: '1',
      },
      '.fs-wrapper div.fs-text-container *': {
        color: '#ffffff',
      },
      '.fs-wrapper div.fs-text-container': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        margin: '0px',
      },
      'div.fs-entry-date, div.fs-entry-title': {
        display: 'none',
      },
      '.fs-slider_v2_5 .fs-wrapper div.fs-timeline-entry': {
        margin: '0 10px',
      },
    };
  })()
);
