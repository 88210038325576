import { Box } from 'theme-ui';
import { Section } from '@snippets';
import Script from 'next/script';

import { Schema } from './FriendBuyCustomWidget.schema';

export function FriendBuyCustomWidget({ cms }) {
  const { friendbuyClassName, section } = cms;

  return (
    <>
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          window["friendbuyAPI"] = friendbuyAPI = window["friendbuyAPI"] || [];
          friendbuyAPI.push([
            "track",
            "page",
            {name: "friends"},
          ]);
          `,
        }}
      />

      <Section section={section} cms={cms}>
        <Box
          data-comp={FriendBuyCustomWidget.displayName}
          className={friendbuyClassName?.replace(/\./g, '')}
        />
      </Section>
    </>
  );
}

FriendBuyCustomWidget.displayName = 'FriendBuyCustomWidget';
FriendBuyCustomWidget.Schema = Schema;
