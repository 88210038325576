import { useState } from 'react';
import { Flex, Box, Heading, Container } from 'theme-ui';

import { Section, Link } from '@snippets';

import { Tab } from './Tab';

import { themed } from './InteractiveHalfMedia.theme';
import { Schema } from './InteractiveHalfMedia.schema';

const ALIGNMENT = {
  right: 'flex-end',
  center: 'center',
  left: 'flex-start',
};

export const InteractiveHalfMedia = themed(({ theme, cms }) => {
  const { heading, content, defaultContent, media, tabs, section } = cms;

  const {
    location: locationDt,
    locationMob,
    ratio: ratioDt,
    ratioMob,
  } = { ...media };

  const imageLeft = locationDt === 'left'; // desktop
  const imageTop = locationMob === 'top'; // mobile

  const textAlign = [content?.alignmentMob, content?.alignment];

  const [activeTab, setActiveTab] = useState(null);

  return (
    <Section section={section} cms={cms}>
      <Container
        data-comp={InteractiveHalfMedia.displayName}
        sx={{
          ...theme.wrapper,
          gridTemplateAreas: [
            imageTop ? '"media" "content"' : '"content" "media"',
            imageLeft ? '"media content"' : '"content media"',
          ],
        }}
      >
        <Flex
          variant="flex.column.center.center"
          sx={theme.block({ gridArea: 'content' })}
        >
          <Flex
            variant="flex.column"
            sx={{
              ...theme.content,
              alignItems: [
                ALIGNMENT[content?.alignmentMob || 'left'],
                ALIGNMENT[content?.alignment || 'left'],
              ],
              textAlign,
            }}
          >
            <Heading
              as="h2"
              sx={{ ...theme.heading, color: section?.color || 'black' }}
            >
              {heading}
            </Heading>

            <Flex variant="flex.column" as="ul" sx={theme.headingList}>
              {tabs?.map(({ heading, link }, index) => {
                return (
                  <Box as="li" key={index}>
                    <Link href={link?.url} tabIndex="-1" sx={theme.link}>
                      <Heading
                        as="h3"
                        variant="text.h1"
                        onMouseEnter={() => {
                          setActiveTab(index);
                        }}
                        onMouseLeave={() => {
                          setActiveTab(null);
                        }}
                        sx={{
                          ...theme.tabHeading,
                          color: section?.color || 'black',
                        }}
                      >
                        {heading}
                      </Heading>
                    </Link>
                  </Box>
                );
              })}
            </Flex>
          </Flex>
        </Flex>

        <Box sx={theme.block({ gridArea: 'media', ratioDt, ratioMob })}>
          {activeTab === null && <Tab tab={defaultContent} />}

          {tabs?.map((tab, index) => {
            const isActive = activeTab === index;

            if (!isActive) return null;

            return <Tab tab={tab} />;
          })}
        </Box>
      </Container>
    </Section>
  );
});

InteractiveHalfMedia.displayName = 'InteractiveHalfMedia';
InteractiveHalfMedia.Schema = Schema;
