import { useMemo, Children, cloneElement } from 'react';
import { useLocalizedOrderTotals } from '@backpackjs/storefront';
import { Box } from 'theme-ui';

import { formatAmountItem } from './formatAmountItem';

export function LocaleOrderTotals({ order, children, ...props }) {
  try {
    if (!order) return null;

    const { localized } = useLocalizedOrderTotals({
      order: { ...order },
    });

    const Components = useMemo(() => {
      let PriceComp = null;
      let LoaderComp = null;
      let ErrorComp = null;

      Children.forEach(children, (child) => {
        const displayName =
          child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName ||
          child?.type?.displayName;

        switch (displayName) {
          case 'Locale.Price': {
            PriceComp = localized
              ? cloneElement(child, {
                  key: 'Locale.Price',
                  currentTotalTax: formatAmountItem(localized.currentTotalTax),
                  currentTotalDuties: formatAmountItem(
                    localized.currentTotalDuties
                  ),
                  currentSubtotalPrice: formatAmountItem(
                    localized.currentSubtotalPrice
                  ),
                  totalPriceV2: formatAmountItem(localized.totalPriceV2),
                  subtotalPriceV2: formatAmountItem(localized.subtotalPriceV2),
                  totalRefundedV2: formatAmountItem(localized.totalRefundedV2),
                  totalShippingPriceV2: formatAmountItem(
                    localized.totalShippingPriceV2
                  ),
                  totalTaxV2: formatAmountItem(localized.totalTaxV2),
                  locale: localized.locale,
                  ...child.props,
                })
              : null;
            break;
          }

          case 'Locale.Error':
            ErrorComp = null;
            break;

          case 'Locale.Loader':
            LoaderComp = null;
            break;

          default:
            break;
        }
      });

      // active components
      return [PriceComp, LoaderComp, ErrorComp].filter(Boolean);
    }, [localized?.totalPriceV2?.amount, localized?.subtotalPriceV2?.amount]);

    return (
      <Box
        data-comp={LocaleOrderTotals.displayName}
        {...props}
        sx={{ ...props.sx }}
      >
        {Components.map((Component) => Component)}
      </Box>
    );
  } catch (error) {
    return null;
  }
}

LocaleOrderTotals.displayName = 'Locale.OrderTotals';
