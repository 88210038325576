export default {
  label: 'Cart Settings',
  name: 'cart',
  component: 'group',
  fields: [
    {
      label: 'Cart Empty Settings',
      name: 'empty',
      component: 'group',
      fields: [
        {
          label: 'Message',
          name: 'message',
          component: 'markdown',
          defaultValue: 'your cart is empty',
        },
        {
          label: 'Link',
          name: 'cta',
          component: 'link',
          defaultValue: {
            text: 'shop best sellers',
            url: '/collections/shop-all',
          },
        },
      ],
    },
    {
      label: 'Promotions',
      name: 'promo',
      component: 'group',
      fields: [
        {
          name: 'enabled',
          label: 'Enable Promotions',
          component: 'toggle',
          description:
            'Enabling promotions require publishing the cart script associated with the promo type selected below.',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
        {
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          name: 'type',
          label: 'Promo Type',
          options: [
            {
              value: 'percentage-off',
              label: 'Percentage Off',
            },
            {
              value: 'gift-with-purchase',
              label: 'Gift With Purchase',
            },
          ],
          defaultValue: 'percentage-off',
        },
        {
          label: 'Percentage Off Settings',
          name: 'percentageOff',
          component: 'group',
          fields: [
            {
              name: 'tiers',
              label: 'Tiers',
              component: 'group-list',
              itemProps: {
                label: '${{item.threshold}}',
              },
              fields: [
                {
                  name: 'enabled',
                  label: 'Enable',
                  component: 'toggle',
                  toggleLabels: {
                    true: 'On',
                    false: 'Off',
                  },
                },
                {
                  label: 'Icon',
                  name: 'icon',
                  component: 'image',
                },
                {
                  label: 'Spend Threshold',
                  name: 'threshold',
                  component: 'number',
                  description:
                    'User must reach minimum cart value/threshold for discount to be applied',
                  defaultValue: 0,
                },
                {
                  label: 'Lock tier message',
                  name: 'lockTier',
                  component: 'text',
                  description:
                    'Denote placement of the remaining spend with a "$"',
                  defaultValue: 'Spend $50 More To Unlock 10% OFF!',
                },
                {
                  label: 'Complete message',
                  description: 'Message to display when tier is complete.',
                  name: 'unlockTier',
                  component: 'text',
                  defaultValue: "You've Unlocked 10% OFF!",
                },
              ],
              defaultValue: [
                {
                  icon: {
                    src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/icon-discount-10.png?v=1691009226',
                  },
                  threshold: 150,
                  lockTier: 'Spend $ More To Unlock <b>10% OFF!</b>',
                  unlockTier:
                    "You've Unlocked <b>10% OFF! + FREE SHIPPING!</b>",
                },
                {
                  icon: {
                    src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/icon-discount-20.png?v=1691009232',
                  },
                  threshold: 200,
                  lockTier: 'Spend $ More To Unlock <b>20% OFF!</b>',
                  unlockTier:
                    "You've Unlocked <b>20% OFF! + FREE SHIPPING!</b>",
                },
              ],
            },
          ],
        },
        {
          label: 'GWP Settings',
          name: 'gwp',
          component: 'group',
          fields: [
            {
              label: 'Icon',
              name: 'icon',
              component: 'image',
            },
            {
              label: 'Spend Threshold',
              name: 'threshold',
              component: 'number',
              description:
                'User must reach minimum cart value/threshold for gwp to be applied',
              defaultValue: 0,
            },
            {
              label: 'Lock tier message',
              name: 'lockTier',
              component: 'text',
              description: 'Denote placement of the remaining spend with a "$"',
              defaultValue: 'Spend $ More To Unlock 10% OFF!',
            },
            {
              label: 'Complete message',
              description: 'Message to display when tier is complete.',
              name: 'unlockTier',
              component: 'text',
              defaultValue: "You've Unlocked 10% OFF!",
            },
            {
              name: 'products',
              label: 'Products List',
              component: 'group-list',
              itemProps: {
                label: '{{item.product.handle}}',
              },
              fields: [
                {
                  name: 'product',
                  component: 'productSearch',
                  label: 'Product',
                },
              ],
            },
          ],
          defaultValue: {
            icon: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/icon-gif_a5fcdaea-e03c-4883-8fdc-0c35af50ba47.png?v=1693339992',
            },
            threshold: 200,
            lockTier: 'Spend $ More To Unlock A <b>FREE GIFT!</b>',
            unlockTier: "You've Unlocked A <b>FREE GIFT + FREE SHIPPING!</b>",
          },
        },
      ],
    },
    {
      label: 'Cart Threshold',
      name: 'cartThreshold',
      component: 'number',
      description:
        'User must reach minimum cart value/threshold for checkout button to be enabled',
      defaultValue: 0,
    },
    {
      label: 'Add-On Cart Threshold',
      name: 'addOnThreshold',
      component: 'number',
      description:
        'If cart contains a line item with a tag of add-on, this threshold value will override the Cart Threshold value above. User must reach minimum cart value/threshold for checkout button to be enabled',
      defaultValue: 20,
    },
    {
      label: 'Shipping, taxes text',
      name: 'shippingText',
      component: 'text',
      defaultValue: 'Discounts, shipping & taxes calculated at checkout',
    },
    {
      label: 'Disclaimer text',
      name: 'disclaimerText',
      component: 'textarea',
      defaultValue:
        'ATTENTION: Once your order is submitted, we cannot cancel or make changes to your order. Please review your order and shipping address before placing your order.',
    },
    {
      name: 'enableDisclaimerOnSidebar',
      label: 'Enable Cart Disclaimer (Inline Cart)',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      name: 'enableDisclaimerOnPage',
      label: 'Enable Cart Disclaimer (Cart Page)',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      label: 'Free shipping meter Settings',
      name: 'freeShipping',
      component: 'group',
      fields: [
        {
          name: 'enabled',
          label: 'Enable free shipping meter',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Icon',
          name: 'icon',
          component: 'image',
        },
        {
          name: 'spend',
          component: 'number',
          label: 'Qualifying spend (USD)',
        },
        {
          name: 'message',
          component: 'text',
          label: 'Message',
          description: 'Denote placement of the remaining spend with a "$"',
        },
        {
          name: 'messageQualified',
          component: 'text',
          label: 'Qualified message',
        },
      ],
      defaultValue: {
        enabled: true,
        icon: {
          src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/freeshipping_icon_56faa975-161a-4959-a1d7-5f2f7bde26b7.png?v=1693440983',
        },
        spend: 50,
        message: `You're only $ away from free shipping!`,
        messageQualified: `You're qualified for free shipping!`,
      },
    },
    {
      label: 'Upsell Settings',
      name: 'upsell',
      component: 'group',
      fields: [
        {
          name: 'enabled',
          label: 'Enable upsell',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          name: 'message',
          component: 'text',
          label: 'Message w/ active cart',
        },
        {
          name: 'messageEmpty',
          component: 'text',
          label: 'Message w/ empty cart',
        },
        {
          name: 'limit',
          component: 'number',
          label: 'Number of upsell products',
        },
        {
          name: 'tag',
          label: 'Searchspring Product Recs Tag',
          component: 'text',
          description:
            'The product recommendations profile tag taken from Searchspring admin, e.g. "cross-sell", "similar", "home", "recently-viewed"',
          defaultValue: 'view-cart',
        },
      ],
      defaultValue: {
        enabled: true,
        message: 'Complete your collection with these favorites',
        messageEmpty: 'Start out with these fan favorites!',
        limit: 4,
      },
    },
  ],
};
