import { useEffect, useCallback, useMemo, useState } from 'react';
import store, {
  useRecoilCallback,
  useSetRecoilState,
  useGotoRecoilSnapshot,
} from '@store';

import {
  useCartAddItems,
  useProductInventoryByHandle,
} from '@backpackjs/storefront';

import { usePostScript } from '@hooks';

export const useAddToCart = ({ selectedVariant, product }) => {
  const setLastItemAdded = useSetRecoilState(store.lastItemAdded);
  const setPlayItemAddedNotification = useSetRecoilState(
    store.playItemAddedNotification
  );
  const gotoSnapshot = useGotoRecoilSnapshot();
  const [isAddStarted, setIsAddStarted] = useState(false);
  const { cartAddItems, ...addItemStatus } = useCartAddItems();
  const { inventory, ...inventoryStatus } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle || product?.handle,
    withQuantity: true,
  });
  const { postScriptAddToCart } = usePostScript({ selectedVariant, product });

  const id = useMemo(() => {
    return Object.keys({ ...inventory?.variants })[0]?.startsWith('gid://')
      ? selectedVariant?.id
      : selectedVariant?.storefrontId;
  }, [inventory?.variants, selectedVariant?.id]);

  // use live inventory data
  const variantInventory = inventory ? inventory?.variants?.[id] : null;

  const isDigitalProduct =
    product?.handle === 'aloha-gift-card' ||
    product?.handle === 'maui-donation';

  const isSoldOut =
    inventoryStatus.success &&
    (!variantInventory ||
      (variantInventory.quantityAvailable <= 0 && !isDigitalProduct));

  const isPreOrder =
    isSoldOut && selectedVariant?.inventoryPolicy === 'CONTINUE';

  const forcedPreOrder = product?.tags.includes('allow-preorder');

  const toggleCartSidebar = useRecoilCallback(({ snapshot }) => async () => {
    const release = snapshot.retain();
    try {
      const updatedState = snapshot
        .map(({ set }) => set(store.modal, null))
        .map(({ set }) => set(store.overlay, true))
        .map(({ set }) => set(store.sidebar, 'cart'));

      // update state
      gotoSnapshot(updatedState);
    } finally {
      release();
    }
  });

  // add oneTime and subs
  const addToCart = useCallback(
    async ({
      quantity = 1,
      itemAttributes = [],
      openCart = true,
      callback,
    } = {}) => {
      if (!selectedVariant || isSoldOut || isAddStarted) return;
      setIsAddStarted(true);

      const attributes = [...itemAttributes];

      if (forcedPreOrder) {
        attributes.push({ key: 'pre_order', value: 'true' });
      }

      const item = {
        merchandiseId: selectedVariant.storefrontId,
        quantity,
        ...(attributes.length > 0 && { attributes }),
      };
      setLastItemAdded(item);

      const cart = await cartAddItems([item]);

      setPlayItemAddedNotification(true);

      setTimeout(() => {
        setIsAddStarted(false);
        setPlayItemAddedNotification(false);
      }, 2000);

      if (cart) {
        postScriptAddToCart();
        if (openCart) {
          toggleCartSidebar();
        }
        if (typeof callback === 'function') {
          callback();
        }
      }
    },
    [selectedVariant?.id, isSoldOut, isAddStarted]
  );

  const addItemStatusByProduct = useMemo(() => {
    return isAddStarted
      ? addItemStatus
      : {
          ...addItemStatus,
          started: false,
          success: false,
          finished: false,
        };
  }, [isAddStarted, addItemStatus]);

  useEffect(() => {
    return () => {
      setIsAddStarted(false);
    };
  }, []);

  return [
    {
      isPreOrder,
      isSoldOut,
      // no longer needed
      selectedPlan: null,
      status: {
        addItem: addItemStatusByProduct,
        inventory,
        inventoryStatus,
      },
    },
    {
      addToCart,
      toggleCartSidebar,
    },
  ];
};
