import { Box } from 'theme-ui';
import dynamic from 'next/dynamic';

import { useMenuDrawer } from './useMenuDrawer';
import { themed } from './MenuDrawer.theme';

const MenuDrawer = dynamic(
  () => import('./MenuDrawer.jsx').then((mod) => mod.MenuDrawer),
  { ssr: false, suspense: false }
);

/**
 * Desktop menu drawer shell — hovered item sub items
 */
export const MenuDrawerShell = themed(({ theme, props }) => {
  const [{ hovered }] = useMenuDrawer();

  return (
    <Box
      data-comp={MenuDrawerShell.displayName}
      as="aside"
      {...props}
      sx={{
        ...theme.shell,
        ...(hovered ? theme.shell.open : theme.shell.closed),
      }}
    >
      <MenuDrawer hovered={hovered} />
    </Box>
  );
});

MenuDrawerShell.displayName = 'MenuDrawerShell';
