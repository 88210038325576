import React, { useMemo, Children, cloneElement } from 'react';
import { Box } from 'theme-ui';

import { Header } from './Header';
import { Body } from './Body';
import { themed } from './Accordions.theme';

export const Accordion = themed(
  ({
    theme,
    borderColor,
    children,
    iconColor,
    index,
    isOpen,
    maxHeight,
    minHeight,
    setOpenIndex,
    textColor,
    openIcon,
    collapseIcon,
    iconWidth,
    ...rawProps
  }) => {
    const { inputRef, ...props } = rawProps;
    const AccordionContent = useMemo(() => {
      let Header = null;
      let Body = null;

      Children.forEach(children, (child) => {
        const displayName =
          child?.props?.__EMOTION_TYPE_PLEASE_DO_NOT_USE__?.displayName ||
          child?.type?.displayName;

        switch (displayName) {
          case 'Header':
            const { inputRef, ...headerProps } = child.props;
            Header = cloneElement(child, {
              iconColor,
              index,
              isOpen,
              minHeight,
              setOpenIndex,
              openIcon,
              collapseIcon,
              iconWidth,
              ...headerProps,
            });
            break;

          case 'Body':
            Body = cloneElement(child, {
              index,
              ...child.props,
            });
            break;

          default:
            break;
        }
      });

      return (
        <>
          {Header}
          {Body}
        </>
      );
    }, [
      Children.count(children),
      children,
      iconColor,
      index,
      isOpen,
      minHeight,
      setOpenIndex,
    ]);

    return (
      <Box
        data-comp={Accordion.displayName}
        role="tablist"
        {...props}
        sx={{
          ...theme.accordion,
          borderColor,
          maxHeight: isOpen ? maxHeight : minHeight,
          ...props.sx,
        }}
      >
        {AccordionContent}
      </Box>
    );
  }
);

Accordion.displayName = 'Accordion';
Accordion.Header = Header;
Accordion.Body = Body;
