import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.heading = {
      fontSize: '36px',
      fontWeight: '700',
      textAlign: 'center',
      mb: 14,
    };

    this.wrapper = {
      maxWidth: '1000px',
      mx: 'auto',
    };
  })()
);
