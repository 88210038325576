import { useState, useEffect } from 'react';
import { Container, Box, Spinner } from 'theme-ui';
import dynamic from 'next/dynamic';

import { Section, VideoPlayButton } from '@snippets';
import { withInView } from '@utils';
import { useModal } from '@hooks';

import { Schema } from './HalfMedia.schema';
import { themed } from './HalfMedia.theme';

const Content = dynamic(
  () => import('./Content').then((module) => module.Content),
  { ssr: false, suspense: false }
);
const Image = dynamic(() => import('./Image').then((module) => module.Image), {
  ssr: false,
  suspense: false,
});

const Video = dynamic(() => import('./Video').then((module) => module.Video), {
  ssr: false,
  suspense: false,
});

export const HalfMedia = withInView(
  themed(({ theme, cms, inView }) => {
    const { media, content, section } = cms;
    const videoPoster = {
      imageMob: { src: media?.video?.videoPosterMb?.src },
      image: { src: media?.video?.videoPosterDt?.src },
    };

    const disableVideoModal = media?.video?.disableVideoModal;
    const autoplay = media?.video?.autoplay || true;

    const imageLeft = media?.location === 'left'; // desktop
    const imageTop = media?.locationMob === 'top'; // mobile

    const mediaTypeMb = media?.mediaTypeMb || 'image';
    const mediaTypeDt = media?.mediaTypeDt || 'image';

    const [{ modal }, { openModal, closeModal }] = useModal();

    const [showVideo, setShowVideo] = useState(disableVideoModal);

    const MODAL_WIDTHS = {
      '56.25%': '80vw',
      '100%': '80vh',
      '125%': '64vh',
      '177.78%': '45vh',
    };

    const toggleVideoModal = () => {
      openModal(
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            pb: [
              media?.video?.aspectRatioMb || '56.25%',
              media?.video?.aspectRatioDt || '56.25%',
            ],
          }}
        >
          <Spinner sx={theme.spinner} />

          <Video
            isModalVideo={!autoplay}
            autoPlay={autoplay}
            posterSources={[
              media?.video?.videoPosterMb?.src,
              media?.video?.videoPosterDt?.src,
            ]}
            videoSources={[media?.video?.videoMb, media?.video?.videoDt]}
            videoTitles={[media?.video?.titleMb, media?.video?.titleDt]}
          />
        </Box>,
        {
          container: {
            width: [
              MODAL_WIDTHS[media?.video?.aspectRatioMb || '56.25%'],
              MODAL_WIDTHS[media?.video?.aspectRatioDt || '56.25%'],
            ],
            maxWidth: '80vw',
            minWidth: 'unset !important',
            p: '0 !important',
          },
          close: {
            top: '-16px',
            right: '-22px',
          },
        }
      );
    };

    useEffect(() => {
      const modalEl = document.getElementById('Modal');
      modalEl.style.backgroundColor = 'transparent';
      modalEl.style.padding = 0;
      modalEl.style.paddingTop = '10px';
      return () => {
        modalEl.style.backgroundColor = 'white';
        modalEl.style.padding = 32;
        modalEl.style.paddingTop = '10px';
      };
    }, [modal]);
    // console.log({ showVideo, disableVideoModal });
    return (
      <Section section={section} cms={cms}>
        <Container
          data-comp={HalfMedia.displayName}
          sx={{
            ...theme.grid,
            gridTemplateAreas: [
              imageTop ? '"media" "content"' : '"content" "media"',
              imageLeft ? '"media content"' : '"content media"',
            ],
            minHeight: [
              `${section?.mobile?.minHeight}px`,
              `${section?.desktop?.minHeight}px`,
            ],
            maxHeight: [
              `${section?.mobile?.maxHeight}px`,
              `${section?.desktop?.maxHeight}px`,
            ],
          }}
        >
          <Box
            sx={{
              ...theme.media,
              ...(media?.bgColor ? { bg: media?.bgColor } : {}),
              minHeight: [media?.image?.minImageHeightMob || 'auto', 'initial'],
              maxHeight: [media?.image?.maxImageHeightMob || 'auto', 'initial'],
              ':before': {
                content: '""',
                float: 'left',
                pb: [media?.image?.ratioMob || '100%', 0],
                minHeight: [media?.image?.minImageHeightMob || 'auto', 'auto'],
                maxHeight: [media?.image?.maxImageHeightMob || 'auto', 'auto'],
              },
            }}
          >
            {inView && (
              <>
                {/* mobile media */}
                <Box
                  sx={{
                    display: ['block', 'none'],
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {mediaTypeMb === 'image' ? (
                    <>
                      {media?.image?.imageMob?.src && (
                        <Image image={media?.image} />
                      )}
                    </>
                  ) : (
                    <Box
                      sx={{
                        height: '100%',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                    >
                      {showVideo || disableVideoModal ? (
                        <Video
                          autoPlay={autoplay}
                          controls={false}
                          muted
                          posterSources={[media?.video?.videoPosterMb?.src]}
                          videoSources={[media?.video?.videoMb]}
                          videoTitles={[media?.video?.titleMb]}
                          sx={{
                            height: '100%',
                          }}
                        />
                      ) : (
                        <>
                          <Image image={videoPoster} />
                          <VideoPlayButton onClick={() => toggleVideoModal()} />
                        </>
                      )}
                    </Box>
                  )}
                </Box>

                {/* desktop media */}
                <Box
                  sx={{
                    display: ['none', 'block'],
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  {mediaTypeDt === 'image' ? (
                    <>
                      {media?.image?.imageMob?.src && (
                        <Image image={media?.image} />
                      )}
                    </>
                  ) : (
                    <Box
                      sx={{
                        height: '100%',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                    >
                      {showVideo || disableVideoModal ? (
                        <Video
                          autoPlay={autoplay}
                          controls={false}
                          muted
                          posterSources={[media?.video?.videoPosterDt?.src]}
                          videoSources={[media?.video?.videoDt]}
                          videoTitles={[media?.video?.titleDt]}
                          sx={{
                            height: '100%',
                          }}
                        />
                      ) : (
                        <>
                          <Image image={videoPoster} />
                          <VideoPlayButton onClick={() => toggleVideoModal()} />
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>

          <Box
            sx={{
              ...theme.content,
              justifyContent: imageLeft ? 'flex-start' : 'flex-end',
              ':before': {
                content: '""',
                float: 'left',
                pb: [0, media?.image?.ratio || '100%'],
              },
            }}
          >
            {inView && <Content content={content} />}
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true, skip: false }
);

HalfMedia.displayName = 'HalfMedia';
HalfMedia.Schema = Schema;
