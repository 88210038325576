import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.tab = {
      position: 'relative',
      height: '100%',
    };

    this.link = {
      height: '100%',
    };

    this.image = {
      height: '100%',
    };
  })()
);
