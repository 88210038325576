import { useEffect } from 'react';

// sets the CSS var --header-height on mount and resize
export const useSetHeaderHeightCssVar = (
  { headerRef } = { headerRef: null }
) => {
  useEffect(() => {
    const setHeaderHeightCssVar = () => {
      if (!headerRef?.current) {
        console.warn('No header ref...');
        return;
      }
      document.documentElement.style.setProperty(
        '--header-height',
        `${headerRef.current.clientHeight}px`
      );
    };

    window.addEventListener('resize', setHeaderHeightCssVar);

    setHeaderHeightCssVar();

    return () => {
      window.removeEventListener('resize', setHeaderHeightCssVar);
    };
  }, []);
};
