import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrap = {
      fontFamily: 'body',
    };
    this.top = {
      mb: '40px',
      textAlign: 'center',
    };
    this.heading = {
      variant: 'text.h2',
      mb: 4,
    };
    this.description = {
      margin: '0 auto',
    };
    this.button = {
      width: '56px',
      height: '56px',
      top: ' 50%',
      borderRadius: '50%',
      minWidth: '30px',
      transform: 'translate(-50%)',
      opacity: 1,
    };
    this.wrapper = {
      '.fs-has-links::after': {
        content: '"SHOP IT"',
        padding: '3px 6px',
        textDecoration: 'underline',
        color: '#ffffff',
      },
      '.fs-wrapper.fs-slider_v2_5-feed.fs-timeline': {
        scrollPaddingLeft: ['5vw', '2vw'],
      },
      '.fs-slider_v2_5  .fs-entry-container': {
        width: ['45%!important', '24%!important'],
        height: '0 !important',
        paddingTop: ['initial', '25%!important'],
        minHeight: '160px',
      },
      '.fs-slider-prev-button': {
        ...this.button,
        left: '2vw',
      },
      '.fs-slider-next-button': {
        ...this.button,
        right: '-1vw',
      },
    };
  })()
);
