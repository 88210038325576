import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.container = {
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      bottom: 0,
      minWidth: 'unset',
      width: '100%',
      bg: 'white',
      height: ['30px', '56px'],
      left: 0,
      right: 0,
      transition: ' opacity 300ms ease 0s',
    };

    this.textWrapper = {
      textAlign: 'center',
      width: '100%',
      transition: 'opacity 300ms ease 0s',
      display: ['none', 'flex'],
      justifyContent: 'center',
      alignItems: 'center',
    };

    this.firstText = {
      variant: 'text.h6',
      lineHeight: 1.1,
      color: 'black',
      transition: 'opacity 300ms ease 0s',
    };

    this.sizeContainer = {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: 'opacity 300ms',
    };

    this.button = () => ({
      display: 'inline-block',
      minWidth: 0,
      margin: 0,
      px: 2,
      flex: 1,

      textAlign: 'center',
      fontFamily: 'heading',
      fontWeight: 700,
      letterSpacing: '0.08em',
      textTransform: 'uppercase',
      fontSize: ['8px', 1], // 12px
      lineHeight: 1.1,

      border: 0,
      borderRight: (t) => `1px solid ${t.colors.lightGray}`,
      borderRadius: 0,
      cursor: 'pointer',
      color: 'black',
      bg: 'white',
      transition: 'color 0s ease 0s, background-color 300ms ease 0s;',

      '&: last-child': {
        borderRight: 'none',
      },

      '&:hover': {
        bg: colors.darkGray,
        color: 'white',
        borderColor: colors.lightGray,
      },

      '&[disabled]': {
        bg: colors.mediumGray,
        color: 'white',
        borderRight: (t) => `1px solid ${t.colors.lightGray}`,
        '&: last-child': {
          borderRight: 'none',
        },
        ':hover': {
          cursor: 'not-allowed',
          bg: colors.mediumGray,
          borderColor: colors.lightGray,
        },
      },
    });
  })()
);
