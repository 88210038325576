import { Box } from 'theme-ui';
import dynamic from 'next/dynamic';

import { Overlay } from './Overlay';
import { useMenuSidebar } from './useMenuSidebar';
import { themed } from './MenuSidebar.theme';

const MenuSidebar = dynamic(
  () => import('./MenuSidebar.jsx').then((mod) => mod.MenuSidebar),
  { ssr: false, suspense: false }
);

/**
 * Mobile menu sidebar shell
 */
export const MenuSidebarShell = themed(({ theme, ...rawProps }) => {
  const { inputRef, ...props } = rawProps;
  const [{ menuSidebar }] = useMenuSidebar();

  return (
    <>
      <Overlay />

      <Box
        as="aside"
        data-comp={MenuSidebarShell.displayName}
        {...props}
        sx={{
          ...props.sx,
          ...theme.shell,
          transform: `translateX(${menuSidebar ? '0' : '-100%'})`,
        }}
      >
        {menuSidebar ? <MenuSidebar /> : null}
      </Box>
    </>
  );
});

MenuSidebarShell.displayName = 'MenuSidebarShell';
