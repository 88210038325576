import { Container, Flex, Box } from 'theme-ui';
import dynamic from 'next/dynamic';

import { withInView, convertAlignToFlex } from '@utils';
import { Section, Link } from '@snippets';

import { Slide } from './Slider/Slide';

import { Schema } from './TileSlider.schema';
import { themed } from './TileSlider.theme';

const Header = dynamic(
  () => import('./Header').then((module) => module.Header),
  { ssr: false, suspense: false }
);
const Slider = dynamic(
  () => import('./Slider').then((module) => module.Slider),
  { ssr: false, suspense: false }
);

export const TileSlider = withInView(
  themed(({ theme, cms, inView }) => {
    const { displayType, gridDisplay, tiles, cta, content, section } = cms;

    return (
      <Section section={section} cms={cms}>
        <Container data-comp={TileSlider.displayName} sx={theme.wrapper}>
          <Box
            data-comp="TileSliderAnchor"
            sx={{
              width: '2px',
              height: '2px',
              position: 'absolute',
              bottom: 'calc(100% + 100px)',
              left: 0,
              visibility: 'hidden',
            }}
          />
          <Box
            sx={{
              px: [section?.mobile?.container === 'container' ? 10 : 0, 0, 0],
            }}
          >
            {inView && <Header content={content} />}
          </Box>

          <Box sx={theme.slider}>
            {inView && (
              <Box>
                {displayType === 'grid' ? (
                  <Box sx={theme.grid(gridDisplay)}>
                    {tiles.map((tile, index) => {
                      return <Slide key={index} tile={tile} />;
                    })}
                  </Box>
                ) : (
                  <Slider tiles={tiles} />
                )}
              </Box>
            )}
          </Box>

          {cta?.text && (
            <Flex
              sx={{
                display: 'flex',
                width: '100%',
                flexDirection: ['column', null, null, 'row'],
                justifyContent: [
                  'flex-start',
                  null,
                  null,
                  convertAlignToFlex(content?.buttonAlign),
                ],
                alignItems: [
                  convertAlignToFlex(content?.buttonAlign),
                  null,
                  null,
                  'flex-start',
                ],
                px: [section?.mobile?.container === 'container' ? 10 : 0, 0, 0],
              }}
            >
              <Link
                variant={`buttons.${content?.ctaStyle}`}
                href={cta.url}
                sx={theme.button}
              >
                {cta.text}
              </Link>
            </Flex>
          )}
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

TileSlider.displayName = 'TileSlider';
TileSlider.Schema = Schema;
