import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.sidebar = {
      item: {
        variant: 'flex.row.between.center',
        gridTemplateColumns: '65px 1fr .33fr',
        alignItems: 'stretch',
        overflow: 'hidden',
        py: 10,
        px: 7,
        width: '100%',
        gridGap: 10,
        color: 'text',
        position: 'relative',
        ':first-of-type': {
          borderBottom: '1px solid lightGray',
        },

        between: {
          borderBottom: (t) => `1px solid ${t.colors.lightGray}`,
        },
        last: {
          borderBottom: (t) => `1px solid ${t.colors.lightGray}`,
        },
      },

      column: {
        left: {
          variant: 'flex.column.start.start',
          width: '70px',
        },

        // center column  — title, quantity etc..
        center: {
          variant: 'flex.column',
          alignSelf: 'center',
          flex: 1,
        },

        // center column  — title, quantity etc..
        right: {
          variant: 'flex.column.end.between',
          height: '100%',
        },
      },

      // elements
      picture: {
        cursor: 'pointer',
        width: '70px',
        objectFit: 'contain',
        mb: 'auto',
        border: '1px solid',
        borderColor: 'lightGray',
        borderRadius: '6px',
        // mr: 10,
      },

      title: {
        product: {
          variant: 'text.sm',
          mb: 'auto',
          cursor: 'pointer',
        },

        variant: {
          variant: 'text.label',
          mb: 2,
        },

        disclaimer: {
          fontSize: '10px',
          lineHeight: '18px',
          mb: 2,
        },

        preorder: {
          variant: 'text.label',
          lineHeight: '18px',
          mt: 4,
        },

        gwp: {
          variant: 'text.label',
          mt: 2,
        },

        plan: {
          variant: 'text.xs',
        },
      },

      remove: {
        cursor: 'pointer',
        width: '10px',
        height: '10px',
        mb: 'auto',
      },

      deleting: {
        variant: 'flex.row.between.center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        bg: 'rgba(255,255,255,0.5)',

        spinner: {
          width: '30px',
          color: 'mediumGray',
          m: 'auto',
        },
      },
    };
    // cart empty — rendered in /cart
    this.page = {
      item: {
        ...this.sidebar.item,

        gridTemplateColumns: '65px .66fr .33fr',

        between: {
          ...this.sidebar.item.between,
          py: 12,
        },
        ':first-of-type': {
          borderTop: '1px solid lightGray',
          borderBottom: '1px solid lightGray',
        },
        last: {
          ...this.sidebar.item.last,
        },
      },

      // left column  — picture..
      column: {
        left: {
          ...this.sidebar.column.left,
        },

        center: {
          ...this.sidebar.column.center,
        },

        right: {
          ...this.sidebar.column.right,
        },
      },

      // elements
      picture: {
        ...this.sidebar.picture,
        width: '120px',
        mr: 12,
      },
      title: {
        product: {
          variant: 'text.base',
          mb: 'auto',
          cursor: 'pointer',
        },

        variant: {
          variant: 'text.label',
          mb: 2,
        },

        disclaimer: {
          fontSize: '10px',
          lineHeight: '18px',
          mb: 2,
        },

        gwp: {
          variant: 'text.label',
          mt: 2,
        },

        plan: {
          variant: 'text.xs',
        },
      },

      remove: {
        ...this.sidebar.remove,
      },

      deleting: {
        ...this.sidebar.deleting,
      },
    };
  })()
);
