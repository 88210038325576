import { Fragment } from 'react';
import { Flex } from 'theme-ui';

import { themed } from './Content.theme';

export const Content = themed(({ theme, children, ...rawProps }) => {
  const { inputRef, ...props } = rawProps;
  return (
    <Flex data-comp={Content.displayName} {...props} sx={theme.content}>
      <Fragment>{children}</Fragment>
    </Flex>
  );
});

Content.displayName = 'Content';
