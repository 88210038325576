import { useMemo } from 'react';
import { Paragraph, Flex } from 'theme-ui';

import { themed } from './Prices.theme';

export const Prices = themed(({ theme: themes, cart, inSidebar }) => {
  const subtotalAmount =
    Number(cart?.estimatedCost?.subtotalAmount?.amount) || 0;

  const theme = inSidebar ? themes.sidebar : themes.page;

  const price = useMemo(
    () => `$${parseFloat(subtotalAmount).toFixed(2).replace(/\.00$/, '')}`,
    [subtotalAmount]
  );

  const totalBeforeDiscounts = useMemo(() => {
    return cart?.lines?.reduce((carry, line) => {
      const subtotalAmount = Number(
        line?.estimatedCost?.subtotalAmount?.amount
      );

      return carry + parseFloat(subtotalAmount);
    }, 0);
  }, [cart?.updatedAt]).toFixed(2);
  return (
    <Flex data-comp={Prices.displayName} sx={theme.price}>
      <Paragraph sx={theme.price.title}>Subtotal</Paragraph>
      <Flex>
        <Paragraph sx={theme.price.amount}>{price}</Paragraph>
        {totalBeforeDiscounts > subtotalAmount && (
          <Paragraph sx={theme.price.totalAmount}>
            ${totalBeforeDiscounts}
          </Paragraph>
        )}
      </Flex>
    </Flex>
  );
});

Prices.displayName = 'Prices';
