import {
  defaultAccordionItems,
  defaultExpandedItems,
  generateSubMenuList,
  subMenuTypes,
} from './menuDefaults';

export const menu = {
  label: 'Menu',
  name: 'menu',
  component: 'group-list',
  itemProps: {
    label: '{{item.link.text}}',
  },
  defaultItem: (item) => ({
    link: {
      url: '',
      text: '',
    },
    links: [
      {
        link: {
          url: '/collections/shop-all',
          text: 'All',
        },
        subMenuType: subMenuTypes.value.accordion,
        subMenuItems: defaultAccordionItems,
      },
      {
        link: {
          url: '/collections/fan-favorites',
          text: 'Fan Favorites',
        },
        subMenuType: subMenuTypes.value.link,
      },
      {
        link: {
          url: '/collections/new',
          text: 'new',
        },
        subMenuType: subMenuTypes.value.link,
      },
      {
        link: {
          url: '/collections/aloha-hale',
          text: 'aloha hale',
        },
        subMenuType: subMenuTypes.value.link,
      },
      {
        link: {
          url: '/collections/collections',
          text: 'collections',
        },
        subMenuType: subMenuTypes.value.expanded,
        subMenuItems: defaultExpandedItems,
      },
    ],
  }),
  fields: [
    {
      label: 'Hide On Mobile',
      description: 'Hide from Mobile Menu Tabs',
      name: 'hideOnMobile',
      component: 'toggle',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      label: 'Link',
      name: 'link',
      component: 'link',
    },
    {
      label: 'Link - Color',
      name: 'linkColor',
      component: 'color',
    },
    // sub links with category
    {
      label: 'Sub Links Heading',
      name: 'subLinksHeading',
      description: 'Heading for linklist below. Will be used for Mega Menu',
      component: 'text',
    },
    {
      label: 'Sub Links',
      name: 'links',
      component: 'group-list',
      itemProps: {
        label: '{{item.link.text}}',
      },
      defaultItem: (item) => ({
        category: {
          url: '',
          text: '',
        },
        link: {
          url: '',
          text: '',
        },
      }),
      fields: [
        {
          label: 'Use as Mega Menu',
          description:
            'If enabled, sub menu will be used as Mega Menu for desktop',
          name: 'enableMegaMenu',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
        {
          label: 'Hide On Desktop',
          description: 'Hide on Desktop',
          name: 'hideOnDesktop',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
        {
          label: 'Sub link',
          name: 'link',
          component: 'link',
        },
        {
          label: 'Sub link - Color',
          name: 'linkColor',
          component: 'color',
        },
        {
          name: 'subMenuType',
          component: 'select',
          label: 'Sub Menu Type',
          options: subMenuTypes.options,
          defaultValue: subMenuTypes.value.link,
        },
        {
          label: 'Sub Menu Heading',
          name: 'subMenuHeading',
          description:
            'Sub Menu Heading for linklist below. Will be used for Mega Menu',
          component: 'text',
        },
        generateSubMenuList('subMenu'),
      ],
      defaultValue: [],
    },
    // bottom sliders
    {
      label: 'Sliders',
      name: 'sliders',
      component: 'group-list',
      itemProps: {
        label: '{{item.heading}}',
      },
      defaultItem: (item) => ({
        heading: 'slider heading',
      }),
      fields: [
        {
          label: 'Slider heading',
          name: 'heading',
          component: 'text',
        },
        generateSubMenuList('slider'),
      ],
      defaultValue: [],
    },
  ],
};

/**
 * Example format
 */

/*
[
  {
    'link': {
      'text': 'SHOP ALL',
      'url': '/collections/shop-all'
    },
    'links': [
      {
        'category': 'By Category',
        'link': {
          'text': 'Supplements',
          'url': '/collections/shop-cbd-supplements/'
        }
      },
      {
        'category': 'By Category',
        'link': {
          'text': 'Bodycare',
          'url': '/collections/shop-cbd-bodycare/'
        }
      },
      {
        'category': 'By Category',
        'link': {
          'text': 'Skincare',
          'url': '/collections/shop-skincare/'
        }
      },
      {
        'category': 'By Category',
        'link': {
          'text': 'Gifts & Bundles',
          'url': '/collections/shop-collections/'
        }
      },
      {
        'category': 'By Category',
        'link': {
          'text': 'Tools & Accessories',
          'url': '/collections/shop-tools/'
        }
      },
      {
        'category': 'By Category',
        'link': {
          'text': 'Subscribe & Save',
          'url': '/pages/subscription-and-save/'
        }
      },
      {
        'category': 'By Category',
        'link': {
          'text': 'Gift Card',
          'url': '/products/rise-ai-giftcard/'
        }
      },
      {
        'category': 'By Benefit',
        'link': {
          'text': 'Stress',
          'url': '/collections/shop-stress/'
        }
      },
      {
        'category': 'By Benefit',
        'link': {
          'text': 'Sleep',
          'url': '/collections/shop-sleep/'
        }
      },
      {
        'category': 'By Benefit',
        'link': {
          'text': 'Recovery',
          'url': '/collections/shop-recovery/'
        }
      },
      {
        'category': 'By Benefit',
        'link': {
          'text': 'Mood',
          'url': '/collections/shop-mood/'
        }
      },
      {
        'category': 'By Benefit',
        'link': {
          'text': 'Skin Health',
          'url': '/collections/shop-skin-health/'
        }
      }
    ],
    'medias': [
      {
        'src': '/images/shopall_01.jpg',
        'link': {
          'text': '50% Off CBD Powders',
          'url': '/pages/faq'
        }
      },
      {
        'src': '/images/shopall_02.jpg',
        'link': {
          'text': 'Better Rewards',
          'url': '/pages/sustainability'
        }
      }
    ]
  },
  {
    'link': {
      'text': 'Supplements',
      'url': '/collections/shop-cbd-supplements/'
    },
    'links': [
      {
        'category': '',
        'link': {
          'text': 'All Supplements',
          'url': '/collections/shop-cbd-supplements/'
        }
      },
      {
        'category': '',
        'link': {
          'text': 'Stress',
          'url': '/collections/shop-stress/'
        }
      },
      {
        'category': '',
        'link': {
          'text': 'Sleep',
          'url': '/collections/shop-sleep/'
        }
      },
      {
        'category': '',
        'link': {
          'text': 'Recovery',
          'url': '/collections/shop-recovery/'
        }
      },
      {
        'category': '',
        'link': {
          'text': 'Mood',
          'url': '/collections/shop-mood/'
        }
      }
    ],
    'medias': [
      {
        'src': '/images/shopall_01.jpg',
        'link': {
          'ext': '50% Off CBD Powders',
          'url': '/pages/faq'
        }
      },
      {
        'src': '/images/supplements.jpg',
        'link': {
          'text': 'How To Take CBD: What to Know',
          'url': '/pages/sustainability'
        }
      }
    ]
  },
  {
    'link': {
      'text': 'Bodycare',
      'url': '/collections/shop-cbd-bodycare/'
    }
  },
  {
    'link': {
      'text': 'Skincare',
      'url': '/collections/shop-skincare/'
    }
  },
  {
    'link': {
      'text': 'Subscribe & Save',
      'url': '/pages/subscription-and-save/'
    }
  },
  {
    'link': {
      'text': 'Earn Cash Back',
      'url': '/pages/rewards/'
    }
  },
  {
    'link': {
      'text': 'About',
      'url': '/pages/about-us'
    },
    'links': [
      {
        'category': 'about',
        'link': {
          'text': 'About Us',
          'url': '/pages/about-us'
        }
      },
      {
        'category': 'about',
        'link': {
          'text': 'Our Values',
          'url': '/pages/our-values'
        }
      },
      {
        'category': 'about',
        'link': {
          'text': 'Open Letter',
          'url': '/pages/open-letter'
        }
      },
      {
        'category': 'about',
        'link': {
          'text': 'FAQ',
          'url': '/pages/faq'
        }
      },
      {
        'category': 'standards',
        'link': {
          'text': 'The Prima Way',
          'url': '/pages/prima-way'
        }
      },
      {
        'category': 'standards',
        'link': {
          'text': 'Science',
          'url': '/pages/science'
        }
      },
      {
        'category': 'standards',
        'link': {
          'text': 'Source',
          'url': '/pages/source'
        }
      },
      {
        'category': 'standards',
        'link': {
          'text': 'Sustainability',
          'url': '/pages/sustainability'
        }
      },
      {
        'category': 'standards',
        'link': {
          'text': '100% Clean',
          'url': '/pages/100-clean'
        }
      },
      {
        'category': 'standards',
        'link': {
          'text': 'Testing & Transparency',
          'url': '/pages/batch'
        }
      },
      {
        'category': 'beyond',
        'link': {
          'text': 'Betterment',
          'url': '/pages/betterment'
        }
      },
      {
        'category': 'beyond',
        'link': {
          'text': 'Giving',
          'url': '/pages/giving'
        }
      }
    ],
    'medias': [
      {
        'src': '/images/about_01.jpg',
        'link': {
          'text': 'Frequently asked questions',
          'url': '/pages/faq'
        }
      },
      {
        'src': '/images/about_02.jpg',
        'link': {
          'text': 'Sustainability',
          'url': '/pages/sustainability'
        }
      }
    ]
  }
]
*/
