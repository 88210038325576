import { useState, useEffect, useCallback } from 'react';

export function useShopifyClient({ product }) {
  // console.log('useShopifyClient', { product });
  const [inventories, setInventories] = useState(null);

  const fetchShopifyClient = useCallback(async (action, body) => {
    // console.log(`Fetching ${action} with body:`, body);
    try {
      const response = await fetch(`/api/store`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({ action, ...body }),
      });
      const json = await response.json();
      return json.data;
    } catch (error) {
      console.error(`Error fetching ${action}:`, error);
      return null;
    }
  }, []);

  const fetchInventories = async ({ product }) => {
    // console.log('Fetching inventories');
    const variantsInventories = await fetchShopifyClient('live-all-inventory', {
      product,
      allVariants: true,
    });
    console.log('Inventories fetched:', variantsInventories);
    setInventories(variantsInventories);
  };
  useEffect(() => {
    // console.clear();
    // console.log('Effect running', { product });

    if (!product || !product.variants) {
      // console.log('No product or productVariants available');
      return;
    }

    fetchInventories({ product });
  }, [product?.id, product?.productVariants, fetchShopifyClient]);

  return inventories;
}
