import { section } from '@settings/common';

export function Schema({ page }) {
  return {
    label: 'Referrals Buddy Pass',
    key: 'referrals-bp',
    fields: [
      {
        name: 'alt',
        label: 'Alt',
        component: 'text',
      },
      {
        name: 'image',
        label: 'Image',
        component: 'image',
      },
      section,
    ],
  };
}
