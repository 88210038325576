export default {
  label: 'Sitewide Sale',
  name: 'sitewideSale',
  component: 'group',
  fields: [
    {
      label: 'Enabled',
      name: 'enabled',
      component: 'toggle',
      toggleLabels: {
        true: true,
        false: false,
      },
      defaultValue: false,
    },
    {
      label: 'Collection',
      name: 'collection',
      component: 'collections',
      description: 'Add collection to apply sitewide sale for each product',
    },
    {
      label: 'Product Page',
      name: 'productPage',
      component: 'group',
      fields: [
        {
          label: 'Text Settings',
          name: 'text',
          component: 'group',
          fields: [
            {
              label: 'Message',
              name: 'message',
              component: 'text',
            },
            {
              label: 'Text Color',
              name: 'textColor',
              component: 'color',
            },
            {
              label: 'Border Color',
              name: 'borderColor',
              component: 'color',
            },
            {
              label: 'Background Color',
              name: 'bgColor',
              component: 'color',
            },
            {
              label: 'Background Color Opacity (%)',
              name: 'bgColorOpacity',
              component: 'number',
              description: 'set the opacity of the background in percentage',
              validate(opacity) {
                if (opacity > 100) {
                  window.__bpCMS.alerts.error(`Max 100% opacity`);
                }
                if (opacity < 0) {
                  window.__bpCMS.alerts.error(`Min 0 opacity`);
                }
              },
              defaultValue: 100,
            },
          ],
        },
      ],
    },
    {
      label: 'Product Card',
      name: 'productCard',
      component: 'group',
      fields: [
        {
          label: 'Text Settings',
          name: 'text',
          component: 'group',
          fields: [
            {
              label: 'Message',
              name: 'message',
              component: 'text',
            },
            {
              label: 'Text Color',
              name: 'textColor',
              component: 'color',
            },
            {
              label: 'Border Color',
              name: 'borderColor',
              component: 'color',
            },
            {
              label: 'Background Color',
              name: 'bgColor',
              component: 'color',
            },
            {
              label: 'Background Color Opacity (%)',
              name: 'bgColorOpacity',
              component: 'number',
              description: 'set the opacity of the background in percentage',
              validate(opacity) {
                if (opacity > 100) {
                  window.__bpCMS.alerts.error(`Max 100% opacity`);
                }
                if (opacity < 0) {
                  window.__bpCMS.alerts.error(`Min 0 opacity`);
                }
              },
              defaultValue: 100,
            },
          ],
        },
      ],
    },
    {
      label: 'Cart Line Item',
      name: 'cartLineItem',
      component: 'group',
      fields: [
        {
          label: 'Text Settings',
          name: 'text',
          component: 'group',
          fields: [
            {
              label: 'Message',
              name: 'message',
              component: 'text',
            },
            {
              label: 'Text Color',
              name: 'textColor',
              component: 'color',
            },
            {
              label: 'Border Color',
              name: 'borderColor',
              component: 'color',
            },
            {
              label: 'Background Color',
              name: 'bgColor',
              component: 'color',
            },
            {
              label: 'Background Color Opacity (%)',
              name: 'bgColorOpacity',
              component: 'number',
              description: 'set the opacity of the background in percentage',
              validate(opacity) {
                if (opacity > 100) {
                  window.__bpCMS.alerts.error(`Max 100% opacity`);
                }
                if (opacity < 0) {
                  window.__bpCMS.alerts.error(`Min 0 opacity`);
                }
              },
              defaultValue: 100,
            },
          ],
        },
      ],
    },
  ],
};
