import { create } from '@theme/create';
import { colors } from '@theme/theme.colors';

export const themed = create(
  new (function () {
    this.grid = {
      position: 'relative',
      display: 'grid',
      gridGap: 0,
      gridTemplateColumns: ['1fr', '1fr 1fr'],
    };

    this.media = {
      gridArea: 'media',
      position: 'relative',
      ml: '100',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      maxHeight: 'inherit',
    };

    this.content = {
      gridArea: 'content',
      display: 'flex',
      position: 'relative',
      width: '100%',
      height: '100%',
      maxHeight: 'inherit',
    };
    this.button = {
      position: 'absolute',
      alignItems: 'center',
      left: '24px',
      bottom: '24px',
      fontSize: '12px',
      transition: 'none',
      paddingLeft: '12px',
      paddingRight: '20px',
      minWidth: '152px',
      zIndex: 10,
      backgroundColor: 'white',
      width: 'fit-content',
      height: '48px',
      border: '1px solid',
      borderColor: 'lightGray',
      display: 'flex',
      top: 'initial',
      right: 'initial',
      color: 'darkGray',
      ':before': {
        content: '""',
        width: 0,
        height: 0,
        mr: 8,
        ml: 8,
        borderTop: '8px solid transparent',
        borderBottom: '8px solid transparent',
        borderLeft: `8px solid ${colors.mediumGray}`,
      },
      ':hover': {
        backgroundColor: 'white',
      },
    };

    this.spinner = {
      color: 'white',
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    };
  })()
);
