import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = {
      alignItems: 'center',
      bg: 'lightgrey',
      boxShadow: 'bottom',
      color: 'text', // sets the color of all nav items and icons
      flexDirection: 'column',
      height: 'auto',
      position: 'sticky',
      width: '100%',
      top: 0,
      zIndex: 30,
    };
  })()
);
