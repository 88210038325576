import { useCallback } from 'react';
import { useGotoRecoilSnapshot, useRecoilState } from 'recoil'; // todo expose on @backpackjs/storefront

import store, { useRecoilCallback, useRecoilValue } from '@store';

const DEBUG = true;

export const useMenuDrawer = (cb = () => {}, data = []) => {
  const gotoSnapshot = useGotoRecoilSnapshot();
  const hovered = useRecoilValue(store.hoveredItem);
  const menuDrawer = useRecoilValue(store.menuDrawer);

  // on click, close the drawer and navigate to url
  const closeDrawerAndNavigate = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const release = snapshot.retain();
        try {
          const updatedState = snapshot.map(({ set }) => {
            set(store.hoveredItem, null);
            set(store.hideDrawerContentsTransition, false);
            set(store.menuDrawer, false);
          });

          // update state
          gotoSnapshot(updatedState);
        } catch (error) {
          console.log('error', error.message);
        } finally {
          release();
        }
      }
  );

  // on link hover, clear a previous un-hover timer
  const clearUnHoverTimer = useCallback((id) => {
    if (window.unHover) {
      clearTimeout(window.unHover);
      window.unHover = null;
    }
  }, []);

  const hoverDrawer = useCallback(() => {
    clearUnHoverTimer();
  }, []);

  const hoverItem = useRecoilCallback(({ snapshot }) => async (hoveredItem) => {
    const release = snapshot.retain();

    try {
      let updatedState;

      if (hoveredItem) {
        clearUnHoverTimer();

        const { links = [], products = [], medias = [] } = hoveredItem;
        const hoveredSubLinks = [
          links?.length ?? null,
          products?.length,
          ...medias,
        ].filter(Boolean);
        const hoveredSubLinksCount = hoveredSubLinks.length;

        // if direct link close shell
        if (!hoveredSubLinksCount) {
          updatedState = snapshot.map(({ set }) => {
            set(store.hoveredItem, hoveredItem);
            set(store.hideDrawerContentsTransition, false);
            set(store.menuDrawer, false);
          });
        } else {
          updatedState = snapshot.map(({ set }) => {
            set(store.hoveredItem, hoveredItem);
            set(store.hideDrawerContentsTransition, false);
            set(store.menuDrawer, true);
          });
        }
      }
      // hoveredItem is null (reset)
      else {
        updatedState = snapshot.map(({ set }) => {
          set(store.hoveredItem, hoveredItem);
          set(store.hideDrawerContentsTransition, false);
          set(store.menuDrawer, false);
        });
      }

      // update state
      gotoSnapshot(updatedState);
    } finally {
      release();
    }
  });

  const unHoverItem = useCallback(() => {
    // if we have a previous un-hover we clear it
    clearUnHoverTimer();

    console.log('hi');

    // we set a new un-hover timer, that can be cancelled
    // by the mouseEnter o another link or the MenuDrawer enter
    window.unHover = setTimeout(() => {
      hoverItem(null); // closes the drawer
      clearUnHoverTimer();
    }, 150);
    // console.log('created timer: via unHoverItem', JSON.stringify(window.unHover))
  }, []);

  const unHoverDrawer = useRecoilCallback(({ snapshot }) => () => {
    const release = snapshot.retain();

    // needed to content from drawer before closing drawer
    const updatedState = snapshot.map(({ set }) => {
      set(store.hideDrawerContentsTransition, true);
    });

    // update state
    gotoSnapshot(updatedState);

    // we set a new un-hover timer, that can be cancelled by the mouseEnter
    window.unHover = setTimeout(async function () {
      // console.log('executing timer: unHoverDrawer after 500ms setting hoveredItem to null')
      try {
        hoverItem(null);

        clearUnHoverTimer();
      } finally {
        release();
      }
    }, 150);

    // console.log('created timer: via unHoverDrawer', JSON.stringify(window.unHover))
  });

  return [
    // state
    {
      hovered, // used to trigger the drawer
      menuDrawer, // used for animations
    },
    // actions
    {
      hoverItem,
      hoverDrawer,
      unHoverItem,
      unHoverDrawer,
      closeDrawerAndNavigate,
    },
  ];
};
