import { useMemo, useState, useEffect, useCallback } from 'react';
import { Container, Box } from 'theme-ui';

import { Section } from '@snippets';
import { useBreakpointValue } from '@hooks';
import { calcResponsiveHeight, withInView } from '@utils';

import { Schema } from './Hero.schema';
import { Slide } from './Slide';
import { Slider, ContentSlider } from './Slider';
import { themed } from './Hero.theme';

export const Hero = withInView(
  themed(({ theme, cms }) => {
    const { slider, section, slides } = cms;

    const contentPosition = useBreakpointValue([
      slider?.contentPositionMob,
      slider?.contentPosition,
    ]);
    const [swiper, setSwiper] = useState();
    const [contentSwiper, setContentSwiper] = useState();
    const [activeIndex, setActiveIndex] = useState(0);
    const [showContentSlider, setShowContentSlider] = useState(false);

    useEffect(() => {
      setShowContentSlider(contentPosition === 'relative');
    }, [contentPosition]);

    const mobileMaxHeight = section?.mobile?.maxHeight;
    const desktopMaxHeight = section?.desktop?.maxHeight;
    const mobileAspectRatio = section?.mobile?.aspectRatio;
    const desktopAspectRatio = section?.desktop?.aspectRatio;

    const height = useMemo(() => {
      return [
        !mobileAspectRatio || mobileAspectRatio === 'none'
          ? calcResponsiveHeight({
              maxHeight: mobileMaxHeight,
              viewport: 768,
            })
          : 'auto',
        !desktopAspectRatio || desktopAspectRatio === 'none'
          ? calcResponsiveHeight({
              maxHeight: desktopMaxHeight,
              viewport: 1280,
            })
          : 'auto',
      ];
    }, [
      mobileMaxHeight,
      desktopMaxHeight,
      mobileAspectRatio,
      desktopAspectRatio,
    ]);

    const setActiveIndexAndSlideToOnSlideChange = useCallback(() => {
      if (!swiper || slides?.length < 2) return;
      swiper?.on('slideChange', () => {
        setActiveIndex(swiper?.realIndex);
        contentSwiper?.slideTo(swiper?.realIndex);
      });
    }, [swiper, contentSwiper, slides?.length]);

    useEffect(() => {
      setActiveIndexAndSlideToOnSlideChange();
    }, [swiper, contentSwiper, slides?.length]);

    return (
      <Section section={section} cms={cms}>
        {/* Regular slider for hero media and contained content */}
        <Container
          data-comp={Hero.displayName}
          sx={{
            ...theme.hero,
            height,
            minHeight: [
              section?.mobile?.minHeight
                ? `${section.mobile.minHeight}px`
                : 'unset',
              section?.desktop?.minHeight
                ? `${section.desktop.minHeight}px`
                : 'unset',
            ],
            maxHeight: [
              section?.mobile?.maxHeight
                ? `${section.mobile.maxHeight}px`
                : 'unset',
              section?.desktop?.maxHeight
                ? `${section.desktop.maxHeight}px`
                : 'unset',
            ],
            borderRadius: [
              section?.mobile?.container !== 'container' ? '6px' : '0',
              section?.desktop?.container !== 'container' ? '6px' : '0',
            ],
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: [
                !mobileAspectRatio || mobileAspectRatio === 'none' ? '100%' : 0,
                !desktopAspectRatio || desktopAspectRatio === 'none'
                  ? '100%'
                  : 0,
              ],
              pb: [
                !mobileAspectRatio || mobileAspectRatio === 'none'
                  ? 0
                  : mobileAspectRatio,
                !desktopAspectRatio || desktopAspectRatio === 'none'
                  ? 0
                  : desktopAspectRatio,
              ],
            }}
          >
            <Box sx={theme.innerWrapper}>
              {slides?.length > 1 && (
                <Slider
                  slides={slides}
                  slider={slider}
                  swiper={swiper}
                  setSwiper={setSwiper}
                  showContentSlider={showContentSlider}
                  activeIndex={activeIndex}
                  autoplay
                />
              )}

              {slides?.length === 1 && (
                <Slide
                  slide={slides[0]}
                  showContentSlider={showContentSlider}
                />
              )}
            </Box>
          </Box>
        </Container>

        {/* Slider for content if displayed below hero image */}
        {showContentSlider && (
          <ContentSlider
            activeIndex={activeIndex}
            bgColor={section?.bgColor}
            contentSwiper={contentSwiper}
            setContentSwiper={setContentSwiper}
            showContentSlider={showContentSlider}
            slider={slider}
            slides={slides}
          />
        )}
      </Section>
    );
  }),
  { triggerOnce: true }
);

Hero.displayName = 'Hero';
Hero.Schema = Schema;
