import { button, position, align } from './common';

import { swatches } from './product/swatches';

export default {
  label: 'Collections Settings',
  name: 'collection',
  component: 'group',
  fields: [
    {
      label: 'Filters',
      name: 'filters',
      component: 'group',
      description: 'Enable, custom filters, show option count, swatches',
      fields: [
        {
          label: 'Enable',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Filters',
          name: 'filters',
          component: 'group-list',
          description: `A filter's options are automatically determined by the products in the collection.\n\nNote: a filter will be hidden if there are no options, or only has one option, which has a count equal to the total number of products in the collection.`,
          itemProps: {
            label: '{{item.label}}',
          },
          fields: [
            {
              label: 'Shopify Property',
              name: 'source',
              component: 'radio-group',
              direction: 'horizontal',
              variant: 'radio',
              description:
                'Note: Shopify tags must separate name and value with "::", e.g. "colorfilter::Red"',
              options: [
                { label: 'Tag', value: 'tag' },
                { label: 'Option', value: 'option' },
                { label: 'Product Type', value: 'productType' },
              ],
            },
            {
              label: 'Name',
              name: 'name',
              component: 'text',
              description: `Letter casing must be same as name in Shopify.\nTags e.g. "colorfilter", "material"\nOptions e.g. "Color", "Size"\nNot applicable for Product Type`,
            },
            {
              label: 'Label',
              name: 'label',
              component: 'text',
            },
            {
              label: 'Order Options By',
              name: 'orderValuesBy',
              component: 'radio-group',
              direction: 'horizontal',
              variant: 'radio',
              options: [
                { label: 'Alphabet', value: 'alphabet' },
                { label: 'Number', value: 'number' },
                { label: 'Custom', value: 'custom' },
              ],
            },
            {
              label: 'Custom Order',
              name: 'customOrder',
              component: 'list',
              field: {
                component: 'text',
              },
            },
          ],
          defaultItem: {
            source: 'tag',
            name: 'tag',
            label: 'Tag',
            orderValuesBy: 'alphabet',
          },
          defaultValue: [
            {
              source: 'productType',
              name: '',
              label: 'Product Type',
              orderValuesBy: 'alphabet',
            },
            {
              source: 'option',
              name: 'Size',
              label: 'Size',
              orderValuesBy: 'custom',
              customOrder: [
                'One Size',
                'OS',
                'O/S',
                'XXS',
                'XX-Small',
                'XS',
                'X-Small',
                'S',
                'Small',
                'M',
                'Medium',
                'L',
                'Large',
                'XL',
                'X-Large',
                'XXL',
                'XX-Large',
                'XXXL',
                'XXX-Large',
              ],
            },
          ],
        },
        // redundant since color filter shopify tags are broad colors
        // {
        //   label: 'Color Groups',
        //   name: 'colorGroups',
        //   component: 'group-list',
        //   description: 'Groups of high level colors used for filtering',
        //   itemProps: {
        //     label: '{{item.group}}',
        //   },
        //   fields: [
        //     {
        //       label: 'Color Group',
        //       name: 'group',
        //       component: 'text',
        //       description: 'The name of the color group. i.e "Black"..',
        //     },
        //     {
        //       label: 'Swatch Color',
        //       name: 'color',
        //       component: 'color',
        //     },
        //     {
        //       label: 'Group Colors List',
        //       name: 'colors',
        //       component: 'list',
        //       defaultItem: 'white',
        //       description:
        //         'A list of colors which are part of this color group. The color must be defined in "handle" format. without the color code. i.e black-dark-grey or black-floral or midnight-maroon...',
        //       field: {
        //         component: 'text',
        //       },
        //     },
        //   ],
        // },
        swatches,
      ],
    },
    {
      label: 'Promotion',
      name: 'promotion',
      component: 'group',
      description: 'Promo tile campaigns',
      fields: [
        {
          label: 'Promo Tile Campaigns',
          name: 'campaigns',
          component: 'group-list',
          itemProps: {
            label: '{{item.name}}',
          },
          fields: [
            {
              label: 'Enable',
              name: 'enabled',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Name',
              name: 'name',
              description: 'For read-only purposes',
              component: 'text',
            },
            {
              label: 'Promo Tiles',
              name: 'promoTiles',
              component: 'group-list',
              itemProps: {
                label: '{{item.position}}: {{item.text.heading}}',
              },
              fields: [
                {
                  label: 'Grid Position',
                  name: 'position',
                  component: 'number',
                  description:
                    'Assigns grid order. If Tile Size takes up two columns, please make sure position is divisible by 3',
                },
                {
                  label: 'Background Settings',
                  name: 'background',
                  component: 'group',
                  description:
                    'Background color, image, tile size, overlay color, overlay opacity',
                  fields: [
                    {
                      label: 'Background Color',
                      name: 'bgColor',
                      component: 'color',
                    },
                    {
                      label: 'Image Alt',
                      name: 'alt',
                      component: 'text',
                      description: 'Brief description of image',
                    },
                    {
                      label: 'Image',
                      name: 'image',
                      component: 'image',
                      description: 'Overrides background color',
                    },
                    {
                      name: 'video',
                      description:
                        'Video url must end with .mp4 unless Vimeo\nClose then reopen modal to see change. Leave empty if you want to use image',
                      label: 'Video url',
                      component: 'text',
                    },
                    {
                      name: 'videoPoster',
                      label: 'Video poster',
                      component: 'image',
                    },
                    {
                      label: 'Tile Size',
                      component: 'radio-group',
                      direction: 'horizontal',
                      variant: 'radio',
                      name: 'tileSize',
                      description: 'Spans 1 or 2 columns',
                      options: [
                        {
                          value: '1',
                          label: '1',
                        },
                        {
                          value: '2',
                          label: '2',
                        },
                      ],
                      defaultValue: '1',
                    },
                    {
                      label: 'Overlay Color',
                      name: 'overlayColor',
                      component: 'color',
                      defaultValue: '#000000',
                    },
                    {
                      label: 'Overlay Opacity',
                      name: 'overlayOpacity',
                      component: 'number',
                      defaultValue: 30,
                    },
                  ],
                },
                {
                  label: 'Text Settings',
                  name: 'text',
                  component: 'group',
                  description: 'Heading, subtext, text color',
                  fields: [
                    {
                      label: 'Heading',
                      name: 'heading',
                      component: 'text',
                    },
                    {
                      label: 'Subtext',
                      name: 'subtext',
                      component: 'markdown',
                    },
                    {
                      label: 'Text Color',
                      name: 'textColor',
                      component: 'color',
                    },
                  ],
                },
                {
                  label: 'Content Settings',
                  name: 'content',
                  description: 'Content alignment, content position',
                  component: 'group',
                  fields: [
                    {
                      name: 'alignment',
                      label: 'Content Alignment (desktop)',
                      component: 'radio-group',
                      direction: 'horizontal',
                      variant: 'radio',
                      options: align.options,
                    },
                    {
                      name: 'position',
                      component: 'select',
                      label: 'Content Position (desktop)',
                      options: position.flex,
                    },
                    {
                      name: 'alignmentMob',
                      label: 'Content Alignment (mobile)',
                      component: 'radio-group',
                      direction: 'horizontal',
                      variant: 'radio',
                      options: align.options,
                    },
                    {
                      name: 'positionMob',
                      component: 'select',
                      label: 'Content Position (mobile)',
                      options: position.flex,
                    },
                  ],
                },
                {
                  label: 'Button Settings',
                  name: 'button',
                  component: 'group',
                  description: 'link, style',
                  fields: [
                    {
                      label: 'Link',
                      name: 'link',
                      component: 'link',
                    },
                    {
                      name: 'style',
                      label: 'Button Style (Desktop)',
                      component: 'select',
                      options: button.options,
                    },
                  ],
                },
              ],
              defaultItem: {
                position: 5,
                text: {
                  heading: 'Promo Tile Heading',
                  subtext:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                },
                content: {
                  alignment: 'center',
                  position: 'center center',
                  alignmentMob: 'center',
                  positionMob: 'center center',
                },
              },
            },
            {
              label: 'Collection Handles',
              name: 'collections',
              component: 'list',
              description:
                'Add all collection handles that will run this campaign, e.g. "all", "best-sellers".\n\nTo apply to search results page, add "search"',
              field: {
                component: 'text',
              },
            },
          ],
          defaultItem: {
            enabled: true,
            name: 'Campaign',
            promoTiles: [
              {
                position: 5,
                text: {
                  heading: 'Promo Tile Heading',
                  subtext:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                },
              },
            ],
            collections: ['shop-all'],
          },
        },
      ],
    },
    {
      label: 'Breadcrumbs',
      name: 'breadcrumbs',
      component: 'group',
      fields: [
        {
          label: 'Parent Links',
          name: 'links',
          component: 'group-list',
          itemProps: {
            label: '{{item.collection.handle}}',
          },
          fields: [
            {
              label: 'Collection',
              name: 'collection',
              component: 'collections',
            },
            {
              label: 'Child Links',
              name: 'links',
              component: 'group-list',
              itemProps: {
                label: '{{item.collection.handle}}',
              },
              fields: [
                {
                  label: 'Collection',
                  name: 'collection',
                  component: 'collections',
                },
                {
                  label: 'Subchild Links',
                  name: 'links',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.collection.handle}}',
                  },
                  fields: [
                    {
                      label: 'Collection',
                      name: 'collection',
                      component: 'collections',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
