import { align, color, section } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Text',
    key: 'text',
    fields: [
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, superheading, body, content alignment',
        component: 'group',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'markdown',
          },
          {
            name: 'headingColor',
            label: 'Heading Color (desktop)',
            component: 'color',
            colors: color.list,
            widget: 'block',
          },
          {
            name: 'headingColorMob',
            label: 'Heading Color (mobile)',
            component: 'color',
            colors: color.list,
            widget: 'block',
          },
          {
            label: 'Enable Uppercase Heading',
            name: 'enableUppercaseHeading',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: true,
          },
          {
            label: 'Superheading',
            name: 'superheading',
            component: 'markdown',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
          {
            name: 'color',
            label: 'Body Color (desktop)',
            component: 'color',
            colors: color.list,
            widget: 'block',
          },
          {
            name: 'colorMob',
            label: 'Body Color (mobile)',
            component: 'color',
            colors: color.list,
            widget: 'block',
          },
          {
            label: 'Content Alignment (desktop)',
            name: 'alignment',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
          {
            label: 'Content Alignment (mobile)',
            name: 'alignmentMob',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: align.options,
          },
          {
            label: 'Content Max Width',
            name: 'contentMaxWidth',
            component: 'number',
          },
        ],
        defaultValue: {
          heading: 'header header header',
          enableHeadingUppercase: true,
          superheading: 'subhead',
          body: 'Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus commodo, tortor condimentum nibh, ut fermentum.',
          alignment: align.value.center,
          alignmentMob: align.value.center,
          contentMaxWidth: 535,
        },
      },
      section,
    ],
  };
};
