import { Video as Player } from '@snippets';
import { themed } from '../VideoBlock.theme';

export const Video = themed(
  ({ videoSources = [], videoTitles = [], videoStyles = {}, ...props }) => {
    return (
      <Player
        videoSources={videoSources}
        videoTitles={videoTitles}
        videoStyles={videoStyles}
        {...props}
      />
    );
  }
);

Video.displayName = 'Video';
