import { OrganizationHead } from './OrganizationHead';
import { PageHead } from './PageHead';
import { ArticleHead } from './ArticleHead';
import { CollectionHead } from './CollectionHead';
import { ProductHead } from './ProductHead';

export function SeoPageSchema(props) {
  const baseUrl = process.env.SITE_URL;
  const { article, blog, collection, page, product } = props;

  let resourceType = page?.resourceType;
  let pageUrl = '';

  if (page?.resourceType === 'home_page') {
    pageUrl = baseUrl;
  } else if (page?.resourceType === 'page') {
    pageUrl = `${baseUrl}/pages/${page.handle}`;
    resourceType = 'page';
  } else if (page?.resourceType === 'product_page') {
    pageUrl = `${baseUrl}/products/${page.handle}`;
  } else if (page?.resourceType === 'collection_page') {
    pageUrl = `${baseUrl}/collections/${page.handle}`;
  } else if (article) {
    pageUrl = `${baseUrl}/articles/${article.handle}`;
    resourceType = 'article';
  } else if (blog) {
    pageUrl = `${baseUrl}/blogs/${blog.handle}`;
    resourceType = 'blog';
  } else {
    pageUrl = baseUrl;
  }

  const showOrgSchema = page?.handle
    ? /(\/|about-us|contact)/.test(page.handle)
    : false;

  return (
    <>
      {showOrgSchema && <OrganizationHead />}

      {resourceType === 'page' && <PageHead page={page} />}

      {resourceType === 'article' && <ArticleHead article={article} />}

      {resourceType === 'product_page' && <ProductHead product={product} />}

      {resourceType === 'collection_page' && (
        <CollectionHead collection={collection} />
      )}
    </>
  );
}
