import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.stickySearch = {
      position: 'sticky',
      top: 0,
      bg: 'background',
      zIndex: 1,
      borderTop: (t) => `1px solid ${t.colors.gray.mediumLight}`,
    };

    this.inputWrapper = {
      py: 10,
      px: 8,
    };
    this.suggestionsWrapper = {
      p: 8,
      flexDirection: 'column',
      gap: 3,
    };

    this.suggested = {
      fontWeight: 600,
      fontStyle: 'italic',
    };

    this.suggestion = (isLast) => ({
      variant: 'text.base',
      textDecoration: 'underline',
      textDecorationThickness: '1px',
      textUnderlineOffset: '1px',
      fontStyle: 'italic',
      mr: !isLast ? 3 : 0,
      ':after': {
        content: !isLast ? '","' : '""',
      },
    });

    this.empty = {
      p: 12,
      pb: 0,
      position: 'relative',
      variant: 'flex.column',
    };

    this.loadingSpinner = {
      width: '22px',
      height: '22px',
      mx: 'auto',
      color: 'mediumGray',
    };

    this.loader = {
      variant: 'flex.row.center',
      height: `calc(var(--viewport-height) - var(--header-height) - var(--promoBar-height) - 200px)`,
    };
  })()
);
