import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      position: 'relative',
      width: '100%',
    };

    this.label = {
      variant: 'text.label',
      position: 'absolute',
      left: 0,
      top: 0,
      minWidth: 'unset',
      px: 8,
      pt: '5px',
      zIndex: 1,
    };

    this.input = {
      variant: 'text.base',
      pb: 3,
      pt: 10,
      px: 8,
      m: 0,
      border: '1px solid #838383',
      bg: 'background',
      borderRadius: '4px',
      '::placeholder': {
        color: 'mediumGray',
        variant: 'text.base',
      },
    };

    this.button = {
      variant: 'text.buttonText',
      width: '100%',
      px: 3,
      py: 7,
      backgroundColor: 'darkGray',
      color: 'white',
      borderRadius: '4px',
      mt: 6,
      ':disabled': {
        opacity: 0.7,
      },
    };
  })()
);
