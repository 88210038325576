import { withInView } from '@utils';
import { Section, RoleList } from '@snippets';
import { Schema } from './Greenhouse.schema';
import { themed } from './Greenhouse.theme';

export const Greenhouse = withInView(
  themed(({ theme, cms }) => {
    const { section } = cms;

    return (
      <Section
        sx={{
          ...theme.container,
        }}
        section={section}
      >
        <RoleList cms={cms} data-comp={Greenhouse.displayName} />
      </Section>
    );
  }),
  { triggerOnce: true }
);

Greenhouse.displayName = 'Greenhouse';
Greenhouse.Schema = Schema;
