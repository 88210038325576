import { create } from '@theme/create';
import { hexToRgbA } from '@utils/common';

export const themed = create(
  new (function () {
    this.wrapper = ({ bgColor, borderColor, bgColorOpacity = 100 }) => ({
      position: 'absolute',
      top: ['5px', '13px'],
      left: ['5px', '13px'],
      display: 'flex',
      alignItems: 'center',
      height: '18px',
      borderRadius: '6px',
      py: 1,
      px: 5,
      background: hexToRgbA(bgColor, bgColorOpacity / 100),
      border: '1px solid ',
      borderColor: borderColor || 'seafoam',
      zIndex: 1,
    });

    this.label = ({ textColor }) => ({
      color: textColor || 'black',
      whiteSpace: 'nowrap',
      fontSize: 0,
      fontWeight: '500',
      textTransform: 'capitalize',
      lineHeight: 1,
    });
  })()
);
