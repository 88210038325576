import { useRouter } from '@backpackjs/storefront';
// todo expose on @backpackjs/storefront
import { useGotoRecoilSnapshot } from '@store';

import store, { useRecoilCallback, useRecoilValue } from '@store';

export const useMenuSidebar = () => {
  // @refresh reset
  const router = useRouter();
  const gotoSnapshot = useGotoRecoilSnapshot();
  const menuSidebar = useRecoilValue(store.menuSidebar);
  const selectedItem = useRecoilValue(store.selectedItem);
  const selectedIndex = useRecoilValue(store.selectedIndex);

  // on click, close the sideBar and navigate to url
  const closeSideBarAndNavigate = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const release = snapshot.retain();
        try {
          const updatedState = snapshot.map(({ set }) => {
            set(store.selectedItem, null);
            set(store.menuSidebar, false);
          });

          // update state
          gotoSnapshot(updatedState);
        } finally {
          release();
        }
      }
  );

  const toggleNestedSidebar = useRecoilCallback(
    ({ snapshot }) =>
      async (selected) => {
        const release = snapshot.retain();

        try {
          let nextState;
          const isOpening = selected?.item;

          if (isOpening) {
            nextState = snapshot.map(({ set }) =>
              set(store.selectedItem, selected.item)
            );

            gotoSnapshot(nextState);
          } else {
            nextState = snapshot.map(({ set }) =>
              set(store.selectedItem, null)
            );

            gotoSnapshot(nextState);
          }
        } finally {
          release();
        }
      }
  );

  const toggleSidebar = useRecoilCallback(
    ({ snapshot }) =>
      async ({ navigateTo } = { navigateTo: null }) => {
        const release = snapshot.retain();
        try {
          let nextState;
          const prevMenuSidebar = snapshot.getLoadable(
            store.menuSidebar
          ).contents;

          const isOpening = prevMenuSidebar === false;
          if (isOpening) {
            // isOpening
            nextState = snapshot.map(({ set }) => {
              set(store.menuSidebar, true);
            });

            // update state
            gotoSnapshot(nextState);
          } else {
            nextState = snapshot
              // reset selected item
              // close sidebar
              .map(({ set }) => {
                set(store.selectedItem, null);
                set(store.menuSidebar, false);
              });

            // update state
            gotoSnapshot(nextState);

            if (navigateTo) {
              router.push(navigateTo);
            }
          }
        } finally {
          release();
        }
      }
  );

  return [
    // state
    {
      menuSidebar,
      menuSidebarNested: Boolean(selectedItem),
      selectedItem,
      selectedIndex,
    },
    // actions
    {
      toggleSidebar,
      toggleNestedSidebar,
      closeSideBarAndNavigate,
    },
  ];
};
