import { useMemo, useEffect, useCallback, useState } from 'react';
import { useCustomer } from '@backpackjs/storefront';

import store, { useSetRecoilState } from '@store';
import { getValidId } from '@utils';

// set shopper id for searchspring intellisuggest
export const useShopperId = () => {
  const customer = useCustomer();
  const setShopperId = useSetRecoilState(store.shopperId);

  const [mounted, setMounted] = useState(false);

  const customerId = useMemo(
    () => (customer?.id ? getValidId(customer.id) : ''),
    [customer?.id]
  );

  const setMountedOnDelayForClearing = useCallback(() => {
    setTimeout(() => setMounted(true), 5000);
  }, []);

  const clearShopperIdOnNoCustomer = useCallback(() => {
    if (!mounted || customerId) return;
    setShopperId(null);
    window.localStorage.removeItem('aloha_shopperId');
  }, [customerId, mounted]);

  const setShopperIdOnMount = useCallback(() => {
    const localShopperId = window.localStorage.getItem('aloha_shopperId');
    if (customerId && localShopperId !== customerId) {
      setShopperId(customerId);
      window.localStorage.setItem('aloha_shopperId', customerId);
      return;
    }
    if (localShopperId) {
      setShopperId(localShopperId);
    }
  }, [customerId]);

  useEffect(() => {
    setMountedOnDelayForClearing();
  }, []);
  useEffect(() => {
    clearShopperIdOnNoCustomer();
  }, [mounted]);
  useEffect(() => {
    setShopperIdOnMount();
  }, [customerId]);
};
