import { section, color } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Featured Collections',
    key: 'featured-collections',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Featured',
      },
      {
        label: 'Display Type (Mobile)',
        direction: 'horizontal',
        variant: 'radio',
        name: 'displayTypeMobile',
        component: 'radio-group',
        options: [
          {
            value: 'slider',
            label: 'Slider',
          },
          {
            value: 'stacked',
            label: 'Stacked',
          },
        ],
        defaultValue: 'slider',
      },
      {
        label: 'Tiles',
        name: 'tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          maxItems: 4,
        },
        fields: [
          {
            label: 'Title',
            name: 'title',
            component: 'text',
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
        ],
        defaultValue: [
          {
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/feature-collections-placeholder.png?v=1712278333',
            },
            title: 'Collection Name',
          },
          {
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/feature-collections-placeholder.png?v=1712278333',
            },
            title: 'Collection Name',
          },
          {
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/feature-collections-placeholder.png?v=1712278333',
            },
            title: 'Collection Name',
          },
          {
            image: {
              src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/feature-collections-placeholder.png?v=1712278333',
            },
            title: 'Collection Name',
          },
        ],
      },
      {
        label: 'Image Settings',
        name: 'image',
        component: 'group',
        fields: [
          {
            label: 'Aspect Ratio',
            component: 'select',
            name: 'ratio',
            options: [
              {
                value: '6/4',
                label: '6:4',
              },
              {
                value: '5/4',
                label: '5:4',
              },
              {
                value: '1/1',
                label: '1:1',
              },
              {
                value: '4/5',
                label: '4:5',
              },
              {
                value: '3/4',
                label: '3:4',
              },
              {
                value: '2/3',
                label: '2:3',
              },
              {
                value: '3/5',
                label: '3:5',
              },
              {
                value: '1/2',
                label: '1:2',
              },
            ],
            defaultValue: '3/4',
          },
        ],
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
