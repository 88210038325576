import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = {
      width: '100%',
      color: 'currentColor',
    };

    this.flex = {
      variant: 'flex.row.between.center',
      width: '100%',
      height: '100%',
    };

    this.text = {
      variant: 'text.label',
      flex: 1,
      whiteSpace: 'nowrap',
      textAlign: 'left',
    };

    this.icon = {
      width: '20px',
      ml: 2,
      transition: 'transform .4s ease-in-out',
    };
  })()
);
