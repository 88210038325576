import { section, position } from '@settings/common';

const aspectRatios = [
  { label: '7:1', value: '7' },
  { label: '6:1', value: '6' },
  { label: '5:1', value: '5' },
  { label: '4:1', value: '4' },
  { label: '3:1', value: '3' },
  { label: '5:2', value: '2.5' },
  { label: '2:1', value: '2' },
  { label: '16:9', value: '1.78' },
  { label: '3:2', value: '1.5' },
  { label: '4:3', value: '1.33' },
  { label: '5:4', value: '1.25' },
  { label: '1:1', value: '1' },
  { label: '7:8', value: '0.88' },
  { label: '4:5', value: '0.8' },
  { label: '3:4', value: '0.75' },
  { label: '2:3', value: '0.67' },
  { label: '9:16', value: '0.56' },
];

export const Schema = () => {
  return {
    label: 'Image',
    key: 'image',
    fields: [
      {
        name: 'alt',
        component: 'text',
        description: 'Brief description of image',
        label: 'Alt',
        defaultValue: 'Image',
      },
      {
        name: 'cta',
        component: 'link',
        label: 'Image Link (optional)',
        description: 'Sets image as clickable link',
      },
      {
        label: 'Desktop Settings',
        name: 'desktop',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
          },
          {
            name: 'position',
            component: 'select',
            label: 'Image Position',
            options: position.object,
          },
          {
            name: 'ratio',
            label: 'Aspect Ratio',
            component: 'select',
            options: aspectRatios,
          },
          {
            name: 'objectFit',
            component: 'select',
            label: 'Image Object fit',
            options: [
              { label: 'Cover', value: 'cover' },
              { label: 'Contain', value: 'contain' },
            ],
            defaultValue: 'cover',
          },
          {
            name: 'maxHeight',
            component: 'number',
            label: 'Max Pixel Height (optional)',
          },
          {
            name: 'maxWidth',
            component: 'number',
            label: 'Max Pixel Width (optional)',
          },
        ],
        defaultValue: {
          src: 'https://images.unsplash.com/photo-1508028339507-ff30e5b2d025?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2552&q=75',
          position: 'center center',
          ratio: '1.78',
          objectFit: 'cover',
        },
      },
      {
        label: 'Mobile Settings',
        name: 'mobile',
        component: 'group',
        fields: [
          {
            name: 'src',
            label: 'Image',
            component: 'image',
          },
          {
            name: 'position',
            component: 'select',
            label: 'Image Position',
            options: position.object,
          },
          {
            name: 'ratio',
            label: 'Aspect Ratio',
            component: 'select',
            options: aspectRatios,
          },
          {
            name: 'objectFit',
            component: 'select',
            label: 'Image Object fit',
            options: [
              { label: 'Cover', value: 'cover' },
              { label: 'Contain', value: 'contain' },
            ],
            defaultValue: 'cover',
          },
          {
            name: 'minHeight',
            component: 'number',
            label: 'Min pixel height (optional)',
          },
          {
            name: 'maxHeight',
            component: 'number',
            label: 'Max Pixel Height (optional)',
          },
        ],
        defaultValue: {
          src: 'https://images.unsplash.com/photo-1508028339507-ff30e5b2d025?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=90',
          position: 'center center',
          ratio: '1',
          minHeight: '',
          objectFit: 'cover',
        },
      },
      section,
    ],
  };
};
