import { section, color, align, button } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Tabbed Slider',
    key: 'tabbed-slider',
    fields: [
      {
        name: 'tabs',
        label: 'Tabs',
        component: 'group-list',
        itemProps: {
          label: '{{item.name}}',
        },
        validate: {
          maxItems: 2,
        },
        fields: [
          {
            name: 'name',
            label: 'Tab Name',
            component: 'text',
          },
          {
            name: 'tiles',
            label: 'Tiles',
            component: 'group-list',
            itemProps: {
              label: '{{item.heading}}',
            },
            fields: [
              {
                label: 'Image',
                name: 'image',
                component: 'image',
              },
              {
                label: 'Custom Heading',
                name: 'heading',
                component: 'text',
              },
              {
                label: 'Description',
                name: 'description',
                component: 'markdown',
              },
              {
                label: 'Link',
                name: 'cta',
                component: 'link',
              },
            ],
            defaultValue: [
              {
                heading: 'Allison Chu',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/allison-chu.jpg?v=1702335347',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
              {
                heading: 'Chelsea Yamase',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/chelsea-yamase.jpg?v=1702335350',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
              {
                heading: 'Coco Ho',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/coco-ho2.jpg?v=1702337468',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
              {
                heading: 'Hinatea Boosie',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/hinatea-boosie.jpg?v=1702335357',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
              {
                heading: 'XXXX XXXX',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/allison-chu.jpg?v=1702335347',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
            ],
          },
        ],
        defaultValue: [
          {
            name: 'Ambassador',
            tiles: [
              {
                heading: 'Allison Chu',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/allison-chu.jpg?v=1702335347',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
              {
                heading: 'Chelsea Yamase',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/chelsea-yamase.jpg?v=1702335350',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
              {
                heading: 'Coco Ho',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/coco-ho2.jpg?v=1702337468',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
              {
                heading: 'Hinatea Boosie',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/hinatea-boosie.jpg?v=1702335357',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
              {
                heading: 'XXXX XXXX',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/allison-chu.jpg?v=1702335347',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
            ],
          },
          {
            name: 'Creators',
            tiles: [
              {
                heading: 'Greg Marino',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/greg-marino.jpg?v=1702339865',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
              {
                heading: 'Ivana Cook',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/ivana-cook.jpg?v=1702339865',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
              {
                heading: 'Kenna Reed',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/kenna-reed.jpg?v=1702339865',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
              {
                heading: 'Malia Kaleopaa',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/malia-kaleopaa.jpg?v=1702339865',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
              {
                heading: 'XXXX XXXX',
                description: 'Persona Description',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/greg-marino.jpg?v=1702339865',
                },
                cta: {
                  text: 'Shop All',
                  url: '/collections/shop-all',
                },
              },
            ],
          },
        ],
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, Heading & Tab Color, tile Content Placement',
        component: 'group',
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            name: 'headingColor',
            label: 'Heading Color',
            component: 'color',
          },
          {
            name: 'tabColor',
            label: 'Tab Color',
            component: 'color',
          },
          {
            name: 'tabHoverColor',
            label: 'Tab Hover Color',
            component: 'color',
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'tileContentPosition',
            label: 'Tile Content Position',
            options: [
              { label: 'Inside', value: 'inside' },
              { label: 'Below', value: 'below' },
            ],
            defaultValue: 'inside',
          },
        ],
        defaultValue: {
          heading: 'Meet Our',
          headingColor: '#000000',
          tabColor: '#000000',
          tabHoverColor: '#DC96DE',
          tileContentPosition: 'inside',
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
