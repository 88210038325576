import { Markdown } from '@snippets';

import { Box, Flex, Heading } from 'theme-ui';

export function TextContent({ text, textAlign = 'center' }) {
  const {
    heading,
    headingFontSize,
    headingFontSizeMobile,
    headingMarginBottomDt,
    headingMarginBottomMob,
    superheading,
    subheading,
    subheadingFontSize,
    color,
    colorMob,
    headingFontFamily,
    headingFontWeight,
    superheadingFontSize,
    superheadingFontSizeMobile,
    subheadingFontSizeMobile,
    headingColorMob,
    headingColor,
  } = text;

  return (
    <Flex data-comp={TextContent.displayName}>
      <Box>
        {/* Desktop */}
        <Box sx={{ display: ['none', 'block'] }}>
          {superheading && (
            <Heading
              as="h6"
              sx={{
                variant: `text.${superheadingFontSize}`,
                fontWeight: 700,
                textAlign,
                color: headingColor,
              }}
            >
              {superheading}*
            </Heading>
          )}

          <Markdown
            text={heading}
            textSx={{
              variant: `text.${
                headingFontSize !== 'hero' ? headingFontSize : 'hero'
              }${headingFontFamily === 'quarto' ? '.quarto' : ''}${
                headingFontWeight === 'light' ? '.light' : ''
              }`,
              textAlign,
              color: [headingColorMob, headingColor],
            }}
            sx={{
              p: {
                p: 0,
              },
              mb: subheading ? `${headingMarginBottomDt || 20}px` : 0,
            }}
          />

          {subheading && (
            <Markdown
              text={subheading}
              textSx={{
                variant: `text.${subheadingFontSize}`,
                fontWeight: 400,
                letterSpacing: 0,
                textTransform: 'normal',
                textAlign,
                color,
              }}
              sx={{
                p: {
                  p: 0,
                },
              }}
            />
          )}
        </Box>

        {/* Mobile */}
        <Box sx={{ display: ['block', 'none'] }}>
          {superheading && (
            <Heading
              as="h6"
              sx={{
                variant: `text.${superheadingFontSizeMobile}`,
                fontWeight: 700,
                textAlign,
                color: headingColor,
              }}
            >
              {superheading}
            </Heading>
          )}

          <Markdown
            text={heading}
            textSx={{
              variant: `text.${
                headingFontSizeMobile !== 'hero'
                  ? headingFontSizeMobile || 'hero'
                  : 'hero'
              }${headingFontFamily === 'quarto' ? '.quarto' : ''}${
                headingFontWeight === 'light' ? '.light' : ''
              }`,
              textAlign,
              color: headingColorMob,
            }}
            sx={{
              p: {
                p: 0,
              },
              mb: subheading ? `${headingMarginBottomMob || 8}px` : 0,
            }}
          />

          {subheading && (
            <Markdown
              text={subheading}
              textSx={{
                variant: `text.${subheadingFontSizeMobile}`,
                fontWeight: 400,
                letterSpacing: 0,
                textTransform: 'normal',
                textAlign,
                color: colorMob,
              }}
              sx={{
                p: {
                  p: 0,
                },
              }}
            />
          )}
        </Box>
      </Box>
    </Flex>
  );
}
