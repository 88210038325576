export const account = {
  form: {
    minHeight: ['initial', '500px'],
    backgroundColor: 'white',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    px: [10, 12],
    py: '55px',
    borderRadius: 4,
    label: {
      variant: 'text.label',
    },
  },
  input: {
    variant: 'text.base',
    p: 8,
    mb: [9],
    border: (t) => `1px solid ${t.colors.mediumGray}`,
    borderRadius: 4,
    ':focus': {
      outline: 'none !important',
      border: (t) => `1px solid ${t.colors.mediumGray}`,
      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.161);',
    },
  },
};
