import { section, color, align, button } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Tiles Slider',
    key: 'tiles-slider',
    fields: [
      {
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        name: 'displayType',
        label: 'Display Type',
        options: [
          {
            value: 'slider',
            label: 'Slider',
          },
          {
            value: 'grid',
            label: 'Grid',
          },
        ],
        defaultValue: 'slider',
      },
      {
        component: 'radio-group',
        direction: 'horizontal',
        variant: 'radio',
        name: 'gridDisplay',
        label: 'Grid Display - Mobile',
        options: [
          {
            value: '2x2',
            label: '2x2',
          },
          {
            value: '1x1',
            label: '1x1',
          },
        ],
        defaultValue: '2x2',
      },
      {
        name: 'tiles',
        label: 'Tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        defaultItem: {
          heading: 'hip packs',
          image:
            'https://cdn.shopify.com/s/files/1/1119/6322/files/image_bb414650-7af1-4a75-8355-3b31dd07f8b1.png?v=1655370361',
          description: 'Perfect for lorem upsumBest paired with lorem ipsum',
          cta: {
            text: 'Shop All',
            url: '/collections/shop-all',
          },
        },
        fields: [
          {
            name: 'image',
            component: 'image',
            label: 'custom image',
          },
          {
            name: 'heading',
            component: 'text',
            label: 'Custom Heading',
          },
          {
            name: 'description',
            component: 'markdown',
            label: 'custom description',
          },
          {
            name: 'cta',
            component: 'link',
            label: 'Custom link',
          },
        ],
        defaultValue: [
          {
            heading: 'pouches',
            image:
              'https://cdn.shopify.com/s/files/1/1119/6322/files/image_bb414650-7af1-4a75-8355-3b31dd07f8b1.png?v=1655370361',
            description: 'Perfect for lorem upsumBest paired with lorem ipsum',
            cta: {
              text: 'Shop All',
              url: '/collections/shop-all',
            },
          },
          {
            heading: 'hip packs',
            image:
              'https://cdn.shopify.com/s/files/1/1119/6322/files/image_caafaabc-fec2-42db-9262-68b10580de95.jpg?v=1654642725',
            description: 'Perfect for lorem upsumBest paired with lorem ipsum',
            cta: {
              text: 'Shop All',
              url: '/collections/shop-all',
            },
          },
          {
            heading: 'totes',
            image:
              'https://cdn.shopify.com/s/files/1/1119/6322/files/image_bb414650-7af1-4a75-8355-3b31dd07f8b1.png?v=1655370361',
            description: 'Perfect for lorem upsumBest paired with lorem ipsum',
            cta: {
              text: 'Shop All',
              url: '/collections/shop-all',
            },
          },
          {
            heading: 'duffles',
            image:
              'https://cdn.shopify.com/s/files/1/1119/6322/files/image_caafaabc-fec2-42db-9262-68b10580de95.jpg?v=1654642725',
            description: 'Perfect for lorem upsumBest paired with lorem ipsum',
            cta: {
              text: 'Shop All',
              url: '/collections/shop-all',
            },
          },
        ],
      },
      {
        name: 'cta',
        component: 'link',
        label: 'Group Button',
      },
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Heading, text align, button style',
        component: 'group',
        fields: [
          {
            name: 'heading',
            label: 'Heading',
            component: 'text',
          },
          {
            name: 'subheading',
            label: 'SubHeading',
            component: 'text',
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'textAlign',
            description: "Doesn't apply on desktop when group list is inline",
            label: 'Heading Alignment',
            options: align.options,
          },
          {
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            name: 'buttonAlign',
            label: 'Button Alignment',
            options: align.options,
          },
          {
            name: 'ctaStyle',
            label: 'Group Button Style',
            component: 'select',
            options: button.options,
          },
        ],
        defaultValue: {
          heading: 'Meet the lineup',
          subheading: '',
          textAlign: align.value.left,
          ctaStyle: 'primary',
        },
      },
      {
        ...section,
        defaultValue: {
          bgColor: color.value.background,
          color: color.value.text,
          desktop: {
            container: 'content',
            mt: 'none',
            py: 'm',
          },
          mobile: {
            container: 'container',
            mt: 'none',
            py: 'm',
          },
        },
      },
    ],
  };
};
