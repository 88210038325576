import { sectionWithHeight } from '@settings/common';

export const Schema = () => {
  return {
    label: 'Brands',
    key: 'brands',
    fields: [
      {
        label: 'Content Settings',
        name: 'content',
        description: 'Title, brands',
        component: 'group',
        fields: [
          {
            name: 'title',
            label: 'Title',
            description: 'Title above brand items',
            component: 'text',
          },
          {
            name: 'brandItems',
            label: 'Brand Items',
            component: 'group-list',
            itemProps: {
              label: '{{item.alt}}',
            },
            defaultItem: {
              alt: 'traveler',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_43.png?v=1655200683',
              },
            },
            fields: [
              {
                name: 'alt',
                label: 'Alt',
                component: 'text',
              },
              {
                name: 'image',
                label: 'Image',
                component: 'image',
              },
            ],
            defaultValue: [
              {
                alt: 'Traveler',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_43.png?v=1655200683',
                },
              },
              {
                alt: 'Forbes',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_42.png?v=1655201875',
                },
              },
              {
                alt: 'Elle',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_44.png?v=1655202121',
                },
              },
              {
                alt: 'Outsize',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_45.png?v=1655202158',
                },
              },
              {
                alt: 'Budget travel',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_46.png?v=1655202252',
                },
              },
              {
                alt: 'Air Canada',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_47_ffed78d0-bf2c-4676-a4f2-37fd4a4c4e0b.png?v=1655202297',
                },
              },
            ],
          },
        ],
        defaultValue: {
          title: 'As seen in',
          brandItems: [
            {
              alt: 'Traveler',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_43.png?v=1655200683',
              },
            },
            {
              alt: 'Forbes',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_42.png?v=1655201875',
              },
            },
            {
              alt: 'Elle',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_44.png?v=1655202121',
              },
            },
            {
              alt: 'Outsize',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_45.png?v=1655202158',
              },
            },
            {
              alt: 'Budget travel',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_46.png?v=1655202252',
              },
            },
            {
              alt: 'Air Canada',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/1119/6322/files/image_47_ffed78d0-bf2c-4676-a4f2-37fd4a4c4e0b.png?v=1655202297',
              },
            },
          ],
        },
      },
      sectionWithHeight,
    ],
  };
};
