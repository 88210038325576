import { Box, Flex, Link } from 'theme-ui';
// import { Svg } from '@snippets';

export function Role({ index, theme, dept, ...job }) {
  if (!job) return null;

  return (
    <>
      {index === 0 && (
        <Flex sx={theme.role.dept}>
          <Box sx={theme.role.dept.divider} />
          <Box sx={theme.role.dept.name}>{dept.name}</Box>
        </Flex>
      )}
      <Link href={job.absolute_url} target="_blank" sx={theme.role}>
        {/* title column */}
        <Box sx={theme.role.position}>{job.title}</Box>

        {/* location / apply column */}
        <Box sx={theme.role.location}>{job.location.name}</Box>

        <Box sx={theme.role.apply}>
          <Link href={job.absolute_url} target="_blank">
            Apply
          </Link>
        </Box>
      </Link>
    </>
  );
}

Role.displayName = 'Role';
