import Head from 'next/head';

import Libs from './Libs';
import Preconnects from './Preconnects';
import Fonts from './Fonts';
import Page from './Page';

export function HeadComponent(props) {
  return (
    <>
      <Head>
        <Preconnects />
        <Page {...props} />
        <Fonts />
      </Head>
      {/* Store URL params in cookies on mount */}
      <script
        id="url-params-cookie-script"
        type="text/javascript"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
          (function () {
            const maxAge = '7776000';
            const getSubdomainIndex = (url) => {
              if (!url) return 1;
              const parts = url.split(".");
              return parts.length === 1 ? 0 : parts.length === 2 ? 1 : 2;
            };
            const subdomainIndex = getSubdomainIndex(window.location.host);
            const generateFbc = (fbclid) => 'fb.' + subdomainIndex + '.' + Date.now() + '.' + fbclid;

            const fbclid = new URLSearchParams(window.location.search).get('fbclid');
            if (fbclid) {
              const fbc = generateFbc(fbclid);
              document.cookie = '_fbc=' + fbc + '; path=/; domain=.' + window.location.hostname + '; max-age=' + maxAge + '; samesite=lax';
            }
          })();`,
        }}
      />
      <Libs />
    </>
  );
}
