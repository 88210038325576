import { Box } from 'theme-ui';

import { Link } from '@snippets';
import store, { useRecoilValue } from '@store';
import { useSidebar } from '@hooks';

import { useSearch } from '../hooks';
import { themed } from './SearchFooter.theme';

export const SearchFooter = themed(({ theme }) => {
  const [{ suggested, isAutocomplete, totalResults }] = useSearch();
  const [, { closeSidebar }] = useSidebar();
  const rawTerm = useRecoilValue(store.rawTerm);

  return totalResults ? (
    <Box data-comp={SearchFooter.displayName} sx={theme.wrapper}>
      <Link
        href="/pages/search"
        query={{ query: isAutocomplete ? suggested?.text : rawTerm }}
        onClick={closeSidebar}
        sx={theme.button}
      >
        See All {totalResults} Results
      </Link>
    </Box>
  ) : null;
});

SearchFooter.displayName = 'SearchFooter';
