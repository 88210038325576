import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.sidebar = {
      variant: 'flex.column.center.center',
      position: 'absolute',
      left: '50%',
      marginRight: '-50%',
      top: '50%',
      px: 8,
      transform: 'translate(-50%, -50%)',
      color: 'text',
      text: {
        variant: 'text.h4',
        textAlign: 'center',
      },

      link: {
        variant: 'buttons.outline',
        mt: 11,
      },
    };

    // cart empty — rendered in /cart
    this.page = {
      variant: 'flex.column.center.center',
      position: 'relative',
      minHeight: '200px',

      text: {
        variant: 'text.h4',
        textAlign: 'center',
      },

      link: {
        variant: 'text.link.2',
        mt: 5,
      },
    };
  })()
);
