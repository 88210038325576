import Script from 'next/script';
import { useEffect } from 'react';

/* Overview:
 * This script implements three new custom GA4 events for behavioral interaction testing.
 * The following data layer events are sent to GA4:
 *   * dl_select_subnav_item
 *   * dl_select_item_list_filter
 *   * dl_deselect_item_list_filter
 * This script sets an explicit `send_to` value and `user_id` where applicable.
 */

const plugins = { all: false, 'google-analytics': true };

const handleSubNaveItem = (event) => {
    window.fueled.track(
        'subnav_select',
        {
            'subnav_item_name': event.detail.ecommerce.click.actionField.text
        },
        { plugins }
    );
};

const handleSelectFilter = (event) => {
    window.fueled.track(
        'filter_select',
        {
            'filter_name': event.detail.ecommerce.click.actionField.filter,
            'filter_option': event.detail.ecommerce.click.actionField.value
        },
        { plugins }
    );
};

const handleUnselectFilter = (event) => {
    window.fueled.track(
        'filter_unselect',
        {
            'filter_name': event.detail.ecommerce.click.actionField.filter,
            'filter_option': event.detail.ecommerce.click.actionField.value
        },
        { plugins }
    );
};

export function FueledCustomGa4EventsScript({ withKey: ga4Id }) {
  useEffect(() => {
    window.addEventListener('dl_select_subnav_item', handleSubNaveItem);
    window.addEventListener('dl_select_item_list_filter', handleSelectFilter);
    window.addEventListener('dl_deselect_item_list_filter', handleUnselectFilter);

    return () => {
      window.removeEventListener('dl_select_subnav_item', handleSubNaveItem);
      window.removeEventListener('dl_select_item_list_filter', handleSelectFilter);
      window.removeEventListener('dl_deselect_item_list_filter', handleUnselectFilter);
    };
  }, []);

  return (
    <Script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: ``,
      }}
    />
  );
}
