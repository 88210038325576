import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.slider = {
      '.swiper-slide-active': {
        '@media only screen and (max-width: 768px)': {
          img: {
            transform: 'scale(1.075)',
          },
          button: {
            opacity: 1,
          },
          '.tile-content': {
            transform: 'translateY(-40px)',
          },
        },
      },
    };
  })()
);
