import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.header = {
      px: [10, 17],
      mb: 10,
    };

    this.heading = {
      fontSize: 3,
      lineHeight: 1,
      textTransform: 'uppercase',
      letterSpacing: '2px',
      mb: 7,
    };

    this.buttonList = {
      overflowY: 'auto',
    };

    this.button = {
      fontSize: ['24px', '36px'],
      fontWeight: 700,
      textTransform: 'uppercase',
      lineHeight: 1,
    };
  })()
);
