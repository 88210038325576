import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.buttonPrev = {
      display: ['none', 'flex'],
      position: 'absolute',
      transform: 'translateY(-50%)',
      zIndex: 1,
      left: '-20px',
      right: 'auto',
      transition: 'opacity 0.2s ease-in-out',
      width: '40px',
      height: '40px',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      borderRadius: '100%',
      border: '1px solid lightGray',
      svg: {
        position: 'relative',
        left: '-2px',
      },
    };

    this.buttonNext = {
      ...this.buttonPrev,
      left: 'auto',
      right: '-20px',
      svg: {
        position: 'relative',
        left: 'auto',
        right: '2px',
      },
    };

    this.icon = {
      width: '20px',
      userSelect: 'none',
    };
  })()
);
