import { Box } from 'theme-ui';
import { withInView } from '@utils';

import { Schema } from './ProductBadge.schema';
import { themed } from './ProductBadge.theme';

// This component's data is rendered by the Product/Main/Media component.
export const ProductBadge = withInView(
  themed(({ cms, theme }) => {
    const { badge } = cms;

    return (
      <Box data-comp={ProductBadge.displayName} sx={theme.badge}>
        {badge}
      </Box>
    );
  }),
  { triggerOnce: true }
);

ProductBadge.displayName = 'ProductBadge';
ProductBadge.Schema = Schema;
