import { NavigationButton } from './NavigationButton';
import { themed } from './Navigation.theme';

export const Navigation = themed(({ theme, swiper }) => {
  return (
    <>
      <NavigationButton
        onClick={() => swiper.slidePrev()}
        src="/svgs/chevron/left.svg#left"
        alt="Previous product"
      />

      <NavigationButton
        onClick={() => swiper.slideNext()}
        src="/svgs/chevron/right.svg#right"
        alt="Next product"
        isNext
      />
    </>
  );
});

Navigation.displayName = 'CartUpsellNavigation';
