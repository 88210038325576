import { useMemo } from 'react';
import Head from 'next/head';
import { useShopifyClient } from '@hooks';

export function ProductHead({ product = {} }) {
  const priceValidDate = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 30);
    const isoDate = date.toISOString();
    return isoDate.split('T')[0];
  }, []);

  const inventories = useShopifyClient({ product });

  const reviewRating =
    product.metafields?.find(
      (m) => m.key === 'rating_average' && m.namespace === 'junip'
    )?.value || '0';

  const reviewCount =
    product.metafields?.find(
      (m) => m.key === 'rating_count' && m.namespace === 'junip'
    )?.value || '0';

  const showAggregate = reviewCount !== '0' && reviewCount > 0;

  const productImageURLs = product?.media
    ?.filter(
      (m) =>
        m.product.handle === product.handle && m.mediaContentType === 'IMAGE'
    )
    .map((m) => m.image.src);

  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `{
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": ${JSON.stringify(
                    process.env.NEXT_PUBLIC_SITE_TITLE || 'Home'
                  )},
                  "item": ${JSON.stringify(process.env.SITE_URL || '')}
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Shop All",
                  "item": ${JSON.stringify(
                    `${process.env.SITE_URL}/collections/shop-all`
                  )}
                },
                {
                  "@type": "ListItem",
                  "position": 3,
                  "name": "Best Sellers",
                  "item": ${JSON.stringify(
                    `${process.env.SITE_URL}/collections/best-sellers`
                  )}
                },
                {
                  "@type": "ListItem",
                  "position": 4,
                  "name": ${JSON.stringify(product.title || '')},
                  "item": ${JSON.stringify(
                    `${process.env.SITE_URL}/products/${product.handle}`
                  )}
                }
              ]
            }`,
        }}
      />

      {!!inventories && (
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `{
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": ${JSON.stringify(product.title || '')},
              "image": ${JSON.stringify(
                productImageURLs[1] ? productImageURLs[1] : productImageURLs[0]
              )},
              "description": ${JSON.stringify(product.description)},
              "brand": {
                "@type": "Brand",
                "name": "ALOHA Collection"
              },
              ${
                showAggregate
                  ? `"aggregateRating": {
                  "@type": "AggregateRating",
                  "itemReviewed": {
                    "@type": "IndividualProduct",
                    "name": ${JSON.stringify(product.title || '')},
                    "url": ${JSON.stringify(
                      `${process.env.SITE_URL}/products/${product.handle}`
                    )}
                  },
                  "ratingValue": ${reviewRating},
                  "ratingCount": ${reviewCount},
                  "reviewCount": ${reviewCount},
                  "worstRating": "0",
                  "bestRating": "5"
                },`
                  : ''
              }
              ${
                product.reviews
                  ? `"review": [
                      ${product.reviews.map(
                        ({ content, createdAt, score, user, title }) => {
                          return `{
                              "@type": "Review",
                              "author": {
                                "@type": "Person",
                                "name": ${JSON.stringify(
                                  user?.displayName || ''
                                )}
                              },
                              "datePublished": ${JSON.stringify(
                                createdAt?.split('T')[0] || ''
                              )},
                              "reviewBody": ${JSON.stringify(content || '')},
                              "name": ${JSON.stringify(title || '')},
                              "reviewRating": {
                                "@type": "Rating",
                                "bestRating": "5",
                                "ratingValue": ${JSON.stringify(
                                  score?.toFixed(0) || ''
                                )},
                                "worstRating": "1"
                              }
                            }`;
                        }
                      )}
                    ],`
                  : ''
              }
              ${
                product.variants?.length === 1
                  ? `"offers": {
                "@type": "Offer",
                "url": ${JSON.stringify(
                  `${process.env.SITE_URL}/products/${product.handle}`
                )},
                "priceCurrency": "USD",
                "price": ${JSON.stringify(
                  product.priceRange?.minVariantPrice?.amount || ''
                )},
                "availability": "https://schema.org/${
                  inventories[0]?.availableForSale ? 'InStock' : 'OutOfStock'
                }",
                "itemCondition": "https://schema.org/NewCondition",
                "priceValidUntil": ${JSON.stringify(priceValidDate || '')}
              }`
                  : `"offers": [
                ${product.variants?.map((v) => {
                  return `{
                "@type": "Offer",
                "url": ${JSON.stringify(
                  `${process.env.SITE_URL}/products/${v.product.handle}?variant=${v.legacyResourceId}`
                )},
                "priceCurrency": "USD",
                "price": ${v.price},
                "availability": "https://schema.org/${
                  inventories?.find((item) => item?.id === v.product.id)
                    ?.availableForSale
                    ? 'InStock'
                    : 'OutOfStock'
                }",
                "itemCondition": "https://schema.org/NewCondition",
                "priceValidUntil": ${JSON.stringify(priceValidDate || '')}
                }`;
                })}
              ]`
              }
            }`,
          }}
        />
      )}
    </Head>
  );
}

ProductHead.displayName = 'ProductHead';
