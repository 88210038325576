import React from 'react';
import { Box, Flex, Input, Label, Paragraph } from 'theme-ui';

import { themed } from './Radio.theme';

export const Radio = themed(
  ({ theme, checked = false, label = '', sx, sxLabel, title, ...props }) => {
    return (
      <Box data-comp={Radio.displayName} {...props}>
        <Label sx={{ ...sx, ...theme.container }}>
          <Input checked={checked} type="radio" sx={theme.input} {...props} />
          <Flex
            sx={{
              ...(!checked ? theme.checkmark : theme.checkmarkChecked),
            }}
            data-type="checkmark"
            aria-checked={checked}
            role="radio"
            tabIndex={0}
            title={title}
          />
          <Paragraph sx={{ ...sxLabel, ...theme.label }}>{label}</Paragraph>
        </Label>
      </Box>
    );
  }
);

Radio.displayName = 'Radio';
