import { create } from '@theme/create';

export const themed = create(
  new (function () {
    (this.wrapper = {
      py: [15, 18],
      maxWidth: ['auto', '874px'],
      mt: ['22px', 10],
    }),
      (this.title = {
       
        variant: 'text.h2',
        mb: [11, 15],
      });
  })()
);
