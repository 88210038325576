import { useCustomer } from '@backpackjs/storefront';
import { Container } from 'theme-ui';
import PropTypes from 'prop-types';
import { layout as layouts } from '@theme/theme.layout';
import { margin, padding } from '@settings/common';

export function Section({ section, cms, children, ...props }) {
  const customer = useCustomer();

  if (!section) {
    return children;
  }

  const exclusiveToLoggedInUsers = section?.loggedInUsersOnly;

  const layout = layouts[section.desktop?.container];
  const layoutMob = layouts[section.mobile?.container];
  const mt = margin?.value?.[section.desktop?.mt];
  const mtMob = margin?.value?.[section.mobile?.mt];
  const py = padding?.value?.[section.desktop?.py];
  const pyMob = padding?.value?.[section.mobile?.py];
  const hideOnDesktop = section?.desktop?.hide;
  const hideOnMobile = section?.mobile?.hide;
  const display = props.sx?.display || 'block';

  return (
    <Container
      data-comp={Section.displayName}
      data-comp-id={cms?.clientId || cms?.cmsId}
      {...props}
      sx={{
        bg: section.bgColor || 'transparent', // need as transparent for parallax to work
        color: section.color || 'text',
        mt: [mtMob?.[0] || 0, mt?.[1] || 0, mt?.[2] || null, mt?.[3] || null],
        py: [pyMob?.[0] || 0, py?.[1] || 0, py?.[2] || null, py?.[3] || null],
        display: [
          hideOnMobile ? 'none' : display,
          hideOnDesktop ? 'none' : display,
          hideOnDesktop ? 'none' : display,
          hideOnDesktop ? 'none' : display,
        ],
        ...(exclusiveToLoggedInUsers &&
          !customer && {
            opacity: '0.4',
            pointerEvents: 'none',
          }),
      }}
    >
      <Container
        sx={{
          position: [
            layoutMob?.position || 'relative',
            layout?.position || 'relative',
          ],
          px: [
            layoutMob?.px?.[0] || 0,
            layout?.px?.[1] || 0,
            layout?.px?.[2] || null,
            layout?.px?.[3] || null,
          ],
          mx: [layoutMob?.mx || 'auto', layout?.mx || 'auto'],
          maxWidth: [
            layoutMob?.maxWidth || 'unset',
            layout?.maxWidth || 'unset',
          ],
        }}
      >
        {children}
      </Container>
    </Container>
  );
}

Section.displayName = 'Section';

Section.propTypes = {
  cms: PropTypes.object,
  children: PropTypes.node,
};
