import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.sidebar = {
      quantity: {
        variant: 'flex.row',
        mt: 4,
        width: 'auto',
        height: '18px',
        border: 'none',
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        'input[type=number]': {
          MozAppearance: 'textfield',
        },

        item: {
          display: 'flex',
          variant: 'flex.row.center',
          width: '18px',
          height: '18px',
          border: '1px solid',
          borderColor: 'lightGray',
          borderRadius: '4px',
          cursor: 'pointer',
          transition: 'all 0.2s ease-in-out',
          ':hover': {
            borderColor: 'gray',
          },

          inputWrapper: {
            variant: 'flex.row.center.center',
            width: '30px',
            position: 'relative',
            height: '100%',
          },

          input: {
            variant: 'text.sm',
            fontSize: '10px',
            textAlign: 'center',
            border: 'none',
            width: '100%',
            p: 0,
          },

          spinner: {
            width: '20px',
            color: 'mediumGray',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },

          icon: {
            width: '10px',
            height: '10px',
          },
        },
      },
    };

    // cart empty — rendered in /cart
    this.page = {
      quantity: {
        ...this.sidebar.quantity,

        item: {
          ...this.sidebar.quantity.item,

          input: {
            ...this.sidebar.quantity.item.input,
          },

          icon: {
            ...this.sidebar.quantity.item.icon,
          },
        },
      },
    };
  })()
);
